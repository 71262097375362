import { compose, withHandlers, lifecycle } from 'recompose';
import { inject } from 'mobx-react';
import storeNames from '../stores/storeNames';
import {
  withSurnameState,
  withPhoneState,
  withPatronymicState,
  withEmailState,
  withDateState,
  withActualAddressState,
  withNameState,
  withActualRegistrationAddressState,
  withOgrnState,
  withRsState,
  withKsState,
  withBikState,
  withBankState,
  withInnState,
  withOgrNameState,
  withLegalAddressState,
  withKppState,
  withLoginState,
  withCustomerEmailState,
  withOgrnipState,
} from './FormHocs';
import { withUserData } from './hocs';
import { checkPhoneNumber } from './validators';

export const withInitializedUserDataInputs = compose(
  inject(storeNames.ProfileStore, storeNames.AccountStore),
  withUserData,
  withSurnameState,
  withPhoneState,
  withPatronymicState,
  withEmailState,
  withDateState,
  withActualAddressState,
  withNameState,
  withActualRegistrationAddressState,
  withOgrnState,
  withOgrnipState,
  withInnState,
  withBankState,
  withBikState,
  withRsState,
  withKsState,
  withOgrNameState,
  withLegalAddressState,
  withKppState,
  withLoginState,
  withCustomerEmailState,
  withHandlers(({ AccountStore }) => ({
    deleteAccountHandler: () => (i, item) => {
      AccountStore.deletePersonalAccount(i, item);
    },
    addAccountHandler: () => () => {
      const number = (AccountStore.accounts && AccountStore.accounts.length) || 0;
      const newAccount = {
        full_name: `Счет №${number + 1}`,
      };
      AccountStore.addPersonalAccount(newAccount);
    },
  })),
  withHandlers(
    ({
      ProfileStore,
      inputHandlerSurname,
      inputHandlerName,
      inputHandlerPatronymic,
      inputHandlerEmail,
      inputHandlerPhone,
      inputHandlerDate,
      inputHandlerOgrName,
      inputHandlerBank,
      inputHandlerRs,
      inputHandlerLegalAddress,
      inputHandlerKs,
      inputHandlerBik,
      inputHandlerInn,
      inputHandlerKpp,
      inputHandlerActualRegistrationAddress,
      inputHandlerOgrn,
      inputHandlerLogin,
      inputHandlerOgrnip,
      inputHandlerActualAddress,
      inputHandlerCustomerEmail,
      userData,
    }) => ({
      inputSetStore: () => (value, inputHandler, name) => {
        inputHandler(value);
        ProfileStore.setProfileStore({ [`${name}`]: value });
      },
      inputSetCustomerStore: () => (value, inputHandler, name) => {
        inputHandler(value);
        ProfileStore.setProfileCustomerStore({ [`${name}`]: value });
      },
      setInputs: () => () => {
        if (userData.customer.contact_person_sname) {
          inputHandlerSurname(userData.customer.contact_person_sname);
          ProfileStore.setProfileCustomerStore({
            [`sname`]: userData.customer.contact_person_sname,
          });
        }
        if (userData.login) {
          inputHandlerLogin(userData.login);
          ProfileStore.setProfileStore({ [`login`]: userData.login });
        }
        if (userData.customer.contact_person_fname) {
          inputHandlerName(userData.customer.contact_person_fname);
          ProfileStore.setProfileCustomerStore({
            [`fname`]: userData.customer.contact_person_fname,
          });
        }
        if (userData.customer.contact_person_mname) {
          inputHandlerPatronymic(userData.customer.contact_person_mname);
          ProfileStore.setProfileCustomerStore({
            [`mname`]: userData.customer.contact_person_mname,
          });
        }
        if (userData.customer.email) {
          inputHandlerCustomerEmail(userData.customer.email);
          ProfileStore.setProfileCustomerStore({
            [`email`]: userData.customer.email,
          });
        }
        if (userData.email) {
          inputHandlerEmail(userData.email);
          ProfileStore.setProfileStore({ [`email`]: userData.email });
        }
        if (userData.login) {
          inputHandlerLogin(userData.login);
          ProfileStore.setProfileStore({ [`login`]: userData.login });
        }
        if (userData.customer.phone) {
          inputHandlerPhone(checkPhoneNumber(userData.customer.phone));
          ProfileStore.setProfileCustomerStore({ [`phone`]: userData.customer.phone });
        }
        if (userData.birth_date) {
          inputHandlerDate(new Date(userData.birth_date));
          ProfileStore.setProfileStore({
            [`birth_date`]: new Date(userData.birth_date),
          });
        }
        if (userData.full_name || (userData.customer && userData.customer.full_name)) {
          inputHandlerOgrName(userData.full_name || userData.customer.full_name);
          ProfileStore.setProfileStore({
            [`full_name`]: userData.full_name || userData.customer.full_name,
          });
        }
        if (
          userData.bank_name ||
          (userData.customerBankInfo && userData.customerBankInfo.bank_name)
        ) {
          inputHandlerBank(userData.bank_name || userData.customerBankInfo.bank_name);
          ProfileStore.setProfileStore({
            [`bank_name`]: userData.bank_name || userData.customerBankInfo.bank_name,
          });
        }
        if (userData.rs || (userData.customerBankInfo && userData.customerBankInfo.rs)) {
          inputHandlerRs(userData.rs || userData.customerBankInfo.rs);
          ProfileStore.setProfileStore({ [`rs`]: userData.rs || userData.customerBankInfo.rs });
        }
        if (userData.post_address || (userData.customer && userData.customer.post_address)) {
          inputHandlerLegalAddress(userData.post_address || userData.customer.post_address);
          ProfileStore.setProfileStore({
            [`post_address`]: userData.post_address || userData.customer.post_address,
          });
        }
        if (userData.ks || (userData.customerBankInfo && userData.customerBankInfo.ks)) {
          inputHandlerKs(userData.ks || userData.customerBankInfo.ks);
          ProfileStore.setProfileStore({ [`ks`]: userData.ks || userData.customerBankInfo.ks });
        }
        if (userData.bik || (userData.customerBankInfo && userData.customerBankInfo.bik)) {
          inputHandlerBik(userData.bik || userData.customerBankInfo.bik);
          ProfileStore.setProfileStore({
            [`bik`]: userData.bik || userData.customerBankInfo.bik,
          });
        }
        if (userData.inn || (userData.customer && userData.customer.inn)) {
          inputHandlerInn(userData.inn || userData.customer.inn);
          ProfileStore.setProfileStore({ [`inn`]: userData.inn || userData.customer.inn });
        }
        if (userData.kpp || (userData.customer && userData.customer.kpp)) {
          inputHandlerKpp(userData.kpp || userData.customer.kpp);
          ProfileStore.setProfileStore({ [`kpp`]: userData.kpp || userData.kpp });
        }
        if (userData.post_address || (userData.customer && userData.customer.post_address)) {
          inputHandlerActualRegistrationAddress(
            userData.post_address || userData.customer.post_address,
          );
          ProfileStore.setProfileStore({
            [`post_address`]: userData.post_address || userData.customer.post_address,
          });
        }
        if (userData.post_address || (userData.customer && userData.customer.post_address)) {
          inputHandlerActualAddress(userData.post_address || userData.customer.post_address);
          ProfileStore.setProfileStore({
            [`post_address`]: userData.post_address || userData.customer.post_address,
          });
        }
        if (userData.ogrn || (userData.customer && userData.customer.ogrn)) {
          inputHandlerOgrn(userData.ogrn || userData.customer.ogrn);
          ProfileStore.setProfileStore({ [`ogrn`]: userData.ogrn || userData.customer.ogrn });
        }
        if (userData.ogrn || (userData.customer && userData.customer.ogrn)) {
          inputHandlerOgrnip(userData.ogrn || userData.customer.ogrn);
          ProfileStore.setProfileStore({ [`ogrn`]: userData.ogrn || userData.customer.ogrn });
        }
      },
    }),
  ),
  lifecycle({
    async componentDidMount() {
      const { setInputs, ProfileStore } = this.props;
      const { fetchUser } = ProfileStore;
      await fetchUser();
      // await fetchAccounts();
      setInputs();
    },
  }),
);
