import { createQueryString } from '../helpers/url';
import { getToken } from '../helpers';

export default class InquiriesCombinedApi {
  constructor(inquiriesApi, discretionApi) {
    this.inquiriesApi = inquiriesApi;
    this.discretionApi = discretionApi;
  }

  async getInquiriesDiscretionList({ limit = 15, page = 1, term = '' }) {
    const subscriptions = await this.inquiriesApi.get(
      `api/scoring/v2/service/score?${createQueryString({
        size: limit,
        page,
        order_direction: 'DESC',
        term,
      })}`,
      getToken(),
      false,
    );

    const discretion = await this.discretionApi.getDiscretion({
      external_subscriptions_ids: (subscriptions.data || []).map(({ score_id }) => score_id),
    });

    subscriptions.data = subscriptions.data.map(sub => {
      const discretionItem = discretion.result.find(({ score_id }) => score_id === sub.score_id);

      if (discretionItem) {
        return { ...sub, discretion: discretionItem, discretionEnabled: discretion.enabled };
      }

      return { ...sub, discretionEnabled: discretion.enabled };
    });

    return subscriptions;
  }
}
