import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { inject, observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Wrapper,
  TableContainer,
  Tr,
  TrTop,
  Th,
  Td,
  Thead,
  Tbody,
  ButtonEdit,
  Button,
} from '../../Customers/Table/TableStyled';
import CheckboxTable from '../../../components/CheckBoxes/CheckBoxTable';
import storeNames from '../../../stores/storeNames';
import Paragraph from '../../../components/Typography/Paragraph';
import { isAuthenticated } from '../../../services/session';
import history from '../../../history';
import { withMountState } from '../../../utils/hocs';

const Table = ({
  setCheckboxStatus,
  accounts,
  confirmed,
  fetchAccounts,
  isBusy,
  setItemId,
  toggleEditAccountPopup,
  hasMoreItemsAll,
  loginByCustomer,
  addFilter,
}) => {
  const accountsActive = accounts ? accounts.filter(item => !item.blocked) : [];
  const list = confirmed ? accountsActive : accounts;
  return (
    <Wrapper>
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => fetchAccounts()}
        hasMore={hasMoreItemsAll}
        style={{ overflow: 'auto' }}
      >
        <TableContainer>
          <Thead>
            <TrTop>
              <Th>Id</Th>
              <Th className="active" onClick={() => addFilter('customer_full_name')}>
                Клиент
              </Th>
              <Th>Id Клиента</Th>
              <Th className="active" onClick={() => addFilter('created_at')} width="200px">
                Дата создания
              </Th>
              <Th className="active" onClick={() => addFilter('full_name')}>
                Название счета
              </Th>
              <Th className="active" onClick={() => addFilter('money')}>
                Баланс, руб
              </Th>
              <Th>Период действия</Th>
              <Th className="active" onClick={() => addFilter('blocked')}>
                Статус
              </Th>
              <Th />
              <Th />
            </TrTop>
          </Thead>
          <Tbody>
            {list &&
              list.map(item => {
                const { full_name: fullName, money } = item;
                const { fname, sname, full_name, email } = item.customer;
                const customerName =
                  full_name || (fname || (sname && `${item.fname} ${item.sname}`));
                // const createdAt = moment(created_at || item.created_at).format('DD.MM.YYYY');
                const isBlocked = item.blocked;
                return (
                  <Tr key={item.id}>
                    <Td>{item.id}</Td>
                    <Td>
                      {customerName && <Paragraph>{customerName}</Paragraph>}
                      <Paragraph>{email}</Paragraph>
                    </Td>
                    <Td>{item.customer.id || ''}</Td>
                    <Td>{`${moment(item.created_at).format('DD.MM.YYYY - H:mm:ss')}`}</Td>
                    <Td>{fullName}</Td>
                    <Td>{money || 0}</Td>
                    <Td width="200px">
                      {item.from_time && item.to_time && (
                        <>
                          {moment(item.from_time).format('DD.MM.YYYY')} &#8212;{' '}
                          {moment(item.to_time).format('DD.MM.YYYY')}
                        </>
                      )}
                      {!item.from_time && item.to_time && <>&#8212; {item.to_time}</>}
                      {!item.to_time && item.from_time && <>{item.from_time} &#8212;</>}
                      {!item.to_time && !item.from_time && <>&#8212;</>}
                    </Td>
                    <Td width="140px">
                      <CheckboxTable
                        checked={!isBlocked}
                        onChange={() => setCheckboxStatus(item.id)}
                      >
                        {!isBlocked ? 'Активный' : 'Неактивный'}
                      </CheckboxTable>
                    </Td>
                    <Td width="30px">
                      <ButtonEdit
                        data-test-id="TEST_BUTTON_EDIT"
                        onClick={() => {
                          setItemId(item.id);
                          toggleEditAccountPopup();
                        }}
                      >
                        edit
                      </ButtonEdit>
                    </Td>
                    <Td width="100px">
                      <Button
                        data-test-id="TEST_BUTTON_CONNECT"
                        onClick={() => loginByCustomer(item.customer_id, email)}
                      >
                        Подключиться
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </TableContainer>
      </InfiniteScroll>
      {isBusy && <div>Идёт обновление данных</div>}
    </Wrapper>
  );
};

Table.propTypes = {
  accounts: PropTypes.array,
  setCheckboxStatus: PropTypes.func,
  confirmed: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  hasMoreItemsAll: PropTypes.bool,
  fetchAccounts: PropTypes.func.isRequired,
  addFilter: PropTypes.func,
  toggleEditAccountPopup: PropTypes.func.isRequired,
  setItemId: PropTypes.func.isRequired,
  loginByCustomer: PropTypes.func.isRequired,
};

Table.defaultProps = {
  addFilter: () => {},
  accounts: [],
  setCheckboxStatus: () => {},
  hasMoreItemsAll: undefined,
};

const enhance = compose(
  inject(storeNames.AccountStore, storeNames.ProfileStore),
  withMountState,
  observer,
  withProps(({ AccountStore, ProfileStore }) => ({
    accounts: AccountStore.allAccounts,
    isBusy: AccountStore.isBusyAll,
    fetchAllAccounts: AccountStore.fetchAllAccounts,
    addSortAndOrder: AccountStore.addSortAndOrder,
    blockAccount: AccountStore.blockAccount,
    hasMoreItemsAll: AccountStore.hasMoreItemsAll,
    getTokenBySuperAdmin: ProfileStore.getTokenBySuperAdmin,
  })),
  withState('offset', 'setOffset', 0),
  withHandlers(() => ({
    loginByCustomer: ({ AccountStore, getTokenBySuperAdmin }) => async (userId, customerName) => {
      const adminAcc = await AccountStore.fetchCustomerAdmin(userId);
      if (!adminAcc) {
        return;
      }
      await getTokenBySuperAdmin({ userId: adminAcc.id, customerName });
      if (isAuthenticated()) {
        AccountStore.addAccountValue({});
        history.replace('/profile');
      }
    },
    setCheckboxStatus: ({
      blockAccount,
      accounts,
      offset,
      fetchAllAccounts,
      setOffset,
    }) => async id => {
      const account = accounts.find(item => item.id === id);
      await blockAccount(account.id, !account.blocked, account.customer_id);
      const qnt = offset >= 15 ? offset : 15;
      const allAccounts = await fetchAllAccounts({ qnt, offset: 0 });
      setOffset(allAccounts.length);
    },
    addFilter: ({
      AccountStore,
      fetchAllAccounts,
      addSortAndOrder,
      offset,
      setOffset,
    }) => async value => {
      addSortAndOrder(value);
      const qnt = offset >= 15 ? offset : 15;
      const accounts = await fetchAllAccounts({ qnt, offset: 0, q: AccountStore.qAccount });
      setOffset(accounts.length);
    },
    fetchAccounts: ({ AccountStore, fetchAllAccounts, setOffset, offset }) => async () => {
      const accounts = await fetchAllAccounts({ offset, q: AccountStore.qAccount });
      setOffset(accounts.length);
    },
  })),
  withHandlers(({ offset }) => ({
    fetchAccountsByQuery: ({
      AccountStore,
      fetchAllAccounts,
      setOffset,
      inputData,
    }) => async () => {
      setOffset(0);
      AccountStore.addQAccount(inputData);
      const accounts = await fetchAllAccounts({ offset, q: AccountStore.qAccount });
      setOffset(accounts.length);
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { fetchAllAccounts, setOffset, offset, fetchAccountsByQuery } = this.props;
      const accounts = await fetchAllAccounts({ offset });
      setOffset(accounts.length);
      const $button = document.querySelector('[data-test-id=TEST_ID_ACCOUNTS_SEARCH]');
      if (!$button) return;
      document
        .querySelector('[data-test-id=TEST_ID_ACCOUNTS_SEARCH]')
        .addEventListener('click', fetchAccountsByQuery);
    },
    componentWillUnmount() {
      const { AccountStore } = this.props;
      AccountStore.addSortAndOrder('');
      AccountStore.deleteOrder();
    },
  }),
);

export default enhance(Table);
