import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TitleText } from '../../components/Typography/Title';
import {
  COLOR_TITLE_BLACK,
  COLOR_ACTION_BLUE,
  COLOR_WHITE,
  COLOR_BLACK_SHADOW_SMALL,
} from '../../constants/colors';
import { tabletMedia, mobileMedia } from '../../constants/styles';
import RadioInput from '../../components/Inputs/RadioInput';
import { Btn } from '../../components/Buttons/Button';
import ParagraphError from '../../components/Typography/ParagraphError';

export const Main = styled.div`
  width: 100%;
  padding: 40px 32px;
  padding-top: 0;
  min-height: calc(100vh - 88px);
  background: #f5f6f8;
  @media (max-width: ${mobileMedia}) {
    padding: 40px 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_TITLE_BLACK};
  padding-top: 20px;
  padding-bottom: 23px;
  line-height: 1.3;
`;

export const RegistrationForm = styled.form`
  max-width: 980px;
  margin: 0 auto;
  background: ${COLOR_WHITE};
  box-shadow: 0px 0px 10px ${COLOR_BLACK_SHADOW_SMALL};
  border-radius: 8px;
  padding: 40px 95px 40px 95px;
  @media (max-width: ${tabletMedia}) {
    padding: 40px 35px 40px 35px;
  }
  @media (max-width: ${mobileMedia}) {
    padding: 40px 24px 40px 24px;
    width: 100%;
  }
`;

export const RadioButtons = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    margin-left: 9px;
  }
`;

export const RadioButton = styled(RadioInput)`
  margin-right: 10px;
`;

export const RegistrationContent = styled.div`
  padding-top: 28px;
  padding-bottom: 24px;
`;

export const RegistrationFooter = styled.div`
  display: flex;
  width: 490px;
  margin: 0 auto;
  margin-bottom: 15px;
  @media (max-width: ${mobileMedia}) {
    width: 96%;
  }
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Lato;
`;

export const TextLink = styled(Link)`
  color: ${COLOR_ACTION_BLUE};
`;

export const RadioButtonWrap = styled.div`
  margin-right: 40px;
  @media (max-width: ${mobileMedia}) {
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;

export const Captcha = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

export const Button = styled(Btn)`
  padding: 11px;
  font-size: 16px;
  font-weight: 500;
  font-family: Lato;
  margin: 0 auto;
  border: 1px solid ${props => (props.disabled ? '#C2CFE0' : COLOR_ACTION_BLUE)};
  background-color: ${props => (props.disabled ? '#C2CFE0' : COLOR_ACTION_BLUE)};
  color: ${props => (props.disabled ? '#90A0B7' : COLOR_WHITE)};
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
`;

export const ParagraphErrorCaptcha = styled(ParagraphError)`
  position: absolute;
  bottom: -15px;
`;
