import { CHECK_CIRCLE_SUCCESS_ICON, ALERT_OCTAGON_ICON } from '../constants/icons';
import { POPUP_STATUS_SUCCESS, POPUP_STATUS_ERROR } from '../constants/statuses';

/**
 * Get icon by status title
 * example: 'success' status
 * @param {string} status - status title
 */
export const getStatusIcon = status => {
  if (!status) return undefined;
  switch (status.toUpperCase()) {
    case POPUP_STATUS_SUCCESS:
      return CHECK_CIRCLE_SUCCESS_ICON;
    case POPUP_STATUS_ERROR:
      return ALERT_OCTAGON_ICON;
    default:
      return undefined;
  }
};
