import { observable, decorate, action } from 'mobx';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';

export default class InquiriesStore {
  constructor(context, dialog) {
    this.context = context;
    this.dialog = dialog;
  }

  signUpForm = {};

  isBusy = false;

  isBusyInquiries = false;

  errors = null;

  inquiries = [];

  hasMoreItems = true;

  deleteInquiries() {
    this.inquiries = [];
  }

  updateInquire(idx, payload) {
    this.inquiries = [
      ...this.inquiries.map(inq => (inq.id === idx ? { ...inq, ...payload } : inq)),
    ];
  }

  updateInquireReports(idx, payload) {
    this.inquiries = [
      ...this.inquiries.map(inq =>
        inq.score_id === idx
          ? {
              ...inq,
              discretion: payload,
            }
          : inq,
      ),
    ];
  }

  setInquiriesStore(state) {
    this.signUpForm = { ...this.signUpForm, ...state };
  }

  fetchInquiries = async ({ limit = 15, page = 1, term = '' }) => {
    if (this.isBusyInquiries) {
      return this.inquiries;
    }
    this.isBusyInquiries = true;
    try {
      const response = await api.inquiriesCombined.getInquiriesDiscretionList({
        limit,
        page,
        term,
      });
      if (page === 1) {
        this.inquiries = [...response.data];
      } else {
        this.inquiries = [...this.inquiries, ...response.data];
      }
      this.hasMoreItems = response.meta.total_pages > page;
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusyInquiries = false;
    }
    return this.inquiries;
  };

  signUp = async payload => {
    this.isBusy = true;
    this.errors = null;
    const data = [
      {
        innOrOgrn: payload.ogrn,
        email: `creport@rescore.online;${payload.email}`,
      },
    ];
    try {
      const response = await api.scoringNew.createInquiry(data, getToken());
      if (response.alerts[0].message || response.code || response.code !== 200) {
        this.errors = response.alerts[0].message || response.code;
        throw new Error(response.alerts[0].message || response.code);
      } else {
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.errors;
  };
}

decorate(InquiriesStore, {
  errors: observable,
  hasMoreItems: observable,
  inquiries: observable,
  isBusy: observable,
  isBusyInquiries: observable,
  signUpForm: observable,
  updateInquire: action.bound,
  updateInquireReports: action.bound,
});
