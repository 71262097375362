import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, lifecycle, withHandlers } from 'recompose';
// eslint-disable-next-line import/no-unresolved
import { useMedia } from 'use-media';
import { useTranslation } from 'react-i18next';
import InputPhone from '../../../components/Inputs/InputPhone';
import {
  InputColumn,
  Content,
  InputWrapper,
  ImgShape,
  ColumnWrap,
  ColumnBottom,
  TextParagraph,
} from './RegistrationUsersStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import { RegistrationFooter, Text, TextLink } from '../RegistrationStyled';
import storeNames from '../../../stores/storeNames';
import InputField from '../../../components/Inputs/InputField';
import { hasValue, trimValue } from '../../../utils/validators';
import { ICON_SHAPE } from '../../../constants/icons';
import { withPasswordChangeType } from '../../../utils/hocs';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import { TEST_SIGNUP } from '../../../constants/testids';
import {
  withSurnameState,
  withPhoneState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withInnState,
  withActualRegistrationAddressState,
  withInputSetStore,
  withNewPasswordState,
  withConfirmPasswordState,
  withLoginState,
  withOgrnipState,
} from '../../../utils/FormHocs';
import { capitalizeFirstLetter } from '../../../utils/string';
import logger from '../../../logger';
import { RESCORE_TERMS_LINK } from '../../../constants/env';

const IndividualEntrepreneur = ({
  email,
  ogrnip,
  login,
  inputHandlerEmail,
  emailError,
  surname,
  surnameValid,
  patronymicValid,
  loginValid,
  emailValid,
  phoneValid,
  nameValid,
  innValid,
  actualRegistrationAddressValid,
  surnameError,
  phone,
  inputHandlerPhone,
  phoneError,
  inputHandlerSurname,
  ogrnipValid,
  patronymic,
  patronymicError,
  loginError,
  ogrnipError,
  inputHandlerOgrnip,
  inputHandlerPatronymic,
  inn,
  innError,
  inputHandlerInn,
  name,
  nameError,
  inputSetStore,
  inputHandlerName,
  actualRegistrationAddress,
  actualRegistrationAddressError,
  inputHandlerActualRegistrationAddress,
  newPassword,
  showNewPassword,
  inputHandlerNewPassword,
  toggleNewPasswordType,
  newPasswordError,
  confirmPassword,
  showConfirmPassword,
  inputHandlerConfirmPassword,
  toggleConfirmPasswordType,
  confirmPasswordError,
  inputHandlerLogin,
  checkedCheckBox,
  confirmed,
  toggleConfirmed,
  getEntity,
}) => {
  const { t } = useTranslation();
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Content>
      <InputColumn order={mobileMedia ? '1' : null}>
        <InputWrapper>
          <InputField
            placeholder={t('reg.ogrnip')}
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="1"
            name="ogrn"
            isRequired
            value={ogrnip}
            isValid={ogrnipValid}
            onChange={value => {
              if (value.length < 16) {
                inputSetStore(value, inputHandlerOgrnip, 'ogrnip');
              }
              // TODO: this length for ogrnIP => 15
              if (value.length > 14) {
                getEntity(value);
              }
            }}
          />
          <ParagraphError>{t(ogrnipError.message)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="2"
            placeholder={t('reg.inn')}
            name="inn"
            isRequired
            value={inn}
            isValid={innValid}
            onChange={value => {
              if (value.length < 13) {
                inputSetStore(value, inputHandlerInn, 'inn');
              }

              if (value.length > 9) {
                getEntity(value);
              }
            }}
          />
          <ParagraphError>{t(innError.message)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="3"
            placeholder={t('reg.surname')}
            value={surname}
            name="surname"
            isValid={surnameValid}
            onChange={value => {
              inputSetStore(value, inputHandlerSurname, 'surname');
            }}
          />
          <ParagraphError>{t(surnameError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="4"
            placeholder={t('reg.name')}
            name="name"
            value={name}
            isValid={nameValid}
            onChange={value => {
              inputSetStore(value, inputHandlerName, 'name');
            }}
          />
          <ParagraphError>{t(nameError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="5"
            placeholder={t('reg.patronymic')}
            name="patronymic"
            value={patronymic}
            isValid={patronymicValid}
            onChange={value => {
              inputSetStore(value, inputHandlerPatronymic, 'patronymic');
            }}
          />
          <ParagraphError>{t(patronymicError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="6"
            placeholder={t('recovery.emailAddress')}
            name="email"
            value={email}
            isRequired
            isValid={emailValid}
            onChange={value => {
              inputSetStore(value, inputHandlerEmail, 'email');
              if (!login || email === login) {
                inputSetStore(value, inputHandlerLogin, 'login');
              }
            }}
          />
          <ParagraphError>{t(emailError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputPhone
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="7"
            placeholder={t('reg.phone')}
            name="phone"
            value={phone}
            isValid={phoneValid}
            onChange={value => {
              inputSetStore(value, inputHandlerPhone, 'phone');
            }}
          />
          <ParagraphError>{t(phoneError)}</ParagraphError>
        </InputWrapper>
      </InputColumn>
      <InputColumn order={mobileMedia ? '3' : null}>
        <ColumnWrap>
          <ColumnBottom>
            <TextParagraph margin="10px 0 0 0" textAlign="center">
              {t('reg.descript')}
            </TextParagraph>
            <InputWrapper>
              <InputField
                placeholder={t('login.login')}
                name="login"
                value={login}
                isRequired
                isValid={loginValid}
                onChange={value => {
                  inputSetStore(value, inputHandlerLogin, 'login');
                }}
              />
              <ParagraphError>{t(loginError)}</ParagraphError>
            </InputWrapper>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="9"
                placeholder={t('login.password')}
                value={newPassword}
                type={showNewPassword}
                onChange={value => {
                  inputSetStore(value, inputHandlerNewPassword, 'newPassword');
                }}
              />
              <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} value={newPassword} />
              <ParagraphError>{t(newPasswordError)}</ParagraphError>
            </InputWrapper>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="10"
                placeholder={t('reg.repeatPass')}
                value={confirmPassword}
                type={showConfirmPassword}
                onChange={value => {
                  inputSetStore(value, inputHandlerConfirmPassword, 'confirmPassword');
                }}
              />
              <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
              <ParagraphError>{t(confirmPasswordError)}</ParagraphError>
            </InputWrapper>
          </ColumnBottom>
        </ColumnWrap>
        <RegistrationFooter>
          <InputWrapper>
            <CheckBox
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="11"
              checked={confirmed}
              testID={`${TEST_SIGNUP}_POLICY_ACCESS`}
              onChange={() => toggleConfirmed()}
            >
              <Text>
                {t('reg.accept_1')}{' '}
                <TextLink
                  to={RESCORE_TERMS_LINK}
                  target="_href"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="12"
                >
                  {t('reg.accept_2')}
                </TextLink>{' '}
                {t('reg.accept_3')}
              </Text>
            </CheckBox>
            <ParagraphError bottom="-20px">
              {checkedCheckBox === false ? 'Примите условия' : ''}
            </ParagraphError>
          </InputWrapper>
        </RegistrationFooter>
      </InputColumn>
      <InputWrapper order={mobileMedia ? '2' : null}>
        <InputField
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex="8"
          placeholder={t('reg.actualRegAddress')}
          name="actualRegistrationAddress"
          isValid={actualRegistrationAddressValid}
          value={actualRegistrationAddress}
          onChange={value => {
            inputSetStore(value, inputHandlerActualRegistrationAddress, 'postAddress');
          }}
        />
        <ParagraphError>{t(actualRegistrationAddressError)}</ParagraphError>
      </InputWrapper>
    </Content>
  );
};

IndividualEntrepreneur.propTypes = {
  surname: PropTypes.string,
  actualRegistrationAddress: PropTypes.string,
  name: PropTypes.string,
  confirmPassword: PropTypes.string,
  newPassword: PropTypes.string,
  surnameError: PropTypes.string,
  login: PropTypes.string,
  email: PropTypes.string,
  patronymic: PropTypes.string,
  showNewPassword: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  ogrnip: PropTypes.string,
  inn: PropTypes.string,
  phone: PropTypes.string,
  phoneError: PropTypes.string,
  nameError: PropTypes.string,
  actualRegistrationAddressError: PropTypes.string,
  emailError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  newPasswordError: PropTypes.string,
  patronymicError: PropTypes.string,
  loginError: PropTypes.string,
  innError: PropTypes.object,
  ogrnipError: PropTypes.object,
  ogrnipValid: PropTypes.bool,
  surnameValid: PropTypes.bool,
  patronymicValid: PropTypes.bool,
  emailValid: PropTypes.bool,
  phoneValid: PropTypes.bool,
  loginValid: PropTypes.bool,
  innValid: PropTypes.bool,
  checkedCheckBox: PropTypes.bool,
  confirmed: PropTypes.bool.isRequired,
  actualRegistrationAddressValid: PropTypes.bool,
  nameValid: PropTypes.bool,
  inputHandlerEmail: PropTypes.func,
  inputHandlerOgrnip: PropTypes.func,
  inputSetStore: PropTypes.func,
  inputHandlerLogin: PropTypes.func,
  inputHandlerInn: PropTypes.func,
  inputHandlerPhone: PropTypes.func,
  inputHandlerSurname: PropTypes.func,
  toggleConfirmed: PropTypes.func.isRequired,
  inputHandlerPatronymic: PropTypes.func,
  inputHandlerActualRegistrationAddress: PropTypes.func,
  inputHandlerName: PropTypes.func,
  inputHandlerNewPassword: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  inputHandlerConfirmPassword: PropTypes.func,
  toggleConfirmPasswordType: PropTypes.func,
  getEntity: PropTypes.func.isRequired,
};

IndividualEntrepreneur.defaultProps = {
  surname: '',
  ogrnip: '',
  inn: '',
  confirmPassword: '',
  newPassword: '',
  login: '',
  name: '',
  actualRegistrationAddress: '',
  patronymic: '',
  email: '',
  showNewPassword: '',
  showConfirmPassword: '',
  phone: '',
  surnameError: '',
  loginError: '',
  nameError: '',
  actualRegistrationAddressError: '',
  emailError: '',
  confirmPasswordError: '',
  newPasswordError: '',
  patronymicError: '',
  phoneError: '',
  innError: undefined,
  ogrnipError: undefined,
  ogrnipValid: false,
  surnameValid: false,
  checkedCheckBox: false,
  patronymicValid: false,
  emailValid: false,
  actualRegistrationAddressValid: false,
  phoneValid: false,
  innValid: false,
  loginValid: false,
  nameValid: false,
  inputHandlerSurname: () => {},
  inputHandlerActualRegistrationAddress: () => {},
  inputHandlerEmail: () => {},
  inputHandlerInn: () => {},
  inputHandlerLogin: () => {},
  inputSetStore: () => {},
  inputHandlerOgrnip: () => {},
  inputHandlerPatronymic: () => {},
  inputHandlerPhone: () => {},
  inputHandlerName: () => {},
  inputHandlerConfirmPassword: () => {},
  inputHandlerNewPassword: () => {},
  toggleNewPasswordType: () => {},
  toggleConfirmPasswordType: () => {},
};

const enchance = compose(
  inject(storeNames.RegistrationStore, storeNames.EntityStore),
  observer,
  withSurnameState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withPhoneState,
  withInnState,
  withActualRegistrationAddressState,
  withOgrnipState,
  withInputSetStore,
  withNewPasswordState,
  withConfirmPasswordState,
  withPasswordChangeType,
  withLoginState,
  withHandlers({
    getEntity: ({
      EntityStore,
      inputHandlerInn,
      inputHandlerOgrnip,
      inputSetStore,
      inputHandlerName,
      inputHandlerSurname,
      inputHandlerPatronymic,
      setInnValid,
      setInnError,
      inputHandlerActualRegistrationAddress,
    }) => async value => {
      const entity = await EntityStore.fetchEntity(trimValue(value));
      const { errors } = EntityStore;
      if (errors && errors.length) return;
      const companyData = entity.local && entity.local.ru;
      const companyName = entity.name && entity.name.local && entity.name.local.short;
      const companyAdress = entity.addresses && (entity.addresses[0].address || '');
      if (companyData) {
        inputSetStore(companyData['инн'], inputHandlerInn, 'inn');
        inputSetStore(companyData['огрн'], inputHandlerOgrnip, 'ogrnip');
      }

      if (companyAdress) {
        inputSetStore(companyAdress || '', inputHandlerActualRegistrationAddress, 'postAddress');
      }

      if (!companyName) return;

      const fullName = companyName.split('ИП ');

      if (!fullName) return;

      if (value.length !== 12) {
        setInnValid(false);
        setInnError({ code: '', message: 'reg.innIpError' });
      } else {
        setInnValid(true);
        setInnError({ code: '', message: '' });
      }

      if (companyData && companyData['инн'].length === 12) {
        setInnValid(true);
        setInnError({ code: '', message: '' });
      }

      try {
        // FIO format from response
        const [sname, fname, mname] = fullName[1].split(' ');
        inputSetStore(capitalizeFirstLetter(sname.toLowerCase()), inputHandlerSurname, 'surname');
        inputSetStore(capitalizeFirstLetter(fname.toLowerCase()), inputHandlerName, 'name');
        inputSetStore(
          capitalizeFirstLetter(mname.toLowerCase()),
          inputHandlerPatronymic,
          'patronymic',
        );
      } catch (ex) {
        logger.error(ex);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        RegistrationStore,
        inputHandlerInn,
        inputHandlerSurname,
        inputHandlerName,
        inputHandlerPatronymic,
        inputHandlerEmail,
        inputHandlerPhone,
        inputHandlerOgrnip,
        inputHandlerNewPassword,
        inputHandlerConfirmPassword,
        inputHandlerLogin,
        getEntity,
      } = this.props;
      if (RegistrationStore.signUpForm.inn) {
        inputHandlerInn(RegistrationStore.signUpForm.inn);
        getEntity(RegistrationStore.signUpForm.inn);
      }
      if (RegistrationStore.signUpForm.surname) {
        inputHandlerSurname(RegistrationStore.signUpForm.surname);
      }
      if (RegistrationStore.signUpForm.name) {
        inputHandlerName(RegistrationStore.signUpForm.name);
      }
      if (RegistrationStore.signUpForm.patronymic) {
        inputHandlerPatronymic(RegistrationStore.signUpForm.patronymic);
      }
      if (RegistrationStore.signUpForm.email) {
        inputHandlerEmail(RegistrationStore.signUpForm.email);
      }
      if (RegistrationStore.signUpForm.phone) {
        inputHandlerPhone(RegistrationStore.signUpForm.phone);
      }
      if (RegistrationStore.signUpForm.ogrnip) {
        inputHandlerOgrnip(RegistrationStore.signUpForm.ogrnip);
      }
      if (RegistrationStore.signUpForm.newPassword) {
        inputHandlerNewPassword(RegistrationStore.signUpForm.newPassword);
      }
      if (RegistrationStore.signUpForm.confirmPassword) {
        inputHandlerConfirmPassword(RegistrationStore.signUpForm.confirmPassword);
      }
      if (RegistrationStore.signUpForm.login) {
        inputHandlerLogin(RegistrationStore.signUpForm.login);
      } else if (RegistrationStore.signUpForm.email) {
        inputHandlerLogin(RegistrationStore.signUpForm.email);
      }
    },
    componentDidUpdate() {
      const {
        emailValid,
        email,
        ogrnip,
        login,
        innValid,
        ogrnipValid,
        inn,
        setIsValidIndividualEntrepreneur,
      } = this.props;
      if (
        hasValue(email) &&
        hasValue(ogrnip) &&
        hasValue(inn) &&
        hasValue(login) &&
        emailValid &&
        innValid &&
        ogrnipValid
      ) {
        setIsValidIndividualEntrepreneur(true);
      } else {
        setIsValidIndividualEntrepreneur(false);
      }
    },
  }),
);

export default enchance(IndividualEntrepreneur);
