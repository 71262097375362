import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ICON_PDF_FILE } from '../../../constants/icons';
import { TableButton } from '../Table/TableStyled';
import subscriptionsStatuses from '../../../constants/subscriptionsStatuses';

const ReportsLinks = ({ state, reports }) => {
  const { t } = useTranslation();
  console.log({ state, reports });
  if (state !== subscriptionsStatuses.classified) return null;

  const openReportsHandler = (type) => () => {
    const url = reports.find(a => a.type === type)?.pdf_url;
    if (url) {
      window.open(url);
    }
  };

  return (
    <>
      <TableButton onClick={openReportsHandler('SCORE_SHORT')} icon={ICON_PDF_FILE}>
        {t('inquiries.report1')}
      </TableButton>
      <TableButton onClick={openReportsHandler('SCORE_FULL')} icon={ICON_PDF_FILE}>
        {t('inquiries.report2')}
      </TableButton>
    </>
  );
};

ReportsLinks.propTypes = {
  state: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ReportsLinks;
