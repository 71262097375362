export const PAYMENT_SUCCESS = 'messages.PAYMENT_SUCCESS';
export const CONFIRMATION_SUCCESS = 'messages.CONFIRMATION_SUCCESS';
export const PAYMENT_WAITING = 'messages.PAYMENT_WAITING';
export const UNKNOWN_ERROR = 'messages.UNKNOWN_ERROR';
export const PAYMENT_CANCELED = 'messages.PAYMENT_CANCELED';
export const SCORING_SUCCESS = 'messages.SCORING_SUCCESS';
export const SCROTING_RESULT_WILL_BE_SEND = 'messages.SCROTING_RESULT_WILL_BE_SEND';
export const FINE = 'messages.FINE';
export const EMAIL_CONFIRMATION_WRONG_CODE = 'messages.EMAIL_CONFIRMATION_WRONG_CODE';
export const EMAIL_CONFIRMATION_NOT_FOUND = 'messages.EMAIL_CONFIRMATION_NOT_FOUND';
export const USER_NOT_FOUND = 'messages.USER_NOT_FOUND';
export const EMAIL_ERROR = 'messages.EMAIL_ERROR';
export const RECOVERY_SENDED = 'messages.RECOVERY_SENDED';
export const NEW_PASSWORD_SETTED = 'messages.NEW_PASSWORD_SETTED';
