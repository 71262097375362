import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withStateHandlers, withState, withHandlers, withProps } from 'recompose';
import { withBoolState, withFetchUserInDidMount, withUserData } from '../../utils/hocs';
import { Wrapper, Title, TopWrapper } from '../Customers/CustomersStyled';
import TopCustomersNavigation from './TopAccountsNavigation/TopAccountNavigation';
import Table from './Table/Table';
import history from '../../history';
import EditAccountPopup from './Popups/EditAccountPopup';
import storeNames from '../../stores/storeNames';
import Button from '../../components/Buttons/Button';

const SuperAccounts = ({
  confirmed,
  toggleConfirmed,
  userData,
  userIsLoading,
  isAdmin,
  inputData,
  setInputData,
  toggleEditAccountPopup,
  editAccountPopup,
  itemId,
  setItemId,
  downloadCSV,
}) => (
  <Wrapper>
    {userData && userData.id && !userIsLoading && isAdmin && (
      <>
        <TopWrapper>
          <Title as="h2">Все счета</Title>
          <Button maxWidth="280px" onClick={() => downloadCSV()}>
            Скачать таблицу
          </Button>
        </TopWrapper>
        <TopCustomersNavigation
          confirmed={confirmed}
          toggleConfirmed={toggleConfirmed}
          inputData={inputData}
          setInputData={setInputData}
        />
        <Table
          confirmed={confirmed}
          setInputData={setInputData}
          inputData={inputData}
          toggleEditAccountPopup={toggleEditAccountPopup}
          setItemId={setItemId}
        />
      </>
    )}
    {userData && userData.id && !userIsLoading && !isAdmin && history.replace('/profile')}
    {editAccountPopup === true ? (
      <EditAccountPopup toggleEditAccountPopup={toggleEditAccountPopup} id={itemId} />
    ) : null}
  </Wrapper>
);

SuperAccounts.propTypes = {
  confirmed: PropTypes.bool,
  toggleConfirmed: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  userIsLoading: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  inputData: PropTypes.string,
  setInputData: PropTypes.func,
  downloadCSV: PropTypes.func,
  toggleEditAccountPopup: PropTypes.func.isRequired,
  editAccountPopup: PropTypes.bool.isRequired,
  itemId: PropTypes.number,
  setItemId: PropTypes.func,
};

SuperAccounts.defaultProps = {
  confirmed: false,
  inputData: '',
  setInputData: () => {},
  userIsLoading: false,
  itemId: undefined,
  setItemId: () => {},
  downloadCSV: () => {},
};

const enhance = compose(
  inject(storeNames.AccountStore, storeNames.IndicatorsStore, storeNames.ProfileStore),
  withFetchUserInDidMount,
  withUserData,
  observer,
  withState('itemId', 'setItemId', undefined),
  withBoolState('editAccountPopup', false),
  withBoolState('confirmed', false),
  withProps(({ AccountStore }) => ({
    csv: AccountStore.csv,
  })),
  withStateHandlers(
    ({ inputValue = '' }) => ({
      inputData: inputValue,
    }),
    {
      setInputData: () => value => ({
        inputData: value,
      }),
    },
  ),
  withHandlers(() => ({
    downloadCSV: ({ AccountStore, IndicatorsStore }) => async () => {
      await AccountStore.downloadCSV();
      if (AccountStore.csv !== '') {
        const link = document.createElement('a');
        link.href = AccountStore.csv.url;
        link.download = 'file.csv';
        link.click();
      } else {
        IndicatorsStore.addErrorIndicators({
          message: 'Произошла ошибка.',
          type: 'error',
        });
      }
    },
  })),
);

export default enhance(SuperAccounts);
