import React from 'react';
import PropTypes from 'prop-types';
// import { inject, observer } from 'mobx-react';
// import { compose, withState, withProps, lifecycle } from 'recompose';
// import { useParams } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// import logger from '../../../logger';
import { useStyles } from './Styles/styles';
import { numSeparator } from './Utils/utils';

const FinanceTable = ({ data, yearFrom, yearTo, depth }) => {
  const classes = useStyles();
  const [openObj, setOpenObj] = React.useState({});
  const rowStyle = d => {
    if (d === 0) return classes.tableBodyRoot;
    if (d === 1) return classes.tableBody;
    return classes.tableBodyLast;
  };
  /* Object.fromEntries(Object.entries(data).map(([key, value]) => [key, false])),
  ); */
  if (typeof data !== 'object') return false;
  const years = [];
  for (let year = yearFrom; year <= yearTo; year += 1) years.push(year);
  // logger.debug('---->', Object.entries(data));
  // logger.debug('entry');
  const switcher = key => {
    setOpenObj({
      ...openObj,
      [key]: !openObj[key],
    });
    // logger.debug('switched', key);
  };
  const compNum = (a, b) => {
    if (
      typeof a[1] === 'object' &&
      typeof b[1] === 'object' &&
      Object.keys(a[1]).length &&
      Object.keys(b[1]).length
    ) {
      let x;
      let y;
      if (!('rowNum' in a[1])) {
        x = Object.entries(a[1])[0][1].rowNum;
      } else {
        x = a[1].rowNum;
      }

      if (!('rowNum' in b[1])) {
        // logger.debug('b1', b[1]);
        y = Object.entries(b[1])[0][1].rowNum;
      } else {
        y = b[1].rowNum;
      }

      if (x > y) return 1;
      if (x < y) return -1;
      return 0;
    }
    return -1;
  };
  /* const splitValue = value => {
    const val = value.toString();
    let str = ` ${val.slice(-3)}`;
    for (let i = -3; i > -val.length; i -= 3) str = ` ${val.slice(i - 3, i) + str}`;
    return str;
  }; */
  return (
    <>
      {data && Object.keys(data).length !== 0 ? (
        <Box ml={depth !== 0 ? '50px' : 0}>
          {Object.entries(data)
            .sort(compNum)
            .map((item, index) => {
              // logger.debug('ITEM:', item);
              const nextDepthLength = Object.keys(item[1]).length;
              const nextDepth =
                typeof item[1] !== 'number' &&
                (('values' in item[1] && nextDepthLength > 2) ||
                  (!('values' in item[1]) && nextDepthLength !== 0));
              return (
                <Box key={item[0]}>
                  {/* !('values' in data) && depth < 1 && !('values' in item[1]) && <>{item[0]}</> */}
                  {item[0] !== 'values' && item[0] !== 'rowNum' && (
                    <>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        {depth < 1 && index === 0 && (
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.tableHeader} style={{ width: '60px' }}>
                                №
                              </TableCell>
                              <TableCell className={classes.tableHeader}>Наименование</TableCell>
                              {years.map(year => (
                                <TableCell
                                  className={classes.tableHeader}
                                  key={year}
                                  align="right"
                                  style={{ width: '160px' }}
                                >
                                  {year}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                        )}
                        {(('rowNum' in item[1] && item[1].rowNum !== null && 'values' in item[1]) ||
                          !('rowNum' in item[1])) && (
                          <TableBody>
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              onClick={() => switcher(item[0])}
                            >
                              <TableCell
                                className={rowStyle(depth)}
                                style={{ width: '60px' }}
                                component="th"
                                scope="row"
                              >
                                {'rowNum' in item[1] ? item[1].rowNum : ''}
                              </TableCell>
                              <TableCell className={rowStyle(depth)} component="th" scope="row">
                                <div style={{ display: 'flex', paddingTop: nextDepth ? '9px' : 0 }}>
                                  <div style={{ display: 'inline-block' }}>
                                    {typeof item[0] !== 'undefined' ? item[0] : '???'}
                                  </div>
                                  {nextDepth && (
                                    <div style={{ paddingLeft: '10px' }}>
                                      {openObj[item[0]] ? <ExpandLess /> : <ExpandMore />}
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                              {'values' in item[1]
                                ? years.map(year => {
                                    // logger.debug(item[1].values);
                                    let item2 = item[1].values.find(it => Number(it.year) === year);
                                    if (!item2) item2 = { year, rowNum: null, value: '-' };
                                    // logger.debug(item2);
                                    return (
                                      <TableCell
                                        key={item2.year}
                                        align="right"
                                        style={{ width: '160px' }}
                                        className={rowStyle(depth)}
                                      >
                                        {numSeparator(item2.value)}
                                      </TableCell>
                                    );
                                  })
                                : years.map(year => (
                                    <TableCell
                                      key={year}
                                      align="right"
                                      style={{ width: '160px' }}
                                      className={rowStyle(depth)}
                                    >
                                      {!Object.keys(item[1]).length && <>-</>}
                                    </TableCell>
                                  ))}
                              {/* 'values' in item[1]
                                ? item[1].values.map(item2 => (
                                    <TableCell
                                      key={item2.year}
                                      align="right"
                                      style={{ width: '160px' }}
                                      className={rowStyle(depth)}
                                    >
                                      {item2.value}
                                    </TableCell>
                                  ))
                                : years.map(year => (
                                    <TableCell key={year} align="right" style={{ width: '160px' }} />
                                )) */}
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                      {Object.keys(item[1]).length !== 0 && (
                        <Collapse in={openObj[item[0]]} timeout="auto" unmountOnExit>
                          <FinanceTable
                            data={item[1]}
                            yearFrom={yearFrom}
                            yearTo={yearTo}
                            depth={depth + 1}
                          />
                        </Collapse>
                      )}
                    </>
                  )}
                </Box>
              );
            })}
        </Box>
      ) : (
        <Box mt={4} pl={2} className={classes.detailTitle} style={{ fontWeight: 'bold' }}>
          Ожидание загрузки...
        </Box>
      )}
    </>
  );
};
FinanceTable.propTypes = {
  data: PropTypes.object,
  yearFrom: PropTypes.number.isRequired,
  yearTo: PropTypes.number.isRequired,
  depth: PropTypes.number,
};
FinanceTable.defaultProps = {
  data: {},
  depth: 0,
};

export default FinanceTable;
