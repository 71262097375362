const percent = (num1, num2) => {
  if (num1 === 0) {
    return 0;
  }
  if (!num1 || !num2) {
    return 100;
  }
  return (num1 / num2) * 100;
};

export default percent;
