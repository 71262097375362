import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-unresolved
import { useMedia } from 'use-media';
import { compose, withHandlers, lifecycle } from 'recompose';
import { useTranslation } from 'react-i18next';
import InputPhone from '../../../components/Inputs/InputPhone';
import storeNames from '../../../stores/storeNames';
import { hasValue, trimValue } from '../../../utils/validators';
import InputField from '../../../components/Inputs/InputField';
import { ICON_SHAPE } from '../../../constants/icons';
import { withPasswordChangeType } from '../../../utils/hocs';
import {
  InputColumn,
  Content,
  InputWrapper,
  ImgShape,
  TextParagraph,
  ColumnWrap,
  ColumnBottom,
} from './RegistrationUsersStyled';
import { RegistrationFooter, Text, TextLink } from '../RegistrationStyled';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import ParagraphError from '../../../components/Typography/ParagraphError';
import { TEST_SIGNUP } from '../../../constants/testids';
import {
  withEmailState,
  withPhoneState,
  withInnState,
  withOgrNameState,
  withLegalAddressState,
  withOgrnState,
  withSurnameState,
  withNameState,
  withInputSetStore,
  withNewPasswordState,
  withConfirmPasswordState,
  withLoginState,
} from '../../../utils/FormHocs';
import { RESCORE_TERMS_LINK } from '../../../constants/env';

const Organization = ({
  surname,
  surnameValid,
  inputHandlerSurname,
  surnameError,
  name,
  nameValid,
  inputHandlerName,
  nameError,
  ogrn,
  ogrnValid,
  inputHandlerOgrn,
  ogrnError,
  email,
  login,
  innValid,
  emailValid,
  loginValid,
  phoneValid,
  ogrNameValid,
  legalAddressValid,
  inputHandlerEmail,
  emailError,
  phone,
  inputHandlerPhone,
  phoneError,
  inn,
  innError,
  inputHandlerInn,
  ogrName,
  inputHandlerOgrName,
  ogrNameError,
  loginError,
  legalAddress,
  legalAddressError,
  inputSetStore,
  inputHandlerLegalAddress,
  newPassword,
  showNewPassword,
  inputHandlerNewPassword,
  toggleNewPasswordType,
  newPasswordError,
  confirmPassword,
  showConfirmPassword,
  inputHandlerConfirmPassword,
  toggleConfirmPasswordType,
  confirmPasswordError,
  inputHandlerLogin,
  checkedCheckBox,
  confirmed,
  toggleConfirmed,
  getEntity,
}) => {
  const { t } = useTranslation();
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Content>
      <InputColumn order={mobileMedia ? '1' : null}>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="1"
            placeholder={t('reg.ogrn')}
            name="ogrn"
            value={ogrn}
            isRequired
            isValid={ogrnValid}
            onChange={value => {
              if (value.length < 14) {
                inputSetStore(value, inputHandlerOgrn, 'ogrn');
              }

              if (value.length > 12) {
                getEntity(value);
              }
            }}
          />
          <ParagraphError>{t(ogrnError.message)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="2"
            placeholder={t('reg.inn')}
            value={inn}
            name="inn"
            isRequired
            isValid={innValid}
            onChange={value => {
              if (value.length < 13) {
                inputSetStore(value, inputHandlerInn, 'inn');
              }

              if (value.length > 9) {
                getEntity(value);
              }
            }}
          />
          <ParagraphError>{t(innError.message)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="3"
            placeholder={t('reg.companyName')}
            value={ogrName}
            isValid={ogrNameValid}
            name="ogrName"
            onChange={value => {
              inputSetStore(value, inputHandlerOgrName, 'ogrName');
            }}
          />
          <ParagraphError>{t(ogrNameError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="4"
            placeholder={t('reg.contactPersonSurname')}
            value={surname}
            name="surname"
            isValid={surnameValid}
            onChange={value => {
              inputSetStore(value, inputHandlerSurname, 'surname');
            }}
          />
          <ParagraphError>{t(surnameError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="5"
            placeholder={t('reg.contactPersonName')}
            name="name"
            value={name}
            isValid={nameValid}
            onChange={value => {
              inputSetStore(value, inputHandlerName, 'name');
            }}
          />
          <ParagraphError>{t(nameError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="6"
            placeholder={t('recovery.emailAddress')}
            value={email}
            name="email"
            isRequired
            isValid={emailValid}
            onChange={value => {
              inputSetStore(value, inputHandlerEmail, 'email');
              if (!login || email === login) {
                inputSetStore(value, inputHandlerLogin, 'login');
              }
            }}
          />
          <ParagraphError>{t(emailError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputPhone
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="7"
            placeholder={t('reg.phone')}
            value={phone}
            name="phone"
            isValid={phoneValid}
            onChange={value => {
              inputSetStore(value, inputHandlerPhone, 'phone');
            }}
          />
          <ParagraphError>{t(phoneError)}</ParagraphError>
        </InputWrapper>
      </InputColumn>
      <InputColumn order={mobileMedia ? '3' : null}>
        <ColumnWrap>
          <ColumnBottom>
            <TextParagraph
              margin="10px 0 0 0"
              textAlign="center"
              fontSize={mobileMedia ? '11px' : '14px'}
              lineHeight={mobileMedia ? '18px' : '22px'}
            >
              {t('reg.descript')}
            </TextParagraph>
            <InputWrapper>
              <InputField
                tabIndex="-1"
                placeholder={t('login.login')}
                name="login"
                isRequired
                value={login}
                isValid={loginValid}
                onChange={value => {
                  inputSetStore(value, inputHandlerLogin, 'login');
                }}
              />
              <ParagraphError>{t(loginError)}</ParagraphError>
            </InputWrapper>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="10"
                placeholder={t('login.password')}
                value={newPassword}
                type={showNewPassword}
                onChange={value => {
                  inputSetStore(value, inputHandlerNewPassword, 'newPassword');
                }}
              />
              <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} value={newPassword} />
              <ParagraphError>{t(newPasswordError)}</ParagraphError>
            </InputWrapper>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="11"
                placeholder={t('reg.repeatPass')}
                value={confirmPassword}
                type={showConfirmPassword}
                onChange={value => {
                  inputSetStore(value, inputHandlerConfirmPassword, 'confirmPassword');
                }}
              />
              <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
              <ParagraphError>{t(confirmPasswordError)}</ParagraphError>
            </InputWrapper>
          </ColumnBottom>
        </ColumnWrap>
        <RegistrationFooter>
          <InputWrapper>
            <CheckBox
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="12"
              checked={confirmed}
              testID={`${TEST_SIGNUP}_POLICY_ACCESS`}
              onChange={() => toggleConfirmed()}
            >
              <Text>
                {t('reg.accept_1')}{' '}
                <TextLink
                  to={RESCORE_TERMS_LINK}
                  target="_href"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="13"
                >
                  {t('reg.accept_2')}
                </TextLink>{' '}
                {t('reg.accept_3')}
              </Text>
            </CheckBox>
            <ParagraphError bottom="-20px">
              {checkedCheckBox === false ? 'Примите условия' : ''}
            </ParagraphError>
          </InputWrapper>
        </RegistrationFooter>
      </InputColumn>
      <InputWrapper order={mobileMedia ? '2' : null}>
        <InputField
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex="8"
          placeholder={t('reg.legalAddress')}
          value={legalAddress}
          name="legalAddress"
          isValid={legalAddressValid}
          onChange={value => {
            inputSetStore(value, inputHandlerLegalAddress, 'postAddress');
          }}
        />
        <ParagraphError>{t(legalAddressError)}</ParagraphError>
      </InputWrapper>
    </Content>
  );
};

Organization.propTypes = {
  ogrn: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  legalAddress: PropTypes.string,
  ogrName: PropTypes.string,
  showNewPassword: PropTypes.string,
  inn: PropTypes.string,
  login: PropTypes.string,
  emailError: PropTypes.string,
  newPasswordError: PropTypes.string,
  ogrNameError: PropTypes.string,
  surnameError: PropTypes.string,
  nameError: PropTypes.string,
  legalAddressError: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  loginError: PropTypes.string,
  ogrnError: PropTypes.object,
  phoneError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  innError: PropTypes.object,
  inputHandlerEmail: PropTypes.func,
  inputHandlerOgrName: PropTypes.func,
  inputHandlerLegalAddress: PropTypes.func,
  toggleConfirmed: PropTypes.func.isRequired,
  inputHandlerLogin: PropTypes.func,
  inputHandlerInn: PropTypes.func,
  inputSetStore: PropTypes.func,
  inputHandlerNewPassword: PropTypes.func,
  inputHandlerPhone: PropTypes.func,
  inputHandlerSurname: PropTypes.func,
  inputHandlerOgrn: PropTypes.func,
  inputHandlerName: PropTypes.func,
  inputHandlerConfirmPassword: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  toggleConfirmPasswordType: PropTypes.func,
  getEntity: PropTypes.func.isRequired,
  innValid: PropTypes.bool,
  ogrNameValid: PropTypes.bool,
  confirmed: PropTypes.bool.isRequired,
  nameValid: PropTypes.bool,
  ogrnValid: PropTypes.bool,
  loginValid: PropTypes.bool,
  checkedCheckBox: PropTypes.bool,
  surnameValid: PropTypes.bool,
  legalAddressValid: PropTypes.bool,
  phoneValid: PropTypes.bool,
  emailValid: PropTypes.bool,
};

Organization.defaultProps = {
  email: '',
  surname: '',
  ogrn: '',
  login: '',
  name: '',
  legalAddress: '',
  inn: '',
  newPassword: '',
  confirmPassword: '',
  showNewPassword: '',
  showConfirmPassword: '',
  phone: '',
  ogrName: '',
  emailError: '',
  newPasswordError: '',
  ogrnError: undefined,
  legalAddressError: '',
  surnameError: '',
  nameError: '',
  loginError: '',
  confirmPasswordError: '',
  ogrNameError: '',
  phoneError: '',
  innError: undefined,
  innValid: false,
  loginValid: false,
  ogrnValid: false,
  phoneValid: false,
  legalAddressValid: false,
  surnameValid: false,
  nameValid: false,
  emailValid: false,
  checkedCheckBox: false,
  ogrNameValid: false,
  inputHandlerEmail: () => {},
  inputHandlerLegalAddress: () => {},
  inputHandlerNewPassword: () => {},
  inputHandlerName: () => {},
  inputHandlerConfirmPassword: () => {},
  inputHandlerOgrn: () => {},
  inputHandlerLogin: () => {},
  inputHandlerSurname: () => {},
  inputSetStore: () => {},
  inputHandlerInn: () => {},
  inputHandlerPhone: () => {},
  inputHandlerOgrName: () => {},
  toggleNewPasswordType: () => {},
  toggleConfirmPasswordType: () => {},
};

const enchance = compose(
  inject(storeNames.RegistrationStore, storeNames.EntityStore),
  observer,
  withEmailState,
  withPhoneState,
  withInnState,
  withSurnameState,
  withNameState,
  withOgrNameState,
  withLegalAddressState,
  withOgrnState,
  withInputSetStore,
  withNewPasswordState,
  withConfirmPasswordState,
  withPasswordChangeType,
  withLoginState,
  withHandlers({
    inputSetStore: ({ RegistrationStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      RegistrationStore.setRegistrationStore({ [`${name}`]: value });
    },
  }),
  withHandlers({
    getEntity: ({
      EntityStore,
      inputHandlerInn,
      inputHandlerOgrn,
      inputHandlerOgrName,
      inputHandlerLegalAddress,
      inputSetStore,
      setInnValid,
      setInnError,
    }) => async value => {
      const entity = await EntityStore.fetchEntity(trimValue(value), true);
      const { errors } = EntityStore;
      if (errors && errors.length) return;
      console.log(entity);
      const companyName = entity.name_short || entity.name_full;
      const companyAdress = '';
      inputSetStore(entity.inn, inputHandlerInn, 'inn');
      inputSetStore(entity.ogrn, inputHandlerOgrn, 'ogrn');
      inputSetStore(companyName, inputHandlerOgrName, 'ogrName');

      if (companyAdress) {
        inputSetStore(companyAdress || '', inputHandlerLegalAddress, 'postAddress');
      }

      if (value.length !== 10) {
        setInnValid(false);
        setInnError({ code: '', message: 'reg.innError' });
      } else {
        setInnValid(true);
        setInnError({ code: '', message: '' });
      }

      if (entity && entity.inn.length === 10) {
        setInnValid(true);
        setInnError({ code: '', message: '' });
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        RegistrationStore,
        inputHandlerInn,
        inputHandlerOgrName,
        inputHandlerSurname,
        inputHandlerName,
        inputHandlerEmail,
        inputHandlerPhone,
        inputHandlerNewPassword,
        inputHandlerConfirmPassword,
        inputHandlerOgrn,
        inputHandlerLogin,
        getEntity,
      } = this.props;
      if (RegistrationStore.signUpForm.inn) {
        inputHandlerInn(RegistrationStore.signUpForm.inn);
        getEntity(RegistrationStore.signUpForm.inn);
      }
      if (RegistrationStore.signUpForm.ogrName) {
        inputHandlerOgrName(RegistrationStore.signUpForm.ogrName);
      }
      if (RegistrationStore.signUpForm.surname) {
        inputHandlerSurname(RegistrationStore.signUpForm.surname);
      }
      if (RegistrationStore.signUpForm.name) {
        inputHandlerName(RegistrationStore.signUpForm.name);
      }
      if (RegistrationStore.signUpForm.email) {
        inputHandlerEmail(RegistrationStore.signUpForm.email);
      }
      if (RegistrationStore.signUpForm.phone) {
        inputHandlerPhone(RegistrationStore.signUpForm.phone);
      }
      if (RegistrationStore.signUpForm.ogrn) {
        inputHandlerOgrn(RegistrationStore.signUpForm.ogrn);
      }
      if (RegistrationStore.signUpForm.newPassword) {
        inputHandlerNewPassword(RegistrationStore.signUpForm.newPassword);
      }
      if (RegistrationStore.signUpForm.confirmPassword) {
        inputHandlerConfirmPassword(RegistrationStore.signUpForm.confirmPassword);
      }
      if (RegistrationStore.signUpForm.login) {
        inputHandlerLogin(RegistrationStore.signUpForm.login);
      } else if (RegistrationStore.signUpForm.email) {
        inputHandlerLogin(RegistrationStore.signUpForm.email);
      }
    },
    componentDidUpdate() {
      const {
        inn,
        email,
        login,
        innValid,
        emailValid,
        phoneValid,
        ogrnValid,
        ogrn,
        setIsValidOrganization,
      } = this.props;
      if (
        innValid &&
        emailValid &&
        phoneValid &&
        ogrnValid &&
        hasValue(inn) &&
        hasValue(email) &&
        hasValue(ogrn) &&
        hasValue(login)
      ) {
        setIsValidOrganization(true);
      } else {
        setIsValidOrganization(false);
      }
    },
  }),
);

export default enchance(Organization);
