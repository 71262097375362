import { action, decorate /* , observable */ } from 'mobx';
import { getI18n } from 'react-i18next';
import api from '../api';
import { getToken } from '../helpers';
import logger from '../logger';

export default class LanguageStore {
  constructor(context, dialog) {
    this.context = context;
    this.dialog = dialog;
  }

  syncLocale = async lang => {
    const token = getToken();
    if (token !== null && ['ru', 'en'].indexOf(lang) > -1) {
      const user = await api.profile.fetchUser(token);
      const resp = await api.customers.syncLocale(user.customer_id, user.id, lang, token);
      if ('statusCode' in resp && resp.statusCode > 300)
        throw new Error(`${resp.error} - ${resp.message[0].constraints.isIn}`);
    }
  };

  openSelector = async () => {
    const i18n = getI18n();
    const lng = i18n.language;
    // console.log('LNG:', lng);

    const model = [];

    /* model.push({
      type: 'LABEL',
      weight: this.entityDetail.ogrn === item.ogrn ? 'bold' : '',
      color: this.entityDetail.ogrn === item.ogrn ? typeToColor(item.type) : '',
      label: `${typeToName(item.type)}: ${item.ogrn !== null ? item.ogrn : ''} - ${
        item.original_full_name
      }`,
    }); */

    const buttons = [
      // eslint-disable-next-line
      { label: 'Русский', color: lng === 'ru' ? 'PLAIN_UNDERLINE' : 'PLAIN', event: 'ru', action: () => {} },
      // eslint-disable-next-line
      { label: 'English', color: lng === 'en' ? 'PLAIN_UNDERLINE' : 'PLAIN', event: 'en', action: () => {} },
      // eslint-disable-next-line
      // { label: '中文', color: lng === 'ch' ? 'PLAIN_UNDERLINE' : 'PLAIN', event: 'ch', action: () => {} },
    ];

    const lang = await this.dialog.show(`${i18n.t('lang.choose')}:`, {
      width: '800px',
      height: '90%',
      model,
      buttonsTop: buttons,
      // cancelText: 'Закрыть',
    });
    if (lang) {
      try {
        await this.syncLocale(lang);
        i18n.changeLanguage(lang);
      } catch (ex) {
        logger.error(ex);
      }
    }
  };
}

decorate(LanguageStore, {
  syncLocale: action,
  openSelector: action,
});
