import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useMedia } from 'use-media';
import { compose, withProps, withHandlers } from 'recompose';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ru from 'date-fns/locale/ru';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { withBoolState, withScrollToTop } from '../../utils/hocs';
import { Wrapper, Container, Main, Overlay, ScrollToTop } from './MainLayoutStyles';
import HeaderMain from './HeaderMain/HeaderMain';
import SideBarMain from './SideBarMain/SideBarMain';
import Indicator from '../../components/Indicator/Indicator';
import storeNames from '../../stores/storeNames';
import DialogPopup from '../../pages/Popups/DialogPopup';
import { COLOR_ACTION_BLUE, COLOR_RED } from '../../constants/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_ACTION_BLUE,
    },
    secondary: {
      main: COLOR_RED,
      contrastText: '#fff',
    },
  },
});

registerLocale('ru', ru);
setDefaultLocale('ru');

const MainLayoutComponent = ({
  layoutProps,
  children,
  confirmed,
  toggleConfirmed,
  toggleOpenMenu,
  openMenu,
  scrollToTop,
  scroll,
  ...restProps
}) => {
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <MuiThemeProvider theme={theme}>
      <Wrapper {...layoutProps}>
        <Indicator />
        <HeaderMain
          toggleConfirmed={toggleConfirmed}
          toggleOpenMenu={toggleOpenMenu}
          confirmed={confirmed}
        />
        <Container>
          <SideBarMain
            toggleOpenMenu={toggleOpenMenu}
            openMenu={openMenu}
            toggleConfirmed={toggleConfirmed}
            confirmed={confirmed}
          />
          <Main className={confirmed ? 'hide' : null}>{cloneElement(children, restProps)}</Main>
          <DialogPopup />
        </Container>
        {mobileMedia && openMenu && <Overlay onClick={() => toggleOpenMenu()} />}
        <ScrollToTop className={scroll && 'active'} onClick={() => scrollToTop()} />
      </Wrapper>
    </MuiThemeProvider>
  );
};

MainLayoutComponent.propTypes = {
  confirmed: PropTypes.bool,
  openMenu: PropTypes.bool,
  toggleConfirmed: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  layoutProps: PropTypes.object.isRequired,
  toggleOpenMenu: PropTypes.func,
  scrollToTop: PropTypes.func.isRequired,
  scroll: PropTypes.bool,
};

MainLayoutComponent.defaultProps = {
  confirmed: false,
  openMenu: false,
  toggleOpenMenu: () => {},
  scroll: false,
};

export const MainLayout = compose(
  inject(storeNames.IndicatorsStore),
  observer,
  withProps(({ IndicatorsStore }) => ({
    confirmed: IndicatorsStore.collapse,
  })),
  withScrollToTop,
  withBoolState('openMenu', false),
  withHandlers(() => ({
    toggleConfirmed: ({ IndicatorsStore }) => () => {
      IndicatorsStore.setCollapse();
    },
  })),
)(MainLayoutComponent);
