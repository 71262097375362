// https://github.com/Kholenkov/js-data-validation/blob/master/data-validation.js
/* eslint-disable */
export function validateBik(bik, error) {
  let result = false;
  if (typeof bik === 'number') {
    // eslint-disable-next-line no-param-reassign
    bik = bik.toString();
  } else if (typeof bik !== 'string') {
    // eslint-disable-next-line no-param-reassign
    bik = '';
  }
  if (/[^0-9]/.test(bik)) {
    // eslint-disable-next-line no-param-reassign
    error.code = 2;
    // eslint-disable-next-line no-param-reassign
    error.message = 'БИК может состоять только из цифр';
  } else if (bik.length > 0 && bik.length !== 9) {
    // eslint-disable-next-line no-param-reassign
    error.code = 3;
    // eslint-disable-next-line no-param-reassign
    error.message = 'БИК может состоять только из 9 цифр';
  } else {
    result = true;
  }
  return result;
}

export function validateInn(inn, error) {
  let result = false;
  if (typeof inn === 'number') {
    // eslint-disable-next-line no-param-reassign
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    // eslint-disable-next-line no-param-reassign
    inn = '';
  }
  if (!inn.length) {
    // eslint-disable-next-line no-param-reassign
    error.code = '0'; //1;
    // eslint-disable-next-line no-param-reassign
    error.message = 'reg.innErrEmpty';
  } else if (/[^0-9]/.test(inn)) {
    // eslint-disable-next-line no-param-reassign
    error.code = '0'; //2;
    // eslint-disable-next-line no-param-reassign
    error.message = 'reg.innErrOnlyDigits';
  } else if ([10, 12].indexOf(inn.length) === -1) {
    // eslint-disable-next-line no-param-reassign
    error.code = '0'; //3;
    // eslint-disable-next-line no-param-reassign
    error.message = 'reg.innErrOnly10Digits';
  } else {
    // eslint-disable-next-line func-names
    const checkDigit = function(inn, coefficients) {
      let n = 0;
      for (let i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      // eslint-disable-next-line radix
      return parseInt((n % 11) % 10);
    };
    switch (inn.length) {
      case 10:
        let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      case 12:
        let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
          result = true;
        }
        break;
    }
    if (!result) {
      error.code = '0'; //4;
      error.message = 'reg.checknumNotValid';
    }
  }
  return result;
}

export function validateKpp(kpp, error) {
  let result = false;
  if (typeof kpp === 'number') {
    kpp = kpp.toString();
  } else if (typeof kpp !== 'string') {
    kpp = '';
  }
  if (!kpp.length) {
    error.code = 1;
    error.message = 'КПП пуст';
  } else if (kpp.length !== 9) {
    error.code = 2;
    error.message =
      'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
  } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
    error.code = 3;
    error.message = 'Неправильный формат КПП';
  } else {
    result = true;
  }
  return result;
}

export function validateKs(ks, bik = 0, error) {
  let result = false;
  if (validateBik(bik, error)) {
    if (typeof ks === 'number') {
      ks = ks.toString();
    } else if (typeof ks !== 'string') {
      ks = '';
    }
    if (/[^0-9]/.test(ks)) {
      error.code = 2;
      error.message = 'К/С может состоять только из цифр';
    } else if (ks.length > 0 && ks.length !== 20) {
      error.code = 3;
      error.message = 'К/С может состоять только из 20 цифр';
    } else {
      let bikKs = '0' + bik.toString().slice(4, 6) + ks;
      let checksum = 0;
      let coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
      for (let i in coefficients) {
        checksum += coefficients[i] * (bikKs[i] % 10);
      }
      if (checksum % 10 === 0) {
        result = true;
      } else {
        result = true;
        // error.code = 4;
        // error.message = 'Неправильное контрольное число';
      }
    }
  }
  return result;
}

export function validateOgrn(ogrn, error) {
  let result = false;
  if (typeof ogrn === 'number') {
    ogrn = ogrn.toString();
  } else if (typeof ogrn !== 'string') {
    ogrn = '';
  }
  if (!ogrn.length) {
    error.code = '0'; //1;
    error.message = 'reg.ogrnErrEmpty';
  } else if (/[^0-9]/.test(ogrn)) {
    error.code = '0'; //2;
    error.message = 'reg.ogrnErrOnlyDigits';
  } else if (ogrn.length !== 13) {
    error.code = '0'; //3;
    error.message = 'reg.ogrnErrOnly13Digits';
  } else {
    let n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
    if (n13 === parseInt(ogrn[12])) {
      result = true;
    } else {
      error.code = 4;
      error.message = 'reg.checknumNotValid';
    }
  }
  return result;
}

export function validateOgrnip(ogrnip, error) {
  let result = false;
  if (typeof ogrnip === 'number') {
    ogrnip = ogrnip.toString();
  } else if (typeof ogrnip !== 'string') {
    ogrnip = '';
  }
  if (!ogrnip.length) {
    error.code = 1;
    error.message = 'reg.ogrnipErrEmpty';
  } else if (/[^0-9]/.test(ogrnip)) {
    error.code = 2;
    error.message = 'reg.ogrnipErrOnlyDigits';
  } else if (ogrnip.length !== 15) {
    error.code = 3;
    error.message = 'reg.ogrnipErrOnly15Digits';
  } else {
    let n15 = parseInt((parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1));
    if (n15 === parseInt(ogrnip[14])) {
      result = true;
    } else {
      error.code = 4;
      error.message = 'reg.checknumNotValid';
    }
  }
  return result;
}

export function validateRs(rs, bik = 0, error) {
  let result = false;
  if (validateBik(bik, error)) {
    if (typeof rs === 'number') {
      rs = rs.toString();
    } else if (typeof rs !== 'string') {
      rs = '';
    }
    if (/[^0-9]/.test(rs)) {
      error.code = 2;
      error.message = 'Р/С может состоять только из цифр';
    } else if (rs.length > 0 && rs.length !== 20) {
      error.code = 3;
      error.message = 'Р/С может состоять только из 20 цифр';
    } else {
      let bikRs = bik.toString().slice(-3) + rs;
      let checksum = 0;
      let coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
      for (let i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        result = true;
      } else {
        result = true;
        // error.code = 4;
        // error.message = 'Неправильное контрольное число';
      }
    }
  }
  return result;
}

export function validateSnils(snils, error) {
  let result = false;
  if (typeof snils === 'number') {
    snils = snils.toString();
  } else if (typeof snils !== 'string') {
    snils = '';
  }
  if (!snils.length) {
    error.code = 1;
    error.message = 'СНИЛС пуст';
  } else if (/[^0-9]/.test(snils)) {
    error.code = 2;
    error.message = 'СНИЛС может состоять только из цифр';
  } else if (snils.length !== 11) {
    error.code = 3;
    error.message = 'СНИЛС может состоять только из 11 цифр';
  } else {
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(snils[i]) * (9 - i);
    }
    let checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit === parseInt(snils.slice(-2))) {
      result = true;
    } else {
      error.code = 4;
      error.message = 'reg.checknumNotValid';
    }
  }
  return result;
}
