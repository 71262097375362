import { observable, decorate, action } from 'mobx';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';

export default class Accounts {
  constructor(context) {
    this.context = context;
  }

  accounts = [];

  scrollAccounts = [];

  account = [];

  allAccounts = [];

  isBusy = false;

  isBusyAll = false;

  isBusyScroll = false;

  accountValue = {};

  hasMoreItems = true;

  hasMoreItemsAll = true;

  qAccount = '';

  sort = '';

  order = '';

  csv = '';

  addSortAndOrder = value => {
    if (value === this.sort && this.order === 'ASC') {
      this.order = 'DESC';
    } else {
      this.sort = value;
      this.order = 'ASC';
    }
  };

  deleteOrder = () => {
    this.order = '';
  };

  setAccountsStore = async state => {
    this.isBusy = true;
    this.accounts = [...this.accounts, ...state];
    this.isBusy = false;
  };

  setAccountsScrollStore = async state => {
    this.isBusyScroll = true;
    this.scrollAccounts = [...this.scrollAccounts, ...state];
    this.isBusyScroll = false;
  };

  setSuperAccountsStore = async state => {
    this.isBusy = true;
    if (!state || !state.length) return;
    this.allAccounts = [...this.allAccounts, ...state];
    this.isBusy = false;
  };

  addPersonalAccount(account) {
    this.isBusy = true;
    this.accounts = [...this.accounts, account];
    this.isBusy = false;
  }

  addAccountValue = async account => {
    this.accountValue = account;
  };

  addQAccount = async value => {
    this.qAccount = value;
  };

  clearAccounts = () => {
    this.accounts = [];
    this.account = [];
    this.accountValue = {};
  };

  deletePersonalAccount = async (index, item) => {
    this.accounts = this.accounts.filter((_, i) => i !== index);

    if (!item.id) return;

    this.isBusy = true;
    try {
      const response = await api.accounts.deleteAccount(
        this.userData.customer_id,
        item.id,
        getToken(),
      );
      logger.info(response);
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
  };

  fetchCustomerAdmin = async customerId => {
    this.isBusy = true;
    let response;
    try {
      response = await api.customers.fetchCustomerAdmin(customerId, getToken());
      logger.info(response);
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return response || undefined;
  };

  blockAccount = async (id, status, customer_id) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = customer_id
        ? { customer_id }
        : await this.context.ProfileStore.fetchUser();
      const response = await api.accounts.deleteAccount(
        customerId,
        id,
        { blocked: status },
        getToken(),
      );
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'accounts.youCantBlockBaseAccount',
          type: 'error',
        });
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.accounts;
  };

  setPersonalAccountsState(account, index) {
    const personalAccount = this.accounts.map((item, i) => {
      return i === index ? account : item;
    });
    this.accounts = personalAccount;
  }

  createNewCustomerAccount = async account => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.accounts.createAccount(customerId, account, getToken());
      if (!response.message || !response.code) {
        logger.info(response);
        await this.fetchMyAccounts({ qnt: 15, offset: 0 });
        this.accounts = [...this.accounts, response];
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'accounts.accountAdded',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
      this.context.IndicatorsStore.addErrorIndicators({
        message: 'services.errorHasOccurred',
        type: 'error',
      });
    } finally {
      this.isBusy = false;
    }
    return this.accounts;
  };

  editCustomerAccount = async (account, id, customer_id = undefined, isAdmin = false) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = customer_id
        ? { customer_id }
        : await this.context.ProfileStore.fetchUser();
      const response = await api.accounts.editAccount(customerId, id, account, getToken());
      if (!response.message || !response.code) {
        logger.info(response);
        if (isAdmin) {
          const allAccounts = this.allAccounts.map(item => {
            return item.id === id ? { ...item, ...account } : item;
          });
          this.allAccounts = allAccounts;
        } else {
          const accounts = this.scrollAccounts.map(item => {
            return item.id === id ? account : item;
          });
          this.scrollAccounts = accounts;
        }
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
      this.context.IndicatorsStore.addErrorIndicators({
        message: 'accounts.accountUpdated',
        type: 'success',
      });
    }
    if (this.accountValue.id === account.id) {
      this.addAccountValue({
        ...account,
        value: account.id,
        text: account.full_name,
      });
    } else {
      this.addAccountValue({
        ...this.accountValue,
        value: this.accountValue.id,
        text: this.accountValue.full_name,
      });
    }
    return this.accounts;
  };

  fetchMyAccounts = async ({ qnt = 15, offset = 0, q }, isHardFetch = false) => {
    if (isHardFetch) {
      this.accounts = [];
    }
    if (!isHardFetch && this.isBusy) {
      return this.accounts;
    }
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.accounts.getAccounts(
        { customerId, qnt, offset, q, sort: this.sort, order: this.order },
        getToken(),
      );
      if (!response.message || !response.code) {
        if (!offset) {
          this.accounts = response;
        } else {
          this.setAccountsStore(response);
        }
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.accounts;
  };

  downloadCSV = async () => {
    this.isBusy = true;
    try {
      const response = await api.accounts.downloadCSV(getToken());
      if (response.message || response.code) {
        this.csv = '';
      } else {
        this.csv = response;
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.csv;
  };

  fetchScrollMyAccounts = async ({ qnt = 15, offset = 0, q }) => {
    if (this.isBusyScroll) {
      return this.scrollAccounts;
    }
    this.isBusyScroll = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.accounts.getAccounts(
        { customerId, qnt, offset, q, sort: this.sort, order: this.order },
        getToken(),
      );
      if (!response.message || !response.code) {
        if (!offset) {
          this.scrollAccounts = response;
        } else {
          this.setAccountsScrollStore(response);
        }
        if (response.length < qnt || !response.length) {
          this.hasMoreItems = false;
        } else {
          this.hasMoreItems = true;
        }
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusyScroll = false;
    }
    return this.scrollAccounts;
  };

  fetchMyAccount = async accountId => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.accounts.getAccount({ customerId, accountId }, getToken());
      if (!response.message || !response.code) {
        this.account = [response];
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.account;
  };

  fetchAllAccounts = async ({ qnt = 15, offset = 0, q }) => {
    if (this.isBusyAll) {
      return this.allAccounts;
    }
    this.isBusyAll = true;
    try {
      const response = await api.accounts.getAllAccounts(
        { qnt, offset, q, sort: this.sort, order: this.order },
        getToken(),
      );
      if (!response.message || !response.code) {
        if (!offset) {
          this.allAccounts = response;
        } else {
          this.setSuperAccountsStore(response);
        }
        if (response.length < qnt || !response.length) {
          this.hasMoreItemsAll = false;
        } else {
          this.hasMoreItemsAll = true;
        }
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusyAll = false;
    }
    return this.allAccounts;
  };
}

decorate(Accounts, {
  sort: observable,
  order: observable,
  errors: observable,
  qAccount: observable,
  accounts: observable,
  account: observable,
  scrollAccounts: observable,
  hasMoreItems: observable,
  csv: observable,
  hasMoreItemsAll: observable,
  accountValue: observable,
  allAccounts: observable,
  isBusy: observable,
  isBusyAll: observable,
  isBusyScroll: observable,
  setAccountsStore: action,
  setPersonalAccountsState: action,
  addPersonalAccount: action,
  deletePersonalAccount: action,
  fetchMyAccounts: action,
});
