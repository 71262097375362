import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Title } from '../../Balance/BalanceStyled';

// https://storage.yandexcloud.net/rescore-bankruptcy/rescore-bankruptcy/1089848006423.png
const EstimationBankruptcy = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={1} sx={{ textAlign: 'center' }}>
        <Title>
          {t('dashboard.bankruptcy.probability')}:{' '}
          {data.bankruptcy ? `${Number(data.bankruptcy)} %` : 'данные ожидаются'}
        </Title>
      </Box>
      <Box mb={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <img
          // src={`https://storage.yandexcloud.net/rescore-bankruptcy/rescore-bankruptcy/${data.ogrn}.png`}
          src={`https://storage.yandexcloud.net/rescore-bankruptcy/rescore-bankruptcy/${data.score_id}.png`}
          alt=""
          loading="lazy"
          style={{ width: '55%', border: '1px solid #f5f5f5' }}
        />
      </Box>
      <Typography
        variant="body1"
        color="textSecondary"
        component="div"
        style={{ textAlign: 'center' }}
      >
        {t('dashboard.bankruptcy.calcDecoding')}
      </Typography>
    </>
  );
};
EstimationBankruptcy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EstimationBankruptcy;
