import API from './base';

export default class ScoringNewApi extends API {
  /**
   *
   * @param data {{innOrOgrn: string, email: string}[]}
   * @param token {string}
   */
  createInquiry(data, token) {
    return this.post(`api/scoring/v2/service/score/entity`, data, token);
  }
}
