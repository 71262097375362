import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ICON_MENU_SUMMARY from '../../../../assets/img/icons/icon-menu-summary.svg';
import ICON_MENU_INQUIRIES from '../../../../assets/img/icons/icon-menu-inquiries.svg';
import ICON_MENU_SERVICES from '../../../../assets/img/icons/icon-menu-services.svg';
import ICON_MENU_SERVICES_ACTIVE from '../../../../assets/img/icons/icon-menu-services-active.svg';
import ICON_MENU_BALANCE from '../../../../assets/img/icons/icon-menu-balance.svg';
import ICON_MENU_BALANCE_ACTIVE from '../../../../assets/img/icons/icon-menu-balance-active.svg';
import ICON_MENU_DOCUMENTS from '../../../../assets/img/icons/icon-menu-documents.svg';
import ICON_MENU_DOCUMENTS_ACTIVE from '../../../../assets/img/icons/icon-menu-documents-active.svg';
import ICON_PROFILE from '../../../../assets/img/icons/icon-profile.svg';
import ICON_MENU_SUMMARY_ACTIVE from '../../../../assets/img/icons/icon-menu-summary-active.svg';
import ICON_MENU_INQUIRIES_ACTIVE from '../../../../assets/img/icons/icon-menu-inquiries-active.svg';
import ICON_PROFILE_ACTIVE from '../../../../assets/img/icons/icon-profile-active.svg';
import ICON_USERS from '../../../../assets/img/icons/icon-users.svg';
import ICON_USERS_ACTIVE from '../../../../assets/img/icons/icon-users-active.svg';
import ICON_ACCOUNTS from '../../../../assets/img/icons/item-accounts.svg';
import ICON_ACCOUNTS_ACTIVE from '../../../../assets/img/icons/item-accounts-active.svg';
import ICON_SHAREDINFO from '../../../../assets/img/icons/item-shared-info.svg';
import ICON_SHAREDINFO_ACTIVE from '../../../../assets/img/icons/item-shared-info-active.svg';
import { mobileMedia } from '../../../../constants/styles';

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 30px;
  border-bottom: 1px solid #ebeff2;
  @media (max-height: 700px) {
    padding-top: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    width: 100%;
  }
`;

export const WrapperSecond = styled(Wrapper)`
  border-bottom: none;
`;

export const NavigationItem = styled.li`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 26px;
  & > a {
    padding-left: 26px;
    background: url(${ICON_MENU_SUMMARY}) no-repeat center left;
    background-size: contain;
  }
  & > .active {
    background: url(${ICON_MENU_SUMMARY_ACTIVE}) no-repeat center left;
    background-size: contain;
  }
  @media (max-height: 700px) {
    margin-bottom: 15px;
  }
  @media (max-width: ${mobileMedia}) {
    margin-left: 52px;
    margin-bottom: 27px;
  }
`;

export const SummaryItem = styled(NavigationItem)``;

export const InquiriesItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_MENU_INQUIRIES}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_MENU_INQUIRIES_ACTIVE}) no-repeat center left;
  }
`;

export const ServicesItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_MENU_SERVICES}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_MENU_SERVICES_ACTIVE}) no-repeat center left;
  }
`;

export const BalanceItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_MENU_BALANCE}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_MENU_BALANCE_ACTIVE}) no-repeat center left;
  }
`;

export const DocumentsItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_MENU_DOCUMENTS}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_MENU_DOCUMENTS_ACTIVE}) no-repeat center left;
  }
`;

export const ProfileItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_PROFILE}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_PROFILE_ACTIVE}) no-repeat center left;
  }
`;

export const AccountsItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_ACCOUNTS}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_ACCOUNTS_ACTIVE}) no-repeat center left;
  }
`;

export const SharedInfoItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_SHAREDINFO}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_SHAREDINFO_ACTIVE}) no-repeat center left;
  }
`;

export const UsersItem = styled(NavigationItem)`
  & > a {
    background: url(${ICON_USERS}) no-repeat center left;
  }
  & > .active {
    background: url(${ICON_USERS_ACTIVE}) no-repeat center left;
  }
`;

export const RouterButton = styled(NavLink)`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  font-family: Lato;
  letter-spacing: 1px;
  color: #334d6e;
  cursor: pointer;
  &.hide {
    display: block;
    font-size: 0;
  }
  @media (max-width: ${mobileMedia}) {
    font-size: 16px;
    &.hide {
      font-size: 16px;
      line-height: 19px;
    }
  }
  &.active {
    color: #109cf1;
  }
  &.disabled {
    cursor: not-allowed;
    color: gray;
  }
`;
