import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import storeNames from '../../../stores/storeNames';
import { getAccessStatus } from '../../../helpers/roles';
import {
  Wrapper,
  Item,
  ItemBottom,
  Img,
  DoubleWrapper,
  Top,
  ItemWrapper,
  BottomButtonWrapper,
  Buttons,
  ButtonReturn,
} from './StatisticStyled';
import Paragraph from '../../../components/Typography/Paragraph';
import { ICON_SCORING, ICON_RUBLE } from '../../../constants/icons';
import { COLOR_LINK_BLACK, COLOR_ACTION_BLUE } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import { SUPER_ADMIN_ROLE } from '../../../constants/roles';
import history from '../../../history';
import { withUserData } from '../../../utils/hocs';

const Statistic = ({
  scoring,
  // monitoring,
  money,
  // toggleOpenReturnRequestPopup,
  toggleOpenReplenishmentAgreementPopup,
  toggleOpenReplenishmentBalancePopup,
  handleLook,
  handlePurchase,
  isUser,
  userData,
  userRoles,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <DoubleWrapper>
        <Top>
          <Item>
            <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
              {t('services.scorings_statisticTitle')}
            </Paragraph>
            <ItemBottom>
              <Img src={ICON_SCORING} />
              <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
                {scoring}
              </Paragraph>
            </ItemBottom>
          </Item>
          {/* <Item>
            <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
              Мониторинги
            </Paragraph>
            <ItemBottom>
              <Img src={ICON_MONITORING} />
              <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
                {monitoring}
              </Paragraph>
            </ItemBottom>
          </Item> */}
        </Top>
        <Buttons>
          <BottomButtonWrapper>
            <ButtonReturn data-test-id="TEST_BUTTON_LOOK" onClick={handleLook}>
              {t('balance.viewDetail')}
            </ButtonReturn>
          </BottomButtonWrapper>
          {!isUser && (
            <BottomButtonWrapper>
              <Button
                boxShadow="none"
                width="233px"
                height="42px"
                lineHeight="15px"
                testID="TEST_BUTTON_BUY"
                backgroundColor="#fff"
                textColor={COLOR_ACTION_BLUE}
                onClick={handlePurchase}
              >
                {t('services.buyServices')}
              </Button>
            </BottomButtonWrapper>
          )}
          <BottomButtonWrapper>
            <Button
              boxShadow="none"
              width="233px"
              height="42px"
              lineHeight="15px"
              backgroundColor="#fff"
              textColor={COLOR_ACTION_BLUE}
              onClick={handlePurchase}
            >
              {t('services.activatePromo')}
            </Button>
          </BottomButtonWrapper>
        </Buttons>
      </DoubleWrapper>
      <ItemWrapper>
        <Item>
          <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
            {t('services.money')}
          </Paragraph>
          <ItemBottom>
            <Img src={ICON_RUBLE} />
            <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
              {!money ? 0 : money}
            </Paragraph>
          </ItemBottom>
        </Item>
        {!isUser && (
          <Buttons>
            {/* <BottomButtonWrapper>
            <ButtonReturn
              data-test-id="TEST_BUTTON_RETURN"
              onClick={() => toggleOpenReturnRequestPopup()}
            >
              Заявка на возврат
            </ButtonReturn>
          </BottomButtonWrapper> */}

            <BottomButtonWrapper onClick={() => toggleOpenReplenishmentBalancePopup()}>
              <Button
                testID="TEST_BUTTON_REPLENISHMENT"
                width="233px"
                height="42px"
                lineHeight="15px"
                sizes={{ margin: '57px 0 0 0' }}
              >
                {t('services.topUpAccount')}
              </Button>
            </BottomButtonWrapper>
            {userData.customer_id === 0 &&
              getAccessStatus({
                userRoles,
                allowedRoles: [SUPER_ADMIN_ROLE],
              }) && (
                <BottomButtonWrapper onClick={() => toggleOpenReplenishmentAgreementPopup()}>
                  <Button
                    testID="TEST_BUTTON_REPLENISHMENT"
                    width="233px"
                    height="42px"
                    lineHeight="15px"
                    sizes={{ margin: '0 0 0 0' }}
                  >
                    {t('balance.underContract')}
                  </Button>
                </BottomButtonWrapper>
              )}
          </Buttons>
        )}
      </ItemWrapper>
    </Wrapper>
  );
};

Statistic.propTypes = {
  scoring: PropTypes.number,
  // monitoring: PropTypes.number,
  money: PropTypes.number,
  handleLook: PropTypes.func,
  handlePurchase: PropTypes.func,
  // toggleOpenReturnRequestPopup: PropTypes.func.isRequired,
  toggleOpenReplenishmentBalancePopup: PropTypes.func.isRequired,
  toggleOpenReplenishmentAgreementPopup: PropTypes.func.isRequired,
  isUser: PropTypes.bool.isRequired,
  userData: PropTypes.object,
  userRoles: PropTypes.array,
};

Statistic.defaultProps = {
  scoring: '',
  // monitoring: '',
  money: undefined,
  handleLook: () => {},
  handlePurchase: () => {},
  userData: {},
  userRoles: [],
};

const enhancer = compose(
  inject(storeNames.ServicesStore),
  observer,
  withUserData,
  withHandlers(({ ServicesStore }) => ({
    handleLook: () => () => {
      ServicesStore.setServicesTab('ActiveServices');
      history.push('/services');
    },
    handlePurchase: () => () => {
      ServicesStore.setServicesTab('Purchase');
      history.push('/services');
    },
  })),
);
export default enhancer(Statistic);
