/**
 * Юридическое лицо
 */
export const CUSTOMER_LEGAL_ENTITY = 'LEGAL_ENTITY';

/**
 * Физическое лицо
 */
export const CUSTOMER_INDIVIDUAL = 'INDIVIDUAL';

/**
 * Индивидуальный предприниматель
 */
export const CUSTOMER_SOLE_PROPRIETOR = 'SOLE_PROPRIETOR';

/**
 * Орган государственной власти
 */
export const CUSTOMER_GOVERNMENT_DEPARTMENT = 'GOVERNMENT_DEPARTMENT';

/**
 * Некоммерческая организация
 */
export const CUSTOMER_NON_PROFIT_ORG = 'NON_PROFIT_ORG';
