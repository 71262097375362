import styled from 'styled-components';
import { COLOR_WHITE, COLOR_BLACK_SHADOW_SMALL, COLOR_LINK_BLACK } from '../../constants/colors';
import { TitleText } from '../../components/Typography/Title';

export const PopupBody = styled.div`
  background-color: ${COLOR_WHITE};
  max-width: 620px;
  max-height: 420px;
  margin: 20px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 18px ${COLOR_BLACK_SHADOW_SMALL};
  border-radius: 4px;
  text-align: center;
`;

export const Container = styled.div`
  margin: 5% 0;
`;

export const Title = styled(TitleText)`
  color: ${COLOR_LINK_BLACK};
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  margin: 0 0 8px;
`;
