import * as moment from 'moment';
import API from './base';
import { getUrlToLKApi } from '../constants/api';
import { createQueryString } from '../helpers/url';

export default class ServicesApi extends API {
  getTariffs(token = null) {
    return this.get(getUrlToLKApi(`tariffs`), token, false);
  }

  getStats(customerId, accountId, token = null) {
    return this.get(
      getUrlToLKApi(`customers/${customerId}/accounts/${accountId}/facilities`),
      token,
      false,
    );
  }

  getActiveTariffs(customerId, accountId, token = null) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/accounts/${accountId}/purchases?active=true&loadRelations=tariff,fuels`,
      ),
      token,
      false,
    );
  }

  buyTariff(customerId, accountId, data, token = null) {
    return this.post(
      getUrlToLKApi(`customers/${customerId}/accounts/${accountId}/purchases`),
      data,
      token,
      false,
    );
  }

  buyCard(customerId, accountId, data, token = null) {
    return this.post(
      getUrlToLKApi(`customers/${customerId}/accounts/${accountId}/payments`),
      data,
      token,
      false,
    );
  }

  buyTariffAgreement(paymentId, data, token = null) {
    return this.put(getUrlToLKApi(`payments/${paymentId}`), data, token, false);
  }

  createTariff(data, token = null) {
    return this.post(getUrlToLKApi(`tariffs`), data, token, false);
  }

  editTariff(tariffId, data, token = null) {
    return this.put(getUrlToLKApi(`tariffs/${tariffId}`), data, token, false);
  }

  deleteTariff(tariffId, token = null) {
    return this.delete(getUrlToLKApi(`tariffs/${tariffId}`), {}, token, false);
  }

  getHistory(
    { customerId, qnt = 20, offset = 0, dateFrom, dateTo, loadRelations = 'user', order = 'DESC' },
    accountId,
    token = null,
  ) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/accounts/${accountId}/spendfuels?${createQueryString({
          qnt,
          offset,
          order,
          loadRelations,
          from_date: dateFrom ? moment(dateFrom).toISOString() : '',
          to_date: dateTo
            ? moment(dateTo)
                .add(1, 'd')
                .toISOString()
            : '',
        })}`,
      ),
      token,
      false,
    );
  }
}
