import AccountStore from './Account';
import BalanceStore from './Balance';
import CustomerStore from './Customer';
import DialogStore from './Dialog';
import DocumentsStore from './Documents';
import EntityStore from './Entity';
import IndicatorsStore from './Indicators';
import InquiriesStore from './Inquiries';
import Modals from './Modals';
import ProfileStore from './Profile';
import RecoveryStore from './Recovery';
import RegistrationStore from './Registration';
import ScoringStore from './Scoring';
import ServicesStore from './Services';
import SharedInfoStore from './SharedInfo';
import UserStore from './User';
import DashboardStore from './Dashboard';
import LanguageStore from './Language';
import API from '../api/base';

class RootStore {
  constructor() {
    // Order matters!
    // this.SessionStore = SessionStore;
    this.DialogStore = new DialogStore(this);
    this.EntityStore = new EntityStore(this, this.DialogStore);
    this.ScoringStore = new ScoringStore(this, this.DialogStore);
    this.RegistrationStore = new RegistrationStore(this, this.DialogStore);
    this.ProfileStore = new ProfileStore(this, this.DialogStore);
    this.CustomerStore = new CustomerStore(this, this.DialogStore);
    this.UserStore = new UserStore(this, this.DialogStore);
    this.AccountStore = new AccountStore(this, this.DialogStore);
    this.RecoveryStore = new RecoveryStore(this, this.DialogStore);
    this.IndicatorsStore = new IndicatorsStore(this, this.DialogStore);
    this.BalanceStore = new BalanceStore(this, this.DialogStore);
    this.ServicesStore = new ServicesStore(this, this.DialogStore);
    this.DocumentsStore = new DocumentsStore(this, this.DialogStore);
    this.InquiriesStore = new InquiriesStore(this, this.DialogStore);
    this.SharedInfoStore = new SharedInfoStore(this, this.DialogStore, this.ProfileStore);
    this.DashboardStore = new DashboardStore(this, this.DialogStore);
    this.LanguageStore = new LanguageStore(this, this.DialogStore);
    this.Modals = new Modals();

    API.setAlert(this.DialogStore.alert);
  }
}

export default new RootStore();
