import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Wrapper } from './BottomContentStyled';
// import CheckBoxes from './CheckBoxes/CheckBoxes';
import PasswordSection from './PasswordSection/PasswordSection';
import { withUserData, withFetchUserInDidMount } from '../../../utils/hocs';

const BottomContent = ({
  currentPasswordError,
  newPasswordError,
  confirmPasswordError,
  setCurrentPasswordError,
  isAdmin,
}) => (
  <Wrapper>
    {/* {!isAdmin && <CheckBoxes />} */}
    {!isAdmin && (
      <PasswordSection
        currentPasswordError={currentPasswordError}
        setCurrentPasswordError={setCurrentPasswordError}
        newPasswordError={newPasswordError}
        confirmPasswordError={confirmPasswordError}
      />
    )}
  </Wrapper>
);

BottomContent.propTypes = {
  currentPasswordError: PropTypes.string,
  setCurrentPasswordError: PropTypes.func,
  newPasswordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
};

BottomContent.defaultProps = {
  currentPasswordError: '',
  newPasswordError: '',
  confirmPasswordError: '',
  setCurrentPasswordError: () => {},
};

const enhance = compose(
  withFetchUserInDidMount,
  withUserData,
);

export default enhance(BottomContent);
