import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../services/session';
import { withUserRoles, withUserTypes } from '../../utils/hocs';
import { getAccessStatus } from '../../helpers/roles';
import { getAccessStatusByUserTypes } from '../../helpers/userTypes';

const RoleRedirectComponent = ({ allowedRoles, allowedTypes, userType, userRoles, checkType }) => {
  if (isAuthenticated() && allowedRoles && userRoles) {
    const hasRole = getAccessStatus({ allowedRoles, userRoles });
    const hasAccess = getAccessStatusByUserTypes({ allowedTypes, userType });
    if (hasRole && !checkType) {
      return <></>;
    }

    if (checkType && hasRole && hasAccess) {
      return <></>;
    }
  }
  return <Redirect to="/profile" />;
};

RoleRedirectComponent.propTypes = {
  allowedRoles: PropTypes.array,
  allowedTypes: PropTypes.array,
  userType: PropTypes.string,
  userRoles: PropTypes.array,
  checkType: PropTypes.bool,
};

RoleRedirectComponent.defaultProps = {
  allowedRoles: ['allowAll'],
  allowedTypes: ['allowAll'],
  checkType: false,
  userRoles: undefined,
  userType: undefined,
};

/**
 * Wait allowedRoles array with rolesname or something content and wait userRoles, default allowAll value
 */
export const RoleRedirect = compose(
  withUserRoles,
  withUserTypes,
)(RoleRedirectComponent);
