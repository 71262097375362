import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { useMedia } from 'use-media';
import Popup from '../../../components/Popup/Popup';
import storeNames from '../../../stores/storeNames';
import { withBoolState } from '../../../utils/hocs';
import {
  Bottom,
  ButtonDisabled,
  ButtonWrapper,
  CheckBoxWrapper,
  FormWrapper,
  InputWrapper,
  Text,
  Wrapper,
} from '../RepeatPopup/PopupStyled';
import { hasValue, trimValue } from '../../../utils/validators';
import ParagraphError from '../../../components/Typography/ParagraphError';
import Paragraph from '../../../components/Typography/Paragraph';
import InputField from '../../../components/Inputs/InputField';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import { getOrigin } from '../../../helpers/url';
import history from '../../../history';
import { COLOR_ACTION_BLUE, COLOR_LINK_BLACK } from '../../../constants/colors';
import {
  withInnState,
  withInputSetInquiriesStore,
  withOgrNameState,
  withOgrnState,
} from '../../../utils/FormHocs';
import {
  STATUS_AFTER_SCORING,
  STATUS_AFTER_SCORING_ERROR,
} from '../../../constants/statusPageUrls';
import Button from '../../../components/Buttons/Button';
import { mapErrorsByCode } from '../../../helpers/errors';

const InquiriesPopup = ({
  toggleInquiriesPopup,
  formHandler,
  inputSetStore,
  ogrn,
  ogrnValid,
  inputHandlerOgrn,
  getEntity,
  ogrnError,
  inn,
  innValid,
  inputHandlerInn,
  innError,
  ogrName,
  ogrNameValid,
  inputHandlerOgrName,
  ogrNameError,
  disabledButton,
  confirmed,
  toggleConfirmed,
  checkedCheckBox,
  newInquiries,
}) => {
  const { t } = useTranslation();
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Popup width="600px" closePopup={toggleInquiriesPopup}>
      <Wrapper>
        <Paragraph
          textColor={COLOR_LINK_BLACK}
          fontSize={mobileMedia ? '24px' : '32px'}
          lineHeight="42px"
          fontWeight="300"
        >
          {t('inquiries.requestToScoring')}
        </Paragraph>
        <FormWrapper onSubmit={formHandler}>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="1"
              placeholder={t('reg.ogrn')}
              name="ogrn"
              value={ogrn}
              isValid={ogrnValid}
              onChange={value => {
                if (value.length < 14) {
                  inputSetStore(value, inputHandlerOgrn, 'ogrn');
                }

                if (value.length > 12) {
                  getEntity(value);
                }
              }}
            />
            <ParagraphError>{t(ogrnError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="2"
              placeholder={t('reg.inn')}
              value={inn}
              name="inn"
              isValid={innValid}
              onChange={value => {
                if (value.length < 13) {
                  inputSetStore(value, inputHandlerInn, 'inn');
                }

                if (value.length > 9) {
                  getEntity(value);
                }
              }}
            />
            <ParagraphError>{t(innError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="3"
              placeholder={t('inquiries.orgName')}
              value={ogrName}
              isValid={ogrNameValid}
              name="ogrName"
              onChange={value => {
                inputSetStore(value, inputHandlerOgrName, 'ogrName');
              }}
            />
            <ParagraphError>{t(ogrNameError)}</ParagraphError>
          </InputWrapper>
          {!newInquiries && (
            <CheckBoxWrapper>
              <CheckBox
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="10"
                marginRight="12px"
                justifyContent="flex-start"
                checked={confirmed}
                testID="TEST_CONFIRMED"
                onChange={() => toggleConfirmed()}
              >
                <Text>{t('inquiries.scoreConfirm')}</Text>
              </CheckBox>
              <ParagraphError top="2px" bottom="-10px">
                {checkedCheckBox === false ? t('inquiries.scoreConfirmError') : ''}
              </ParagraphError>
            </CheckBoxWrapper>
          )}
          <Bottom>
            <ButtonWrapper>
              <ButtonDisabled
                disabled={disabledButton}
                data-test-id="TEST_BUTTON_WRITE"
                height="42px"
                lineHeight="15px"
                width="220px"
                onClick={e => formHandler(e)}
              >
                {t('inquiries.createRequest')}
              </ButtonDisabled>
              <Button
                testID="TEST_BUTTON_CANCEL"
                width="220px"
                textColor={COLOR_ACTION_BLUE}
                boxShadow="none"
                backgroundColor="#fff"
                type="submit"
                height="42px"
                lineHeight="15px"
                onClick={() => toggleInquiriesPopup()}
              >
                {t('form.cancel')}
              </Button>
            </ButtonWrapper>
          </Bottom>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

InquiriesPopup.propTypes = {
  toggleInquiriesPopup: PropTypes.func,
  formHandler: PropTypes.func,
  ogrnValid: PropTypes.bool,
  inputSetStore: PropTypes.func,
  ogrn: PropTypes.string,
  ogrnError: PropTypes.object,
  inputHandlerOgrn: PropTypes.func,
  getEntity: PropTypes.func.isRequired,
  newInquiries: PropTypes.bool.isRequired,
  inn: PropTypes.string,
  innValid: PropTypes.bool,
  inputHandlerInn: PropTypes.func,
  innError: PropTypes.object,
  ogrName: PropTypes.string,
  checkedCheckBox: PropTypes.bool,
  ogrNameValid: PropTypes.bool,
  disabledButton: PropTypes.bool,
  confirmed: PropTypes.bool.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  inputHandlerOgrName: PropTypes.func,
  ogrNameError: PropTypes.string,
};

InquiriesPopup.defaultProps = {
  toggleInquiriesPopup: () => {},
  formHandler: () => {},
  inputSetStore: () => {},
  ogrn: '',
  ogrnValid: false,
  checkedCheckBox: false,
  inputHandlerOgrn: () => {},
  ogrnError: undefined,
  inn: '',
  innValid: false,
  disabledButton: undefined,
  inputHandlerInn: () => {},
  innError: {},
  ogrName: '',
  ogrNameValid: false,
  inputHandlerOgrName: () => {},
  ogrNameError: '',
};

const enchance = compose(
  inject(
    storeNames.InquiriesStore,
    storeNames.EntityStore,
    storeNames.ProfileStore,
    storeNames.AccountStore,
  ),
  observer,
  withInnState,
  withOgrnState,
  withOgrNameState,
  withInputSetInquiriesStore,
  withState('disabledButton', 'setDisabledButton', false),
  withState('checkedCheckBox', 'setCheckBox', true),
  withBoolState('confirmed', false),
  withHandlers(() => ({
    inputSetStore: ({ InquiriesStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      InquiriesStore.setInquiriesStore({ [`${name}`]: value });
    },
    formHandler: ({
      InquiriesStore,
      ProfileStore,
      innValid,
      ogrnValid,
      setDisabledButton,
      confirmed,
      setCheckBox,
      newInquiries,
    }) => async e => {
      e.preventDefault();
      if (confirmed === false) {
        setCheckBox(false);
      } else {
        setCheckBox(true);
      }
      const signUpData = {
        inn: InquiriesStore.signUpForm.inn,
        ogrn: InquiriesStore.signUpForm.ogrn,
        ogrName: InquiriesStore.signUpForm.ogrName,
        email: ProfileStore.userData.email,
      };
      if (innValid && ogrnValid && (newInquiries || confirmed)) {
        setDisabledButton(true);
        const callbackUrl = `${getOrigin()}/`;
        const error = await InquiriesStore.signUp(signUpData);
        if (error) {
          if (['SUBSCRIPTION_ALREADY_EXISTS'].includes(error)) {
            const url = STATUS_AFTER_SCORING_ERROR(
              `${callbackUrl}requests`,
              mapErrorsByCode(error),
            );
            history.replace(url);
          } else if (error === 'SUBSCRIPTIONS_LIMIT_EXCEEDED') {
            const url = STATUS_AFTER_SCORING_ERROR(
              `${callbackUrl}services`,
              mapErrorsByCode(error),
            );
            history.replace(url);
          } else {
            const url = STATUS_AFTER_SCORING_ERROR(
              `${callbackUrl}requests`,
              mapErrorsByCode(error),
            );
            history.replace(url);
          }
        } else {
          const statusUrl = STATUS_AFTER_SCORING(`${callbackUrl}requests`);
          history.replace(statusUrl);
        }
      }
    },
    getEntity: ({
      EntityStore,
      inputHandlerInn,
      inputHandlerOgrn,
      inputHandlerOgrName,
      inputSetStore,
    }) => async value => {
      const entity = await EntityStore.fetchEntity(trimValue(value));
      const { errors } = EntityStore;
      if (errors && errors.length) return;
      if (entity) {
        inputSetStore(entity.inn, inputHandlerInn, 'inn');
        inputSetStore(entity.ogrn, inputHandlerOgrn, 'ogrn');
        inputSetStore(entity.name_short || entity.name_full, inputHandlerOgrName, 'ogrName');
      }
    },
  })),
  lifecycle({
    async componentDidMount() {
      const {
        setDisabledButton,
        id,
        inquiries,
        inputHandlerOgrn,
        inputSetStore,
        inputHandlerInn,
        inputHandlerOgrName,
        setOgrnError,
        setInnError,
      } = this.props;
      setDisabledButton(true);
      setOgrnError({ code: '', message: '' });
      setInnError({ code: '', message: '' });
      if (id) {
        const item = inquiries.find(el => el.score_id === id);
        inputSetStore(item.inn, inputHandlerInn, 'inn');
        inputSetStore(item.ogrn, inputHandlerOgrn, 'ogrn');
        inputSetStore(item.name_full, inputHandlerOgrName, 'ogrName');
      }
    },
    componentDidUpdate(prevProps) {
      const { setDisabledButton } = this.props;
      const { inn, ogrn, ogrnValid, innValid } = prevProps;
      if (
        inn !== this.props.inn ||
        ogrn !== this.props.ogrn ||
        ogrnValid !== this.props.ogrnValid ||
        innValid !== this.props.innValid
      ) {
        if (
          !hasValue(this.props.inn) ||
          !hasValue(this.props.ogrn) ||
          !this.props.ogrnValid ||
          !this.props.innValid
        ) {
          setDisabledButton(true);
        } else {
          setDisabledButton(false);
        }
      }
    },
  }),
);

export default enchance(InquiriesPopup);
