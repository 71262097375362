import { observable, decorate, action } from 'mobx';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';
import { mapErrorsByCode } from '../helpers/errors';

export default class CustomerStore {
  constructor(context) {
    this.context = context;
  }

  token = null;

  сustomers = [];

  isBusy = false;

  inquiries = [];

  email = '';

  hasMoreItems = true;

  qCustomer = '';

  sort = '';

  order = '';

  count = '';

  addSortAndOrder = value => {
    if (value === this.sort && this.order === 'ASC') {
      this.order = 'DESC';
    } else {
      this.sort = value;
      this.order = 'ASC';
    }
  };

  deleteOrder = () => {
    this.order = '';
  };

  setCustomersEmail(value) {
    this.email = value;
  }

  addQCustomer = async value => {
    this.qCustomer = value;
  };

  async setCustomersStatus(client) {
    try {
      const response = await api.customers.setUserBlocked(
        { customerId: client.customer_id, userId: client.id, blocked: client.blocked },
        getToken(),
      );

      const сustomers = this.сustomers.map(item => {
        return item.id === client.id ? client : item;
      });
      this.сustomers = сustomers;

      logger.info(response);
    } catch (ex) {
      logger.error(ex);
    }
    return this.сustomers;
  }

  setInquiriesStatus(client) {
    const inquiries = this.inquiries.map(item => {
      return item.id === client.id ? client : item;
    });
    this.inquiries = inquiries;
  }

  setInquiriesExpired(client) {
    const inquiries = this.inquiries.map(item => {
      return item.id === client.id ? client : item;
    });
    this.inquiries = inquiries;
  }

  setInquiriesStore(state) {
    this.inquiries = [...this.inquiries, ...state];
  }

  fetchCustomerAdmins = async ({ qnt = 15, offset = 0, q }) => {
    if (this.isBusy) {
      return this.сustomers;
    }
    this.isBusy = true;
    try {
      const response = await api.customers.fetchCustomerAdmins(
        { qnt, offset, q, sort: this.sort, order: this.order },
        getToken(),
      );
      if (!offset) {
        this.сustomers = response;
      } else {
        this.сustomers = [...this.сustomers, ...response];
      }
      if (response.length < qnt || !response.length) {
        this.hasMoreItems = false;
      } else {
        this.hasMoreItems = true;
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.сustomers;
  };

  getCount = async () => {
    try {
      const response = await api.customers.getCount(getToken());
      if (!response.message || !response.code) {
        this.count = response;
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    }
    return this.stats;
  };

  createUser = async userData => {
    this.isBusy = true;
    logger.info(userData);
    try {
      const { access_token, code, statusCode, validationErrors } = await api.customers.createUser(
        userData,
        getToken(),
      );
      logger.info(access_token, code, statusCode, validationErrors);
      if (validationErrors || code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(validationErrors || code),
          type: 'error',
        });
        throw new Error(mapErrorsByCode(validationErrors || code));
      } else {
        this.token = access_token;
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.token;
  };
}

decorate(CustomerStore, {
  sort: observable,
  order: observable,
  email: observable,
  qCustomer: observable,
  hasMoreItems: observable,
  token: observable,
  errors: observable,
  isBusy: observable,
  сustomers: observable,
  inquiries: observable,
  count: observable,
  fetchCustomerAdmins: action,
});
