import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLOR_WHITE } from '../../constants/colors';

export const TitleText = styled.h1`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.2;
  color: ${props => (props.textColor ? props.textColor : COLOR_WHITE)};
`;

const Title = ({ as = 'h1', children, textColor }) => (
  <TitleText textColor={textColor} as={as}>
    {children}
  </TitleText>
);

Title.propTypes = {
  as: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]).isRequired,
};

Title.defaultProps = {
  as: 'h1',
  textColor: undefined,
};

export default Title;
