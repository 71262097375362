import * as numeral from 'numeral';

/* export const numSeparator = value => {
  const val = value.toString();
  let str = ` ${val.slice(-3)}`;
  for (let i = -3; i > -val.length; i -= 3) str = ` ${val.slice(i - 3, i) + str}`;
  return str;
}; */

export const numSeparator = (value, integer = false) => {
  return Number.isNaN(Number(value)) ? '-' : numeral(value).format(integer ? '0,0' : '0,0.00');
  /* : Number(value)
        .toFixed(Number.isInteger(value) ? 0 : 2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' '); */
};

export const prettyAddress = str => {
  if (str === null) return '';
  return str
    .split(',')
    .map(item => item.trim())
    .reduce(
      (prev, item) => (item.length === 0 ? prev : `${prev}${prev.length === 0 ? '' : ', '}${item}`),
      '',
    );
};

export const upperCaseFirst = str => {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
};

export const prettyCurrency = curr => {
  return curr === 'RUB' ? 'руб' : curr;
};
