import API from './base';
import { getUrlToLKApi } from '../constants/api';
import { createQueryString } from '../helpers/url';

export default class UsersApi extends API {
  getCustomerUsers(
    { customerId, qnt = 20, offset = 0, q = '', loadRelations = 'account', sort = '', order = '' },
    token = null,
  ) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/users?${createQueryString({
          qnt,
          offset,
          q,
          loadRelations,
          sort,
          order,
        })}`,
      ),
      token,
      false,
    );
  }

  getCustomer({ customerId, userId, loadRelations = 'account' }, token = null) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/users/${userId}?${createQueryString({
          loadRelations,
        })}`,
      ),
      token,
      false,
    );
  }

  createCustomerUser(customerId, data, token = null) {
    return this.post(getUrlToLKApi(`customers/${customerId}/users`), data, token, false);
  }

  editCustomerUser(customerId, userId, data, token = null) {
    return this.put(getUrlToLKApi(`customers/${customerId}/users/${userId}`), data, token, false);
  }

  deleteUser(customerId, userId, data, token = null) {
    return this.put(
      getUrlToLKApi(`customers/${customerId}/users/${userId}/setBlocked`),
      data,
      token,
      false,
    );
  }
}
