import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';

/* eslint-disable import/prefer-default-export, no-useless-escape */
export const checkIsEmailValid = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const hasValue = value => {
  if (typeof value === 'string') {
    const str = value.trim();
    return !!str.length;
  }
  return !isNil(value) || !!value;
};

export const trimValue = value => {
  if (isNil(isEmpty(trim(`${value}`)))) return '';
  const str = trim(`${value}`);
  return str;
};

export const toLowerCaseAndTrim = value => {
  if (isNil(isEmpty(trim(`${value}`)))) return '';
  const str = trimValue(value);
  return str.toLowerCase();
};

export const getEmailEqualMessage = ({ email, emailAgain }) => {
  if (String(toLowerCaseAndTrim(email)) !== String(toLowerCaseAndTrim(emailAgain))) {
    return 'Проверьте правильность ввода, адреса эл. почты не совпадают. ';
  }
  return '';
};

export const checkPhoneNumber = number => {
  if (number !== null && number) {
    const result = number.split('');
    if (result[0] === '8') {
      result.splice(0, 1);
      result.unshift('+', '7');
      return result.join('');
    }
    if (result[0] === '+' && result[2] === '8') {
      result.splice(4, 1, '9');
      result.splice(2, 2);
      result.splice(5, 2);
      result.splice(8, 1);
      result.splice(10, 1);
      return result.join('');
    }
    if (result[2] === ' ' && result[4] === '8') {
      result.splice(4, 1, '9');
      result.splice(2, 2);
      result.splice(5, 2);
      result.splice(8, 1);
      result.splice(10, 1);
      return result.join('');
    }
    if (result[0] === '+' && result[2] === '7') {
      result.splice(4, 1, '9');
      result.splice(2, 2);
      result.splice(5, 2);
      result.splice(8, 1);
      result.splice(10, 1);
      return result.join('');
    }
    if (result[2] === ' ' && result[4] === '7') {
      result.splice(4, 1, '9');
      result.splice(2, 2);
      result.splice(5, 2);
      result.splice(8, 1);
      result.splice(10, 1);
      return result.join('');
    }
    if (result[2] === ' ') {
      result.splice(2, 2);
      result.splice(5, 2);
      result.splice(8, 1);
      result.splice(10, 1);
      return result.join('');
    }
  }
  return number;
};
