export const Acts = [
  {
    id: 0,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 1,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 2,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 3,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 4,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 5,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 6,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 7,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 8,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 9,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 10,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 11,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 12,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 13,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 14,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 15,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 16,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 17,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 0,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 1,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 2,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 3,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 4,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 5,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 6,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 7,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 8,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 9,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 10,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 11,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 12,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 13,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 14,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 15,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 16,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 17,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
];
export const Accounts = [
  {
    id: 30,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 31,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 32,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 33,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 34,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 35,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 36,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 37,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 38,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 39,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 40,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 41,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 42,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 43,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 44,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 45,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 46,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 47,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 30,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 31,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 32,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 33,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 34,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 35,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 36,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 37,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 38,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 39,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 40,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 41,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 42,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 43,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 44,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 45,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 46,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
  {
    id: 47,
    number: 30300,
    format: 'pdf',
    size: '54kb',
  },
];
