import styled from 'styled-components';
import { COLOR_WHITE } from '../../constants/colors';
import { mobileMedia } from '../../constants/styles';
import { ICON_UP } from '../../constants/icons';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  background: #f5f6f8;
`;

export const Container = styled.div`
  padding: 40px 32px 40px 0;
  display: flex;
  align-items: stretch;
  @media (max-width: ${mobileMedia}) {
    padding: 40px 0 0 0;
  }
`;

export const Main = styled.div`
  background: ${COLOR_WHITE};
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 19px;
  padding-bottom: 60px;
  width: calc(100% - 289px);
  margin-left: 289px;
  min-height: 600px;
  &.hide {
    display: block;
    margin-left: 100px;
  }
  &.hide {
    width: calc(100% - 100px);
  }
  @media (max-width: 1300px) {
    margin-left: 210px;
    width: calc(100% - 210px);
  }
  @media (max-width: ${mobileMedia}) {
    margin-left: 0px;
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background: #000;
  opacity: 0.2;
  z-index: 10;
  height: 150%;
`;

export const ScrollToTop = styled.button`
  display: none;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  position: fixed;
  border: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.13);
  bottom: 10px;
  right: 30px;
  cursor: pointer;
  font-size: 0;
  outline: none;
  background: #fff url(${ICON_UP}) no-repeat center;
  &.active {
    display: block;
  }
`;
