import styled from 'styled-components';
import { TitleText } from '../../../components/Typography/Title';
import { COLOR_TITLE_BLACK, COLOR_LINK_BLACK, COLOR_WHITE } from '../../../constants/colors';
import { mobileMedia } from '../../../constants/styles';

export const Title = styled(TitleText)`
  font-size: 32px;
  text-align: center;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-bottom: 25px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Wrapper = styled.div`
  padding: 43px 50px 54px;
  @media (max-width: ${mobileMedia}) {
    padding: 43px 25px 54px;
  }
`;

export const FormWrapper = styled.form`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: ${mobileMedia}) {
    width: 100%;
  }
`;

export const DateContent = styled.div``;

export const ImgShape = styled.img`
  position: absolute;
  cursor: pointer;
  right: 0;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;

export const DateInputWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  @media (max-width: ${mobileMedia}) {
    font-size: 15px;
  }
`;

export const PlaceHolder = styled.div`
  position: absolute;
  font-size: 14px;
  line-height: 14px;
  margin-left: 0px;
  margin-top: -5px;
  color: #90a0b7;
  background: ${COLOR_WHITE};
  z-index: 1;
  padding-left: 3px;
  padding-right: 3px;
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const TopText = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 2px;
`;

export const DiscText = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 2px;
  color: #90a0b7;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 43px;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    margin-top: 35px;
    align-items: center;
    button {
      margin-top: 20px;
    }
  }
`;
