import styled from 'styled-components';
import { TitleText } from '../../components/Typography/Title';
import {
  COLOR_TITLE_BLACK,
  COLOR_RED,
  COLOR_LINK_BLACK,
  COLOR_ACTION_BLUE,
} from '../../constants/colors';
import { ICON_CARD_CHECK, ICON_MINUS, ICON_PLUS } from '../../constants/icons';
import { mobileMedia } from '../../constants/styles';

export const Wrapper = styled.div`
  padding: 43px 50px 54px;
  @media (max-width: ${mobileMedia}) {
    padding: 43px 15px 54px;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 25px;
  position: relative;
  width: 362px;
  @media (max-width: ${mobileMedia}) {
    width: 100%;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 28px;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileMedia}) {
    max-width: 365px;
    margin: 0 auto;
  }
`;

export const TextArea = styled.textarea`
  margin-top: 25px;
  width: 100%;
  min-height: 155px;
  padding: 18px 14px;
  border-radius: 4px;
  border: 1px solid #abb1ba;
`;

export const Title = styled(TitleText)`
  max-width: ${props => (props.width ? props.width : '415px')};
  margin: 0 ${props => (props.align === 'left' || props.align === 'right' ? 0 : 'auto')};
  font-size: 32px;
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-bottom: 12px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 82px;
`;

export const MethodsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`;

export const Img = styled.img`
  opacity: ${props => (props.disabled ? '0.3' : '1')};
`;

export const CardMethod = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.disabled ? '0.3' : '1')};
  cursor: ${props => (props.disabled ? '' : 'pointer')};
  background: #fff;
  justify-content: space-between;
  border: 2px solid #90a0b7;
  width: 161px;
  height: 161px;
  border-radius: 4px;
  padding: 25px 3px;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border: 1px solid #c2cfe0;
    border-radius: 100%;
    top: 9px;
    right: 9px;
  }
  &.active {
    cursor: pointer;
    border: 2px solid #109cf1;
    &:before {
      background: url(${ICON_CARD_CHECK});
      border: none;
    }
  }
  @media (max-width: ${mobileMedia}) {
    width: 154px;
    height: 154px;
  }
`;

export const TariffName = styled.p`
  text-align: center;
  color: ${COLOR_TITLE_BLACK};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 21px;
  font-family: Lato;
`;

export const TariffContainer = styled.div`
  width: 560px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  margin-bottom: 36px;
  margin-top: 21px;
`;

export const Scoring = styled.div`
  font-family: Lato;
  font-weight: 500;
  flex-shrink: 0;
  color: #90a0b7;
  font-size: 18px;
  line-height: 18px;
`;

export const Scorings = styled.div`
  display: flex;
  align-items: center;
`;

export const ScoringScore = styled.div`
  color: ${COLOR_RED};
  font-size: 40px;
  font-family: Lato;
  line-height: 42px;
  font-weight: 700;
  margin-right: 7px;
`;

export const ScoringRight = styled.div``;

export const Top = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: center;
`;

export const ScoringTop = styled.div`
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: ${COLOR_LINK_BLACK};
`;

export const ScoringTime = styled.div`
  font-family: Lato;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #707683;
`;

export const MonitoringWrapper = styled.div`
  font-family: Lato;
  font-weight: 500;
  color: #90a0b7;
  font-size: 18px;
  line-height: 18px;
`;

export const ScoringWrapper = styled.div`
  font-family: Lato;
  font-weight: 500;
  color: #90a0b7;
  font-size: 18px;
  line-height: 18px;
  margin-right: 50px;
`;

export const Monitoring = styled.div`
  display: flex;
  align-items: center;
`;

export const MonitoringScore = styled.div`
  color: ${COLOR_ACTION_BLUE};
  font-size: 40px;
  font-family: Lato;
  font-weight: 500;
  line-height: 42px;
  margin-right: 8px;
`;

export const MonitoringRight = styled.div``;

export const MonitoringTop = styled.div`
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: ${COLOR_LINK_BLACK};
`;

export const MonitoringTime = styled.div`
  font-family: Lato;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #707683;
`;

export const Bottom = styled.div`
  background: #fafafa;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 68px;
  padding-right: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled.div`
  color: ${COLOR_LINK_BLACK};
  font-size: 24px;
  font-weight: 600;
  font-family: Lato;
`;

export const Controls = styled.div`
  min-width: 120px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
`;

export const Minus = styled.div`
  width: 50px;
  height: 100%;
  cursor: pointer;
  background: url(${ICON_MINUS}) no-repeat center;
`;

export const Plus = styled.div`
  width: 50px;
  height: 100%;
  cursor: pointer;
  background: url(${ICON_PLUS}) no-repeat center;
`;
export const Count = styled.div`
  color: ${COLOR_LINK_BLACK};
  font-weight: 600;
  font-family: Lato;
  font-size: 24px;
  line-height: 29px;
`;

export const Result = styled.div`
  color: ${COLOR_LINK_BLACK};
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  display: flex;
`;

export const ResultPrice = styled.div`
  color: ${COLOR_LINK_BLACK};
  font-family: Lato;
  font-weight: 600;
  font-size: 24px;
  margin-left: 7px;
`;
