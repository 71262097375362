import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, lifecycle, withProps, withHandlers } from 'recompose';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import storeNames from '../../../stores/storeNames';
import { Accounts } from '../DocumentsMockData';
import { Wrapper, Item, Left, Right, TableButton } from './TableStyled';
import Parapgraph from '../../../components/Typography/Paragraph';

const TableAccounts = ({ accounts, itemsLength, setItemsLength, setItemId, setHasMoreItems }) => {
  let items = accounts.slice(0, itemsLength);
  const fetchMoreData = () => {
    items = [...items, ...accounts.slice(itemsLength, setItemsLength(itemsLength + 20))];
    if (itemsLength > items.length) {
      setHasMoreItems(false);
    }
  };
  return (
    <Wrapper>
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => fetchMoreData()}
        hasMore
        style={{ overflow: 'auto' }}
      >
        {items &&
          items.map(item => (
            <Item key={item.id}>
              <Left>
                <Parapgraph textColor="#192A3E" fontSize="14px">
                  Акт за оказанные услуги №{item.number}
                </Parapgraph>
              </Left>
              <Right>
                <TableButton
                  data-test-id="TEST_BUTTON_DOWNLOAD"
                  onClick={() => {
                    setItemId(item.id);
                  }}
                >
                  Скачать
                </TableButton>
                <Parapgraph textColor="#90A0B7" fontSize="14px">
                  {`${item.format}, ${item.size}`}
                </Parapgraph>
              </Right>
            </Item>
          ))}
      </InfiniteScroll>
    </Wrapper>
  );
};

TableAccounts.propTypes = {
  accounts: PropTypes.array,
  itemsLength: PropTypes.number.isRequired,
  setItemsLength: PropTypes.func.isRequired,
  setItemId: PropTypes.func,
  setHasMoreItems: PropTypes.func,
};

TableAccounts.defaultProps = {
  accounts: [],
  setItemId: () => {},
  setHasMoreItems: () => {},
};

const enhance = compose(
  inject(storeNames.DocumentsStore),
  observer,
  withProps(({ DocumentsStore }) => ({
    accounts: DocumentsStore.acts,
  })),
  withState('offset', 'setOffset', 0),
  withState('itemsLength', 'setItemsLength', 20),
  withState('hasMoreItems', 'setHasMoreItems', true),
  withHandlers(() => ({
    fetchAccounts: () => async () => {},
  })),
  lifecycle({
    async componentDidMount() {
      const { DocumentsStore } = this.props;
      DocumentsStore.setDocumentsActs(Accounts);
    },
    componentWillUnmount() {
      const { DocumentsStore } = this.props;
      DocumentsStore.removeDocumentsActs();
    },
  }),
);

export default enhance(TableAccounts);
