import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-unresolved
import { useMedia } from 'use-media';
import { useTranslation } from 'react-i18next';
import storeNames from '../../../stores/storeNames';
import { hasValue } from '../../../utils/validators';
import InputField from '../../../components/Inputs/InputField';
import InputPhone from '../../../components/Inputs/InputPhone';
import { ICON_SHAPE } from '../../../constants/icons';
import { withPasswordChangeType } from '../../../utils/hocs';
import { RegistrationFooter, Text, TextLink } from '../RegistrationStyled';
import {
  InputColumn,
  Content,
  DateInput,
  TextWrapper,
  TextData,
  Description,
  DateInputWrap,
  InputWrapper,
  ImgShape,
  TextParagraph,
  ColumnWrap,
  ColumnBottom,
  OptionalInput,
  DescriptionOptional,
} from './RegistrationUsersStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import { TEST_SIGNUP } from '../../../constants/testids';
import {
  withSurnameState,
  withPhoneState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withActualAddressState,
  withDateState,
  withInputSetStore,
  withNewPasswordState,
  withConfirmPasswordState,
  withLoginState,
} from '../../../utils/FormHocs';
import InputDate from '../../../components/Inputs/InputDate';
import { RESCORE_TERMS_LINK } from '../../../constants/env';

const Individual = ({
  email,
  birth_date,
  inputHandlerEmail,
  emailError,
  surname,
  surnameValid,
  nameValid,
  emailValid,
  phoneValid,
  actualAddressValid,
  surnameError,
  phone,
  inputHandlerPhone,
  phoneError,
  inputHandlerSurname,
  inputHandlerDate,
  patronymic,
  inputSetStore,
  inputHandlerPatronymic,
  name,
  nameError,
  inputHandlerName,
  actualAddress,
  actualAddressError,
  inputHandlerActualAddress,
  newPassword,
  showNewPassword,
  inputHandlerNewPassword,
  toggleNewPasswordType,
  newPasswordError,
  confirmPassword,
  showConfirmPassword,
  inputHandlerConfirmPassword,
  toggleConfirmPasswordType,
  confirmPasswordError,
  checkedCheckBox,
  confirmed,
  toggleConfirmed,
}) => {
  const { t } = useTranslation();
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Content>
      <InputColumn order={mobileMedia ? '1' : null}>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="1"
            placeholder={t('reg.surname')}
            value={surname}
            name="surname"
            isValid={surnameValid}
            onChange={value => {
              inputSetStore(value, inputHandlerSurname, 'surname');
            }}
          />
          <ParagraphError>{t(surnameError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="2"
            placeholder={t('reg.name')}
            type="text"
            name="name"
            value={name}
            isValid={nameValid}
            onChange={value => {
              inputSetStore(value, inputHandlerName, 'name');
            }}
          />
          <ParagraphError>{t(nameError)}</ParagraphError>
        </InputWrapper>
        <OptionalInput>
          <TextWrapper>
            {!patronymic ? <DescriptionOptional>{t('reg.optional')}</DescriptionOptional> : null}
          </TextWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="3"
            placeholder={t('reg.patronymic')}
            name="patronymic"
            value={patronymic}
            onChange={value => {
              inputSetStore(value, inputHandlerPatronymic, 'patronymic');
            }}
          />
        </OptionalInput>
        <DateInput>
          <TextWrapper>
            <TextData>{t('reg.birthDate')}</TextData>
            <Description>{t('reg.optional')}</Description>
          </TextWrapper>
          <DateInputWrap>
            <InputDate
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="4"
              name="birth_date"
              value={birth_date}
              onChange={value => {
                inputSetStore(value, inputHandlerDate, 'birth_date');
              }}
            />
          </DateInputWrap>
        </DateInput>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="5"
            placeholder={t('recovery.emailAddress')}
            name="email"
            value={email}
            isRequired
            isValid={emailValid}
            onChange={value => {
              inputSetStore(value, inputHandlerEmail, 'email');
            }}
          />
          <ParagraphError>{t(emailError)}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputPhone
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="6"
            isValid={phoneValid}
            placeholder={t('reg.phone')}
            name="phone"
            value={phone}
            onChange={value => {
              inputSetStore(value, inputHandlerPhone, 'phone');
            }}
          />
          <ParagraphError>{t(phoneError)}</ParagraphError>
        </InputWrapper>
      </InputColumn>
      <InputColumn order={mobileMedia ? '3' : null}>
        <ColumnWrap>
          <ColumnBottom>
            <TextParagraph margin="10px 0 0 0" textAlign="center">
              {t('reg.descript')}
            </TextParagraph>
            <InputWrapper>
              <InputField name="login" placeholder={t('login.login')} value={email} />
            </InputWrapper>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="8"
                placeholder={t('login.password')}
                value={newPassword}
                type={showNewPassword}
                onChange={value => {
                  inputSetStore(value, inputHandlerNewPassword, 'newPassword');
                }}
              />
              <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} value={newPassword} />
              <ParagraphError>{t(newPasswordError)}</ParagraphError>
            </InputWrapper>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="9"
                placeholder={t('reg.repeatPass')}
                value={confirmPassword}
                type={showConfirmPassword}
                onChange={value => {
                  inputSetStore(value, inputHandlerConfirmPassword, 'confirmPassword');
                }}
              />
              <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
              <ParagraphError>{t(confirmPasswordError)}</ParagraphError>
            </InputWrapper>
          </ColumnBottom>
        </ColumnWrap>
        <RegistrationFooter>
          <InputWrapper>
            <CheckBox
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="10"
              checked={confirmed}
              testID={`${TEST_SIGNUP}_POLICY_ACCESS`}
              onChange={() => toggleConfirmed()}
            >
              <Text>
                {t('reg.accept_1')}{' '}
                <TextLink
                  to={RESCORE_TERMS_LINK}
                  target="_href"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="11"
                >
                  {t('reg.accept_2')}
                </TextLink>{' '}
                {t('reg.accept_3')}
              </Text>
            </CheckBox>
            <ParagraphError bottom="-20px">
              {checkedCheckBox === false ? 'Примите условия' : ''}
            </ParagraphError>
          </InputWrapper>
        </RegistrationFooter>
      </InputColumn>
      <InputWrapper order={mobileMedia ? '2' : null}>
        <InputField
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex="7"
          placeholder={t('reg.actualAddress')}
          name="actualAddress"
          value={actualAddress}
          isValid={actualAddressValid}
          onChange={value => {
            inputSetStore(value, inputHandlerActualAddress, 'postAddress');
          }}
        />
        <ParagraphError>{t(actualAddressError)}</ParagraphError>
      </InputWrapper>
    </Content>
  );
};

Individual.propTypes = {
  surname: PropTypes.string,
  name: PropTypes.string,
  birth_date: PropTypes.any,
  confirmPassword: PropTypes.string,
  actualAddress: PropTypes.string,
  surnameError: PropTypes.string,
  actualAddressError: PropTypes.string,
  email: PropTypes.string,
  newPassword: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  showNewPassword: PropTypes.string,
  patronymic: PropTypes.string,
  phone: PropTypes.string,
  phoneError: PropTypes.string,
  nameError: PropTypes.string,
  emailError: PropTypes.string,
  newPasswordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  surnameValid: PropTypes.bool,
  emailValid: PropTypes.bool,
  phoneValid: PropTypes.bool,
  actualAddressValid: PropTypes.bool,
  nameValid: PropTypes.bool,
  inputHandlerEmail: PropTypes.func,
  checkedCheckBox: PropTypes.bool,
  confirmed: PropTypes.bool.isRequired,
  inputSetStore: PropTypes.func,
  inputHandlerPhone: PropTypes.func,
  inputHandlerDate: PropTypes.func,
  inputHandlerSurname: PropTypes.func,
  inputHandlerPatronymic: PropTypes.func,
  toggleConfirmed: PropTypes.func.isRequired,
  inputHandlerActualAddress: PropTypes.func,
  inputHandlerName: PropTypes.func,
  inputHandlerNewPassword: PropTypes.func,
  inputHandlerConfirmPassword: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  toggleConfirmPasswordType: PropTypes.func,
};

Individual.defaultProps = {
  surname: '',
  birth_date: undefined,
  actualAddress: '',
  confirmPassword: '',
  name: '',
  patronymic: '',
  email: '',
  phone: '',
  newPassword: '',
  showConfirmPassword: '',
  showNewPassword: '',
  surnameError: '',
  actualAddressError: '',
  nameError: '',
  emailError: '',
  newPasswordError: '',
  phoneError: '',
  confirmPasswordError: '',
  surnameValid: false,
  emailValid: false,
  phoneValid: false,
  checkedCheckBox: false,
  actualAddressValid: false,
  nameValid: false,
  inputHandlerSurname: () => {},
  inputHandlerEmail: () => {},
  inputHandlerActualAddress: () => {},
  inputHandlerPatronymic: () => {},
  inputHandlerPhone: () => {},
  inputHandlerNewPassword: () => {},
  inputHandlerConfirmPassword: () => {},
  inputSetStore: () => {},
  inputHandlerDate: () => {},
  inputHandlerName: () => {},
  toggleNewPasswordType: () => {},
  toggleConfirmPasswordType: () => {},
};

const enchance = compose(
  inject(storeNames.RegistrationStore),
  observer,
  withSurnameState,
  withPhoneState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withDateState,
  withActualAddressState,
  withInputSetStore,
  withNewPasswordState,
  withConfirmPasswordState,
  withPasswordChangeType,
  withLoginState,
  lifecycle({
    componentDidMount() {
      const {
        RegistrationStore,
        inputHandlerSurname,
        inputHandlerName,
        inputHandlerPatronymic,
        inputHandlerEmail,
        inputHandlerPhone,
        inputHandlerDate,
        inputHandlerNewPassword,
        inputHandlerConfirmPassword,
        inputHandlerLogin,
      } = this.props;
      if (RegistrationStore.signUpForm.surname) {
        inputHandlerSurname(RegistrationStore.signUpForm.surname);
      }
      if (RegistrationStore.signUpForm.name) {
        inputHandlerName(RegistrationStore.signUpForm.name);
      }
      if (RegistrationStore.signUpForm.patronymic) {
        inputHandlerPatronymic(RegistrationStore.signUpForm.patronymic);
      }
      if (RegistrationStore.signUpForm.email) {
        inputHandlerEmail(RegistrationStore.signUpForm.email);
        inputHandlerLogin(RegistrationStore.signUpForm.login);
      }
      if (RegistrationStore.signUpForm.phone) {
        inputHandlerPhone(RegistrationStore.signUpForm.phone);
      }
      if (RegistrationStore.signUpForm.birth_date) {
        inputHandlerDate(RegistrationStore.signUpForm.birth_date);
      }
      if (RegistrationStore.signUpForm.newPassword) {
        inputHandlerNewPassword(RegistrationStore.signUpForm.newPassword);
      }
      if (RegistrationStore.signUpForm.confirmPassword) {
        inputHandlerConfirmPassword(RegistrationStore.signUpForm.confirmPassword);
      }
    },
    componentDidUpdate() {
      const { emailValid, email, setIsValidIndividual } = this.props;
      if (hasValue(email) && emailValid) {
        setIsValidIndividual(true);
      } else {
        setIsValidIndividual(false);
      }
    },
  }),
);

export default enchance(Individual);
