import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers, lifecycle, withProps, withState } from 'recompose';
import { useTranslation, withTranslation } from 'react-i18next';
import { useMedia } from 'use-media';
import Popup from '../../../components/Popup/Popup';
import storeNames from '../../../stores/storeNames';
import {
  Wrapper,
  ButtonWrapperCreate,
  InputWrapperCreate,
  FormWrapper,
  SelectWrapper,
} from './PopupsStyled';
import Select from '../../../components/Select/Select';
import ParagraphError from '../../../components/Typography/ParagraphError';
import Paragraph from '../../../components/Typography/Paragraph';
import InputField from '../../../components/Inputs/InputField';
import { hasValue } from '../../../utils/validators';
import { COLOR_LINK_BLACK, COLOR_ACTION_BLUE } from '../../../constants/colors';
import {
  withAgreementNumberState,
  withInputSetServicesStore,
  withCountTariffState,
} from '../../../utils/FormHocs';
import Button from '../../../components/Buttons/Button';

const PurshaseAgreementPopup = ({
  toggleOpenPurshaseAgreementPopup,
  formHandler,
  agreementNumber,
  agreementNumberValid,
  inputHandlerAgreementNumber,
  agreementNumberError,
  inputSetServicesStore,
  tariffSelectValue,
  setTariffSelectValue,
  countTariff,
  countTariffValid,
  inputHandlerCountTariff,
  countTariffError,
  tariffs,
  tariffSelectValueError,
}) => {
  const { t } = useTranslation();
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Popup width="600px" closePopup={toggleOpenPurshaseAgreementPopup}>
      <Wrapper>
        <Paragraph
          textColor={COLOR_LINK_BLACK}
          fontSize={mobileMedia ? '24px' : '32px'}
          lineHeight="42px"
          fontWeight="300"
          textAlign="center"
        >
          {t('services.tariffPaymentForContract')}
        </Paragraph>
        <FormWrapper onSubmit={formHandler}>
          <InputWrapperCreate>
            <InputField
              placeholder={t('services.contractNumber')}
              value={agreementNumber}
              name="agreementNumber"
              isValid={agreementNumberValid}
              onChange={value => {
                inputSetServicesStore(value, inputHandlerAgreementNumber, 'agreementNumber');
              }}
            />
            <ParagraphError>{t(agreementNumberError)}</ParagraphError>
          </InputWrapperCreate>
          {tariffs.length > 1 && (
            <SelectWrapper>
              <Select
                value={tariffSelectValue}
                setValue={setTariffSelectValue}
                placeholder={t('services.selectTariff')}
                name="tariffSelectValue"
                options={tariffs}
              />
              <ParagraphError>{t(tariffSelectValueError)}</ParagraphError>
            </SelectWrapper>
          )}
          <InputWrapperCreate>
            <InputField
              placeholder={t('services.quantity')}
              value={countTariff}
              name="countTariff"
              isValid={countTariffValid}
              onChange={value => {
                inputSetServicesStore(value, inputHandlerCountTariff, 'countTariff');
              }}
            />
            <ParagraphError>{t(countTariffError)}</ParagraphError>
          </InputWrapperCreate>
          <ButtonWrapperCreate>
            <Button
              testID="TEST_BUTTON_ADD"
              width="303px"
              type="submit"
              sizes={{ margin: '0 20px' }}
            >
              {t('services.pay')}
            </Button>
            <Button
              testID="TEST_BUTTON_CANCEL"
              width="303px"
              type="submit"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleOpenPurshaseAgreementPopup()}
            >
              {t('form.cancel')}
            </Button>
          </ButtonWrapperCreate>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

PurshaseAgreementPopup.propTypes = {
  toggleOpenPurshaseAgreementPopup: PropTypes.func,
  formHandler: PropTypes.func,
  agreementNumber: PropTypes.string,
  tariffSelectValueError: PropTypes.string,
  agreementNumberValid: PropTypes.bool,
  tariffSelectValue: PropTypes.string,
  setTariffSelectValue: PropTypes.func,
  inputHandlerAgreementNumber: PropTypes.func,
  agreementNumberError: PropTypes.string,
  inputSetServicesStore: PropTypes.func,
  countTariff: PropTypes.string,
  countTariffValid: PropTypes.bool,
  inputHandlerCountTariff: PropTypes.func,
  countTariffError: PropTypes.string,
  tariffs: PropTypes.array,
};

PurshaseAgreementPopup.defaultProps = {
  toggleOpenPurshaseAgreementPopup: () => {},
  formHandler: () => {},
  agreementNumber: '',
  tariffSelectValueError: '',
  agreementNumberValid: false,
  inputHandlerAgreementNumber: () => {},
  agreementNumberError: '',
  inputSetServicesStore: () => {},
  tariffs: [],
  countTariff: '',
  tariffSelectValue: '',
  setTariffSelectValue: () => {},
  countTariffValid: false,
  inputHandlerCountTariff: () => {},
  countTariffError: '',
};

const enhancer = compose(
  inject(storeNames.ServicesStore, storeNames.ProfileStore),
  observer,
  withTranslation(),
  withAgreementNumberState,
  withInputSetServicesStore,
  withCountTariffState,
  withState('tariffSelectValueError', 'setTariffSelectValueError', ''),
  withProps(({ ServicesStore }) => ({
    tariffs:
      (ServicesStore.servicesTariffs.length &&
        ServicesStore.servicesTariffs.map(item => ({
          ...item,
          value: item.id,
          text: item.full_name,
        }))) ||
      [],
  })),
  withHandlers(() => ({
    inputSetServicesStore: ({ ServicesStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      ServicesStore.setServicesTariffStore({ [`${name}`]: value });
    },
    formHandler: ({
      ServicesStore,
      agreementNumber,
      setAgreementNumberValid,
      setAgreementNumberError,
      countTariff,
      toggleOpenPurshaseAgreementPopup,
      setCountTariffError,
      setCountTariffValid,
      agreementNumberValid,
      countTariffValid,
      setTariffSelectValueError,
      tariffSelectValue,
      t,
    }) => async e => {
      // const { t } = useTranslation();
      e.preventDefault();

      if (!tariffSelectValue) {
        setTariffSelectValueError(t('services.selectTariff'));
      } else {
        setTariffSelectValueError('');
      }

      if (!agreementNumber) {
        setAgreementNumberError(t('services.writeContractNumber'));
        setAgreementNumberValid(false);
      } else {
        setAgreementNumberValid(true);
        setAgreementNumberError('');
      }
      if (!countTariff) {
        setCountTariffError(t('services.writeTariffCount'));
        setCountTariffValid(false);
      } else {
        setCountTariffValid(true);
        setCountTariffError('');
      }

      if (
        hasValue(agreementNumber) &&
        hasValue(countTariff) &&
        agreementNumberValid &&
        tariffSelectValue &&
        countTariffValid
      ) {
        const data = {
          with_purchase: true,
          purchase_tariff_id: Number(tariffSelectValue.id),
          purchase_qnt: Number(countTariff),
          contract_succeed: true,
        };
        await ServicesStore.buyTariffAgreement(agreementNumber, data);
        if (!ServicesStore.errors) {
          toggleOpenPurshaseAgreementPopup();
        }
      }
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { ServicesStore, ProfileStore } = this.props;
      const { fetchMyTariffs } = ServicesStore;
      await fetchMyTariffs();
      await ProfileStore.fetchUser();
    },
  }),
);

export default enhancer(PurshaseAgreementPopup);
