import { observable, decorate } from 'mobx';
import api from '../api';
import logger from '../logger';

export default class ScoringStore {
  constructor(context) {
    this.context = context;
  }

  confirmationUrl = null;

  isBusy = false;

  successPayment = async data => {
    this.isBusy = true;
    try {
      const response = await api.scoring.startBillingBeforeScore(data);
      this.confirmationUrl = response && response.confirmation_url;
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.confirmationUrl;
  };
}

decorate(ScoringStore, {
  confirmationUrl: observable,
  isBusy: observable,
});
