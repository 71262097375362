/* eslint-disable consistent-return */
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ICON_ACTIVITY, ICON_SEARCH, ICON_SUCCEEDED } from '../../../constants/icons';
import subscriptionsStatuses from '../../../constants/subscriptionsStatuses';
import { Img } from '../Table/TableStyled';

const InnerWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  img {
    width: fit-content;
  }

  > div + div {
    margin-top: 4px;
  }
`;

const statusIconMap = {
  [subscriptionsStatuses.inProcessing]: ICON_ACTIVITY,
  [subscriptionsStatuses.underway]: ICON_SEARCH,
  [subscriptionsStatuses.classified]: ICON_SUCCEEDED,
};

const discretionStatusResolver = allowed => (allowed ? 'inquiries.ready' : 'inquiries.inProcess');

const discretionIconResolver = allowed =>
  allowed
    ? statusIconMap[subscriptionsStatuses.classified]
    : statusIconMap[subscriptionsStatuses.inProcessing];

const ActivityTd = ({ item }) => {
  const { t } = useTranslation();
  const { state, discretion } = item;

  return (
    <td>
      <InnerWrapper>
        <Tooltip title={state} placement="top" arrow>
          <div>
            <Img src={statusIconMap[state]} />
          </div>
        </Tooltip>
        {discretion && (
          <Tooltip
            title={t(discretionStatusResolver(discretion.allow_pdf_download))}
            placement="top"
            arrow
          >
            <div>
              <Img src={discretionIconResolver(discretion.allow_pdf_download)} />
            </div>
          </Tooltip>
        )}
      </InnerWrapper>
    </td>
  );
};

ActivityTd.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActivityTd;
