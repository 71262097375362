import styled from 'styled-components';
import { COLOR_BLACK_TEXT, COLOR_ACTION_BLUE } from '../../../constants/colors';
import { ICON_EDIT, ICON_DELETE } from '../../../constants/icons';
import { tabletMedia } from '../../../constants/styles';

export const Wrapper = styled.div``;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  min-width: 880px;
`;
export const TrTop = styled.tr`
  border-bottom: 2px solid #dadada;
`;
export const Tr = styled.tr`
  border-bottom: 1px solid #dadada;
`;
export const Thead = styled.thead``;
export const Tbody = styled.tbody``;

export const Th = styled.th`
  font-size: 13px;
  line-height: 19px;
  color: #707683;
  text-align: left;
  font-weight: 600;
  font-family: Lato;
  padding-bottom: 11px;
  &.active {
    color: #109cf1;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TableButton = styled.button`
  position: relative;
  color: ${COLOR_ACTION_BLUE};
  border: none;
  background: none;
  font-weight: 600;
  font-family: Lato;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  outline: none;
  padding-left: 30px;
  @media (max-width: ${tabletMedia}) {
    padding-left: 10px;
    font-size: 12px;
  }
`;

export const Td = styled.td`
  color: ${COLOR_BLACK_TEXT};
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  font-family: Lato;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 200px;
  padding-right: 15px;
`;

export const TdName = styled(Td)`
  font-family: Lato;
  font-weight: 600;
`;

export const TdButton = styled(Td)`
  max-width: 30px;
  padding-right: 5px;
`;

export const ButtonEdit = styled.button`
  font-size: 0;
  background: url(${ICON_EDIT}) no-repeat center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ButtonDelete = styled(ButtonEdit)`
  background: url(${ICON_DELETE}) no-repeat center;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-family: Lato;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #5c6470;
`;
