import React from 'react';
import PropTypes from 'prop-types';

// import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import * as moment from 'moment';
import { Title } from '../../Balance/BalanceStyled';
import { useStyles } from './Styles/styles';
import { numSeparator } from './Utils/utils';
import logger from '../../../logger';

const Tabloid = ({ list, keyArray }) => {
  const classes = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          {keyArray.map((item, index) => (
            <TableCell key={index} className={classes.tableHeader}>
              {item.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((data, index) => (
          <TableRow key={index}>
            {keyArray.map((item, index2) => (
              <>
                <Tooltip
                  title={'hint' in item ? data[item.hint] : ''}
                  placement="top-start"
                  arrow
                  key={index2}
                >
                  <TableCell className={classes.tableBodyRoot}>
                    {typeof item.key === 'object' ? (
                      item.key.map(key => <>{data[key]} </>)
                    ) : (
                      <>{data[item.key]}</>
                    )}
                  </TableCell>
                </Tooltip>
              </>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
Tabloid.propTypes = {
  list: PropTypes.array.isRequired,
  keyArray: PropTypes.array.isRequired,
};

const Founders = ({ obj }) => {
  const founders = [
    {
      path: 'person_ru',
      keyArray: [
        { title: 'Персона', key: ['surname', 'first_name', 'patronymic'] },
        { title: 'ИНН', key: 'inn' },
        { title: 'Доля', key: 'percent' },
      ],
    },
    {
      path: 'organization_ru',
      keyArray: [
        { title: 'Организация', key: 'owner_name' },
        { title: 'ОГРН', key: 'owner_ogrn' },
        { title: 'Доля', key: 'percent' },
      ],
    },
    {
      path: 'organization_foreign',
      keyArray: [
        { title: 'Оффшорная компания', key: 'owner_name' },
        { title: 'Страна', key: 'country_name', hint: 'owner_address' },
        { title: 'Номер регистрации', key: 'owner_reg_number', hint: 'owner_reg_org_name' },
        { title: 'Доля', key: 'percent' },
      ],
    },
    {
      path: 'pif_ru',
      keyArray: [
        { title: 'ПИФ', key: 'owner_pif_name' },
        { title: 'УК', key: 'owner_pif_management_org_name' },
        { title: 'ОГРН УК', key: 'owner_pif_management_org_ogrn' },
        { title: 'Доля', key: 'percent' },
      ],
    },
    {
      path: 'state_ru',
      keyArray: [
        { title: 'Регион', key: 'owner_region_name' },
        { title: 'Муниципальное образование', key: 'owner_municipal_name' },
        { title: 'Учредитель', key: 'owner_name' },
        { title: 'ОГРН', key: 'owner_ogrn' },
      ],
    },
  ];
  return (
    <>
      {founders.map((item, index) =>
        obj[item.path].length ? (
          <Tabloid key={index} list={obj[item.path]} keyArray={item.keyArray} />
        ) : (
          false
        ),
      )}
    </>
  );
};
Founders.propTypes = {
  obj: PropTypes.object.isRequired,
};

const Persons = ({ list }) => {
  const keyArray = [
    { title: 'Должность', key: 'position_name' },
    { title: 'Персона', key: ['surname', 'first_name', 'patronymic'] },
    { title: 'ИНН', key: 'inn' },
  ];
  return <>{list.length ? <Tabloid list={list} keyArray={keyArray} /> : 'Не указано'}</>;
};
Persons.propTypes = {
  list: PropTypes.array.isRequired,
};

const getAddress = data => {
  if (data === undefined) return '';
  return `(${data.post_index}) ${data.region_type} ${data.region_name}, ${
    data.city_type !== null ? `${data.city_type} ${data.city_name},` : ''
  } ${data.street_type} ${data.street_name}${data.house ? `, ${data.house}` : ''}`;
};

const Okveds = ({ list }) => {
  const classes = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeader}>Код</TableCell>
          <TableCell align="left" className={classes.tableHeader}>
            Деятельность
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((data, index) => (
          <TableRow key={index}>
            <TableCell className={classes.tableBodyRoot}>{data.okved_code}</TableCell>
            <TableCell align="left" className={classes.tableBodyRoot}>
              {data.description}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
Okveds.propTypes = {
  list: PropTypes.array.isRequired,
};

const keyList = [
  // { title: 'Статус', path: 'main_status', format: a => a },
  { title: 'ОГРН', path: 'ogrn', format: a => a },
  { title: 'ИНН', path: 'inn', format: a => a },
  { title: 'КПП', path: 'kpp', format: a => a },
  {
    title: 'Дата регистрации',
    path: 'registration_date',
    format: a => (a ? moment.utc(a).format('DD.MM.YYYY') : ''),
  },
  { title: 'Адрес регистрации', path: 'address', format: a => getAddress(a) },
  {
    title: 'Основной вид деятельности',
    compose: [
      { path: 'main_okved.okved_code', format: a => `(${a})` },
      { path: 'main_okved.description' },
    ],
  },
  {
    title: 'Уставный капитал',
    path: 'authorized_capital',
    format: a => (a ? `${numSeparator(a)} руб` : ''),
  },
  {
    title: 'Текущий статус',
    path: 'main_status_description',
    color: d => (Number(d.main_status) !== 0 ? 'error' : 'textSecondary'),
  },
  {
    title: 'Учредители',
    path: 'founders',
    format: a => <Founders obj={a} />,
  },
  {
    title: 'Сотрудники',
    path: 'persons',
    format: a => <Persons list={a} />,
  },
  {
    title: 'Полный список ОКВЭД',
    path: 'okved',
    format: a => <Okveds list={a} />,
  },
];

const RegistrationData = ({ data }) => {
  const classes = useStyles();
  logger.debug('REGIST: ');
  logger.debug(data);
  // console.log('RegistrationData: ', JSON.stringify(data));
  if (Object.keys(data).length === 0) return <></>;

  const getFromPath = (obj, path) => {
    // logger.debug(obj, path);
    const pathArr = path.split('.');
    let child = obj;
    // eslint-disable-next-line
    for (const key of pathArr) {
      child = child[key] ? child[key] : '';
    }
    return child;
  };
  const getValue = item => {
    return 'format' in item
      ? item.format(getFromPath(data, item.path))
      : getFromPath(data, item.path);
  };
  return (
    <>
      <Title style={{ textAlign: 'center' }}>{data.name_full ? data.name_full : ''}</Title>
      {keyList.map((item, index) => (
        <Grid
          key={index}
          className={classes.indent}
          container
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="body1" color="textSecondary" component="div">
            {item.title}:
          </Typography>
          <Typography
            variant="body1"
            color={'color' in item ? item.color(data) : 'textSecondary'}
            component="div"
            style={{ width: '70%', textAlign: 'right' }}
          >
            {'compose' in item ? item.compose.map(item2 => ` ${getValue(item2)}`) : getValue(item)}
          </Typography>
        </Grid>
      ))}
    </>
  );
};
RegistrationData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RegistrationData;
