import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useMedia } from 'use-media';
import { hasValue } from '../../../utils/validators';
import InputPhone from '../../../components/Inputs/InputPhone';
import { Content, InputColumn, InputWrapper, ImgWrap, Img, Button } from './ProfileUsersStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import InputField from '../../../components/Inputs/InputField';
import { withUserData } from '../../../utils/hocs';
import { getAccessStatus } from '../../../helpers/roles';
import { withInitializedUserDataInputs } from '../../../utils/initFormHocs';
import Typography from '../../../components/Typography/Paragraph';
import { SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE } from '../../../constants/roles';
import { ICON_EMAIL_VERIFID, ICON_EMAIL_NOVERIFID } from '../../../constants/icons';
import { STATUS_CONFIRMATION, getUrl } from '../../../constants/statusPageUrls';
import { getUrlObject } from '../../../helpers/url';
// import PersonalAccounts from './PersonalAccounts/PersonalAccounts';

const LegalEntity = ({
  bik,
  bank_name,
  rs,
  ks,
  bikValid,
  bankValid,
  rsValid,
  ksValid,
  ogrn,
  ogrnValid,
  inputHandlerOgrn,
  ogrnError,
  bikError,
  bankError,
  rsError,
  ksError,
  name,
  surname,
  email,
  phone,
  ogrName,
  inn,
  nameError,
  legalAddress,
  // actualAddress,
  inputSetStore,
  nameValid,
  inputHandlerName,
  inputHandlerSurname,
  surnameError,
  inputHandlerOgrName,
  // inputHandlerActualAddress,
  // actualAddressError,
  ogrNameError,
  inputHandlerLegalAddress,
  legalAddressError,
  customerEmailError,
  inputHandlerCustomerEmail,
  inputHandlerEmail,
  inputHandlerBik,
  customerEmailValid,
  inputHandlerBank,
  inputHandlerRs,
  inputHandlerKs,
  emailError,
  inputHandlerPhone,
  phoneError,
  inputHandlerInn,
  innError,
  customerEmail,
  surnameValid,
  ogrNameValid,
  emailValid,
  phoneValid,
  innValid,
  legalAddressValid,
  userRoles,
  userData,
  handlerConfirmation,
  handlerConsumerConfirmation,
  inputSetCustomerStore,
  login,
  loginValid,
  inputHandlerLogin,
  loginError,
  // accountsIsLoading,
  // actualAddressValid,
  // deleteAccountHandler,
  // addAccountHandler,
}) => {
  const { t } = useTranslation();
  const isDisabled = !getAccessStatus({
    userRoles,
    allowedRoles: [SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE],
  });
  const isDisabledInnOgrn = !getAccessStatus({
    userRoles,
    allowedRoles: [SUPER_ADMIN_ROLE],
  });
  const mobMedia = useMedia({ maxWidth: 768 });
  return (
    <>
      {!mobMedia && (
        <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
          {t('profile.personalData')}
        </Typography>
      )}
      <Content>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="1"
              placeholder={t('recovery.emailAddress')}
              name="email"
              disabled
              value={email}
              isValid={emailValid}
              onChange={value => {
                inputSetStore(value, inputHandlerEmail, 'email');
              }}
            />
            <ImgWrap>
              <Img src={userData.email_confirmed ? ICON_EMAIL_VERIFID : ICON_EMAIL_NOVERIFID} />
            </ImgWrap>
            <ParagraphError>{t(emailError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            {!userData.email_confirmed && (
              <Button
                type="submit"
                data-test-id="TEST_BUTTON_CONFORMATION"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="2"
                onClick={e => handlerConfirmation(e)}
              >
                {t('profile.confirmEmail')}
              </Button>
            )}
          </InputWrapper>
        </InputColumn>
        <InputColumn>
          <InputWrapper>
            <InputField
              placeholder={t('login.login')}
              name="login"
              disabled
              value={login}
              isValid={loginValid}
              onChange={value => {
                inputSetStore(value, inputHandlerLogin, 'login');
              }}
            />
            <ParagraphError>{t(loginError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
      </Content>
      {!mobMedia && (
        <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
          {t('profile.legalData')}
        </Typography>
      )}
      <Content>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="3"
              placeholder={t('reg.ogrn')}
              name="ogrn"
              value={ogrn}
              isValid={ogrnValid}
              disabled={isDisabledInnOgrn}
              onChange={value => {
                if (value.length < 14) {
                  inputSetStore(value, inputHandlerOgrn, 'ogrn');
                }
              }}
            />
            <ParagraphError>{t(ogrnError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="4"
              placeholder={t('reg.inn')}
              name="inn"
              value={inn}
              isValid={innValid}
              disabled={isDisabledInnOgrn}
              onChange={value => {
                if (value.length < 13) {
                  inputSetStore(value, inputHandlerInn, 'inn');
                }
              }}
            />
            <ParagraphError>{t(innError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="5"
              placeholder={t('reg.companyName')}
              disabled={isDisabled}
              type="text"
              name="full_name"
              value={ogrName}
              isValid={ogrNameValid}
              onChange={value => {
                inputSetStore(value, inputHandlerOgrName, 'full_name');
              }}
            />
            <ParagraphError>{t(ogrNameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="6"
              placeholder={t('reg.contactPersonSurname')}
              type="text"
              disabled={isDisabled}
              name="surname"
              value={surname}
              isValid={surnameValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerSurname, 'contact_person_sname');
              }}
            />
            <ParagraphError>{t(surnameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="7"
              placeholder={t('reg.contactPersonName')}
              type="text"
              name="name"
              disabled={isDisabled}
              value={name}
              isValid={nameValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerName, 'contact_person_fname');
              }}
            />
            <ParagraphError>{t(nameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="8"
              placeholder={t('recovery.emailAddress')}
              name="customerEmail"
              disabled={isDisabled}
              value={customerEmail}
              isValid={customerEmailValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerCustomerEmail, 'email');
              }}
            />
            <ImgWrap>
              <Img
                src={userData.customer.email_confirmed ? ICON_EMAIL_VERIFID : ICON_EMAIL_NOVERIFID}
              />
            </ImgWrap>
            <ParagraphError>{t(customerEmailError)}</ParagraphError>
          </InputWrapper>
          {!isDisabled && (
            <InputWrapper>
              {!userData.customer.email_confirmed && (
                <Button
                  type="submit"
                  data-test-id="TEST_BUTTON_CONFORMATION"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="9"
                  onClick={e => handlerConsumerConfirmation(e)}
                >
                  {t('profile.confirmEmail')}
                </Button>
              )}
            </InputWrapper>
          )}
          <InputWrapper>
            <InputPhone
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="10"
              placeholder={t('reg.phone')}
              name="phone"
              disabled={isDisabled}
              value={phone}
              isValid={phoneValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerPhone, 'phone');
              }}
            />
            <ParagraphError>{t(phoneError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="12"
              placeholder={t('reg.bik')}
              name="bik"
              value={bik}
              disabled={isDisabled}
              isValid={bikValid}
              onChange={value => {
                if (value.length < 10) {
                  inputSetStore(value, inputHandlerBik, 'bik');
                }
              }}
            />
            <ParagraphError>{t(bikError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="13"
              placeholder={t('reg.bankName')}
              name="bank_name"
              disabled={isDisabled}
              value={bank_name}
              isValid={bankValid}
              onChange={value => {
                inputSetStore(value, inputHandlerBank, 'bank_name');
              }}
            />
            <ParagraphError>{t(bankError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="14"
              placeholder={t('reg.bankAccount')}
              name="rs"
              value={rs}
              disabled={isDisabled}
              isValid={rsValid}
              onChange={value => {
                if (value.length < 21) {
                  inputSetStore(value, inputHandlerRs, 'rs');
                }
              }}
            />
            <ParagraphError>{t(rsError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="15"
              placeholder={t('reg.correspondentAccount')}
              name="ks"
              value={ks}
              isValid={ksValid}
              disabled={isDisabled}
              onChange={value => {
                if (value.length < 21) {
                  inputSetStore(value, inputHandlerKs, 'ks');
                }
              }}
            />
            <ParagraphError>{t(ksError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="11"
            placeholder={t('reg.legalAddress')}
            name="post_address"
            disabled={isDisabled}
            value={legalAddress}
            isValid={legalAddressValid}
            onChange={value => {
              inputSetCustomerStore(value, inputHandlerLegalAddress, 'post_address');
            }}
          />
          <ParagraphError>{t(legalAddressError)}</ParagraphError>
        </InputWrapper>
        {/* <PersonalAccounts
      accountsIsLoading={accountsIsLoading}
      deleteAccountHandler={deleteAccountHandler}
      addAccountHandler={addAccountHandler}
    /> */}
      </Content>
    </>
  );
};

LegalEntity.propTypes = {
  bik: PropTypes.string,
  bank_name: PropTypes.string,
  rs: PropTypes.string,
  ks: PropTypes.string,
  login: PropTypes.string,
  name: PropTypes.string,
  ogrn: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  customerEmailError: PropTypes.string,
  inputHandlerCustomerEmail: PropTypes.func,
  customerEmailValid: PropTypes.bool,
  customerEmail: PropTypes.string,
  phone: PropTypes.string,
  ogrName: PropTypes.string,
  inn: PropTypes.string,
  surnameError: PropTypes.string,
  nameError: PropTypes.string,
  legalAddressError: PropTypes.string,
  phoneError: PropTypes.string,
  bikError: PropTypes.object,
  bankError: PropTypes.string,
  rsError: PropTypes.string,
  ksError: PropTypes.string,
  // actualAddressError: PropTypes.string,
  ogrNameError: PropTypes.string,
  // ogrnError: PropTypes.string,
  emailError: PropTypes.string,
  loginError: PropTypes.string,
  ogrnError: PropTypes.object,
  innError: PropTypes.object,
  legalAddress: PropTypes.string,
  // actualAddress: PropTypes.string,
  inputSetStore: PropTypes.func,
  nameValid: PropTypes.bool,
  inputHandlerOgrName: PropTypes.func,
  inputHandlerName: PropTypes.func,
  inputHandlerSurname: PropTypes.func,
  // inputHandlerActualAddress: PropTypes.func,
  inputHandlerLegalAddress: PropTypes.func,
  inputHandlerEmail: PropTypes.func,
  inputHandlerPhone: PropTypes.func,
  inputHandlerOgrn: PropTypes.func,
  inputHandlerInn: PropTypes.func,
  inputHandlerBik: PropTypes.func,
  inputHandlerBank: PropTypes.func,
  inputHandlerRs: PropTypes.func,
  inputSetCustomerStore: PropTypes.func,
  inputHandlerKs: PropTypes.func,
  // deleteAccountHandler: PropTypes.func,
  // addAccountHandler: PropTypes.func,
  surnameValid: PropTypes.bool,
  ogrNameValid: PropTypes.bool,
  bikValid: PropTypes.bool,
  bankValid: PropTypes.bool,
  rsValid: PropTypes.bool,
  ksValid: PropTypes.bool,
  emailValid: PropTypes.bool,
  ogrnValid: PropTypes.bool,
  phoneValid: PropTypes.bool,
  loginValid: PropTypes.bool,
  innValid: PropTypes.bool,
  legalAddressValid: PropTypes.bool,
  userRoles: PropTypes.array,
  userData: PropTypes.object,
  inputHandlerLogin: PropTypes.func,
  handlerConfirmation: PropTypes.func,
  handlerConsumerConfirmation: PropTypes.func,
  // accountsIsLoading: PropTypes.bool.isRequired,
  // actualAddressValid: PropTypes.bool,
};
LegalEntity.defaultProps = {
  bik: '',
  bank_name: '',
  rs: '',
  ks: '',
  name: '',
  surname: '',
  ogrn: '',
  login: '',
  email: '',
  phone: '',
  ogrName: '',
  inn: '',
  nameError: '',
  userData: {},
  userRoles: [],
  phoneError: '',
  legalAddressError: '',
  emailError: '',
  innError: '',
  loginError: '',
  ogrnError: undefined,
  bikError: undefined,
  customerEmailError: '',
  inputHandlerCustomerEmail: () => {},
  customerEmailValid: false,
  customerEmail: '',
  bankError: '',
  rsError: '',
  ksError: '',
  ogrNameError: '',
  // actualAddressError: '',
  surnameError: '',
  legalAddress: '',
  // actualAddress: '',
  nameValid: false,
  surnameValid: false,
  ogrNameValid: false,
  emailValid: false,
  ogrnValid: false,
  phoneValid: false,
  bikValid: false,
  loginValid: false,
  bankValid: false,
  rsValid: false,
  ksValid: false,
  innValid: false,
  legalAddressValid: false,
  // actualAddressValid: false,
  inputHandlerName: () => {},
  inputSetCustomerStore: () => {},
  // addAccountHandler: () => {},
  inputHandlerSurname: () => {},
  // inputHandlerActualAddress: () => {},
  inputHandlerOgrn: () => {},
  inputHandlerOgrName: () => {},
  inputHandlerLegalAddress: () => {},
  // deleteAccountHandler: () => {},
  inputHandlerEmail: () => {},
  inputHandlerPhone: () => {},
  inputHandlerInn: () => {},
  inputHandlerBik: () => {},
  inputHandlerLogin: () => {},
  inputHandlerBank: () => {},
  inputHandlerRs: () => {},
  handlerConfirmation: () => {},
  handlerConsumerConfirmation: () => {},
  inputHandlerKs: () => {},
  inputSetStore: () => {},
};

const enhance = compose(
  withUserData,
  withInitializedUserDataInputs,
  withHandlers(({ ProfileStore }) => ({
    handlerConfirmation: () => async e => {
      e.preventDefault();
      const collbackUrl = getUrlObject();
      const data = {
        return_url: `${getUrl()}${STATUS_CONFIRMATION(collbackUrl.href, 'profile')}&entity=user`,
      };
      ProfileStore.confirmationEmail(data, ProfileStore.userData.id);
      localStorage.setItem('USER_ID', ProfileStore.userData.id);
    },
    handlerConsumerConfirmation: () => async e => {
      e.preventDefault();
      const collbackUrl = getUrlObject();
      const data = {
        return_url: `${getUrl()}${STATUS_CONFIRMATION(
          collbackUrl.href,
          'profile',
        )}&entity=customer`,
      };
      ProfileStore.confirmationCustomerEmail(data);
      localStorage.setItem('CUSTOMER_ID', ProfileStore.userData.customer_id);
    },
  })),
  lifecycle({
    componentDidUpdate() {
      const {
        ogrnValid,
        innValid,
        phoneValid,
        emailValid,
        customerEmailValid,
        bikValid,
        rsValid,
        ksValid,
        setIsValidLegalEntity,
        login,
      } = this.props;
      if (
        ogrnValid &&
        innValid &&
        phoneValid &&
        bikValid &&
        rsValid &&
        ksValid &&
        emailValid &&
        customerEmailValid &&
        hasValue(login)
      ) {
        setIsValidLegalEntity(true);
      } else {
        setIsValidLegalEntity(false);
      }
    },
  }),
);

export default enhance(LegalEntity);
