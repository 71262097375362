import styled from 'styled-components';
import { TitleText } from '../../../components/Typography/Title';
import { COLOR_TITLE_BLACK, COLOR_LINK_BLACK } from '../../../constants/colors';
import { mobileMedia } from '../../../constants/styles';

export const Title = styled(TitleText)`
  font-size: 32px;
  text-align: center;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-bottom: 25px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Wrapper = styled.div`
  padding: 43px 50px 54px;
  @media (max-width: ${mobileMedia}) {
    padding: 43px 25px 54px;
  }
`;

export const FormWrapper = styled.form`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const DateContent = styled.div`
  width: 190px;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputWrapperNotes = styled.div`
  position: relative;
  width: 100%;
`;

export const DateInputWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  @media (max-width: ${mobileMedia}) {
    font-size: 15px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 47%;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 8px;
  @media (max-width: ${mobileMedia}) {
    width: 100%;
  }
`;

export const TopText = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 13px;
  margin-top: 13px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 73px;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    margin-top: 35px;
    align-items: center;
    button {
      margin-top: 20px;
    }
  }
`;

export const TextArea = styled.textarea`
  margin-top: 25px;
  width: 100%;
  min-height: 100px;
  padding: 18px 14px;
  border-radius: 4px;
  border: 1px solid #abb1ba;
`;
