import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { compose, withHandlers } from 'recompose';
import Popup from '../../../components/Popup/Popup';
import { Wrapper, TextWrapper, TextWrap } from '../RepeatPopup/PopupStyled';
import Paragraph from '../../../components/Typography/Paragraph';
import { COLOR_LINK_BLACK, COLOR_BLACK_LOGO, COLOR_RED } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import storeNames from '../../../stores/storeNames';

const ExpiredPopup = ({ toggleExpiredPopup, buttonHandler }) => {
  const { t } = useTranslation();
  return (
    <Popup width="600px" closePopup={toggleExpiredPopup}>
      <Wrapper>
        <Paragraph textColor={COLOR_LINK_BLACK} fontSize="32px" lineHeight="42px" fontWeight="300">
          {t('inquiries.monitoring')}
        </Paragraph>
        <TextWrapper>
          <Paragraph textColor={COLOR_RED} fontSize="18px" fontWeight="500">
            {t('inquiries.monitoringDescription1')}
          </Paragraph>
          <TextWrap>
            <Paragraph textColor={COLOR_BLACK_LOGO} fontSize="18px" fontWeight="500">
              {t('inquiries.monitoringDescription2')}
            </Paragraph>
          </TextWrap>
        </TextWrapper>
        <Button
          height="42px"
          data-test-id="TEST_BUTTON_RUN"
          lineHeight="15px"
          width="245px"
          onClick={() => buttonHandler()}
        >
          {t('inquiries.debitAndStart')}
        </Button>
      </Wrapper>
    </Popup>
  );
};

ExpiredPopup.propTypes = {
  toggleExpiredPopup: PropTypes.func,
  buttonHandler: PropTypes.func,
};

ExpiredPopup.defaultProps = {
  toggleExpiredPopup: () => {},
  buttonHandler: () => {},
};

const enchance = compose(
  inject(storeNames.CustomerStore),
  observer,
  withHandlers(() => ({
    buttonHandler: ({ id, toggleExpiredPopup, CustomerStore }) => () => {
      const client = CustomerStore.inquiries.find(item => item.id === id);
      client.expired = !client.expired;
      CustomerStore.setInquiriesExpired(client);
      toggleExpiredPopup();
    },
  })),
);

export default enchance(ExpiredPopup);
