import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, lifecycle, withProps, withState, withStateHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';

import ModalsProvider from '../../components/ModalsProvider';
import {
  withActiveValue,
  withBoolState,
  withFetchUserInDidMount,
  withUserData,
} from '../../utils/hocs';
import storeNames from '../../stores/storeNames';
import { Button, /* ButtonRating, */ Buttons, Title, Top, Wrapper } from './InquiriesStyled';
import Table from './Table/Table';
import RepeatPopup from './RepeatPopup/RepeatPopup';
import ReportPopup from './ReportPopup/ReportPopup';
import ExpiredPopup from './ExpiredPopup/ExpiredPopup';
import history from '../../history';
import InquiriesPopup from './InquiriesPopup/InquiriesPopup';
// import { hasAntiRatingCheck } from '../../constants/env';
import TopInquiriesNavigation from './TopInquiriesNavigation/TopInquiriesNavigation';

const Inquiries = ({
  confirmed,
  toggleRepeatPopup,
  toggleReportPopup,
  toggleExpiredPopup,
  repeatPopup,
  expiredPopup,
  reportPopup,
  setItemId,
  itemId,
  toggleInquiriesPopup,
  inquiriesPopup,
  inquiries,
  inputData,
  setInputData,
  accountValue,
  hasMoreItems,
  stats,
  isUserRole,
  userIsLoading,
  userData,
  setNewInquiries,
  newInquiries,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        {userData && userData.id && !userIsLoading && isUserRole && (
          <>
            <Top>
              <Title as="h2">{t('sidebar.requests')}</Title>
              <Buttons>
                {/* hasAntiRatingCheck && (
                  <ButtonRating
                    data-test-id="TEST_BUTTON_ANTIRATING"
                    href="https://service02.rescore.online"
                    target="_blank"
                  >
                    {t('inquiries.checkAntiratingMoratory')}
                  </ButtonRating>
                ) */}
                {!accountValue.blocked && stats.scores > 0 && (
                  <Button
                    data-test-id="TEST_BUTTON_ADD_ACCOUNT"
                    onClick={() => {
                      toggleInquiriesPopup();
                      setItemId('');
                      setNewInquiries(true);
                    }}
                  >
                    {t('inquiries.newRequest')}
                  </Button>
                )}
              </Buttons>
            </Top>
            <TopInquiriesNavigation inputData={inputData} setInputData={setInputData} />
            <Table
              confirmed={confirmed}
              toggleInquiriesPopup={toggleInquiriesPopup}
              toggleReportPopup={toggleReportPopup}
              toggleExpiredPopup={toggleExpiredPopup}
              setItemId={setItemId}
              inquiries={!inquiries ? [] : inquiries}
              hasMoreItems={hasMoreItems}
              setNewInquiries={setNewInquiries}
              inputData={inputData}
              setInputData={setInputData}
            />
            {repeatPopup ? <RepeatPopup id={itemId} toggleRepeatPopup={toggleRepeatPopup} /> : null}
            {reportPopup ? <ReportPopup id={itemId} toggleReportPopup={toggleReportPopup} /> : null}
            {expiredPopup ? (
              <ExpiredPopup id={itemId} toggleExpiredPopup={toggleExpiredPopup} />
            ) : null}
            {inquiriesPopup ? (
              <InquiriesPopup
                id={itemId}
                toggleInquiriesPopup={toggleInquiriesPopup}
                inquiries={inquiries}
                newInquiries={newInquiries}
              />
            ) : null}
            <ModalsProvider />
          </>
        )}
        {userData && userData.id && !userIsLoading && !isUserRole && history.replace('/profile')}
      </Wrapper>
    </>
  );
};
Inquiries.propTypes = {
  accountValue: PropTypes.object,
  confirmed: PropTypes.bool,
  expiredPopup: PropTypes.bool.isRequired,
  hasMoreItems: PropTypes.bool,
  inquiries: PropTypes.array,
  inquiriesPopup: PropTypes.bool.isRequired,
  inputData: PropTypes.string,
  isUserRole: PropTypes.bool.isRequired,
  itemId: PropTypes.string,
  newInquiries: PropTypes.bool.isRequired,
  repeatPopup: PropTypes.bool.isRequired,
  reportPopup: PropTypes.bool.isRequired,
  setInputData: PropTypes.func.isRequired,
  setItemId: PropTypes.func.isRequired,
  setNewInquiries: PropTypes.func.isRequired,
  stats: PropTypes.object,
  toggleExpiredPopup: PropTypes.func,
  toggleInquiriesPopup: PropTypes.func,
  toggleRepeatPopup: PropTypes.func,
  toggleReportPopup: PropTypes.func,
  userData: PropTypes.object.isRequired,
  userIsLoading: PropTypes.bool,
};

Inquiries.defaultProps = {
  accountValue: {},
  confirmed: false,
  hasMoreItems: undefined,
  inquiries: [],
  inputData: '',
  itemId: '',
  stats: {},
  toggleExpiredPopup: () => {},
  toggleInquiriesPopup: () => {},
  toggleRepeatPopup: () => {},
  toggleReportPopup: () => {},
  userIsLoading: false,
};

const enhance = compose(
  inject(storeNames.InquiriesStore, storeNames.ServicesStore),
  withActiveValue(false, true, false, false, false, true),
  observer,
  withProps(({ InquiriesStore, ServicesStore }) => ({
    inquiries: InquiriesStore.inquiries,
    hasMoreItems: InquiriesStore.hasMoreItems,
    stats: ServicesStore.stats,
  })),
  withFetchUserInDidMount,
  withUserData,
  withStateHandlers(
    ({ inputValue = '' }) => ({
      inputData: inputValue,
    }),
    {
      setInputData: () => value => ({
        inputData: value,
      }),
    },
  ),
  withBoolState('confirmed', false),
  withBoolState('inquiriesPopup', false),
  withBoolState('repeatPopup', false),
  withBoolState('reportPopup', false),
  withBoolState('expiredPopup', false),
  withState('itemId', 'setItemId', ''),
  withState('newInquiries', 'setNewInquiries', false),
  lifecycle({
    async componentWillUnmount() {
      const { InquiriesStore } = this.props;
      InquiriesStore.deleteInquiries();
    },
  }),
);

export default enhance(Inquiries);
