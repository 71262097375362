/**
 * Batch two arrays
 * return array with two datas
 * @param {array} result - array of main results
 */

export const merge2RoleArrays = array => {
  const result = array.reduce((acc, item) => {
    let index;
    // eslint-disable-next-line array-callback-return, consistent-return
    const hasItem = acc.find((opt, i) => {
      if (opt.value === item.value) {
        index = i;
        return opt;
      }
    });
    if (hasItem) {
      const opt = acc[index];
      acc[index] = { ...opt, ...item };
      return acc;
    }
    return [...acc, item];
  }, []);
  return result;
};
