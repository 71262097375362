import styled from 'styled-components';
import { TitleText } from '../../components/Typography/Title';
import { COLOR_TITLE_BLACK, COLOR_ACTION_BLUE, COLOR_WHITE } from '../../constants/colors';
import { Btn } from '../../components/Buttons/Button';
import { mobileMedia } from '../../constants/styles';

export const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 21px;
  @media (max-width: 1068px) {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
  }
`;

export const ProfileContent = styled.div``;

export const Title = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-bottom: 26px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Button = styled(Btn)`
  padding: 11px;
  font-size: 16px;
  font-weight: 500;
  font-family: Lato;
  border: 1px solid ${props => (props.disabled ? '#C2CFE0' : COLOR_ACTION_BLUE)};
  background-color: ${props => (props.disabled ? '#C2CFE0' : COLOR_ACTION_BLUE)};
  color: ${props => (props.disabled ? '#90A0B7' : COLOR_WHITE)};
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
`;
