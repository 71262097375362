import React /* , { useState } */ from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import * as moment from 'moment';
// import logger from '../../../logger';
import { useStyles } from './Styles/styles';
import { numSeparator, upperCaseFirst, prettyCurrency } from './Utils/utils';

const StateContractsTable = ({ stateContracts, statistic, yearFrom, yearTo /* , loaded */ }) => {
  const sc = useStyles();
  const years = [];
  for (let year = yearFrom; year <= yearTo; year += 1) years.push(year);
  // const [year, setYear] = useState(legalCases.years.length ? legalCases.years[0].year : null);

  return (
    /* loaded ? */
    <Box mb={12} mt={2}>
      {stateContracts.length === 0 ? (
        <Box
          mt={1}
          pl={2}
          className={sc.detailTitle}
          style={{ color: '#2dd45b', fontWeight: 'bold' }}
        >
          Госконтракты отсутствуют
        </Box>
      ) : (
        <>
          <Box mb={4} mt={2}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={sc.tableHeader}>Статистика</TableCell>
                  {years.map(year => (
                    <TableCell
                      className={sc.tableHeader}
                      key={year}
                      align="right"
                      style={{ width: '160px' }}
                    >
                      {year}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {statistic.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => {}}
                  >
                    <TableCell className={sc.tableBodyRoot} component="th" scope="row">
                      {item.title}
                    </TableCell>
                    {/* 'values' in item
                      ? */ years.map(year => {
                      let item2 = item.values.find(it => Number(it.year) === year);
                      if (!item2) item2 = { year, value: '-' };
                      return (
                        <TableCell
                          key={item2.year}
                          align="right"
                          style={{ width: '160px' }}
                          className={sc.tableBodyRoot}
                        >
                          {numSeparator(item2.value, true)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>

          <Box mt={1} pl={2} className={sc.detailTitle}>
            Детальная информация:
          </Box>

          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={sc.tableHeader} style={{ width: '10%' }}>
                  Номер контракта
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '8%' }}>
                  Дата
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '12%' }}>
                  Сумма
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '70%' }}>
                  Предмет контракта
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stateContracts.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className={sc.tableBodyWide}>
                    <Box>
                      <a
                        href={
                          item.contract_number.length <= 20
                            ? `https://zakupki.gov.ru/epz/contract/search/results.html?searchString=${item.contract_number}&morphology=on&fz44=on&fz94=on&contractStageList_0=on&contractStageList_1=on&contractStageList_2=on&contractStageList_3=on`
                            : `https://zakupki.gov.ru/epz/contractfz223/search/results.html?searchString=${item.contract_number}&morphology=on&statuses_0=on&statuses_1=on&statuses_2=on&statuses_3=on`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography variant="body2" style={{ color: '#2d64eb' }} component="div">
                          {item.contract_number}
                        </Typography>
                      </a>
                    </Box>
                  </TableCell>
                  <TableCell className={sc.tableBodyWide}>
                    {moment.utc(item.contract_date).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell className={sc.tableBodyWide}>
                    {numSeparator(item.amount)} {prettyCurrency(item.currency)}
                  </TableCell>
                  <TableCell className={sc.tableBodyWide}>{upperCaseFirst(item.subject)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  ); /* : (
    <Box mt={4} pl={2} className={sc.detailTitle} style={{ fontWeight: 'bold' }}>
      Ожидание загрузки...
    </Box>
  ); */
};
StateContractsTable.propTypes = {
  // empty: PropTypes.string,
  stateContracts: PropTypes.array,
  statistic: PropTypes.array,
  yearFrom: PropTypes.number,
  yearTo: PropTypes.number,
  // loaded: PropTypes.bool.isRequired,
};
StateContractsTable.defaultProps = {
  // empty: null,
  stateContracts: [], // { years: [], details: [] },
  statistic: [],
  yearFrom: 0,
  yearTo: 0,
};

export default StateContractsTable;
