import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import ICON_BELL from '../../../assets/img/icons/bell.svg';
import { useMedia } from 'use-media';
import { Link } from 'react-router-dom';
import { COLOR_WHITE } from '../../../constants/colors';
import { RESCORE_LOGO } from '../../../constants/icons';
import TextButton from '../../../components/Buttons/TextButton';
import { withLogoutButton } from '../../../utils/ButtonHocs';
import { HEADER_LOGOUT_BUTTON } from '../../../constants/testids';
import { mobileMedia } from '../../../constants/styles';

const Wrapper = styled.div`
  width: 100%;
  height: 72px;
  background: ${COLOR_WHITE};
  border-bottom: 1px solid #c2cfe0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 36px;
  padding-top: 26px;
  padding-bottom: 26px;
  @media (max-width: ${mobileMedia}) {
    justify-content: space-between;
    padding-right: 15px;
  }
`;

const Img = styled.img`
  display: block;
  width: 126px;
  margin-left: 24px;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

const Menu = styled.div`
  width: 24px;
  align-self: center;
  height: 24px;
  position: relative;
  cursor: pointer;
  display: block;
`;

const Span = styled.span`
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background: #f7685b;
  opacity: 1;
  left: 2px;
  top: 5px;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  &:nth-child(2) {
    top: 12px;
  }
  &:nth-child(3) {
    top: 19px;
  }
`;

// const Img = styled.img`
//   width: 16px;
//   height: 19.5px;
//   cursor: pointer;
//   display: block;
// `;

// const Image = styled.div`
//   position: relative;
//   margin: 0 23px;
//   &:before {
//     position: absolute;
//     content: '';
//     width: 8px;
//     height: 8px;
//     background: #f7685b;
//     border-radius: 100%;
//     cursor: pointer;
//     right: 0;
//     top: 0;
//   }
// `;

const HeaderMain = ({ onLogout, toggleOpenMenu, openLanguageSelector }) => {
  const mobMedia = useMedia({ maxWidth: 768 });
  const { t } = useTranslation();
  return (
    <Wrapper>
      {mobMedia ? (
        <Link to="/">
          <Img src={RESCORE_LOGO} />
        </Link>
      ) : null}
      <RightContent>
        {/* <Image data-test-id="TEST_BUTTON_BELL">
        <Img src={ICON_BELL} />
        </Image> */}
        <TextButton
          margin="30px"
          onClick={() => openLanguageSelector()}
          text={t('header.language')}
        />
        {!mobMedia && (
          <TextButton
            testID={HEADER_LOGOUT_BUTTON}
            onClick={() => onLogout()}
            text={t('header.logout')}
          />
        )}
        {mobMedia && (
          <Menu onClick={() => toggleOpenMenu()}>
            <Span />
            <Span />
            <Span />
          </Menu>
        )}
      </RightContent>
    </Wrapper>
  );
};

HeaderMain.propTypes = {
  onLogout: PropTypes.func.isRequired,
  toggleOpenMenu: PropTypes.func.isRequired,
  openLanguageSelector: PropTypes.func.isRequired,
};

const enhancer = withLogoutButton;

export default enhancer(HeaderMain);
