import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers } from 'recompose';
import storeNames from '../../../stores/storeNames';
import {
  BottomButtonWrapper,
  Buttons,
  DoubleWrapper,
  Img,
  Item,
  ItemBottom,
  Top,
  Wrapper,
} from './StatisticStyled';
import Paragraph from '../../../components/Typography/Paragraph';
import { ICON_RUBLE, ICON_SCORING } from '../../../constants/icons';
import { COLOR_ACTION_BLUE, COLOR_LINK_BLACK } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import { withUserData } from '../../../utils/hocs';

const Statistic = ({
  scoring,
  // monitoring,
  money,
  toggleOpenReplenishmentBalancePopup,
  toggleOpenPromoPopup,
  handlePurchase,
  accountId,
  isUser,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <DoubleWrapper>
        <Top>
          <Item>
            <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
              {t('services.scorings_statisticTitle')}
            </Paragraph>
            <ItemBottom>
              <Img src={ICON_SCORING} />
              <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
                {scoring}
              </Paragraph>
            </ItemBottom>
          </Item>
          {/* <Item>
            <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
              Мониторинги
            </Paragraph>
            <ItemBottom>
              <Img src={ICON_MONITORING} />
              <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
                {monitoring}
              </Paragraph>
            </ItemBottom>
          </Item> */}
        </Top>
        {!isUser && (
          <Buttons>
            <BottomButtonWrapper onClick={handlePurchase}>
              <Button width="233px" height="42px" lineHeight="15px" testID="TEST_BUTTON_PURCHASE">
                {t('services.buyServices')}
              </Button>
            </BottomButtonWrapper>
          </Buttons>
        )}
        <Buttons>
          <BottomButtonWrapper onClick={() => toggleOpenPromoPopup()}>
            <Button
              boxShadow="none"
              width="233px"
              height="42px"
              lineHeight="15px"
              backgroundColor="#fff"
              textColor={COLOR_ACTION_BLUE}
            >
              {t('services.activatePromo')}
            </Button>
          </BottomButtonWrapper>
        </Buttons>
      </DoubleWrapper>
      <DoubleWrapper>
        <Top>
          <Item>
            <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
              {t('services.money')}
            </Paragraph>
            <ItemBottom>
              <Img src={ICON_RUBLE} />
              <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
                {!money ? 0 : money}
              </Paragraph>
            </ItemBottom>
          </Item>
        </Top>
        {!isUser && (
          <Buttons>
            <BottomButtonWrapper onClick={() => toggleOpenReplenishmentBalancePopup()}>
              <Button
                boxShadow="none"
                width="233px"
                height="42px"
                lineHeight="15px"
                backgroundColor="#fff"
                textColor={COLOR_ACTION_BLUE}
                testID="TEST_BUTTON_REPLENISHMENT_SERVICES"
                disabled={!accountId}
              >
                {t('services.topUpAccount')}
              </Button>
            </BottomButtonWrapper>
          </Buttons>
        )}
      </DoubleWrapper>
    </Wrapper>
  );
};

Statistic.propTypes = {
  scoring: PropTypes.number,
  // monitoring: PropTypes.number,
  money: PropTypes.number,
  handlePurchase: PropTypes.func,
  toggleOpenPromoPopup: PropTypes.func.isRequired,
  toggleOpenReplenishmentBalancePopup: PropTypes.func.isRequired,
  isUser: PropTypes.bool.isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Statistic.defaultProps = {
  scoring: undefined,
  // monitoring: undefined,
  money: undefined,
  accountId: '',
  handlePurchase: () => {},
};

const enchance = compose(
  inject(storeNames.ServicesStore),
  observer,
  withUserData,
  withHandlers(({ ServicesStore }) => ({
    handlePurchase: () => () => {
      ServicesStore.setServicesTab('Purchase');
    },
  })),
);

export default enchance(Statistic);
