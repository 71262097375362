import styled from 'styled-components';
import {
  COLOR_BLACK_TEXT,
  COLOR_ACTION_BLUE,
  COLOR_GRAY,
  COLOR_DISABLED,
} from '../../../constants/colors';
import { ICON_EDIT, ICON_DELETE } from '../../../constants/icons';
import { tabletMedia } from '../../../constants/styles';

export const Wrapper = styled.div``;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  min-width: 880px;
`;
export const TrTop = styled.tr`
  border-bottom: 2px solid #dadada;
`;
export const Tr = styled.tr`
  border-bottom: 1px solid #dadada;
  pointer-events: ${props => (props.disabled ? 'none' : '')};
  color: ${props => (props.mark ? COLOR_ACTION_BLUE : '')};
  color: ${props => (props.disabled ? COLOR_DISABLED : '')};
  cursor: ${props => (props.pointer ? 'pointer' : '')};
`;
export const Thead = styled.thead``;
export const Tbody = styled.tbody`
  color: ${COLOR_BLACK_TEXT};
`;

export const Th = styled.th`
  font-size: 13px;
  line-height: 19px;
  color: #707683;
  text-align: left;
  font-weight: 600;
  font-family: Lato;
  padding-bottom: 11px;
  &.active {
    color: #109cf1;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TableButton = styled.button`
  position: relative;
  color: ${props => (props.disabled ? COLOR_DISABLED : COLOR_ACTION_BLUE)};
  border: none;
  background: none;
  font-weight: 600;
  font-family: Lato;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  outline: none;
  padding-left: 30px;
  @media (max-width: ${tabletMedia}) {
    padding-left: 10px;
    font-size: 12px;
  }
`;

export const Td = styled.td`
  cursor: ${props => (props.pointer ? 'pointer' : '')};
  color: ${props => (props.masked ? COLOR_GRAY : '')};
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  font-family: Lato;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 200px;
  padding-right: 15px;
`;

export const Tds = styled.td`
  cursor: ${props => (props.pointer ? 'pointer' : '')};
  color: ${props => (props.masked ? COLOR_GRAY : '')};
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  font-family: Lato;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 200px;
  padding-right: 15px;
`;

export const ImageTd = styled.td`
  cursor: ${props => (props.pointer ? 'pointer' : '')};
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: auto;
  padding-right: 2px;
  width: 60%;
`;

export const TdName = styled(Td)`
  font-family: Lato;
  font-weight: 600;
`;

export const TdButton = styled(Td)`
  max-width: 30px;
  padding-right: 5px;
`;

export const ButtonEdit = styled.button`
  font-size: 0;
  background: url(${ICON_EDIT}) no-repeat center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ButtonDelete = styled(ButtonEdit)`
  background: url(${ICON_DELETE}) no-repeat center;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-family: Lato;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #5c6470;
`;

export const Img = styled.img``;
export const ImgData = styled.img`
  margin-left: 10px;
`;

export const ActivityTd = styled.td`
  position: relative;
  @keyframes tooltips-vert {
    to {
      opacity: 0.9;
      transform: translate(0, -50%);
    }
  }
  &:hover {
    img {
      display: none;
    }
    &:after {
      content: 'Не опубликована';
      position: absolute;
      top: 50%;
      left: 0;
      background: #fff;
      z-index: 50;
      width: auto;
      height: 15px;
      font-size: 11px;
      border-right-width: 0;
      border-left-color: #333;
      animation: tooltips-vert 300ms ease-out forwards;
    }
  }
  &.signed {
    &:hover {
      &:after {
        content: 'Подписана успешно';
      }
    }
  }
  &.hint {
    &:hover {
      &:after {
        content: '${props => props.hint}';
      }
    }
  }
`;
