import React /* , { useRef, useState } */ from 'react';
import PropTypes from 'prop-types';
import { compose, /* withHandlers, withState, withProps, */ lifecycle } from 'recompose';
// import Typography from '../../../../components/Typography/Paragraph';
/* import {
    Title,
    Indent,
    Shift,
    Wrapper,
    FormWrapper,
    InputWrapper,
    InputWrapperNotes,
    TopText,
    TextWrapper,
    DateInputWrap,
    DateWrapper,
    ButtonWrapper,
    DateContent,
    TextArea,
    Description,
    TableContainer
  } from '../SharedInfoPopupStyled'; */
import { /* makeStyles, */ withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { COLOR_ACTION_BLUE } from '../../constants/colors';
import Button from '../../components/Buttons/Button';
import Popup from '../../components/Popup/Popup';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const ProgressPopup = ({ title, /* toggleProgressPopup, */ progress, cancel }) => {
  // if(progress == 100) toggleProgressPopup()
  return (
    <Popup width="600px" closePopup={() => {}} borderRadius="5px">
      <Box p={3}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" color="textPrimary">
            {/* progress !== 100 ? title : 'Завершение' */ title}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" pt={1}>
          <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              progress,
            )}%`}</Typography>
          </Box>
        </Box>

        <Box width="100%" display="flex" alignItems="center" justifyContent="center" pt={2}>
          <Button
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="6"
            testID="TEST_BUTTON_CANCEL"
            width="303px"
            textColor={COLOR_ACTION_BLUE}
            boxShadow="none"
            backgroundColor="#fff"
            sizes={{ margin: '0 20px' }}
            onClick={() => cancel()}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </Popup>
  );
};

ProgressPopup.propTypes = {
  title: PropTypes.string,
  // toggleProgressPopup: PropTypes.func.isRequired,
  progress: PropTypes.number,
  cancel: PropTypes.func,
};

ProgressPopup.defaultProps = {
  title: '',
  progress: 0,
  cancel: () => {},
};

const enchance = compose(
  // withProps(() => ({})),
  /* withHandlers(() => ({
    formHandler: (
      {
        // toggleProgressPopup,
      },
    ) => async e => {
      e.preventDefault();
      // toggleProgressPopup();
    },
  })), */
  lifecycle({
    async componentDidMount() {
      // const {
      // } = this.props;
    },
  }),
);

export default enchance(ProgressPopup);
