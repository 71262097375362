import {
  withState,
  compose,
  withHandlers,
  mapProps,
  withProps,
  withStateHandlers,
  lifecycle,
} from 'recompose';
import { inject, observer } from 'mobx-react';
import omit from 'lodash/fp/omit';
import { animateScroll as scroll } from 'react-scroll';
import { capitalizeFirstLetter } from './string';
import storeNames from '../stores/storeNames';
import {
  isAdmin,
  getAccessStatus,
  isUser,
  isCustomerAdmin,
  isUserRole,
  isOnlyCustomerAdmin,
} from '../helpers/roles';
import { validateOgrn, validateOgrnip, validateInn } from './entity-validators';
import { getEntityError } from './entityHelper';
import { SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT } from '../constants/roles';
import logger from '../logger';
import { hasDisclosure } from '../constants/env';

/**
 * Removes props from component
 */
export const omitProps = compose(
  mapProps,
  omit,
);

/**
 * Provides component with bool state, injects state with handlers
 * Example: withBoolState('isOpen', true) results in wrapped component having following props:
 * { isOpen: true, toggleIsOpen, isOpenOff, isOpenOn, setIsOpen }
 *
 * @param {string} boolName state key, e.g isOpen
 * @param {bool} initialState initial state (true/false)
 */
export const withBoolState = (boolName, initialState = false) => {
  if (!boolName || typeof boolName !== 'string') {
    throw new Error('invalid boolName');
  }
  if (typeof initialState !== 'boolean') {
    throw new Error('invalid initialState');
  }

  return compose(
    withState(boolName, 'setState', initialState),
    withHandlers({
      [`toggle${capitalizeFirstLetter(boolName)}`]: props => callback => {
        console.log(callback);
        return props.setState(!props[boolName], callback);
      },
      [`${boolName}Off`]: props => callback => props.setState(false, callback),
      [`${boolName}On`]: props => callback => props.setState(true, callback),
      [`set${capitalizeFirstLetter(boolName)}`]: props => (value, callback) =>
        props.setState(value, callback),
    }),
    omitProps(['setState']),
  );
};

/**
 *
 */
export const withHandlePressEnter = withHandlers({
  handlePressEnter: ({ formHandler }) => e => {
    if (typeof formHandler !== 'function') {
      throw new Error('invalid handler type, wait Function');
    }
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') {
      formHandler();
    }
  },
});

export const withScrollToTop = compose(
  withBoolState('scroll', false),
  withHandlers({
    scrollToTop: () => () => {
      scroll.scrollToTop();
    },
    checkScrollTop: ({ setScroll }) => () => {
      if (window.pageYOffset > 700) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { checkScrollTop } = this.props;
      window.scrollTo(0, 0);
      document.addEventListener('scroll', checkScrollTop);
    },
    componentWillUnmount() {
      const { checkScrollTop } = this.props;
      document.removeEventListener('scroll', checkScrollTop);
    },
  }),
);

/**
 * logic in homepage and request page for ogrn ibb ogrnip input
 */
export const withRequestInputState = compose(
  withStateHandlers(
    {
      entityState: { error: { code: '0', message: '', common: '' } },
    },
    {
      setEntity: () => value => {
        const error = { code: '0', message: '' };
        if (value.length > 12 && value.length < 15) {
          const result = validateOgrn(value, error);
          return { entityState: { state: value, isValid: result, error } };
        }

        if (value.length > 14) {
          const result = validateOgrnip(value, error);
          return { entityState: { state: value, isValid: result, error } };
        }

        const result = validateInn(value, error);
        return { entityState: { state: value, isValid: result, error } };
      },
      setEntityState: () => (error, data) => {
        const { message } = error;
        const { err, common } = getEntityError(message, data);
        return {
          entityState: {
            state: undefined,
            isValid: false,
            error: {
              ...error,
              message: err,
              common,
            },
          },
        };
      },
    },
  ),
  withStateHandlers(
    ({ setEntity }) => ({
      inputData: '',
      setEntity,
    }),
    {
      setInputData: ({ setEntity }) => value => {
        setEntity(value);
        return { inputData: value };
      },
    },
  ),
);
/**
 * Get user roles
 */
export const withUserRoles = compose(
  inject(storeNames.ProfileStore),
  observer,
  withProps(({ ProfileStore }) => ({
    userRoles:
      (ProfileStore.userData &&
        ProfileStore.userData.roles &&
        ProfileStore.userData.roles.map(item => item.role_name)) ||
      [],
  })),
);

/**
 * Get user types
 */
export const withUserTypes = compose(
  inject(storeNames.ProfileStore),
  observer,
  withProps(({ ProfileStore }) => ({
    userType:
      (ProfileStore.userData &&
        ProfileStore.userData.customer &&
        ProfileStore.userData.customer.type) ||
      '',
  })),
);

export const withUserData = compose(
  inject(storeNames.ProfileStore),
  withUserRoles,
  withProps(({ ProfileStore, userRoles }) => ({
    userData: ProfileStore.userData,
    userIsLoading: ProfileStore.isBusy,
    accountsIsLoading: ProfileStore.isAccontsBusy,
    isAdmin: isAdmin({ userRoles }),
    isUser: isUser({ userRoles }),
    isUserRole: isUserRole({ userRoles }),
    isCustomerAdmin: isCustomerAdmin({ userRoles }),
    isOnlyCustomerAdmin: isOnlyCustomerAdmin({ userRoles }),
    isAdminCustomer: isAdmin({ userRoles }) && ProfileStore.userData.customer_id === 0,
    userType:
      (ProfileStore.userData &&
        ProfileStore.userData.customer &&
        ProfileStore.userData.customer.type) ||
      'INDIVIDUAL',
  })),
);

export const withFetchUserInDidMount = compose(
  inject(storeNames.ProfileStore),
  observer,
  withProps(({ ProfileStore }) => ({
    fetchUser: ProfileStore.fetchUser,
    userIsLoading: ProfileStore.isBusy,
    userId: ProfileStore.userData.id,
  })),
  lifecycle({
    async componentDidMount() {
      const { fetchUser } = this.props;
      await fetchUser();
    },
  }),
);

export const withFetchAccessRequestCountInDidMount = compose(
  inject(storeNames.SharedInfoStore),
  observer,
  withProps(({ SharedInfoStore }) => ({
    fetchAccessRequestCount: SharedInfoStore.fetchAccessRequestCount,
    accessRequestCount: SharedInfoStore.accessRequestCount,
  })),
  lifecycle({
    async componentDidMount() {
      const { fetchAccessRequestCount } = this.props;
      if (hasDisclosure) {
        await fetchAccessRequestCount();
      }
    },
  }),
);

export const withPasswordChangeType = withStateHandlers(
  {
    showCurrentPassword: 'password',
    showNewPassword: 'password',
    showConfirmPassword: 'password',
  },
  {
    toggleCurrentPasswordType: showCurrentPassword => () => ({
      showCurrentPassword: `${
        showCurrentPassword.showCurrentPassword === 'password' ? 'text' : 'password'
      }`,
    }),
    toggleNewPasswordType: showNewPassword => () => ({
      showNewPassword: `${showNewPassword.showNewPassword === 'password' ? 'text' : 'password'}`,
    }),
    toggleConfirmPasswordType: showConfirmPassword => () => ({
      showConfirmPassword: `${
        showConfirmPassword.showConfirmPassword === 'password' ? 'text' : 'password'
      }`,
    }),
  },
);

export const withMountState = compose(
  withState('mount', 'setMount', false),
  lifecycle({
    componentDidMount() {
      const { setMount } = this.props;
      setMount(true);
    },
    componentWillUnmount() {
      const { setMount } = this.props;
      setMount(false);
    },
  }),
);

export const withAccounts = compose(
  withProps(({ AccountStore }) => ({
    account:
      (AccountStore.account.length &&
        AccountStore.account.map(item => ({
          ...item,
          value: item.id,
          text: item.full_name,
        }))) ||
      [],
    accounts:
      (AccountStore.accounts.length &&
        AccountStore.accounts.map(item => ({
          ...item,
          value: item.id,
          text: item.full_name,
        }))) ||
      [],
  })),
);

export const withActiveValue = (
  hasActive = true,
  hasTariff = true,
  hasTable = true,
  hasPurchases = true,
  hasPayments = true,
  hasInquiries = true,
) => {
  return compose(
    inject(
      storeNames.ServicesStore,
      storeNames.AccountStore,
      storeNames.BalanceStore,
      storeNames.ProfileStore,
      storeNames.InquiriesStore,
    ),
    observer,
    withProps(({ AccountStore, ProfileStore, InquiriesStore }) => ({
      accountValue: AccountStore.accountValue,
      userData: ProfileStore.userData,
      /** */
      fetchUser: ProfileStore.fetchUser,
      userIsLoading: ProfileStore.isBusy,
      inquiriesLoading: InquiriesStore.isBusyInquiries,
      userId: ProfileStore.userData.id,
      /** */
      userRoles:
        (ProfileStore.userData &&
          ProfileStore.userData.roles &&
          ProfileStore.userData.roles.map(item => item.role_name)) ||
        [],
    })),
    withState('offset', 'setOffset', 0),
    withState('page', 'setPage', 1),
    withHandlers(({ AccountStore }) => ({
      setAccountValue: () => accountValue => {
        AccountStore.addAccountValue(accountValue);
      },
    })),
    lifecycle({
      async componentDidMount() {
        const {
          AccountStore,
          ServicesStore,
          BalanceStore,
          accountValue,
          InquiriesStore,
          setOffset,
          offset,
          page,
          setPage,
          fetchUser,
        } = this.props;
        const user = await fetchUser();
        const { fetchMyAccounts, addAccountValue, fetchMyAccount } = AccountStore;
        const { fetchStats, fetchActiveTariffs, fetchHistory } = ServicesStore;
        const { fetchPurchases, fetchPayments } = BalanceStore;
        const { fetchInquiries } = InquiriesStore;
        const userRoles =
          (user && user.roles && user.roles.map(item => item.role_name)) || undefined;

        const hasUser = !getAccessStatus({
          userRoles,
          allowedRoles: [CUSTOMER_ADMIN_ROLE, SUPER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT],
        });

        if ((user.customer_id === 0 && hasUser) || hasUser) {
          if (user.account_id !== null) {
            await fetchMyAccount(user.account_id);
          }
        } else {
          await fetchMyAccounts({ qnt: 100000, offset });
        }

        if (userRoles && userRoles.length) {
          const hasAccess = getAccessStatus({
            userRoles,
            allowedRoles: [SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT],
          });

          if (user && !user.account_id && !hasAccess) {
            logger.error(`User ${user.id} haven't access to accounts`);
            return;
          }
        }

        let account;
        if ((user.customer_id === 0 && hasUser) || hasUser) {
          account =
            (AccountStore.account.length &&
              user &&
              user.account_id &&
              AccountStore.account.find(item => item.id === user.account_id)) ||
            (AccountStore.account.length &&
              AccountStore.account.find(item => item.is_base === true)) ||
            (AccountStore.account.length && {
              ...AccountStore.account[0],
              text: AccountStore.account[0].full_name,
              value: AccountStore.account[0].id,
            }) ||
            '';
        } else {
          account =
            (AccountStore.accounts.length &&
              user &&
              user.account_id &&
              AccountStore.accounts.find(item => item.id === user.account_id)) ||
            (AccountStore.accounts.length &&
              AccountStore.accounts.find(item => item.is_base === true)) ||
            (AccountStore.accounts.length && {
              ...AccountStore.accounts[0],
              text: AccountStore.accounts[0].full_name,
              value: AccountStore.accounts[0].id,
            }) ||
            '';
        }

        if (account) {
          if (!accountValue.id) {
            addAccountValue({ ...account, text: account.full_name, value: account.id });
            if (hasTariff) await fetchStats(account.id);
            if (hasActive) await fetchActiveTariffs(account.id);
            if (hasTable) {
              const items = await fetchHistory({ offset }, account.id);
              setOffset(items.length);
            }
            if (hasPurchases) {
              const items = await fetchPurchases({ offset }, account.id);
              setOffset(items.length);
            }
            if (hasPayments) {
              const items = await fetchPayments({ offset }, account.id);
              setOffset(items.length);
            }
            if (hasInquiries) {
              await fetchInquiries({ page }, account.id);
              setPage(page + 1);
            }
          }
          if (accountValue.id) {
            if (hasTariff) await fetchStats(accountValue.id);
            if (hasActive) await fetchActiveTariffs(accountValue.id);
            if (hasTable) {
              const items = await fetchHistory({ offset }, accountValue.id);
              setOffset(items.length);
            }
            if (hasPurchases) {
              const items = await fetchPurchases({ offset }, accountValue.id);
              setOffset(items.length);
            }
            if (hasPayments) {
              const items = await fetchPayments({ offset }, accountValue.id);
              setOffset(items.length);
            }
            if (hasInquiries) {
              await fetchInquiries({ page }, accountValue.id);
              setOffset(page + 1);
            }
          }
        }
      },
    }),
  );
};
