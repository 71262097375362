import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import * as moment from 'moment';
// import logger from '../../../logger';
import { useStyles } from './Styles/styles';
import { numSeparator } from './Utils/utils';

const keyList = [
  { key: 'main_status_description', title: 'Статус', format: a => a },
  { key: 'ogrn', title: 'ОГРН', format: a => a },
  { key: 'inn', title: 'ИНН', format: a => a },
  { key: 'kpp', title: 'КПП', format: a => a },
  {
    key: 'registration_date',
    title: 'Дата регистрации',
    format: a => (a ? moment.utc(a).format('DD.MM.YYYY') : ''),
  },
  // { key: 'okved_code', title: 'Код ведения деятельности', format: a => a },
  { key: 'main_okved', title: 'Основная деятельность', format: a => a && a.description },
  {
    key: 'authorized_capital',
    title: 'Уставный капитал',
    format: a => (a ? `${numSeparator(a)} руб` : ''),
  },
];

const EntityCard = ({ /* entity, */ entityDetail }) => {
  const classes = useStyles();
  return (
    <Card id="entity_card" className={classes.root} variant="outlined">
      <CardContent className={classes.title}>
        <Typography gutterBottom variant="h5" component="h2">
          {entityDetail.name_short ? entityDetail.name_short : ''}&nbsp;
        </Typography>
        <Typography className={classes.titleFullName} variant="caption" component="div">
          {entityDetail.name_full ? entityDetail.name_full : ''}&nbsp;
        </Typography>
      </CardContent>
      <Divider />
      <CardContent className={classes.body}>
        {keyList.map((item, index) => (
          <Grid
            key={index}
            className={classes.indent}
            container
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="textSecondary" component="div">
              {item.title}:
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              style={{ width: '50%', textAlign: 'right' }}
            >
              {item.format(entityDetail[item.key])}
            </Typography>
          </Grid>
        ))}
      </CardContent>
      {/* <CardActions className={classes.body}>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
          </CardActions> */}
    </Card>
  );
};
EntityCard.propTypes = {
  // entity: PropTypes.object.isRequired,
  entityDetail: PropTypes.object.isRequired,
};

export default EntityCard;
