import styled from 'styled-components';
import { TitleText } from '../../components/Typography/Title';
import { COLOR_TITLE_BLACK, COLOR_ACTION_BLUE } from '../../constants/colors';
import { mobileMedia, tabletMedia, tabletSmMedia } from '../../constants/styles';

export const Title = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-right: 20px;
  @media (max-width: ${tabletSmMedia}) {
    margin-right: 0px;
  }
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

export const Wrapper = styled.div`
  padding-left: 32px;
  padding-right: 22px;
  position: relative;
  @media (max-width: ${tabletSmMedia}) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    padding-left: 3px;
    padding-right: 3px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 55px;
  @media (max-width: ${tabletSmMedia}) {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 303px;
  @media (max-width: ${mobileMedia}) {
    width: 100%;
    max-width: 360px;
  }
`;

export const Tabs = styled.div`
  border-bottom: 1px solid #c2cfe0;
  margin-bottom: 60px;
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
  @media (max-width: 960px) {
    margin-bottom: 20px;
  }
`;

export const TabsItem = styled.button`
  outline: none;
  color: ${COLOR_ACTION_BLUE};
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-family: Lato;
  padding: 0;
  padding-bottom: 15px;
  margin-right: 48px;
  position: relative;
  &.active {
    color: #334d6e;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 4px;
      background: #334d6e;
      bottom: 0;
      left: 0;
    }
  }
  @media (max-width: ${tabletMedia}) {
    margin-right: 50px;
    font-size: 22px;
  }
  @media (max-width: ${mobileMedia}) {
    font-size: 18px;
    padding-bottom: 8px;
    margin-right: 16px;
  }
`;
