import styled from 'styled-components';
import { COLOR_ACTION_BLUE, COLOR_WHITE } from '../../../constants/colors';
import { tabletSmMedia, mobileMedia } from '../../../constants/styles';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 102px;
  @media (max-width: ${tabletSmMedia}) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: ${mobileMedia}) {
    margin-bottom: 35px;
  }
`;

export const DoubleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 42px;
  padding-top: 16px;
  margin-right: 36px;
  width: 321px;
  @media (max-width: ${tabletSmMedia}) {
    margin: 0px;
    margin-top: 30px;
  }
  @media (max-width: ${mobileMedia}) {
    width: 100%;
    max-width: 360px;
    margin-top: 12px;
  }
`;

export const ItemWrapper = styled.div`
  width: 321px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 42px;
  padding-top: 16px;
  margin-right: 36px;
  justify-content: center;
  @media (max-width: ${tabletSmMedia}) {
    margin: 0px;
    margin-top: 30px;
  }
  @media (max-width: ${mobileMedia}) {
    width: 100%;
    max-width: 360px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const Img = styled.img`
  width: 37px;
  height: 37px;
  margin-right: 15px;
  margin-bottom: 7px;
`;

export const BottomButtonWrapper = styled.div`
  margin-top: 15px;
  @media (max-width: ${mobileMedia}) {
    display: flex;
    justify-content: center;
  }
`;

export const Buttons = styled.div``;

export const ButtonReturn = styled.button`
  outline: none;
  border: none;
  background: ${COLOR_WHITE};
  color: ${COLOR_ACTION_BLUE};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 42px;
  margin-bottom: 10px;
  font-family: Lato;
`;
