import React from 'react';
import { compose, withHandlers, withProps, withState, lifecycle } from 'recompose';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Wrapper, AuthoForm, RegLink } from '../Login/AuthorizationStyled';
import {
  withNewPasswordState,
  withConfirmPasswordState,
  withEmailState,
} from '../../utils/FormHocs';
import { TEST_BUTTON_RESET_PASSWORD } from '../../constants/testids';
import { COLOR_ACTION_BLUE, COLOR_WHITE } from '../../constants/colors';
import storeNames from '../../stores/storeNames';
import Button from '../../components/Buttons/Button';
import {
  Title,
  Par,
  InputWrapper,
  PopupBody,
  BottomOffset,
  BottomOffsetBig,
} from './RecoveryStyled';
import {
  ImgShape,
  ParagraphError,
} from '../Profile/BottomContent/PasswordSection/PasswordSectionStyled';
import InputField from '../../components/Inputs/InputField';
import { ICON_SHAPE } from '../../constants/icons';
import { prepareValidatePassword } from '../../helpers/validatePassword';
import { withPasswordChangeType } from '../../utils/hocs';
import { getUrlObject, removeUrlParams, getOrigin } from '../../helpers/url';
import history from '../../history';
import {
  STATUS_NEW_PASSWORD_SETTED,
  STATUS_RECOVERY_TOKEN_SENDED,
  STATUS_PASSWORD_RESET_ALREADY_SUCCEED,
} from '../../constants/statusPageUrls';
import { RECOVERY_ERRORS } from '../../constants/errors';
import { mapErrorsByCode } from '../../helpers/errors';
import { toLowerCaseAndTrim } from '../../utils/validators';

const CreateNewPasswordText = () => (
  <>
    <Trans i18nKey="recovery.CreateNewPasswordText">
      <Par>Придумайте надежный пароль,</Par>
      <Par>который содержит желательно:</Par>
      <Par>заглавные, строчные, цифры и символы</Par>
    </Trans>
  </>
);

const PrepareNewPassword = () => (
  <>
    <Trans i18nKey="recovery.PrepareNewPassword">
      <Par>Укажите E-mail, использованный при</Par>
      <Par>регистрации. На него будет выслана</Par>
      <Par>ссылка на восстановление пароля.</Par>
    </Trans>
  </>
);

const RecoveryComponent = ({
  formHandler,
  isBusy,
  newPassword,
  showNewPassword,
  inputHandlerNewPassword,
  toggleNewPasswordType,
  newPasswordError,
  confirmPassword,
  showConfirmPassword,
  inputHandlerConfirmPassword,
  toggleConfirmPasswordType,
  confirmPasswordError,
  email,
  emailValid,
  emailError,
  inputHandlerEmail,
  passwordResetId,
  passwordResetCode,
}) => {
  const { t } = useTranslation();
  const isPrepare = !passwordResetCode || !passwordResetId;
  return (
    <PopupBody>
      <Wrapper>
        <Title as="h2">{(isPrepare && t('recovery.forbidPass')) || t('recovery.newPass')}</Title>
        <AuthoForm onSubmit={formHandler}>
          {isPrepare && (
            <>
              <BottomOffset>
                <PrepareNewPassword />
              </BottomOffset>
              <BottomOffsetBig>
                <InputWrapper>
                  <InputField
                    placeholder={t('recovery.emailAddress')}
                    name="email"
                    value={email}
                    isValid={emailValid}
                    onChange={inputHandlerEmail}
                  />
                  <ParagraphError>{t(emailError)}</ParagraphError>
                </InputWrapper>
              </BottomOffsetBig>
            </>
          )}
          {!isPrepare && (
            <>
              <CreateNewPasswordText />
              <BottomOffsetBig>
                <InputWrapper>
                  <InputField
                    placeholder={t('recovery.newPass')}
                    margin="24px 0"
                    name="newPassword"
                    value={newPassword}
                    autoComplete="new-password"
                    type={showNewPassword}
                    onChange={inputHandlerNewPassword}
                  />
                  <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} />
                  <ParagraphError>{newPasswordError}</ParagraphError>
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    placeholder={t('recovery.repeatNewPass')}
                    margin="24px 0"
                    name="confirmPassword"
                    autoComplete="new-password"
                    value={confirmPassword}
                    type={showConfirmPassword}
                    onChange={inputHandlerConfirmPassword}
                  />
                  <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
                  <ParagraphError>{confirmPasswordError}</ParagraphError>
                </InputWrapper>
              </BottomOffsetBig>
            </>
          )}

          <BottomOffset>
            <Button
              width="100%"
              textColor={COLOR_WHITE}
              backgroundColor={COLOR_ACTION_BLUE}
              type="submit"
              disabled={isBusy}
              data-test-id={TEST_BUTTON_RESET_PASSWORD}
            >
              {(isPrepare && t('recovery.send')) || t('recovery.savePass')}
            </Button>
          </BottomOffset>
          <RegLink data-test-id="TEST_LINK_REGISTRATION" to="/login">
            {t('recovery.backToAuth')}
          </RegLink>
        </AuthoForm>
      </Wrapper>
    </PopupBody>
  );
};

RecoveryComponent.propTypes = {
  confirmPassword: PropTypes.string,
  newPassword: PropTypes.string,
  showNewPassword: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  newPasswordError: PropTypes.string,
  passwordResetId: PropTypes.string,
  passwordResetCode: PropTypes.string,
  isBusy: PropTypes.bool,
  toggleConfirmPasswordType: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  inputHandlerConfirmPassword: PropTypes.func,
  inputHandlerNewPassword: PropTypes.func,
  formHandler: PropTypes.func,
  email: PropTypes.string,
  emailValid: PropTypes.bool,
  emailError: PropTypes.string,
  inputHandlerEmail: PropTypes.func,
};

RecoveryComponent.defaultProps = {
  confirmPassword: '',
  newPassword: '',
  email: '',
  showNewPassword: '',
  showConfirmPassword: '',
  confirmPasswordError: '',
  newPasswordError: '',
  passwordResetId: '',
  passwordResetCode: '',
  emailError: '',
  isBusy: false,
  emailValid: false,
  formHandler: () => {},
  toggleConfirmPasswordType: () => {},
  toggleNewPasswordType: () => {},
  inputHandlerConfirmPassword: () => {},
  inputHandlerNewPassword: () => {},
  inputHandlerEmail: () => {},
};

export const RecoveryPage = compose(
  inject(storeNames.RecoveryStore),
  observer,
  withNewPasswordState,
  withConfirmPasswordState,
  withPasswordChangeType,
  withEmailState,
  withProps(({ RecoveryStore }) => ({
    isBusy: RecoveryStore.isBusy,
    prepareRecovery: RecoveryStore.prepareRecovery,
    resetAndSetNewPassword: RecoveryStore.resetAndSetNewPassword,
  })),
  withState('newPasswordError', 'setNewPasswordError', ''),
  withState('confirmPasswordError', 'setConfirmPasswordError', ''),
  withState('passwordResetId', 'setPasswordResetId', ''),
  withState('passwordResetCode', 'setPasswordResetCode', ''),
  withHandlers(() => ({
    preparePassword: ({ email, prepareRecovery, setEmailError, setEmailValid }) => async () => {
      const collbackUrl = `${getOrigin()}`;
      const statusUrl = STATUS_RECOVERY_TOKEN_SENDED(`${collbackUrl}/login`);
      const error = await prepareRecovery(
        { email: toLowerCaseAndTrim(email), returnUrl: `${collbackUrl}/recovery` },
        () => {
          history.replace(statusUrl);
        },
      );

      if (error) {
        setEmailError(mapErrorsByCode(error, RECOVERY_ERRORS));
        setEmailValid(false);
      }
    },
    resetPassword: ({
      resetAndSetNewPassword,
      passwordResetId,
      passwordResetCode,
      newPassword,
    }) => async () => {
      const collbackUrl = `${getOrigin()}/`;
      const statusUrl = STATUS_NEW_PASSWORD_SETTED(`${collbackUrl}login`);
      const error = await resetAndSetNewPassword(
        { passwordResetId, passwordResetCode, newPassword },
        () => {
          history.replace(statusUrl);
        },
      );

      if (error) {
        const url = STATUS_PASSWORD_RESET_ALREADY_SUCCEED(
          `${collbackUrl}login`,
          mapErrorsByCode(error, RECOVERY_ERRORS),
        );
        history.replace(url);
      }
    },
  })),
  withHandlers(() => ({
    formHandler: ({
      newPassword,
      setNewPasswordError,
      setConfirmPasswordError,
      confirmPassword,
      passwordResetId,
      passwordResetCode,
      resetPassword,
      preparePassword,
      email,
      emailValid,
    }) => async e => {
      e.preventDefault();

      if (passwordResetCode && passwordResetId) {
        const passwordIsValid = prepareValidatePassword({
          newPassword,
          setNewPasswordError,
          setConfirmPasswordError,
          confirmPassword,
        });

        if (passwordIsValid) {
          await resetPassword();
        }
      }

      if (email && emailValid && (!passwordResetCode || !passwordResetId)) {
        await preparePassword();
      }
    },
  })),
  lifecycle({
    componentDidMount() {
      const { setPasswordResetId, setPasswordResetCode } = this.props;
      const url = getUrlObject();
      const resetId = url.searchParams.get('passwordResetId');
      const resetCode = url.searchParams.get('passwordResetCode');
      setPasswordResetId(resetId);
      setPasswordResetCode(resetCode);
      removeUrlParams();
    },
  }),
)(RecoveryComponent);
