import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLOR_WHITE } from '../../constants/colors';
import { tabletMedia } from '../../constants/styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
`;

const Span = styled.span`
  position: absolute;
  top: ${props => (props.top ? props.top : '50%')};
  transform: ${props => (props.transform ? props.transform : 'translate(-50%, -50%)')};
  left: 0;
  height: 18px;
  width: 18px;
  background: ${COLOR_WHITE};
  border: 2px solid rgba(6, 115, 154, 0.5);
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Label = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  color: ${props => (props.textColor ? props.textColor : '#5c6470')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
  font-size: 16px;
  padding-left: 35px;
  user-select: none;
  font-family: Lato;
  @media (max-width: ${tabletMedia}) {
    font-size: 14px;
    padding-right: 15px;
    padding-left: 15px;
  }

  ${Input}:checked ~ ${Span} {
    border: 2px solid #06739a;
  }

  ${Input}:checked ~ ${Span}:after {
    display: block;
  }

  ${Span}:after {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #06739a;
  }
`;

const RadioInput = ({
  onClick,
  children,
  name,
  checked,
  value,
  testID,
  textColor,
  fontWeight,
  top,
  transform,
  tabIndex,
}) => (
  <Wrapper onClick={e => onClick(e.target.value)}>
    <Label textColor={textColor} fontWeight={fontWeight}>
      {children}
      <Input
        type="radio"
        data-test-id={testID}
        name={name}
        tabIndex={tabIndex}
        defaultChecked={checked}
        value={value}
      />
      <Span top={top} transform={transform} />
    </Label>
  </Wrapper>
);

RadioInput.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  testID: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  tabIndex: PropTypes.string,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  top: PropTypes.string,
  transform: PropTypes.string,
};

RadioInput.defaultProps = {
  onClick: () => {},
  name: '',
  tabIndex: '',
  children: undefined,
  testID: undefined,
  checked: false,
  value: '',
  textColor: '',
  fontWeight: '',
  top: '',
  transform: '',
};

export default RadioInput;
