import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import { useTranslation } from 'react-i18next';
import { withBoolState, withFetchUserInDidMount, withUserData } from '../../utils/hocs';
import { Wrapper, Title, Top, Button } from './UsersStyled';
import Table from './Table/Table';
import AddUserPopup from './Popups/AddUserPopup/AddUserPopup';
import EditUserPopup from './Popups/EditUserPopup/EditUserPopup';
import { RoleRender } from '../../components/RoleComponents';
import { CUSTOMER_ADMIN_ROLE } from '../../constants/roles';

const Users = ({
  confirmed,
  toggleConfirmed,
  addUserPopup,
  toggleAddUserPopup,
  editUserPopup,
  toggleEditUserPopup,
  roleSelectValue,
  setRoleSelectValue,
  setItemId,
  setPersonalAccountValue,
  personalAccountValue,
  itemId,
  userId,
  setOneOffset,
  oneOffset,
  init,
  setInit,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <Top>
          <Title as="h2">{t('sidebar.users')}</Title>
          <RoleRender allowedRoles={[CUSTOMER_ADMIN_ROLE]}>
            <Button data-test-id="TEST_BUTTON_ADD_USER" onClick={() => toggleAddUserPopup()}>
              {t('users.addUser')}
            </Button>
          </RoleRender>
        </Top>
        <Table
          confirmed={confirmed}
          toggleConfirmed={toggleConfirmed}
          editUserPopup={editUserPopup}
          toggleEditUserPopup={toggleEditUserPopup}
          addUserPopup={addUserPopup}
          setItemId={setItemId}
          userId={userId}
          setOneOffset={setOneOffset}
          oneOffset={oneOffset}
          init={init}
          setInit={setInit}
        />
      </Wrapper>
      {addUserPopup === true ? (
        <AddUserPopup
          roleSelectValue={roleSelectValue}
          setRoleSelectValue={setRoleSelectValue}
          toggleAddUserPopup={toggleAddUserPopup}
          setPersonalAccountValue={setPersonalAccountValue}
          personalAccountValue={personalAccountValue}
        />
      ) : null}
      {editUserPopup === true ? (
        <EditUserPopup
          id={itemId}
          userId={userId}
          roleSelectValue={roleSelectValue}
          setRoleSelectValue={setRoleSelectValue}
          toggleEditUserPopup={toggleEditUserPopup}
          setPersonalAccountValue={setPersonalAccountValue}
          personalAccountValue={personalAccountValue}
          setOneOffset={setOneOffset}
        />
      ) : null}
    </>
  );
};

Users.propTypes = {
  confirmed: PropTypes.bool,
  toggleConfirmed: PropTypes.func.isRequired,
  toggleAddUserPopup: PropTypes.func.isRequired,
  toggleEditUserPopup: PropTypes.func.isRequired,
  setRoleSelectValue: PropTypes.func.isRequired,
  setItemId: PropTypes.func.isRequired,
  setPersonalAccountValue: PropTypes.func.isRequired,
  roleSelectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  personalAccountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  addUserPopup: PropTypes.bool.isRequired,
  editUserPopup: PropTypes.bool.isRequired,
  itemId: PropTypes.number,
  userId: PropTypes.number,
  oneOffset: PropTypes.number,
  setOneOffset: PropTypes.func,
  setInit: PropTypes.func,
  init: PropTypes.bool,
};

Users.defaultProps = {
  confirmed: false,
  itemId: undefined,
  userId: undefined,
  roleSelectValue: '',
  personalAccountValue: '',
  setOneOffset: () => {},
  setInit: () => {},
  oneOffset: 0,
  init: false,
};

const enhance = compose(
  withFetchUserInDidMount,
  withUserData,
  withBoolState('addUserPopup', false),
  withBoolState('confirmed', false),
  withBoolState('editUserPopup', false),
  withState('init', 'setInit', false),
  withState('oneOffset', 'setOneOffset', 0),
  withState('roleSelectValue', 'setRoleSelectValue', ''),
  withState('itemId', 'setItemId', undefined),
  withState('personalAccountValue', 'setPersonalAccountValue', ''),
);

export default enhance(Users);
