import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose, withHandlers } from 'recompose';
import storeNames from '../../../stores/storeNames';
import Popup from '../../../components/Popup/Popup';
import InputField from '../../../components/Inputs/InputField';
import Paragraph from '../../../components/Typography/Paragraph';
import { hasValue } from '../../../utils/validators';
import { Wrapper, InputWrapper, FormWrapper, Title, TextArea, ButtonWrapper } from './PopupsStyled';
import { COLOR_BLACK_LOGO, COLOR_ACTION_BLUE } from '../../../constants/colors';
import { withBalanceInputSetStore, withMoneyState, withReasonState } from '../../../utils/FormHocs';
import ParagraphError from '../../../components/Typography/ParagraphError';
import Button from '../../../components/Buttons/Button';

const ReturnRequestPopup = ({
  money,
  reason,
  inputHandlerReason,
  toggleOpenReturnRequestPopup,
  inputSetStore,
  formHandler,
  moneyError,
  inputHandlerMoney,
}) => {
  const { t } = useTranslation();
  return (
    <Popup
      width="600px"
      boxShadow="0px 7px 48px rgba(0, 0, 0, 0.08);"
      borderRadius="4px"
      closePopup={toggleOpenReturnRequestPopup}
    >
      <Wrapper>
        <Title as="h2">{t('balance.refundRequest')}</Title>
        <FormWrapper onSubmit={formHandler}>
          <InputWrapper>
            <Paragraph
              textColor={COLOR_BLACK_LOGO}
              fontSize="18px"
              fontWeight="500"
              textAlign="center"
            >
              {t('balance.writeAmount')}
            </Paragraph>
            <InputField
              placeholder={t('balance.amount')}
              name="money"
              value={money}
              onChange={value => {
                inputSetStore(value, inputHandlerMoney, 'money');
              }}
            />
            <ParagraphError>{t(moneyError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <Paragraph
              textColor={COLOR_BLACK_LOGO}
              fontSize="18px"
              fontWeight="500"
              textAlign="center"
            >
              {t('balance.writeRefundReason')}
            </Paragraph>
            <TextArea
              placeholder={t('balance.writeRefundReason')}
              name="reason"
              value={reason}
              onChange={event => {
                inputSetStore(event, inputHandlerReason, 'reason');
              }}
            />
          </InputWrapper>
          <ButtonWrapper>
            <Button testID="TEST_BUTTON_SEND" width="233px" type="submit">
              {t('form.send')}
            </Button>
            <Button
              testID="TEST_BUTTON_CANCEL"
              width="233px"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              type="submit"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleOpenReturnRequestPopup()}
            >
              {t('form.cancel')}
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

ReturnRequestPopup.propTypes = {
  inputSetStore: PropTypes.func,
  toggleOpenReturnRequestPopup: PropTypes.func.isRequired,
  formHandler: PropTypes.func,
  money: PropTypes.string,
  reason: PropTypes.string,
  moneyError: PropTypes.string,
  inputHandlerMoney: PropTypes.func,
  inputHandlerReason: PropTypes.func,
};

ReturnRequestPopup.defaultProps = {
  money: '',
  moneyError: '',
  reason: '',
  inputSetStore: () => {},
  formHandler: () => {},
  inputHandlerMoney: () => {},
  inputHandlerReason: () => {},
};

const enchance = compose(
  inject(storeNames.BalanceStore, storeNames.IndicatorsStore),
  observer,
  withTranslation(),
  withBalanceInputSetStore,
  withMoneyState,
  withReasonState,
  withHandlers(() => ({
    formHandler: ({
      money,
      moneyValid,
      toggleOpenReturnRequestPopup,
      setMoneyError,
      IndicatorsStore,
      t,
    }) => async e => {
      // const { t } = useTranslation();
      e.preventDefault();

      if (moneyValid && !hasValue(money)) {
        setMoneyError(t('balance.writeAmount'));
        IndicatorsStore.addErrorIndicators({
          message: t('balance.checkFieldsCorrection'),
          type: 'error',
        });
      }

      if (moneyValid && hasValue(money)) {
        toggleOpenReturnRequestPopup();
        IndicatorsStore.addErrorIndicators({
          message: t('balance.requestSent'),
          type: 'success',
        });
      } else {
        IndicatorsStore.addErrorIndicators({
          message: t('balance.checkFieldsCorrection'),
          type: 'error',
        });
      }
    },
  })),
);

export default enchance(ReturnRequestPopup);
