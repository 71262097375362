import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose, withHandlers, withState, withProps } from 'recompose';
import storeNames from '../../stores/storeNames';
import Popup from '../../components/Popup/Popup';
import InputField from '../../components/Inputs/InputField';
import Paragraph from '../../components/Typography/Paragraph';
import { hasValue } from '../../utils/validators';
import { getUrlObject } from '../../helpers/url';
import {
  Wrapper,
  InputWrapper,
  FormWrapper,
  Title,
  ButtonWrapper,
  MethodsWrapper,
  CardMethod,
  Img,
} from './PopupsStyled';
import { STATUS_AFTER_PAYMENT, getUrl } from '../../constants/statusPageUrls';
import ParagraphError from '../../components/Typography/ParagraphError';
import { COLOR_BLACK_LOGO, COLOR_ACTION_BLUE } from '../../constants/colors';
import { withBalanceInputSetStore, withMoneyState, withReasonState } from '../../utils/FormHocs';
import Button from '../../components/Buttons/Button';
import { ICON_CARD, ICON_SCORE, ICON_SCORE_ACTIVE, ICON_CARD_ACTIVE } from '../../constants/icons';
import { hasRefillByCard } from '../../constants/env';

const ReplenishmentPopup = ({
  money,
  toggleOpenReplenishmentBalancePopup,
  inputSetStore,
  formHandler,
  moneyError,
  inputHandlerMoney,
  setCardMethod,
  cardMethod,
}) => {
  const { t } = useTranslation();
  return (
    <Popup
      width="600px"
      boxShadow="0px 7px 48px rgba(0, 0, 0, 0.08);"
      borderRadius="4px"
      closePopup={toggleOpenReplenishmentBalancePopup}
    >
      <Wrapper>
        <Title as="h2">{t('balance.balanceTopUp')}</Title>
        <FormWrapper onSubmit={formHandler}>
          <InputWrapper>
            <Paragraph
              textColor={COLOR_BLACK_LOGO}
              fontSize="18px"
              fontWeight="500"
              textAlign="center"
            >
              {t('balance.writeAmount')}
            </Paragraph>
            <InputField
              placeholder={t('balance.amount')}
              name="money"
              value={money}
              onChange={value => {
                inputSetStore(value, inputHandlerMoney, 'money');
              }}
            />
            <ParagraphError>{t(moneyError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <Paragraph
              textColor={COLOR_BLACK_LOGO}
              fontSize="18px"
              fontWeight="500"
              textAlign="center"
            >
              {t('balance.topUpCase')}
            </Paragraph>
            <MethodsWrapper>
              <CardMethod
                disabled={!hasRefillByCard}
                className={cardMethod === 'Card' ? 'active' : null}
                onClick={() => hasRefillByCard && setCardMethod('Card')}
                data-test-id="TEST_BUTTON_CARD"
              >
                <Img src={cardMethod === 'Card' ? ICON_CARD_ACTIVE : ICON_CARD} />
                <Paragraph
                  textColor={COLOR_BLACK_LOGO}
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="24px"
                  textAlign="center"
                >
                  {t('balance.bankCards')}
                </Paragraph>
              </CardMethod>
              <CardMethod
                className={cardMethod === 'Counter' ? 'active' : null}
                onClick={() => setCardMethod('Counter')}
                data-test-id="TEST_BUTTON_COUNTER"
              >
                <Img src={cardMethod === 'Counter' ? ICON_SCORE_ACTIVE : ICON_SCORE} />
                <Paragraph
                  textColor={COLOR_BLACK_LOGO}
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="24px"
                  textAlign="center"
                >
                  {t('services.invoiceForPayment')}
                </Paragraph>
              </CardMethod>
            </MethodsWrapper>
          </InputWrapper>
          <ButtonWrapper>
            <Button testID="TEST_BUTTON_SEND" width="233px" type="submit">
              {t('services.pay')}
            </Button>
            <Button
              testID="TEST_BUTTON_CANCEL"
              width="233px"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              type="submit"
              sizes={{ margin: '0 0 0 20px' }}
              onClick={() => toggleOpenReplenishmentBalancePopup()}
            >
              {t('form.cancel')}
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

ReplenishmentPopup.propTypes = {
  inputSetStore: PropTypes.func,
  toggleOpenReplenishmentBalancePopup: PropTypes.func.isRequired,
  formHandler: PropTypes.func,
  money: PropTypes.string,
  cardMethod: PropTypes.string,
  moneyError: PropTypes.string,
  inputHandlerMoney: PropTypes.func,
  setCardMethod: PropTypes.func,
};

ReplenishmentPopup.defaultProps = {
  money: '',
  moneyError: '',
  cardMethod: '',
  inputSetStore: () => {},
  formHandler: () => {},
  inputHandlerMoney: () => {},
  setCardMethod: () => {},
};

const enchance = compose(
  inject(storeNames.BalanceStore, storeNames.IndicatorsStore),
  observer,
  withTranslation(),
  withBalanceInputSetStore,
  withMoneyState,
  withReasonState,
  withState('cardMethod', 'setCardMethod', 'Counter'),
  withProps(({ BalanceStore }) => ({
    contract: BalanceStore.contract,
  })),
  withHandlers(() => ({
    formHandler: ({
      toggleOpenReplenishmentBalancePopup,
      money,
      moneyValid,
      setMoneyError,
      IndicatorsStore,
      cardMethod,
      BalanceStore,
      accountValue,
      t,
    }) => async e => {
      e.preventDefault();

      if (!accountValue.id) {
        IndicatorsStore.addErrorIndicators({
          message: t('services.noAvailableAccounts'),
          type: 'error',
        });
        return;
      }

      if (moneyValid && !hasValue(money)) {
        setMoneyError(t('balance.writeAmount'));
        IndicatorsStore.addErrorIndicators({
          message: t('balance.checkFieldsCorrection'),
          type: 'error',
        });
      }

      if (!cardMethod) {
        IndicatorsStore.addErrorIndicators({
          message: t('services.selectPaymentMethod'),
          type: 'error',
        });
      }

      if (moneyValid && hasValue(money) && cardMethod === 'Card') {
        const collbackUrl = getUrlObject();
        const data = {
          amount: Number(money),
          is_return: false,
          redirect_url: `${getUrl()}${STATUS_AFTER_PAYMENT(collbackUrl.href, 'balance')}`,
          returned_payment_id: null,
        };

        localStorage.setItem('ACCOUNT_ID', accountValue.id);
        await BalanceStore.buyCard(accountValue.id, data);
        window.location.href = BalanceStore.buyUrl.yk_confirmation_url;
      }
      if (moneyValid && hasValue(money) && cardMethod === 'Counter') {
        const data = {
          amount: Number(money),
          is_return: false,
          redirect_url: ``,
          returned_payment_id: null,
          with_contract: true,
        };

        await BalanceStore.buyCard(accountValue.id, data);
        if (BalanceStore.contract !== '') {
          const link = document.createElement('a');
          link.href = BalanceStore.contract;
          link.download = 'contract.docx';
          link.click();
          await BalanceStore.deleteContract();
          IndicatorsStore.addErrorIndicators({
            message: t('services.invoiceDownloaded'),
            type: 'success',
          });
          toggleOpenReplenishmentBalancePopup();
        } else {
          IndicatorsStore.addErrorIndicators({
            message: t('services.errorHasOccurred'),
            type: 'error',
          });
        }
      }
    },
  })),
);

export default enchance(ReplenishmentPopup);
