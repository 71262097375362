import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { inject, observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import { Wrapper, TableContainer, Tr, TrTop, Th, Td, Button, Thead, Tbody } from './TableStyled';
import storeNames from '../../../stores/storeNames';
import { isAuthenticated } from '../../../services/session';
import history from '../../../history';
import Parapgraph from '../../../components/Typography/Paragraph';

const Table = ({ customers, fetchCustomers, isBusy, loginByCustomer, hasMoreItems, addFilter }) => {
  return (
    <Wrapper>
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => fetchCustomers()}
        hasMore={hasMoreItems}
        style={{ overflow: 'auto' }}
      >
        <TableContainer>
          <Thead>
            <TrTop>
              <Th className="active" onClick={() => addFilter('full_name')}>
                Наименование
              </Th>
              <Th className="active" onClick={() => addFilter('inn')}>
                ИНН
              </Th>
              <Th className="active" onClick={() => addFilter('ogrn')}>
                ОГРН
              </Th>
              <Th className="active" onClick={() => addFilter('created_at')}>
                Дата регистрации
              </Th>
              {/* <Th>На счету, Р.</Th> */}
              <Th />
            </TrTop>
          </Thead>
          <Tbody>
            {customers &&
              customers.map(item => {
                const fullName =
                  item.full_name || (item.fname || (item.sname && `${item.fname} ${item.sname}`));
                const INN = item.inn || 'Не указан';
                const OGRN = item.ogrn || 'Не указан';
                const createdAt = moment(item.created_at).format('DD.MM.YYYY');
                return (
                  <Tr key={item.id}>
                    <Td>
                      {fullName && <Parapgraph>{fullName}</Parapgraph>}
                      <Parapgraph>{item.email}</Parapgraph>
                    </Td>
                    <Td>{INN}</Td>
                    <Td>{OGRN}</Td>
                    <Td>{createdAt}</Td>
                    {/* <Td>{item.customer.money_in_the_account}</Td> */}
                    <Td>
                      <Button
                        data-test-id="TEST_BUTTON_CONNECT"
                        onClick={() => loginByCustomer(item.id, fullName || item.email)}
                      >
                        Подключиться
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </TableContainer>
      </InfiniteScroll>
      {isBusy && <div>Идёт обновление данных</div>}
    </Wrapper>
  );
};

Table.propTypes = {
  customers: PropTypes.array,
  isBusy: PropTypes.bool.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  fetchCustomers: PropTypes.func.isRequired,
  loginByCustomer: PropTypes.func.isRequired,
  addFilter: PropTypes.func,
};

Table.defaultProps = { addFilter: () => {}, customers: [] };

const enhance = compose(
  inject(storeNames.CustomerStore, storeNames.ProfileStore, storeNames.AccountStore),
  observer,
  withProps(({ CustomerStore, ProfileStore }) => ({
    customers: CustomerStore.сustomers,
    isBusy: CustomerStore.isBusy,
    hasMoreItems: CustomerStore.hasMoreItems,
    fetchCustomerAdmins: CustomerStore.fetchCustomerAdmins,
    addSortAndOrder: CustomerStore.addSortAndOrder,
    getTokenBySuperAdmin: ProfileStore.getTokenBySuperAdmin,
  })),
  withState('offset', 'setOffset', 0),
  withHandlers(() => ({
    fetchCustomers: ({ CustomerStore, fetchCustomerAdmins, setOffset, offset }) => async () => {
      const customers = await fetchCustomerAdmins({ offset, q: CustomerStore.qCustomer });
      setOffset(customers.length);
    },
  })),
  withHandlers(({ offset }) => ({
    loginByCustomer: ({ AccountStore, getTokenBySuperAdmin }) => async (userId, customerName) => {
      const adminAcc = await AccountStore.fetchCustomerAdmin(userId);
      if (!adminAcc) {
        return;
      }
      await getTokenBySuperAdmin({ userId: adminAcc.id, customerName });
      if (isAuthenticated()) {
        AccountStore.addAccountValue({});
        history.replace('/profile');
      }
    },
    addFilter: ({
      CustomerStore,
      fetchCustomerAdmins,
      addSortAndOrder,
      setOffset,
    }) => async value => {
      addSortAndOrder(value);
      const qnt = offset >= 15 ? offset : 15;
      const customers = await fetchCustomerAdmins({ qnt, offset: 0, q: CustomerStore.qCustomer });
      setOffset(customers.length);
    },
    fetchCustomersByQuery: ({
      fetchCustomerAdmins,
      setOffset,
      inputData,
      CustomerStore,
    }) => async () => {
      setOffset(0);
      CustomerStore.addQCustomer(inputData);
      const customers = await fetchCustomerAdmins({ offset, q: CustomerStore.qCustomer });
      setOffset(customers.length);
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { fetchCustomerAdmins, setOffset, offset, fetchCustomersByQuery } = this.props;
      const customers = await fetchCustomerAdmins({ offset });
      setOffset(customers.length);
      const $button = document.querySelector('[data-test-id=TEST_ID_CUSTOMERS_SEARCH]');
      if (!$button) return;
      document
        .querySelector('[data-test-id=TEST_ID_CUSTOMERS_SEARCH]')
        .addEventListener('click', fetchCustomersByQuery);
    },
    componentWillUnmount() {
      const { CustomerStore } = this.props;
      CustomerStore.addSortAndOrder('');
      CustomerStore.deleteOrder();
    },
  }),
);

export default enhance(Table);
