import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const ParapgraphText = styled.p`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '22px')};
  text-align: ${({ textAlign }) => textAlign || undefined};
  margin: ${props => (props.margin ? props.margin : '0')};
  color: ${props => (props.textColor ? props.textColor : '#707683')};
`;

const Parapgraph = ({
  as = 'p',
  children,
  textColor,
  textAlign,
  fontSize,
  margin,
  lineHeight,
  fontWeight,
}) => (
  <ParapgraphText
    textColor={textColor}
    as={as}
    fontWeight={fontWeight}
    textAlign={textAlign}
    fontSize={fontSize}
    margin={margin}
    lineHeight={lineHeight}
  >
    {children}
  </ParapgraphText>
);

Parapgraph.propTypes = {
  as: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
  lineHeight: PropTypes.string,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  children: PropTypes.any.isRequired,
};

Parapgraph.defaultProps = {
  as: 'p',
  textAlign: '',
  lineHeight: undefined,
  fontSize: undefined,
  margin: undefined,
  textColor: undefined,
  fontWeight: undefined,
  //  children: '',
};

export default Parapgraph;
