/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, withHandlers } from 'recompose';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import Paragraph from '../Typography/Paragraph';
import { ICON_CLOSE } from '../../constants/icons';
import { mobileMedia } from '../../constants/styles';
import storeNames from '../../stores/storeNames';

const setTypeIndicator = type => {
  if (type === 'success') {
    return '#84E6B1';
  }
  if (type === 'error') {
    return '#FCC0BB';
  }
  return '#FFF7AD';
};

const Wrapper = styled.div`
  position: fixed;
  width: 52%;
  height: 66px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding-left: 50px;
  padding-right: 50px;
  left: 50%;
  z-index: 10000;
  top: 25px;
  margin-left: -26%;
  cursor: pointer;
  background: ${props => (props.errors.length > 0 ? setTypeIndicator(props.errors[0].type) : null)};
  &.active {
    display: flex;
  }
  &:before {
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    background: url(${ICON_CLOSE});
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
  @media (max-width: ${mobileMedia}) {
    width: 98%;
    height: auto;
    padding: 15px;
    left: 1%;
    margin-left: 0;
    &:before {
      display: none;
    }
  }
`;

class Indicator extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (!this.ref.current) return;
    const isOutside = !this.ref.current.contains(e.target);
    const { handlerIndicator } = this.props;
    if (isOutside) {
      handlerIndicator();
    }
  };

  render() {
    const { errors, handlerIndicator } = this.props;
    return (
      <Wrapper
        ref={this.ref}
        errors={errors}
        onClick={handlerIndicator}
        className={errors.length > 0 ? 'active' : null}
      >
        <Paragraph fontSize="14px" textColor="#192A3E">
          {errors.length > 0 ? errors[0].message : ''}
        </Paragraph>
      </Wrapper>
    );
  }
}

Indicator.propTypes = {
  errors: PropTypes.array,
  handlerIndicator: PropTypes.func,
};

Indicator.defaultProps = {
  errors: [],
  handlerIndicator: () => {},
};

const enchance = compose(
  inject(storeNames.IndicatorsStore),
  observer,
  withProps(({ IndicatorsStore }) => ({
    errors: IndicatorsStore.errors,
  })),
  withHandlers(() => ({
    handlerIndicator: ({ IndicatorsStore }) => () => {
      IndicatorsStore.deleteErrorIndicators();
    },
  })),
);

export default enchance(Indicator);
