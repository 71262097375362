import API from './base';
import { getUrlToLKApi } from '../constants/api';
import { createQueryString } from '../helpers/url';

export default class CustomersApi extends API {
  createUser(data, token = null) {
    return this.post(getUrlToLKApi('auth/registration'), data, token, false);
  }

  getCount(token = null) {
    return this.get(getUrlToLKApi(`customers/count`), token, false);
  }

  fetchCustomerAdmins({ qnt = 20, offset = 0, q = '', sort = '', order = '' }, token = null) {
    return this.get(
      getUrlToLKApi(`customers/?${createQueryString({ qnt, offset, q, sort, order })}`),
      token,
      false,
    );
  }

  fetchCustomerAdmin(customerId, token = null) {
    return this.get(getUrlToLKApi(`customers/${customerId}/customerAdmin`), token, false);
  }

  editCustomer(customerId, data, token = null) {
    return this.put(getUrlToLKApi(`customers/${customerId}`), data, token, false);
  }

  editCustomerBankInfo(customerId, data, token = null) {
    return this.put(getUrlToLKApi(`customers/${customerId}/bankInfo`), data, token, false);
  }

  setUserBlocked({ customerId, userId, blocked = false }, token = null) {
    return this.put(
      getUrlToLKApi(`customers/${customerId}/users/${userId}/setBlocked`),
      { blocked },
      token,
      false,
    );
  }

  getCustomer(id, token = null) {
    return this.get(getUrlToLKApi(`customers/${id}`), token, false);
  }

  getCustomerBankInfo(id, token = null) {
    return this.get(getUrlToLKApi(`customers/${id}/bankInfo`), token, false);
  }

  updateUserPassword({ customerId, userId, old_password, new_password }, token = null) {
    return this.put(
      getUrlToLKApi(`/customers/${customerId}/users/${userId}/changePassword`),
      { old_password, new_password },
      token,
      false,
    );
  }

  updateNewUserPassword(customerId, userId, new_password, token = null) {
    return this.put(
      getUrlToLKApi(`/customers/${customerId}/users/${userId}/changePassword`),
      { new_password },
      token,
      false,
    );
  }

  syncLocale(customerId, userId, lang, token = null) {
    return this.put(
      getUrlToLKApi(`customers/${customerId}/users/${userId}/lang`),
      { lang },
      token,
      false,
    );
  }
}
