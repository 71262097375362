export const BALANCE_TYPES = {
  REPLENISHMENT: 'Replenishment',
  DEBIT: 'Debit',
};

export const STATUS = {
  PENDING: 'pending',
  CANCELED: 'canceled',
  SUCCEEDED: 'succeeded',
};
