import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose, withHandlers, lifecycle } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import {
  withDateFromState,
  withDateToState,
  withProfileInputSetStore,
} from '../../../utils/FormHocs';
import { withActiveValue } from '../../../utils/hocs';
import {
  Top,
  RightContainer,
  WrapperDate,
  DateInputWrap,
  ButtonWrapper,
  TableWrapper,
  TableContainer,
  Thead,
  TrTop,
  Th,
  Tbody,
  Tr,
  Td,
  TdDebit,
  TdDescription,
  TdButton,
  ButtonEdit,
} from './TableStyled';
import Button from '../../../components/Buttons/Button';
import Paragraph from '../../../components/Typography/Paragraph';
import storeNames from '../../../stores/storeNames';
import InputDate from '../../../components/Inputs/InputDate';

const TableDebit = ({
  dateFrom,
  dateTo,
  inputHandlerDateTo,
  inputHandlerDateFrom,
  inputSetStore,
  handlerButton,
  fetchMoreData,
  purchases,
  hasMoreItemsPurchases,
  addFilter,
  handleAct,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Top>
        <RightContainer>
          <Paragraph fontSize="16px" textColor="#2E3238">
            {t('services.period')}
          </Paragraph>
          <WrapperDate>
            <DateInputWrap>
              <InputDate
                padding="12px 19px"
                name="dateFrom"
                margin="0 8px 0 0"
                value={dateFrom}
                onChange={value => {
                  inputSetStore(value, inputHandlerDateFrom, 'dateFrom');
                }}
              />
              —
              <InputDate
                padding="12px 19px"
                name="dateTo"
                value={dateTo}
                margin="0 0 0 8px"
                onChange={value => {
                  inputSetStore(value, inputHandlerDateTo, 'dateTo');
                }}
              />
            </DateInputWrap>
          </WrapperDate>
          <ButtonWrapper>
            <Button
              minWidth="127px"
              height="42px"
              lineHeight="15px"
              onClick={() => handlerButton()}
            >
              {t('services.show')}
            </Button>
          </ButtonWrapper>
        </RightContainer>
      </Top>
      {purchases.length > 0 ? (
        <TableWrapper>
          <InfiniteScroll
            initialLoad={false}
            loadMore={() => fetchMoreData()}
            hasMore={hasMoreItemsPurchases}
            style={{ overflow: 'auto' }}
          >
            <TableContainer>
              <Thead>
                <TrTop>
                  <Th className="active" onClick={() => addFilter('created_at')} width="200px">
                    {t('services.dateTime')}
                  </Th>
                  <Th width="150px">{t('balance.debit')}</Th>
                  <Th>{t('balance.description')}</Th>
                  <Th>{t('balance.customerFullName')}</Th>
                  <Th />
                </TrTop>
              </Thead>
              <Tbody>
                {purchases &&
                  purchases.map(item => (
                    <Tr key={item.id}>
                      <Td>{`${moment(item.created_at).format('DD.MM.YYYY - H:mm:ss')}`}</Td>
                      <TdDebit>
                        {item.tariff.price
                          ? `- ${item.tariff.price * item.qnt.toLocaleString('ru-RU')}`
                          : null}
                      </TdDebit>
                      <TdDescription>
                        {!item.description
                          ? `${t('services.serviceBuying')} ${item.tariff.full_name} ${
                              item.qnt > 1 ? ` x${item.qnt}` : ''
                            }
                         `
                          : item.description}
                      </TdDescription>
                      <Td>{`${item.user.sname} ${item.user.fname} ${item.user.mname}`}</Td>
                      <TdButton>
                        <ButtonEdit
                          data-test-id="TEST_BUTTON_ACT"
                          onClick={() => handleAct(item.id)}
                        >
                          act
                        </ButtonEdit>
                      </TdButton>
                    </Tr>
                  ))}
              </Tbody>
            </TableContainer>
          </InfiniteScroll>
        </TableWrapper>
      ) : (
        t('balance.noPurchaseHistory')
      )}
    </>
  );
};

TableDebit.propTypes = {
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  inputHandlerDateTo: PropTypes.func,
  inputHandlerDateFrom: PropTypes.func,
  inputSetStore: PropTypes.func,
  handlerButton: PropTypes.func,
  fetchMoreData: PropTypes.func,
  handleAct: PropTypes.func,
  purchases: PropTypes.array,
  addFilter: PropTypes.func,
  hasMoreItemsPurchases: PropTypes.bool,
};

TableDebit.defaultProps = {
  dateFrom: undefined,
  dateTo: undefined,
  hasMoreItemsPurchases: undefined,
  purchases: [],
  inputHandlerDateTo: () => {},
  inputHandlerDateFrom: () => {},
  inputSetStore: () => {},
  handlerButton: () => {},
  fetchMoreData: () => {},
  addFilter: () => {},
  handleAct: () => {},
};

const enchance = compose(
  inject(storeNames.BalanceStore, storeNames.IndicatorsStore),
  observer,
  withTranslation(),
  withActiveValue(false, false, false, true, false, false),
  withProfileInputSetStore,
  withDateFromState,
  withDateToState,
  withHandlers(() => ({
    addFilter: ({ offset, setOffset, BalanceStore, accountValue }) => async value => {
      BalanceStore.addSortAndOrderPurchases(value);
      const qnt = offset >= 15 ? offset : 15;
      const items = await BalanceStore.fetchPurchases(
        {
          dateFrom: BalanceStore.dateFromPurchases,
          dateTo: BalanceStore.dateToPurchases,
          qnt,
          offset: 0,
        },
        accountValue.id,
      );
      setOffset(items.length);
    },
    handleAct: ({ BalanceStore, IndicatorsStore, accountValue, t }) => async id => {
      // const { t } = useTranslation();
      await BalanceStore.getActs(accountValue.id, id);
      if (BalanceStore.act !== '') {
        const link = document.createElement('a');
        link.href = BalanceStore.act.act_download_url;
        link.download = 'act.pdf';
        link.click();
      } else {
        IndicatorsStore.addErrorIndicators({
          message: t('services.errorHasOccurred'),
          type: 'error',
        });
      }
    },
    handlerButton: ({ dateFrom, dateTo, BalanceStore, accountValue }) => async () => {
      BalanceStore.addDatePurchases({ dateFrom, dateTo });
      await BalanceStore.fetchPurchases(
        { dateFrom: BalanceStore.dateFromPurchases, dateTo: BalanceStore.dateToPurchases },
        accountValue.id,
      );
    },
    fetchMoreData: ({ BalanceStore, setOffset, offset, accountValue }) => async () => {
      const items = await BalanceStore.fetchPurchases(
        { dateFrom: BalanceStore.dateFromPurchases, dateTo: BalanceStore.dateToPurchases, offset },
        accountValue.id,
      );
      setOffset(items.length);
    },
  })),
  lifecycle({
    componentWillUnmount() {
      const { BalanceStore } = this.props;
      BalanceStore.addSortAndOrderPurchases('id');
      BalanceStore.deleteOrderPurchases();
    },
  }),
);

export default enchance(TableDebit);
