import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Img = styled.img(({ size }) => ({
  display: 'block',
  maxWidth: size || 'auto',
  maxheight: size || 'auto',
  width: '100%',
}));

export const Icon = ({ internal, image, size }) => (
  <>{internal && image && <Img src={image} size={size} />}</>
);

Icon.propTypes = {
  internal: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Icon.defaultProps = {
  internal: true,
  image: undefined,
  size: undefined,
};
