import { getI18n } from 'react-i18next';
import logger from '../logger';
import history from '../history';
import { __DEV__ } from '../constants/env';
import { AUTH_URL } from '../constants/routes';
import { removeToken, getToken } from '../helpers';
import { removeSuperAdminToken, getSuperAdminToken } from '../helpers/session';
import { mapErrorsByCode } from '../helpers/errors';

const TIMEOUT = 30000; // 30 sec timeout
let alert = () => {};

function fetchWithTimeout(...args) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const t = setTimeout(() => {
      resolve({ status: 0, error: 'Request timed out' });
    }, TIMEOUT);
    try {
      const res = await fetch(...args);
      resolve(res);
    } catch (err) {
      reject(err);
    } finally {
      clearTimeout(t);
    }
  });
}

function xhrWithTimeout(uri, params, callback) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const t = setTimeout(() => {
      resolve({ status: 0, error: 'Request timed out' });
    }, params.timeout);
    try {
      const request = new XMLHttpRequest();
      request.open(params.method, uri);
      // eslint-disable-next-line
      for (const pair of params.headers.entries()) {
        request.setRequestHeader(pair[0], pair[1]);
      }

      request.addEventListener('progress', e => {
        // download progress as percentage
        const percent_completed = (e.loaded / e.total) * 100;
        // console.log('Download percent:', percent_completed);
        callback(percent_completed);
      });

      request.upload.addEventListener('progress', e => {
        // upload progress as percentage
        const percent_completed = (e.loaded / e.total) * 100;
        // console.log('Upload percent:', percent_completed);
        callback(percent_completed);
      });

      // eslint-disable-next-line no-use-before-define, no-unused-vars
      request.addEventListener('load', e => {
        // HTTP status message (200, 404 etc)
        // console.log(request.status);
        // console.log(request.response);
        resolve(request);
      });

      request.addEventListener('error', e => {
        logger.error('MULTIPART ERROR:', e);
        reject(e);
      });

      request.upload.addEventListener('error', e => {
        logger.error('MULTIPART ERROR:', e);
        reject(e);
      });

      request.send(params.body);
    } finally {
      clearTimeout(t);
    }
  });
}

export default class BaseAPI {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error('Base url is required');
    }
    this.baseUrl = baseUrl;
    this.baseHeaders = new Headers();
    this.baseHeaders.append('Content-Type', 'application/json');
    this.baseHeaders.append('Accept', 'application/json');
    this.reset();
  }

  static setAlert(alertFunc) {
    alert = alertFunc;
    this.alert = alertFunc;
  }

  reset = () => {
    this.abortController = new AbortController();
  };

  break = () => {
    this.abortController.abort();
  };

  authHeaders = token => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Accept', 'application/json');
    return headers;
  };

  post(url, body, token = null, showErrorScreenOnFail) {
    const uri = `${this.baseUrl}/${url}`;
    const params = {
      method: 'POST',
      headers: token ? this.authHeaders(token) : this.baseHeaders,
      body: JSON.stringify(body),
    };
    // if (!Config.__TEST__) {
    logger.info('POST: ', uri, params);
    // }
    return fetchWithTimeout(uri, params)
      .catch(BaseAPI.throwConnectionError)
      .then(async response => {
        await BaseAPI.validateResponse(response, showErrorScreenOnFail);
        return response;
      })
      .then(response => response.json());
  }

  patch(url, body, token = null, showErrorScreenOnFail) {
    const uri = `${this.baseUrl}/${url}`;
    const params = {
      method: 'PATCH',
      headers: token ? this.authHeaders(token) : this.baseHeaders,
      body: JSON.stringify(body),
    };
    // if (!Config.__TEST__) {
    logger.info('PATCH: ', uri, params);
    // }
    return fetchWithTimeout(uri, params)
      .catch(BaseAPI.throwConnectionError)
      .then(async response => {
        await BaseAPI.validateResponse(response, showErrorScreenOnFail);
        return response;
      })
      .then(response => response.json());
  }

  put(url, body, token = null, showErrorScreenOnFail) {
    const uri = `${this.baseUrl}/${url}`;
    const params = {
      method: 'PUT',
      headers: token ? this.authHeaders(token) : this.baseHeaders,
      body: JSON.stringify(body),
    };
    // if (!Config.__TEST__) {
    logger.info('PUT: ', uri, params);
    // }
    return fetchWithTimeout(uri, params)
      .catch(BaseAPI.throwConnectionError)
      .then(async response => {
        await BaseAPI.validateResponse(response, showErrorScreenOnFail);
        return response;
      })
      .then(response => response.json());
  }

  get(url, token = null, showErrorScreenOnFail, raw = false, baseUrl = null) {
    // if (!Config.__TEST__) {
    // eslint-disable-next-line no-param-reassign
    if (baseUrl === null) baseUrl = this.baseUrl;
    logger.info(`GET: ${baseUrl}/${url}`);
    // }
    return fetchWithTimeout(`${baseUrl}/${url}`, {
      method: 'GET',
      headers: token ? this.authHeaders(token) : this.baseHeaders,
      signal: this.abortController.signal,
      // mode: nocors ? 'no-cors' : 'cors',
    })
      .catch(BaseAPI.throwConnectionError)
      .then(async response => {
        if (response) await BaseAPI.validateResponse(response, showErrorScreenOnFail, raw);
        return response;
      })
      .then(response => {
        if (response) return raw ? response.blob() /* arrayBuffer() */ : response.json();
        throw new Error('Break');
      });
  }

  delete(url, body, token = null, showErrorScreenOnFail) {
    // if (!Config.__TEST__) {
    logger.info(`DELETE: ${this.baseUrl}/${url}`);
    // }
    return fetchWithTimeout(`${this.baseUrl}/${url}`, {
      method: 'DELETE',
      headers: token ? this.authHeaders(token) : this.baseHeaders,
      body: JSON.stringify(body),
    })
      .catch(BaseAPI.throwConnectionError)
      .then(async response => {
        await BaseAPI.validateResponse(response, showErrorScreenOnFail);
        return response;
      })
      .then(response => response.json());
  }

  /**
   * Throws connection error.
   */
  static throwConnectionError(error) {
    if (error.name === 'AbortError') return;
    const i18n = getI18n();
    const err = `⚠️ ${i18n.t('messages.SERVER_CONNECTION_ERROR')}`;
    logger.error(err);
    alert(err);
    throw new Error(error);
  }

  /**
   * Validates HTTP response and throws error if something goes wrong.
   * @param {Response} response
   * @param showErrorScreenOnFail
   */
  static async validateResponse(response, showErrorScreenOnFail = false, raw = false) {
    // Redirect user to login screen if he is not authorized
    // Actions.reset(routes.welcome);
    if (response.status === 401) {
      if (getToken() !== null || getSuperAdminToken() !== null) {
        removeToken();
        removeSuperAdminToken();
        history.push(AUTH_URL);
        window.location.reload();
        return Promise.resolve(true);
      }
      removeToken();
      removeSuperAdminToken();
      history.push(AUTH_URL);
      return Promise.resolve(true);
    }
    if (response.status >= 400 || response.error) {
      // if (!Config.__TEST__) {
      logger.error(response);
      // }
      try {
        const response2 = response.clone();
        const ret = await response2.json();
        if ('code' in ret) response.error = mapErrorsByCode(ret.code);
        // eslint-disable-next-line no-empty
      } catch (e) {}

      const err =
        'error' in response ? response.error : `${response.status} - ${response.statusText}`;
      logger.error(`Ошибка: ${err}`);

      if (showErrorScreenOnFail) {
        // Actions.push(routes.error);
        history.push('/error');
        return Promise.resolve(false);
      }

      await alert(`⚠️ ${err}`);
      // return Promise.reject(new Error(`Ошибка: ${err}`));
      // return Promise.resolve(false);

      // if error screen is not shown caller should handler error by himself
      // return Promise.reject(new Error('Response validation'));
    }

    // if (Config.__TEST__) return Promise.resolve(true);
    if (__DEV__ && typeof response.clone === 'function' && !raw) {
      const clonedResponse = response.clone();
      let body;
      try {
        body = await clonedResponse.text();
      } catch (e) {
        body = null;
      }
      logger.info({
        response,
        body,
      });
    } else {
      logger.info(response);
    }

    return Promise.resolve(true);
  }

  sendMultipart(method, url, formData, token = null, showErrorScreenOnFail, progressCallback) {
    const uri = `${this.baseUrl}/${url}`;

    const headers = new Headers();
    // headers.append('Content-Type', 'multipart/form-data');
    if (token) headers.append('Authorization', `Bearer ${token}`);
    // headers.append('Accept', 'application/json');

    const params = {
      method,
      headers,
      body: formData,
      timeout: TIMEOUT,
    };
    // if (!Config.__TEST__) {
    logger.info('MULTIPART: ', uri, params);
    // }

    return xhrWithTimeout(uri, params, progressCallback)
      .catch(BaseAPI.throwConnectionError)
      .then(async response => {
        // console.log('finish');
        await BaseAPI.validateResponse(response, showErrorScreenOnFail);
        return JSON.parse(response.response);
      });
    // .then(response => response.json());
  }
}
