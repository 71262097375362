import React from 'react';
import styled from 'styled-components';
import { withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import ICON_CHECK from '../../assets/img/icons/check.svg';
import { COLOR_GRAY } from '../../constants/colors';

const Wrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: flex-start;
`;

const Checkbox = styled.div`
  border-radius: 4px;
  border: 2px solid ${COLOR_GRAY};
  margin-right: ${props => (props.marginRight ? props.marginRight : '22px')};
  min-width: ${props => (props.size ? props.size : '18px')};
  height: ${props => (props.size ? props.size : '18px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Content = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #5c6470;
`;

const Img = styled.img`
  display: block;
  margin: 0 auto;
  width: ${props => (props.size ? props.size : '')};
  height: ${props => (props.size ? props.size : '')};
`;

const CheckBox = ({
  children,
  onChange,
  onKeyPress,
  checked,
  testID,
  tabIndex,
  justifyContent,
  marginRight,
  size,
}) => {
  const sizebox = size !== 0 ? `${size}px` : '';
  const sizecheck = size !== 0 ? `${size / 2}px` : '';
  return (
    <Wrapper onClick={onChange} justifyContent={justifyContent}>
      <Checkbox
        size={sizebox}
        data-test-id={testID}
        onKeyPress={onKeyPress}
        tabIndex={tabIndex}
        marginRight={marginRight}
      >
        {checked && <Img size={sizecheck} src={ICON_CHECK} />}
      </Checkbox>
      <Content>{children}</Content>
    </Wrapper>
  );
};

CheckBox.propTypes = {
  size: PropTypes.number,
  testID: PropTypes.string,
  marginRight: PropTypes.string,
  tabIndex: PropTypes.string,
  justifyContent: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
};

CheckBox.defaultProps = {
  size: 0,
  checked: false,
  testID: undefined,
  tabIndex: '',
  marginRight: '',
  justifyContent: '',
  onChange: () => {},
  children: '',
};

const enhance = withHandlers(() => ({
  onKeyPress: ({ onChange }) => e => {
    if (e.charCode === 13 || e.keyCode === 13) {
      onChange();
    }
  },
}));

export default enhance(CheckBox);
