import React from 'react';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Td } from '../Table/TableStyled';

const StyledDateTd = styled(Td)`
  div + div {
    margin-top: 16px;
  }
`;

const DateTd = ({ item }) => {
  const { t } = useTranslation();
  return (
    <StyledDateTd>
      <div>
        {`${moment(item.created_at).format('DD.MM.YYYY - H:mm:ss')}`}
        <span style={{ marginLeft: 16 }}>{t('inquiries.score')}</span>
      </div>
      {item.discretion && (
        <div>
          {moment(item.discretion.created_at).format('DD.MM.YYYY - H:mm:ss')}
          <span style={{ marginLeft: 16 }}>{t('inquiries.DO')}</span>
        </div>
      )}
    </StyledDateTd>
  );
};

DateTd.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DateTd;
