import styled from 'styled-components';
import { COLOR_ACTION_BLUE, COLOR_WHITE } from '../../../constants/colors';
import { Btn } from '../../../components/Buttons/Button';

export const InputColumn = styled.div`
  display: flex;
  justify-content: space-between;
  width: 47%;
  flex-wrap: wrap;
  @media (max-width: 1068px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  max-width: 650px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 27px;
  width: 100%;
  @media (max-width: 1068px) {
    flex-direction: column;
  }
`;

export const Button = styled(Btn)`
  padding: 11px;
  font-size: 16px;
  font-weight: 500;
  font-family: Lato;
  border: 1px solid ${COLOR_ACTION_BLUE};
  background-color: ${COLOR_ACTION_BLUE};
  color: ${COLOR_WHITE};
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Wrapper = styled(Content)``;

export const ImgWrap = styled.div`
  display: inline-block;
  position: absolute;
  right: 6px;
  top: 29px;
`;
export const Img = styled.img``;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const OptionalInput = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  color: #90a0b7;
  font-size: 12px;
  line-height: 19px;
  margin-left: 15px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

export const ButtonDelete = styled.button`
  color: ${COLOR_ACTION_BLUE};
  font-size: 16px;
  line-height: 19px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  height: 70px;
`;
