import styled from 'styled-components';
import { TitleText } from '../../components/Typography/Title';
import { COLOR_TITLE_BLACK, COLOR_ACTION_BLUE, COLOR_WHITE } from '../../constants/colors';
import { mobileMedia } from '../../constants/styles';

export const Title = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  @media (max-width: 942px) {
    margin-bottom: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Wrapper = styled.div`
  padding-left: 32px;
  padding-right: 5px;
  @media (max-width: ${mobileMedia}) {
    padding-left: 3px;
    padding-right: 3px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  @media (max-width: 942px) {
    flex-direction: column;
  }
  @media (max-width: ${mobileMedia}) {
    align-items: center;
  }
`;

/* export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
  @media (max-width: ${mobileMedia}) {
    align-items: center;
  }
`; */

export const Button = styled.button`
  display: block;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border: none;
  outline: none;
  color: ${COLOR_WHITE};
  text-transform: uppercase;
  border-radius: 4px;
  background: ${COLOR_ACTION_BLUE};
  font-weight: 600;
  font-family: Lato;
  cursor: pointer;
  padding-right: 28px;
  padding-left: 28px;
  @media (max-width: 942px) {
    height: 40px;
  }
  @media (max-width: ${mobileMedia}) {
    max-width: 400px;
  }
`;
