import styled, { createGlobalStyle } from 'styled-components';
import { COLOR_WHITE } from '../../../constants/colors';
import { mobileMedia } from '../../../constants/styles';
import { ICON_LOGOUT } from '../../../constants/icons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 256px;
  justify-content: space-between;
  background: ${COLOR_WHITE};
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  margin-top: -112px;
  margin-bottom: -40px;
  margin-right: 48px;
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
  min-height: 100vh;
  position: fixed;
  &.hide {
    width: 68px;
  }
  @media (max-width: 1300px) {
    width: 185px;
  }
  @media (max-width: ${mobileMedia}) {
    display: none;
    &.open {
      display: block;
      width: 303px;
      bottom: 0;
      top: 0;
      right: 0;
      margin: 0;
      z-index: 10000;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
html, body {
  @media (max-width: ${mobileMedia}) {
      overflow-y: ${props => (props.open ? 'hidden' : 'auto')}
  }
}`;

export const Img = styled.img`
  display: block;
  width: 126px;
  margin-left: 24px;
  &.hide {
    width: 50px;
    margin-left: 12px;
  }
  @media (max-width: ${mobileMedia}) {
    &.hide {
      width: 126px;
    }
  }
`;

export const ImgClose = styled.img`
  position: absolute;
  top: 25px;
  right: 10px;
  cursor: pointer;
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomContent = styled.div`
  margin-top: 20px;
  padding-left: 27px;
  @media (max-width: ${mobileMedia}) {
    padding-left: 54px;
    position: absolute;
    bottom: 30px;
  }
`;

export const ExitButton = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: Lato;
  color: #334d6e;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  &:before {
    position: absolute;
    content: '';
    background: url(${ICON_LOGOUT});
    width: 24px;
    height: 24px;
    top: -4px;
    left: 0;
  }
`;
