import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isUnauthenticated } from '../../services/session';
import { AUTH_URL } from '../../constants/routes';
import history from '../../history';
import { getAccessStatus } from '../../helpers/roles';

/**
 * Return route or use history replace
 * @param {Object} param0
 * @param {Boolean} param0.isForUnauth - flag for isForUnauth users only page
 * @param {Array} param0.allowedRoles - array of roles, who can open this route
 * @param {Array} param0.userRoles - array of user roles
 */
const PrivateRoute = ({ component: Component, isForUnauth, allowedRoles, userRoles, ...rest }) => {
  const cb = !isForUnauth ? isAuthenticated : isUnauthenticated;
  const pathname = !isForUnauth ? AUTH_URL : '/profile';
  if (isAuthenticated() && allowedRoles && userRoles) {
    const status = getAccessStatus({ allowedRoles, userRoles });
    if (!status) {
      history.replace('/profile');
      return <></>;
    }
  }

  if (isForUnauth && isAuthenticated()) {
    history.replace('/profile');
    return <></>;
  }
  return (
    <Route
      {...rest}
      render={props => (cb() ? <Component {...props} /> : <Redirect to={pathname} />)}
    />
  );
};

PrivateRoute.propTypes = {
  isForUnauth: PropTypes.bool,
  allowedRoles: PropTypes.array,
  userRoles: PropTypes.array,
  component: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  isForUnauth: false,
  allowedRoles: undefined,
  userRoles: undefined,
};

export default PrivateRoute;
