import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Title } from '../../Balance/BalanceStyled';
import { ICON_THUMB_UP, ICON_THUMB_DOWN, ICON_THUMBS_UP_DOWN } from '../../../constants/icons';

import { useStyles } from './Styles/styles';

const EstimationCategory = ({ entity, entityDetail }) => {
  const sc = useStyles();
  const renderType = type => {
    if (type === 'POSITIVE') return <img src={ICON_THUMB_UP} alt="" />;
    if (type === 'NEGATIVE') return <img src={ICON_THUMB_DOWN} alt="" />;
    if (type === 'NEUTRAL') return <img src={ICON_THUMBS_UP_DOWN} alt="" />;
    return '';
  };
  return (
    <>
      <Box mb={1} sx={{ textAlign: 'center' }}>
        <Title>
          Категория платежеспособности:{' '}
          {entity.category ? entity.category.slice(2) : 'данные ожидаются'}
        </Title>
      </Box>

      <Box mx={2} mb={3} sx={{ textAlign: '' }}>
        <Typography variant="body1" color="textSecondary" component="div">
          {'assessments' in entityDetail && entityDetail.assessments.interpreterDescription}
        </Typography>
      </Box>

      <Box mb={1} sx={{ textAlign: 'center' }}>
        <Box mt={1} pl={2} className={sc.detailTitle}>
          Детальный анализ:
        </Box>
        <Table sx={{ maxWidth: '650px' }} aria-label="simple table">
          {/* <TableHead>
            <TableRow>
              <TableCell className={sc.tableHeader}>Статистика</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {'assessments' in entityDetail &&
              entityDetail.assessments.interpreterPath.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {}}
                >
                  <TableCell className={sc.tableBodyRoot} component="th" scope="row">
                    {item.text}
                  </TableCell>
                  <TableCell className={sc.tableBodyRoot} align="right">
                    {renderType(item.type)}
                  </TableCell>
                  {/* years.map(year => {
                  let item2 = item.values.find(it => Number(it.year) === year);
                  if (!item2) item2 = { year, value: '-' };
                  return (
                    <TableCell
                      key={item2.year}
                      align="right"
                      style={{ width: '160px' }}
                      className={sc.tableBodyRoot}
                    >
                      {numSeparator(item2.value, true)}
                    </TableCell>
                  );
                }) */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
EstimationCategory.propTypes = {
  entity: PropTypes.object.isRequired,
  entityDetail: PropTypes.object.isRequired,
};

export default EstimationCategory;
