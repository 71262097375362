import { AUTH_URL } from '../constants/routes';
import history from '../history';
import { getToken, removeToken } from '../helpers';

export const requestAuthRevoke = () => {
  // TODO: Add revoke auth token request
  removeToken();
  history.push(AUTH_URL);
};

export const isAuthenticated = () => Boolean(getToken());
export const isUnauthenticated = () => !isAuthenticated();
