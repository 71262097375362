import styled from 'styled-components';
import { COLOR_LINK_BLACK } from '../../../constants/colors';
import { mobileMedia } from '../../../constants/styles';

export const Wrapper = styled.div`
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 27px 34px 32px 32px;
  margin-bottom: 24px;
  max-width: 560px;
`;

export const Title = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 16px;
  line-height: 19px;
  font-family: Lato;
  font-weight: 800;
  letter-spacing: 0.01em;
  display: inline;
`;

export const DateActive = styled.p`
  color: #90a0b7;
  font-size: 11px;
  line-height: 18px;
  font-family: Lato;
  font-weight: 500;
  display: inline-block;
  margin-left: 10px;
  @media (max-width: ${mobileMedia}) {
    display: block;
    margin-left: 0px;
    margin-top: 10px;
  }
`;

export const ItemWrapper = styled.div``;

export const Img = styled.img`
  width: 27px;
  height: 27px;
  margin-right: 18px;
`;

export const Middle = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 16px;
  line-height: 18px;
  font-family: Lato;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: ${mobileMedia}) {
    margin-top: 10px;
  }
`;

export const AllCount = styled.div`
  margin-left: 16px;
  color: ${COLOR_LINK_BLACK};
  font-weight: 600;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  display: inline-block;
`;

export const AllCountSpan = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  font-family: Lato;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
`;

export const ScoresLine = styled.div`
  width: 160px;
  background: #e8d9d8;
  border-radius: 8px;
  position: relative;
  height: 12px;
  &:before {
    content: '';
    background: #f7685b;
    border-radius: 8px;
    position: absolute;
    height: 100%;
    top: 0;
    max-width: 100%;
    left: 0;
    width: ${props => (props.width ? props.width : '0')};
  }
`;

export const MonitorsLine = styled(ScoresLine)`
  width: 160px;
  background: #d0dfe7;
  border-radius: 8px;
  position: relative;
  height: 12px;
  &:before {
    content: '';
    background: #109cf1;
    border-radius: 8px;
    position: absolute;
    height: 100%;
    top: 0;
    max-width: 100%;
    left: 0;
    width: ${props => (props.width ? props.width : '0')};
  }
`;

export const Bottom = styled.div`
  display: flex;
  margin-top: 17px;
  padding-left: 45px;
  align-items: center;
`;

export const DateLine = styled(ScoresLine)`
  height: 4px;
  background: #ddd8e8;
  margin-right: 16px;
  &:before {
    background: #885af8;
    max-width: 100%;
  }
`;

export const DateText = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 11px;
  font-family: Lato;
  line-height: 18px;
  font-weight: 500;
`;
