import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers } from 'recompose';
import {
  Wrapper,
  Title,
  TopWrapper,
  // Subtitle,
  // RadioWrapper,
  // RadioButton,
  // RadioText,
  // RadioRedText,
  // ButtonWrapper,
  // RadioWrapperAddress,
  // RadioRedBottomText,
  Tabs,
  TabsItem,
} from './DocumentsStyled';
// import Button from '../../components/Buttons/Button';
import TableActs from './Table/TableActs';
import TableAccounts from './Table/TableAccounts';

const Documents = ({
  // checkedRadioButton,
  // setCheckedRadioButton,
  // handlerButton,
  documentsTab,
  setDocumentsTab,
  setItemId,
}) => (
  <>
    <Wrapper>
      <TopWrapper>
        <Title as="h2">Документы</Title>
      </TopWrapper>
      {/* <Subtitle>Выберите способ документооборота</Subtitle>
      <RadioWrapper>
        <RadioButton
          textColor="#192A3E"
          fontWeight="600"
          testID="TEST_RADIO_EDO"
          name="EDO"
          value="EDO"
          checked={checkedRadioButton === 'EDO'}
          top="0"
          transform="none"
          onClick={setCheckedRadioButton}
        >
          Электронный документооборот (ЭДО)
          <RadioText>
            Электронный документооборот (ЭДО) - передача электронных документов осуществляется с
            соблюдением требований законодательства через аккредитованного ФНС РФ оператора
            электронного документооборота ООО &quot;Компания Тензор&quot; (http://tensor.ru) с
            использованием веб-решения СБиС (http://tensor.ru/edo). Для обработки электронных
            документов вы можете использовать ту же подпись, что и для сдачи электронной отчетности,
            или бесплатно получить новую на сайте компании Тензор.
          </RadioText>
          <RadioRedText>
            Обратите внимание. что, в данном случае, обмен отчетными документами на бумажном
            носителе не применяется!
          </RadioRedText>
        </RadioButton>
      </RadioWrapper>
      <ButtonWrapper>
        <Button
          testID="TEST_BUTTON_SEND"
          height="42px"
          lineHeight="15px"
          minWidth="190px"
          onClick={handlerButton}
        >
          Подключить
        </Button>
      </ButtonWrapper>
      <RadioWrapperAddress>
        <RadioButton
          textColor="#192A3E"
          fontWeight="600"
          testID="TEST_RADIO_ACTUAL"
          name="EDO"
          value="ACTUAL"
          checked={checkedRadioButton === 'ACTUAL'}
          top="0"
          transform="none"
          onClick={setCheckedRadioButton}
        >
          Отправка на фактический адрес
          <RadioRedBottomText>
            Стоимость доставки документов определяется почтовым оператором.
          </RadioRedBottomText>
        </RadioButton>
      </RadioWrapperAddress> */}
      <Tabs>
        <TabsItem
          data-test-id="TEST_TAB_ACTS"
          className={documentsTab === 'Acts' ? 'active' : null}
          onClick={() => setDocumentsTab('Acts')}
        >
          Акты
        </TabsItem>
        <TabsItem
          data-test-id="TEST_TAB_ACCOUNTS"
          className={documentsTab === 'Accounts' ? 'active' : null}
          onClick={() => setDocumentsTab('Accounts')}
        >
          Счета
        </TabsItem>
      </Tabs>
      {documentsTab === 'Acts' ? <TableActs setItemId={setItemId} /> : null}
      {documentsTab === 'Accounts' ? <TableAccounts /> : null}
    </Wrapper>
  </>
);

Documents.propTypes = {
  // checkedRadioButton: PropTypes.string,
  documentsTab: PropTypes.string,
  // setCheckedRadioButton: PropTypes.func,
  // handlerButton: PropTypes.func,
  setDocumentsTab: PropTypes.func,
  setItemId: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  // checkedRadioButton: '',
  documentsTab: '',
  // setCheckedRadioButton: () => {},
  // handlerButton: () => {},
  setDocumentsTab: () => {},
};

const enchance = compose(
  withState('checkedRadioButton', 'setCheckedRadioButton', 'EDO'),
  withState('documentsTab', 'setDocumentsTab', 'Acts'),
  withState('itemId', 'setItemId', ''),
  withHandlers(() => ({
    handlerButton: () => () => {},
  })),
);

export default enchance(Documents);
