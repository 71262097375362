import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { Provider as MobxProvider } from 'mobx-react';
import { Helmet } from 'react-helmet';
import './index.scss';
import * as serviceWorker from './serviceWorker';

import Routes from './routes';
import stores from './stores';

ReactDOM.render(
  <MobxProvider {...stores}>
    <Helmet>
      <title>rescore.online</title>
      <meta name="description" content="Rescore application" />
    </Helmet>
    <Routes />
  </MobxProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
