export const COLOR_BLACK_LOGO = '#192a3e';
export const COLOR_BLACK_TEXT = '#323C47';
export const COLOR_LINK_BLACK = '#334D6E';
export const COLOR_ACTION_BLUE = '#109cf1';
export const COLOR_WHITE = '#fff';
export const COLOR_RED = '#f7685B';
export const COLOR_GREEN = '#2ed47a';
export const COLOR_YELLOW = '#e4d42e';
export const COLOR_TITLE_BLACK = '#03273F';
export const COLOR_PURPLE = '#885AF8';
export const COLOR_GRAY = '#abb1ba';
export const COLOR_DISABLED = '#cbd1da';
export const COLOR_BLACK_SHADOW_SMALL = 'rgba(0, 0, 0, 0.07)';
