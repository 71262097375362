export const __DEV__ = !!process.env.REACT_APP__DEV__ || process.env.NODE_ENV === 'development';
if (!__DEV__) {
  // eslint-disable-next-line no-console
  console.log = () => {};
}

export const API_URL_LK = process.env.REACT_APP_API_URL_LK || 'http://localhost:9000';
export const API_URL_BILLING = process.env.REACT_APP_API_URL_BILLING || 'http://localhost:9000';

export const RESCORE_TOKEN = process.env.REACT_APP_RESCORE_TOKEN;
if (!RESCORE_TOKEN) {
  throw new Error('REACT_APP_RESCORE_TOKEN is not provided in env');
}

const scoringDurationString = process.env.REACT_APP_SCORING_VALIDITY_DAYS_DURATION || '10';
export const SCORING_VALIDITY_DAYS_DURATION = Number.parseInt(scoringDurationString, 10);

const options = (process.env.REACT_APP_RESCORE_OPTIONS || '').split(',');
export const hasDisclosure = !options.includes('no_disclosure');
export const hasDiscretion = !options.includes('no_discretion');
export const hasRegistration = !options.includes('no_registration');
export const hasRefillByCard = !options.includes('no_refill_by_card');
export const hasSimpleScoring = !options.includes('no_simple_scoring');
export const hasAntiRatingCheck = !options.includes('no_antirating_check');
export const RESCORE_TERMS_LINK = process.env.REACT_APP_RESCORE_TERMS_LINK || '/terms.pdf';
