import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import * as moment from 'moment';
// import logger from '../../../logger';
import { useStyles } from './Styles/styles';
import { numSeparator } from './Utils/utils';

const LegalCaseTable = ({
  title,
  empty,
  legalCases,
  legalCasesSummary,
  showOpponents,
  titleColor,
  loaded,
}) => {
  const classes = useStyles();
  const [year, setYear] = useState(legalCases.years.length ? legalCases.years[0].year : null);
  return (
    <Box mb={12} mt={2}>
      {title && (
        <Typography
          className={classes.tableTitle}
          style={{ color: titleColor }}
          variant="h6"
          component="h6"
        >
          {title}
        </Typography>
      )}

      {legalCasesSummary.length === 0 && loaded ? (
        <Box
          mt={4}
          pl={2}
          className={classes.detailTitle}
          style={{ color: '#2dd45b', fontWeight: 'bold' }}
        >
          {empty !== null ? empty : 'Судебные дела отсутствуют'}
        </Box>
      ) : (
        <>
          {legalCasesSummary.length !== 0 && (
            <Box mb={5}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader} style={{ width: '20%' }}>
                      Год подачи
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '20%' }}>
                      Количество исков
                    </TableCell>
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: '60%', textAlign: 'right' }}
                    >
                      Сумма
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {legalCasesSummary.map((item, index) => (
                    <TableRow
                      key={index}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: year === item.year ? '#eaeaea' : '',
                      }}
                      onClick={() => setYear(item.year)}
                    >
                      <TableCell className={classes.tableBodyRoot}>{item.year}</TableCell>
                      <TableCell className={classes.tableBodyRoot}>{item.quantity}</TableCell>
                      <TableCell className={classes.tableBodyRoot} style={{ textAlign: 'right' }}>
                        {Object.entries(item.amount).map((item2, index2) => (
                          <p key={index2}>
                            {numSeparator(item2[1])} {item2[0]}
                          </p>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {loaded ? (
            <>
              <Box mt={1} pl={2} className={classes.detailTitle}>
                Детальная информация:
              </Box>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader} style={{ width: '13%' }}>
                      Номер дела
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '10%' }}>
                      Тип
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '20%' }}>
                      Категория
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '12%' }}>
                      Участники
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '10%' }}>
                      Сумма иска
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '9%' }}>
                      Дата подачи
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '10%' }}>
                      Суд
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '9%' }}>
                      Состояние
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '10%' }}>
                      Решение
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {legalCases.details.map(
                    (item, index) =>
                      new Date(item.date).getFullYear() === year && (
                        <TableRow key={index}>
                          <TableCell className={classes.tableBodyRoot}>{item.number}</TableCell>
                          <TableCell className={classes.tableBodyRoot}>{item.type}</TableCell>
                          <TableCell className={classes.tableBodyRoot}>{item.category}</TableCell>
                          <TableCell
                            className={classes.link}
                            onClick={() => {
                              showOpponents(item.opponents, item.opponentsLength, item.caseId);
                            }}
                          >
                            Посмотреть ({item.opponentsLength})
                          </TableCell>
                          <TableCell className={classes.tableBodyRoot}>
                            {numSeparator(item.amount.value)} {item.amount.currency}
                          </TableCell>
                          <TableCell className={classes.tableBodyRoot}>
                            {moment.utc(item.date).format('DD.MM.YYYY')}
                          </TableCell>
                          <TableCell className={classes.tableBodyRoot}>{item.court}</TableCell>
                          <TableCell className={classes.tableBodyRoot}>
                            {item.isActive ? 'В процессе' : 'Завершен'}
                          </TableCell>
                          <TableCell className={classes.tableBodyRoot}>{item.result}</TableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            </>
          ) : (
            <Box mt={4} pl={2} className={classes.detailTitle} style={{ fontWeight: 'bold' }}>
              Ожидание загрузки...
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
LegalCaseTable.propTypes = {
  title: PropTypes.string,
  empty: PropTypes.string,
  legalCases: PropTypes.object,
  legalCasesSummary: PropTypes.array,
  showOpponents: PropTypes.func.isRequired,
  titleColor: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  // initYear: PropTypes.number,
};
LegalCaseTable.defaultProps = {
  title: null,
  empty: null,
  legalCases: { years: [], details: [] },
  legalCasesSummary: [],
  titleColor: '#222',
  // initYear: null,
};

export default LegalCaseTable;
