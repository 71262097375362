import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers, withState, lifecycle, withProps } from 'recompose';
import { withTranslation } from 'react-i18next';
import * as moment from 'moment';
import InputPhone from '../../../../components/Inputs/InputPhone';
import storeNames from '../../../../stores/storeNames';
import Popup from '../../../../components/Popup/Popup';
import InputField from '../../../../components/Inputs/InputField';
import { getAccessStatus } from '../../../../helpers/roles';
import Button from '../../../../components/Buttons/Button';
import { hasValue, toLowerCaseAndTrim, trimValue } from '../../../../utils/validators';
import { ICON_SHAPE } from '../../../../constants/icons';
import {
  Title,
  Wrapper,
  FormWrapper,
  InputWrapper,
  // TopText,
  // DiscText,
  // TextWrapper,
  DateInputWrap,
  DateWrapper,
  PlaceHolder,
  ButtonWrapper,
  ImgShape,
  DateContent,
} from '../UsersPopupStyled';
import ParagraphError from '../../../../components/Typography/ParagraphError';
import { COLOR_ACTION_BLUE } from '../../../../constants/colors';
import Paragraph from '../../../../components/Typography/Paragraph';
import Select from '../../../../components/Select/Select';
import { withPasswordChangeType, withAccounts, withUserData } from '../../../../utils/hocs';
import {
  withInputSetStore,
  withSurnameState,
  withPhoneState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withDateFromState,
  withDateToState,
  withNewPasswordState,
  withConfirmPasswordState,
} from '../../../../utils/FormHocs';
import {
  CUSTOMER_ROLES,
  CUSTOMER_USER,
  CUSTOMER_ADMIN_ROLE,
  SUPER_ADMINS_ROLES,
} from '../../../../constants/roles';
import InputDate from '../../../../components/Inputs/InputDate';
import { InputColumn } from '../../../Registration/RegistrationUsers/RegistrationUsersStyled';

const AddUserPopup = ({
  nameValid,
  name,
  nameError,
  inputHandlerName,
  dateFrom,
  dateTo,
  email,
  inputHandlerEmail,
  emailError,
  emailValid,
  phoneValid,
  phone,
  inputHandlerPhone,
  phoneError,
  patronymic,
  inputHandlerPatronymic,
  surname,
  surnameValid,
  surnameError,
  inputHandlerSurname,
  inputSetStore,
  toggleAddUserPopup,
  roleSelectValue,
  setRoleSelectValue,
  roleSelectValueError,
  setPersonalAccountValue,
  personalAccountValue,
  formHandler,
  inputHandlerDateTo,
  inputHandlerDateFrom,
  personalAccountValueError,
  dateValueError,
  newPassword,
  confirmPassword,
  showNewPassword,
  inputHandlerNewPassword,
  newPasswordError,
  confirmPasswordError,
  toggleNewPasswordType,
  toggleConfirmPasswordType,
  inputHandlerConfirmPassword,
  showConfirmPassword,
  accounts,
  userRoles,
  userData,
  t,
}) => (
  <Popup closePopup={toggleAddUserPopup}>
    <Wrapper>
      <Title as="h2">{t('users.addingUser')}</Title>
      <Paragraph fontSize="14px" textColor="#334D6E" textAlign="center">
        {t('users.addingUserNote')}
      </Paragraph>
      <FormWrapper onSubmit={formHandler}>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="1"
              placeholder={t('reg.surname')}
              value={surname}
              name="sname"
              isValid={surnameValid}
              onChange={value => {
                inputSetStore(value, inputHandlerSurname, 'sname');
              }}
            />
            <ParagraphError>{t(surnameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="2"
              placeholder={t('reg.name')}
              type="text"
              name="fname"
              value={name}
              isValid={nameValid}
              onChange={value => {
                inputSetStore(value, inputHandlerName, 'fname');
              }}
            />
            <ParagraphError>{t(nameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="3"
              placeholder={t('reg.patronymic')}
              name="mname"
              value={patronymic}
              onChange={value => {
                inputSetStore(value, inputHandlerPatronymic, 'mname');
              }}
            />
            <ParagraphError />
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="4"
              placeholder="E-mail"
              name="email"
              value={email}
              isValid={emailValid}
              onChange={value => {
                inputSetStore(value, inputHandlerEmail, 'email');
              }}
            />
            <ParagraphError>{t(emailError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputPhone
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="5"
              placeholder={t('reg.phone')}
              name="phone"
              value={phone}
              isValid={phoneValid}
              onChange={value => {
                inputSetStore(value, inputHandlerPhone, 'phone');
              }}
            />
            <ParagraphError>{t(phoneError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <Select
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="6"
              multiply
              value={roleSelectValue}
              setValue={setRoleSelectValue}
              placeholder={t('users.chooseARole')}
              name="roleSelectValue"
              options={
                userData.customer_id === 0 &&
                getAccessStatus({
                  userRoles,
                  allowedRoles: [CUSTOMER_ADMIN_ROLE],
                })
                  ? SUPER_ADMINS_ROLES
                  : CUSTOMER_ROLES
              }
            />
            <ParagraphError>{t(roleSelectValueError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <Select
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="7"
              value={personalAccountValue}
              setValue={setPersonalAccountValue}
              placeholder={t('users.chooseAccount')}
              name="personalAccountValue"
              options={accounts && accounts}
            />
            <ParagraphError>{t(personalAccountValueError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
        <InputColumn>
          {/* <TextWrapper>
            <TopText>{t('users.userValidityPeriod')}</TopText>
            <DiscText>({t('reg.optional')})</DiscText>
            </TextWrapper> */}
          <DateWrapper>
            <PlaceHolder>
              {t('users.userValidityPeriod')} ({t('reg.optional')})
            </PlaceHolder>
            <DateInputWrap>
              <DateContent>
                <InputDate
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="8"
                  name="dateFrom"
                  value={dateFrom}
                  onChange={value => {
                    inputSetStore(value, inputHandlerDateFrom, 'dateFrom');
                  }}
                />
              </DateContent>
              —
              <DateContent>
                <InputDate
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="9"
                  name="dateTo"
                  value={dateTo}
                  onChange={value => {
                    inputSetStore(value, inputHandlerDateTo, 'dateTo');
                  }}
                />
              </DateContent>
            </DateInputWrap>
            <ParagraphError>{t(dateValueError)}</ParagraphError>
          </DateWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="10"
              placeholder={t('login.password')}
              value={newPassword}
              type={showNewPassword}
              name="newPassword"
              onChange={value => {
                inputSetStore(value, inputHandlerNewPassword, 'newPassword');
              }}
            />
            <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} value={newPassword} />
            <ParagraphError>{t(newPasswordError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="11"
              placeholder={t('reg.repeatPass')}
              value={confirmPassword}
              type={showConfirmPassword}
              name="confirmPassword"
              onChange={value => {
                inputSetStore(value, inputHandlerConfirmPassword, 'confirmPassword');
              }}
            />
            <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
            <ParagraphError>{t(confirmPasswordError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
        <ButtonWrapper>
          <Button
            width="303px"
            type="submit"
            sizes={{ margin: '0 20px' }}
            testID="TEST_BUTTON_ADD"
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="12"
          >
            {t('form.add')}
          </Button>
          <Button
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="13"
            testID="TEST_BUTTON_CANCEL"
            width="303px"
            type="submit"
            textColor={COLOR_ACTION_BLUE}
            boxShadow="none"
            backgroundColor="#fff"
            sizes={{ margin: '0 20px' }}
            onClick={() => toggleAddUserPopup()}
          >
            {t('form.cancel')}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  </Popup>
);

AddUserPopup.propTypes = {
  toggleAddUserPopup: PropTypes.func.isRequired,
  roleSelectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  personalAccountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmPassword: PropTypes.string,
  setRoleSelectValue: PropTypes.func.isRequired,
  roleSelectValueError: PropTypes.string,
  setPersonalAccountValue: PropTypes.func.isRequired,
  inputSetStore: PropTypes.func,
  surname: PropTypes.string,
  newPasswordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  newPassword: PropTypes.string,
  surnameError: PropTypes.string,
  surnameValid: PropTypes.bool,
  inputHandlerSurname: PropTypes.func,
  formHandler: PropTypes.func,
  name: PropTypes.string,
  nameValid: PropTypes.bool,
  nameError: PropTypes.string,
  showNewPassword: PropTypes.string,
  email: PropTypes.string,
  inputHandlerName: PropTypes.func,
  inputHandlerEmail: PropTypes.func,
  emailError: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  emailValid: PropTypes.bool,
  phone: PropTypes.string,
  phoneValid: PropTypes.bool,
  inputHandlerPhone: PropTypes.func,
  patronymic: PropTypes.string,
  phoneError: PropTypes.string,
  userRoles: PropTypes.array,
  inputHandlerPatronymic: PropTypes.func,
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  inputHandlerDateFrom: PropTypes.func,
  personalAccountValueError: PropTypes.string,
  dateValueError: PropTypes.string,
  inputHandlerDateTo: PropTypes.func,
  inputHandlerNewPassword: PropTypes.func,
  inputHandlerConfirmPassword: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  toggleConfirmPasswordType: PropTypes.func,
  accounts: PropTypes.array,
  userData: PropTypes.object,
  t: PropTypes.func.isRequired,
};

AddUserPopup.defaultProps = {
  surname: '',
  surnameError: '',
  dateTo: undefined,
  name: '',
  confirmPassword: '',
  nameError: '',
  email: '',
  roleSelectValueError: '',
  personalAccountValueError: '',
  newPasswordError: '',
  confirmPasswordError: '',
  emailError: '',
  personalAccountValue: '',
  showNewPassword: '',
  showConfirmPassword: '',
  patronymic: '',
  phoneError: '',
  roleSelectValue: '',
  dateValueError: '',
  newPassword: '',
  dateFrom: undefined,
  phone: '',
  surnameValid: false,
  nameValid: false,
  emailValid: false,
  phoneValid: false,
  userData: {},
  accounts: [],
  userRoles: [],
  inputSetStore: () => {},
  inputHandlerName: () => {},
  formHandler: () => {},
  inputHandlerEmail: () => {},
  inputHandlerSurname: () => {},
  inputHandlerPhone: () => {},
  inputHandlerPatronymic: () => {},
  inputHandlerDateTo: () => {},
  inputHandlerDateFrom: () => {},
  inputHandlerNewPassword: () => {},
  inputHandlerConfirmPassword: () => {},
  toggleNewPasswordType: () => {},
  toggleConfirmPasswordType: () => {},
};

const enchance = compose(
  inject(
    storeNames.CustomerStore,
    storeNames.AccountStore,
    storeNames.UserStore,
    storeNames.IndicatorsStore,
    storeNames.ProfileStore,
  ),
  withTranslation(),
  observer,
  withSurnameState,
  withInputSetStore,
  withPhoneState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withDateFromState,
  withDateToState,
  withNewPasswordState,
  withConfirmPasswordState,
  withPasswordChangeType,
  withUserData,
  withProps(({ userRoles, ProfileStore }) => ({
    rolesValues:
      ProfileStore.userData.customer_id === 0 &&
      getAccessStatus({
        userRoles,
        allowedRoles: [CUSTOMER_ADMIN_ROLE],
      })
        ? SUPER_ADMINS_ROLES.map(item => ({ ...item, checked: false }))
        : CUSTOMER_ROLES.map(item => ({ ...item, checked: false })),
  })),
  withAccounts,
  withState('roleSelectValueError', 'setRoleSelectValueError', ''),
  withState('personalAccountValueError', 'setPersonalAccountValueError', ''),
  withState('dateValueError', 'setDateValueError', ''),
  withState('newPasswordError', 'setNewPasswordError', ''),
  withState('confirmPasswordError', 'setConfirmPasswordError', ''),
  // withState('emailError', 'setEmailError', ''),
  // withState('nameError', 'setNameError', ''),
  withHandlers(({ /* dateValueError, */ phoneError }) => ({
    formHandler: ({
      IndicatorsStore,
      surname,
      name,
      personalAccountValue,
      roleSelectValue,
      email,
      phone,
      patronymic,
      UserStore,
      toggleAddUserPopup,
      setRoleSelectValueError,
      setRoleSelectValue,
      setPersonalAccountValue,
      dateFrom,
      emailValid,
      setDateValueError,
      dateTo,
      confirmPassword,
      newPassword,
      setNewPasswordError,
      setConfirmPasswordError,
      setPersonalAccountValueError,
      setEmailError,
      setNameError,
      accounts,
    }) => async e => {
      e.preventDefault();

      // ВАЛИДАЦИЯ ПАРОЛЯ //
      let passwordIsValid = true;
      let accountError = false;
      const regexValid = /^[a-zA-Z0-9]+$/;
      if (!newPassword) {
        passwordIsValid = false;
        setNewPasswordError('reg.inputPass');
      } else {
        setNewPasswordError('');
        if (newPassword.length < 8) {
          passwordIsValid = false;
          setNewPasswordError('reg.passNotLess');
        } else {
          setNewPasswordError('');
          if (!regexValid.test(newPassword)) {
            passwordIsValid = false;
            setNewPasswordError('reg.passTerm');
            setConfirmPasswordError('');
          } else {
            setNewPasswordError('');
            if (newPassword !== confirmPassword) {
              setConfirmPasswordError('reg.passNotSame');
              passwordIsValid = false;
            } else {
              setConfirmPasswordError('');
              passwordIsValid = true;
            }
          }
        }
      }
      // ВАЛИДАЦИЯ ПАРОЛЯ //

      const dateFromValue = dateFrom && moment(dateFrom).format('YYYY-MM-DD');
      const dateToValue = dateTo && moment(dateTo).format('YYYY-MM-DD');
      const roleValues = roleSelectValue.filter(item => item.checked);
      let rolesValid = false;
      if (roleValues.length === 0) {
        setRoleSelectValueError('users.inputRole');
      } else {
        setRoleSelectValueError('');
        rolesValid = true;
        // roleValues.forEach(item => {
        //   if (item.value === CUSTOMER_USER) {
        //     setPersonalAccountValueError('Укажите счет');
        //     accountError = true;
        //   }
        // });
        if (personalAccountValue) {
          setPersonalAccountValueError('');
          accountError = false;
        }
      }

      let dateValid = false;
      if ((dateFrom && !dateTo) || (!dateFrom && dateTo)) {
        setDateValueError('accounts.enterBothDates');
        return;
      }

      const dateFromSec = new Date(dateFrom).getTime();
      const dateToSec = new Date(dateTo).getTime();

      if (dateFromSec > dateToSec) {
        setDateValueError('accounts.datesOrderError');
        return;
      }
      setDateValueError('');
      dateValid = true;

      if (!name) {
        setNameError('users.inputName');
      } else {
        setNameError('');
      }

      if (!email) {
        setEmailError('users.inputEmail');
      } else {
        setEmailError('');
      }

      if (
        dateValid &&
        !phoneError &&
        passwordIsValid &&
        hasValue(name) &&
        hasValue(email) &&
        emailValid &&
        !accountError &&
        rolesValid
      ) {
        const accountValue = personalAccountValue.value;
        const sname = trimValue(surname || '');
        const fname = trimValue(name || '');
        const mname = trimValue(patronymic || '');
        const phoneValue = trimValue(phone || '');
        const emailValue = toLowerCaseAndTrim(email || '');
        const login = toLowerCaseAndTrim(email || '');
        const newUser = {
          account_id: accountValue || (accounts && accounts[0].id),
          sname,
          fname,
          mname,
          login,
          from_time: dateFromValue,
          to_time: dateToValue,
          password: newPassword,
        };
        if (phoneValue) {
          newUser.phone = phoneValue;
        }
        if (emailValue) {
          newUser.email = emailValue;
        }
        if (roleValues.length) {
          newUser.roles = roleValues.map(item => ({
            role_name: item.value,
            account_id: accountValue || (accounts && accounts[0].id),
          }));
        }
        if (!roleValues.length) {
          newUser.roles = [{ role_name: CUSTOMER_USER }];
        }
        const { error } = await UserStore.createNewCustomerUser(newUser);
        if (!error) {
          setRoleSelectValue('');
          setPersonalAccountValue('');
          await UserStore.fetchMyUsers({ offset: 0 });
          toggleAddUserPopup();
        }
      } else {
        IndicatorsStore.addErrorIndicators({
          message: 'balance.checkFieldsCorrection',
          type: 'error',
        });
      }
    },
  })),
  lifecycle({
    async componentDidMount() {
      const {
        setPersonalAccountValue,
        setRoleSelectValue,
        AccountStore,
        rolesValues,
        accounts,
        t,
      } = this.props;
      const { fetchMyAccounts } = AccountStore;
      await fetchMyAccounts({ qnt: 100000, offset: 0 });
      setPersonalAccountValue(accounts && accounts[0]);
      const rolesValuesI18n = rolesValues.map(item => {
        return {
          ...item,
          text: t(item.text),
          checked: item.value === 'CUSTOMER_USER',
        };
      });
      setRoleSelectValue(rolesValuesI18n);
    },
  }),
);

export default enchance(AddUserPopup);
