import { observable, decorate, action } from 'mobx';
// import logger from '../logger';

export default class DialogStore {
  constructor(context) {
    this.context = context;
  }

  width = '';

  height = '';

  isWait = false;

  isShow = false;

  isFade = false;

  persistent = false;

  title = '';

  progress = -1;

  loaderTimeout = null;

  buttonsRedraw = 0;

  loader = state => {
    if (state) {
      this.loaderTimeout = setTimeout(() => {
        this.isWait = true;
      }, 500);
    } else {
      clearTimeout(this.loaderTimeout);
      this.isWait = false;
    }
  };

  show = (
    title,
    {
      width = '700px',
      height = '',
      persistent = false,

      isAccept = false,
      acceptDangerColor = false,
      acceptText = '',
      accept = null,

      isCancel = false,
      cancelText = '',
      cancel = null,

      isProgress = false,

      model = [],
      buttons = [],
      buttonsTop = [],
    } = {},
  ) => {
    this.title = title;
    this.width = width;
    this.height = height;

    this.progress = isProgress ? 0 : -1;
    this.persistent = persistent;
    this.isShow = true;
    this.isFade = true;

    this.model = model;
    this.buttons = buttons;
    this.buttonsTop = buttonsTop;

    if (isAccept === true || acceptText) {
      this.buttons.push({
        label: acceptText.length !== 0 ? acceptText : 'Да',
        event: 'ACCEPT',
        color: acceptDangerColor ? 'WARNING' : 'REGULAR',
        action: accept !== null ? accept : () => {},
        // close: true,
      });
    }

    if (isCancel === true || cancelText) {
      this.buttons.push({
        label: cancelText.length !== 0 ? cancelText : 'Нет',
        event: 'CANCEL',
        color: 'CANCEL',
        action: cancel !== null ? cancel : () => {},
        // close: true,
      });
    }

    // eslint-disable-next-line
    for (const item of this.model) {
      if (!('value' in item)) {
        if (item.type === 'CHECKBOX') item.value = false;
        if (item.type === 'TEXT') item.value = '';
        if (item.type === 'NUMBER') item.value = 0;
      }
    }

    const buttonsProcess = (_buttons, resolve) => {
      // eslint-disable-next-line
      for (const item of _buttons) {
        if (Array.isArray(item.label) && !('toggle' in item)) item.toggle = 0;
        if (!('color' in item)) item.color = 'REGULAR';
        item.callback = () => {
          if ('action' in item) {
            if (Array.isArray(item.action)) item.action[item.toggle](this);
            else item.action(this);
          }
          if ('event' in item) {
            if (item.event === 'ACCEPT') {
              resolve(true);
            } else if (item.event === 'CANCEL') {
              resolve(false);
            } else resolve(item.event);
          }
        };
      }
    };

    return new Promise(resolve => {
      buttonsProcess(this.buttons, resolve);
      buttonsProcess(this.buttonsTop, resolve);
    });
  };

  alert = text => {
    this.show(text, { buttons: [{ label: 'OK', event: 'ACCEPT', color: 'WARNING' }] });
  };

  toggleButton = index => {
    this.buttonsRedraw += 1;
    this.buttons[index].toggle += 1;
    if (this.buttons[index].toggle >= this.buttons[index].label.length)
      this.buttons[index].toggle = 0;
  };

  setAll = (type, value) => {
    this.model = this.model.map(item => ({
      ...item,
      value: item.type === type ? value : item.value,
    }));
  };

  fadeOut = () => {
    this.isFade = false;
  };

  hide = () => {
    this.isShow = false;
    this.progress = -1;
    this.isAccept = false;
    this.isCancel = false;
  };

  setTitle = newTitle => {
    this.title = newTitle;
  };

  setProgress = newProgress => {
    this.progress = newProgress;
  };

  changeField = (index, newValue) => {
    const temp = JSON.parse(JSON.stringify(this.model));
    temp[index].value = newValue;
    this.model = temp;
  };

  getResultObj = () => {
    const res = {};
    // eslint-disable-next-line
    for (const item of this.model) {
      if ('name' in item) res[item.name] = item.value;
    }
    return res;
  };

  getResultArr = () => {
    const res = [];
    // eslint-disable-next-line
    for (const item of this.model) {
      if (item.value !== undefined) res.push(item.value);
    }
    return res;
  };

  getSelectedNameArr = () => {
    const res = [];
    // eslint-disable-next-line
    for (const item of this.model) {
      if ('name' in item && item.value === true) res.push(item.name);
    }
    return res;
  };
}

decorate(DialogStore, {
  isWait: observable,
  isShow: observable,
  isFade: observable,
  persistent: observable,
  show: action,
  hide: action,
  fadeOut: action,

  title: observable,
  setTitle: action,

  progress: observable,
  setProgress: action,

  isAccept: observable,
  acceptDangerColor: observable,
  acceptText: observable,
  accept: action,
  isCancel: observable,
  cancelText: observable,
  cancel: action,

  width: observable,
  height: observable,
  model: observable,
  changeField: action,
  buttons: observable,
  buttonsTop: observable,
  toggleButton: action,
  buttonsRedraw: observable,
});
