import ACTIVITY from '../assets/img/icons/icon-activity.svg';
import ALERT_OCTAGON_ICON from '../assets/img/icons/alert-octagon.svg';
import BRIEFCASE from '../assets/img/icons/icon-briefcase.svg';
import BRIEFCASE_ACTIVE from '../assets/img/icons/icon-briefcase-active.svg';
import BUTTON_DELETE from '../assets/img/icons/button_delete.svg';
import BUTTON_EDIT from '../assets/img/icons/button_edit.svg';
import CANCELED from '../assets/img/icons/icon-canceled.svg';
import CARD from '../assets/img/icons/icon-card.svg';
import CARD_ACTIVE from '../assets/img/icons/icon-card-active.svg';
import CARD_CHECK from '../assets/img/icons/icon-card-check.svg';
import CHECK_CIRCLE_SUCCESS_ICON from '../assets/img/icons/check-circle.svg';
import CLOSE from '../assets/img/icons/close.svg';
import DELETE from '../assets/img/icons/icon-delete.svg';
import EDIT from '../assets/img/icons/icon-edit.svg';
import EMAIL_NOVERIFID from '../assets/img/icons/icon-email-noverifid.svg';
import EMAIL_VERIFID from '../assets/img/icons/icon-email-verifid.svg';
import EXPIRED from '../assets/img/icons/icon-expired.svg';
import FILE from '../assets/img/icons/icon-file.svg';
import LOGO_R from '../assets/img/rescore_logo.jpg';
import LOGO_T from '../assets/img/temiz_logo.jpg';
import LOGOUT from '../assets/img/icons/icon_logout.svg';
import MINUS from '../assets/img/icons/icon-minus.svg';
import MONITORING from '../assets/img/icons/icon-monitoring.svg';
import PDF_FILE from '../assets/img/icons/icon-pdf-file.svg';
import WORD_FILE from '../assets/img/icons/icon-word-file.svg';
import EXCEL_FILE from '../assets/img/icons/icon-excel-file.svg';
import PENDING from '../assets/img/icons/icon-pending.svg';
import PLUS from '../assets/img/icons/icon-plus.svg';
import REPEAT from '../assets/img/icons/icon-repeat.svg';
import REQ from '../assets/img/icons/icon-req.svg';
import RUBLE from '../assets/img/icons/icon-ruble.svg';
import SCORE from '../assets/img/icons/icon-score.svg';
import SCORE_ACTIVE from '../assets/img/icons/icon-score-active.svg';
import SCORING from '../assets/img/icons/icon-scoring.svg';
import SEARCH from '../assets/img/icons/icon-search.svg';
import SELECT_ARROW from '../assets/img/icons/icon-select-arrow.svg';
import SHAPE from '../assets/img/icons/shape.svg';
import SUCCEEDED from '../assets/img/icons/icon_succeeded.svg';
import TABLE_ACTIVITY from '../assets/img/icons/icon-table-activity.svg';
import TABLE_PAUSE from '../assets/img/icons/icon-table-pause.svg';
import TABLE_RECTANGLE from '../assets/img/icons/icon-table-rectangle.svg';
import UP from '../assets/img/icons/icon-up.svg';
import USER_EDIT from '../assets/img/icons/icon-user-edit.svg';
import THUMB_UP from '../assets/img/icons/icon-thumb-up.svg';
import THUMB_DOWN from '../assets/img/icons/icon-thumb-down.svg';
import THUMBS_UP_DOWN from '../assets/img/icons/icon-thumbs-up-down.svg';

export const ICON_ACTIVITY = ACTIVITY;
export const ICON_BRIEFCASE = BRIEFCASE;
export const ICON_BRIEFCASE_ACTIVE = BRIEFCASE_ACTIVE;
export const ICON_BUTTON_DELETE = BUTTON_DELETE;
export const ICON_BUTTON_EDIT = BUTTON_EDIT;
export const ICON_CANCELED = CANCELED;
export const ICON_CARD = CARD;
export const ICON_CARD_ACTIVE = CARD_ACTIVE;
export const ICON_CARD_CHECK = CARD_CHECK;
export const ICON_CLOSE = CLOSE;
export const ICON_DELETE = DELETE;
export const ICON_EDIT = EDIT;
export const ICON_EMAIL_NOVERIFID = EMAIL_NOVERIFID;
export const ICON_EMAIL_VERIFID = EMAIL_VERIFID;
export const ICON_EXPIRED = EXPIRED;
export const ICON_FILE = FILE;
export const ICON_LOGOUT = LOGOUT;
export const ICON_MINUS = MINUS;
export const ICON_MONITORING = MONITORING;
export const ICON_PDF_FILE = PDF_FILE;
export const ICON_WORD_FILE = WORD_FILE;
export const ICON_EXCEL_FILE = EXCEL_FILE;
export const ICON_PENDING = PENDING;
export const ICON_PLUS = PLUS;
export const ICON_REPEAT = REPEAT;
export const ICON_REQ = REQ;
export const ICON_RUBLE = RUBLE;
export const ICON_SCORE = SCORE;
export const ICON_SCORE_ACTIVE = SCORE_ACTIVE;
export const ICON_SCORING = SCORING;
export const ICON_SEARCH = SEARCH;
export const ICON_SELECT_ARROW = SELECT_ARROW;
export const ICON_SHAPE = SHAPE;
export const ICON_SUCCEEDED = SUCCEEDED;
export const ICON_TABLE_ACTIVITY = TABLE_ACTIVITY;
export const ICON_TABLE_PAUSE = TABLE_PAUSE;
export const ICON_TABLE_RECTANGLE = TABLE_RECTANGLE;
export const ICON_UP = UP;
export const ICON_USER_EDIT = USER_EDIT;
export const ICON_THUMB_UP = THUMB_UP;
export const ICON_THUMB_DOWN = THUMB_DOWN;
export const ICON_THUMBS_UP_DOWN = THUMBS_UP_DOWN;
export const RESCORE_LOGO = process.env.REACT_APP_LOGO === 'rescore' ? LOGO_R : LOGO_T;

export { CHECK_CIRCLE_SUCCESS_ICON, ALERT_OCTAGON_ICON };
