import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose, withHandlers, withState, lifecycle } from 'recompose';
import * as moment from 'moment';
import storeNames from '../../../../stores/storeNames';
import Popup from '../../../../components/Popup/Popup';
import InputField from '../../../../components/Inputs/InputField';
import Button from '../../../../components/Buttons/Button';
import { getAccessStatus } from '../../../../helpers/roles';
import { hasValue } from '../../../../utils/validators';
import { RoleRender } from '../../../../components/RoleComponents';
import {
  Title,
  Wrapper,
  FormWrapper,
  InputWrapper,
  InputWrapperNotes,
  TopText,
  TextWrapper,
  DateInputWrap,
  DateWrapper,
  ButtonWrapper,
  DateContent,
  TextArea,
} from '../AccountPopupStyled';
import ParagraphError from '../../../../components/Typography/ParagraphError';
import { withPasswordChangeType, withAccounts, withUserData } from '../../../../utils/hocs';
import { COLOR_ACTION_BLUE } from '../../../../constants/colors';
import {
  withInputSetStore,
  withDateFromState,
  withDateToState,
  withMoneyState,
  withNotesState,
  withNameAccount,
} from '../../../../utils/FormHocs';
import {
  SUPER_ADMIN_ROLE,
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_ACCOUNTANT,
} from '../../../../constants/roles';
import InputDate from '../../../../components/Inputs/InputDate';
import { InputColumn } from '../../../Registration/RegistrationUsers/RegistrationUsersStyled';

const EditAccountPopup = ({
  nameAccountValid,
  nameAccount,
  money,
  moneyValid,
  inputHandlerMoney,
  moneyError,
  nameAccountError,
  inputHandlerNameAccount,
  dateFrom,
  dateTo,
  notes,
  inputHandlerNotes,
  inputSetStore,
  toggleEditAccountPopup,
  formHandler,
  inputHandlerDateTo,
  inputHandlerDateFrom,
  dateValueError,
  userRoles,
}) => {
  const { t } = useTranslation();
  return (
    <Popup closePopup={toggleEditAccountPopup}>
      <Wrapper>
        <Title as="h2">{t('accounts.accountEdit')}</Title>
        <FormWrapper onSubmit={formHandler}>
          <InputColumn>
            <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT]}>
              <InputWrapper>
                <InputField
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="1"
                  placeholder={t('accounts.name')}
                  value={nameAccount}
                  name="nameAccount"
                  isValid={nameAccountValid}
                  onChange={value => {
                    inputSetStore(value, inputHandlerNameAccount, 'nameAccount');
                  }}
                />
                <ParagraphError>{t(nameAccountError)}</ParagraphError>
              </InputWrapper>
            </RoleRender>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="2"
                placeholder={t('balance.amount')}
                type="text"
                name="money"
                value={money}
                disabled={
                  !getAccessStatus({
                    userRoles,
                    allowedRoles: [SUPER_ADMIN_ROLE],
                  })
                }
                isValid={moneyValid}
                onChange={value => {
                  inputSetStore(value, inputHandlerMoney, 'money');
                }}
              />
              <ParagraphError>{t(moneyError)}</ParagraphError>
            </InputWrapper>
          </InputColumn>
          <InputColumn>
            <TextWrapper>
              <TopText>{t('accounts.validityPeriod')}</TopText>
            </TextWrapper>
            <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT]}>
              <DateWrapper>
                <DateInputWrap>
                  <DateContent>
                    <InputDate
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex="4"
                      type="date"
                      name="dateFrom"
                      value={dateFrom}
                      onChange={value => {
                        inputSetStore(value, inputHandlerDateFrom, 'dateFrom');
                      }}
                    />
                  </DateContent>
                  —
                  <DateContent>
                    <InputDate
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex="5"
                      type="date"
                      name="dateTo"
                      value={dateTo}
                      onChange={value => {
                        inputSetStore(value, inputHandlerDateTo, 'dateTo');
                      }}
                    />
                  </DateContent>
                </DateInputWrap>
                <ParagraphError>{t(dateValueError)}</ParagraphError>
              </DateWrapper>
            </RoleRender>
          </InputColumn>
          <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT]}>
            <InputWrapperNotes>
              <TextArea
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="3"
                placeholder={t('accounts.note')}
                name="notes"
                value={notes}
                onChange={event => {
                  inputSetStore(event, inputHandlerNotes, 'notes');
                }}
              />
            </InputWrapperNotes>
          </RoleRender>
          <ButtonWrapper>
            <Button
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="7"
              testID="TEST_BUTTON_SAVE"
              width="303px"
              type="submit"
              sizes={{ margin: '0 20px' }}
            >
              {t('form.save')}
            </Button>
            <Button
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="6"
              testID="TEST_BUTTON_CANCEL"
              width="303px"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleEditAccountPopup()}
            >
              {t('form.cancel')}
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

EditAccountPopup.propTypes = {
  toggleEditAccountPopup: PropTypes.func.isRequired,
  inputSetStore: PropTypes.func,
  formHandler: PropTypes.func,
  nameAccount: PropTypes.string,
  nameAccountError: PropTypes.string,
  money: PropTypes.string,
  moneyError: PropTypes.string,
  nameAccountValid: PropTypes.bool,
  moneyValid: PropTypes.bool,
  inputHandlerMoney: PropTypes.func,
  inputHandlerNameAccount: PropTypes.func,
  notes: PropTypes.string,
  inputHandlerNotes: PropTypes.func,
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  userRoles: PropTypes.array,
  inputHandlerDateFrom: PropTypes.func,
  dateValueError: PropTypes.string,
  inputHandlerDateTo: PropTypes.func,
};

EditAccountPopup.defaultProps = {
  dateTo: undefined,
  nameAccount: '',
  money: '0',
  moneyError: '',
  nameAccountError: '',
  notes: '',
  dateValueError: '',
  userRoles: [],
  dateFrom: undefined,
  nameAccountValid: false,
  moneyValid: false,
  inputSetStore: () => {},
  inputHandlerNameAccount: () => {},
  formHandler: () => {},
  inputHandlerNotes: () => {},
  inputHandlerMoney: () => {},
  inputHandlerDateTo: () => {},
  inputHandlerDateFrom: () => {},
};

const enchance = compose(
  inject(storeNames.CustomerStore, storeNames.AccountStore, storeNames.IndicatorsStore),
  observer,
  withTranslation(),
  withUserData,
  withInputSetStore,
  withDateFromState,
  withDateToState,
  withMoneyState,
  withNotesState,
  withNameAccount,
  withPasswordChangeType,
  withAccounts,
  withState('dateValueError', 'setDateValueError', ''),
  withHandlers(({ dateValueError }) => ({
    formHandler: ({
      IndicatorsStore,
      nameAccount,
      money,
      notes,
      AccountStore,
      toggleEditAccountPopup,
      dateFrom,
      id,
      setDateValueError,
      moneyValid,
      dateTo,
      userRoles,
      t,
    }) => async e => {
      // const { t } = useTranslation();
      e.preventDefault();
      const dateFromValue = dateFrom && moment(dateFrom).format('YYYY-MM-DD');
      const dateToValue = dateTo && moment(dateTo).format('YYYY-MM-DD');

      if ((dateFrom && !dateTo) || (!dateFrom && dateTo)) {
        setDateValueError(t('accounts.enterBothDates'));
        return;
      }

      const dateFromSec = new Date(dateFrom).getTime();
      const dateToSec = new Date(dateTo).getTime();

      if (dateFromSec > dateToSec) {
        setDateValueError(t('accounts.datesOrderError'));
        return;
      }
      setDateValueError('');

      if (!dateValueError && hasValue(nameAccount) && moneyValid) {
        const newAccount = {
          id,
          full_name: nameAccount || '',
          notes,
        };

        if (
          Number(money) &&
          getAccessStatus({
            userRoles,
            allowedRoles: [SUPER_ADMIN_ROLE],
          })
        ) {
          newAccount.money = Number(money);
        }

        if (dateFromValue) {
          newAccount.from_time = dateFromValue;
        }

        if (dateToValue) {
          newAccount.to_time = dateToValue;
        }

        await AccountStore.editCustomerAccount(newAccount, id);
        toggleEditAccountPopup();
      } else {
        IndicatorsStore.addErrorIndicators({
          message: t('balance.checkFieldsCorrection'),
          type: 'error',
        });
      }
    },
  })),
  lifecycle({
    async componentDidMount() {
      const {
        AccountStore,
        id,
        inputHandlerMoney,
        inputHandlerNameAccount,
        inputHandlerNotes,
        inputHandlerDateTo,
        inputHandlerDateFrom,
      } = this.props;
      const account = AccountStore.scrollAccounts.find(item => item.id === id);
      if (!account) return;
      inputHandlerMoney(`${account.money || 0}`);
      inputHandlerNameAccount(account.full_name || '');
      inputHandlerNotes(account.notes || '');
      inputHandlerDateTo(account.to_time ? new Date(account.to_time) : '');
      inputHandlerDateFrom(account.from_time ? new Date(account.from_time) : '');
    },
  }),
);

export default enchance(EditAccountPopup);
