import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, /* withHandlers, */ withProps, lifecycle } from 'recompose'; // withState
// import * as moment from 'moment';
import {
  // FormControl,
  FormControlLabel,
  // FormLabel,
  Radio,
  RadioGroup,
  // Divider,
  Box,
  // Chip,
  // Fab,
} from '@material-ui/core';
import { withBoolState } from '../../../../utils/hocs'; // withFetchUserInDidMount, withUserData
import storeNames from '../../../../stores/storeNames';
import Popup from '../../../../components/Popup/Popup';
import InputField from '../../../../components/Inputs/InputField';
import Typography from '../../../../components/Typography/Paragraph';
import Button from '../../../../components/Buttons/Button';
import {
  Title,
  Indent,
  Shift,
  Wrapper,
  FormWrapper,
  InputWrapper,
  //  InputWrapperNotes,
  //  TopText,
  //  TextWrapper,
  DateInputWrap,
  DateWrapper,
  ButtonWrapper,
  DateContent,
  TextArea,
  //  Description,
  TableContainer,
} from '../SharedInfoPopupStyled';
import {
  Tr,
  TrTop,
  Th,
  Td,
  // ActivityTd,
  Img,
  Thead,
  Tbody,
  //  TdButton,
  //  ButtonEdit,
  //  CheckBoxWrapper,
  TableButton,
  //  Text,
} from '../../Table/TableStyled';
import { COLOR_ACTION_BLUE, COLOR_BLACK_TEXT } from '../../../../constants/colors';
import { /* ICON_SUCCEEDED, */ ICON_DELETE } from '../../../../constants/icons';
import CheckBox from '../../../../components/CheckBoxes/CheckBox';
import Select from '../../../../components/Select/Select';
import UploaderPopup from '../UploaderPopup/UploaderPopup';
import ProgressPopup from '../../../Popups/ProgressPopup';
import YesNoPopup from '../../../Popups/YesNoPopup';
import logger from '../../../../logger';
import InputDate from '../../../../components/Inputs/InputDate';

const ParagraphNumber = props => {
  const { path } = props;
  let parnum = '';
  // eslint-disable-next-line
  for (let index = 0; index < path.length; index++) {
    const item = path[index];
    parnum += item + 1 + (index < path.length - 1 ? '.' : '');
  }
  return parnum;
};

const RenderElements = ({
  items,
  showFileDialog,
  changeField,
  editMode,
  addComposite,
  deleteComposite,
  deleteFiles,
}) => {
  if (items === undefined) return <></>;

  let indent = 0;
  return (
    <>
      {items &&
        items.map(item => (
          <Shift tab={item.indexPath.length > 1 ? 2 : 0} key={item.id + item.index /* indexPath */}>
            {/* eslint-disable-next-line */}
            <Indent top={item.indexPath.length > 1 || !indent++ ? '' : '50px'}>
              <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
                {editMode && item.type !== 'COMPOSITE' && item.minimumQnt > 0 && `* `}
                <ParagraphNumber path={item.indexPath} /> - {item.title}
                {item.description}
              </Typography>
              {editMode && item.type === 'COMPOSITE' && item.maximumQnt > 1 && (
                <div style={{ margin: '0px 20px 0px' }}>
                  {item.canAddBtn && (
                    <TableButton testID="TEST_BUTTON_ADD" onClick={() => addComposite(item)}>
                      Добавить еще один
                    </TableButton>
                  )}
                  {item.canDelBtn && (
                    <TableButton testID="TEST_BUTTON_DEL" onClick={() => deleteComposite(item)}>
                      Удалить
                    </TableButton>
                  )}
                </div>
              )}
            </Indent>

            {item.type === 'COMPOSITE' && (
              <Indent>
                <RenderElements
                  items={item.elements}
                  editMode={editMode}
                  showFileDialog={showFileDialog}
                  changeField={changeField}
                  addComposite={addComposite}
                  deleteComposite={deleteComposite}
                  deleteFiles={deleteFiles}
                />
              </Indent>
            )}

            <div style={{ paddingLeft: '30px' }}>
              {item.type === 'CHECKBOX' && (
                <InputWrapper style={{ cursor: 'pointer' }}>
                  <CheckBox
                    size={30}
                    justifyContent="left"
                    checked={item.value[0]}
                    onChange={() => {
                      if (editMode) {
                        const newValue = !item.value[0];
                        changeField(item.indexPath, 'value', newValue, 0);
                      }
                    }}
                  >
                    <Typography fontSize="16px" lineHeight="32px">
                      {item.value[0] ? 'Да' : 'Нет'}
                    </Typography>
                  </CheckBox>
                </InputWrapper>
              )}

              {item.type === 'SELECT' && (
                <InputWrapper>
                  <Select
                    readonly={!editMode}
                    placeholder="Выберите один из предложенных вариантов..."
                    value={item.value[0]}
                    options={item.options}
                    setValue={value => {
                      changeField(item.indexPath, 'value', value, 0);
                    }}
                  />
                </InputWrapper>
              )}

              {item.type === 'RADIO' && (
                <InputWrapper>
                  <RadioGroup
                    aria-label="radioselector"
                    name="radioselector"
                    value={item.value[0]}
                    onChange={event => {
                      if (editMode) {
                        changeField(item.indexPath, 'value', event.target.value, 0);
                      }
                    }}
                  >
                    {item.options.map(option => (
                      <FormControlLabel
                        disabled={!editMode}
                        key={option}
                        value={option}
                        control={<Radio color="primary" />}
                        label={option}
                        style={{ color: COLOR_BLACK_TEXT }}
                      />
                    ))}
                  </RadioGroup>
                </InputWrapper>
              )}

              {item.type === 'INT' && (
                <InputWrapper>
                  <InputField
                    disabled={!editMode}
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="2"
                    placeholder={item.hint}
                    type="number"
                    name="name"
                    value={'value' in item ? item.value[0] : ''}
                    onChange={value => {
                      changeField(item.indexPath, 'value', value, 0);
                      // inputSetStore(value, inputHandlerNameAccount, 'nameAccount');
                    }}
                  />
                </InputWrapper>
              )}

              {item.type === 'DATE' && (
                <DateWrapper>
                  <DateInputWrap>
                    <DateContent style={{ width: '100%' }}>
                      <InputDate
                        disabled={!editMode}
                        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                        tabIndex="2"
                        type="date"
                        name="dateType"
                        value={item.value[0]}
                        onChange={value => {
                          changeField(item.indexPath, 'value', value, 0);
                        }}
                      />
                    </DateContent>
                  </DateInputWrap>
                </DateWrapper>
              )}

              {item.type === 'TEXT' && (
                <InputWrapper>
                  {item.lines && item.lines < 2 ? (
                    <InputField
                      disabled={!editMode}
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex="2"
                      placeholder={item.hint}
                      type="text"
                      name="name"
                      value={'value' in item ? item.value[0] : ''}
                      onChange={value => {
                        changeField(item.indexPath, 'value', value, 0);
                      }}
                    />
                  ) : (
                    <TextArea
                      disabled={!editMode}
                      lines={item.lines}
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex="2"
                      placeholder={item.hint}
                      type="text"
                      name="name"
                      value={'value' in item ? item.value[0] : ''}
                      onChange={event => {
                        changeField(item.indexPath, 'value', event.target.value, 0);
                      }}
                    />
                  )}
                </InputWrapper>
              )}

              {item.type === 'FILE' && (
                <div>
                  <TableContainer>
                    <Thead>
                      <TrTop>
                        <Th width="10px" />
                        <Th width="80%">Имя файла</Th>
                        <Th width="18%">Размер</Th>
                        {/* <Th width="80px">Подпись</Th> */}
                      </TrTop>
                    </Thead>
                    <Tbody>
                      {item.fileData &&
                        item.fileData.map((fileitem, index) => (
                          <Tr key={fileitem.fileName /* + fileitem.file.saved */}>
                            <Td>
                              {editMode && (
                                <CheckBox
                                  checked={fileitem.selected}
                                  onChange={() => {
                                    logger.debug('check');
                                    changeField(
                                      item.indexPath,
                                      'fileData',
                                      !fileitem.selected,
                                      index,
                                    );
                                    // fileitem.selected = true; // !fileitem.selected;
                                  }}
                                />
                              )}
                            </Td>
                            <Td masked={fileitem.file && !fileitem.file.saved}>
                              {fileitem.fileName}
                            </Td>
                            <Td masked={fileitem.file && !fileitem.file.saved}>
                              {fileitem.fileSize}
                            </Td>
                            {/*
                              <ActivityTd className={fileitem.signed ? 'signed' : ''}>
                                {fileitem.signed ? <Img src={ICON_SUCCEEDED} /> : null}
                              </ActivityTd>
                            */}
                          </Tr>
                        ))}
                    </Tbody>
                  </TableContainer>
                  {editMode && (
                    <div style={{ margin: '15px -7px 15px' }}>
                      {item.fileData.length < item.maximumQnt && (
                        <TableButton
                          testID="TEST_BUTTON_ADD"
                          onClick={() => {
                            showFileDialog(
                              item.allowedMediaTypes,
                              /* item.fileData, */ item.indexPath,
                            );
                          }}
                        >
                          Добавить
                        </TableButton>
                      )}
                      <TableButton
                        testID="TEST_BUTTON_DEL"
                        onClick={() => {
                          logger.debug('delete files');
                          deleteFiles(item.indexPath);
                        }}
                      >
                        Удалить
                      </TableButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Shift>
        ))}
    </>
  );
};

RenderElements.propTypes = {
  items: PropTypes.array,
  editMode: PropTypes.bool,
  showFileDialog: PropTypes.func,
  changeField: PropTypes.func,
  addComposite: PropTypes.func,
  deleteComposite: PropTypes.func,
  deleteFiles: PropTypes.func,
};

RenderElements.defaultProps = {
  items: [],
  editMode: false,
  showFileDialog: () => {},
  changeField: () => {},
  addComposite: () => {},
  deleteComposite: () => {},
  deleteFiles: () => {},
};

const EditQuestionaryPopup = ({
  //  formId,
  questionary,
  changeField,
  addToField,
  // addFile,
  toggleEditQuestionaryPopup,
  //  formHandler,
  publishedViewLock,
  editMode,
  setEditMode,
  editStartedFlag,
  editSavedFlag,
  checkIsSaved,
  fetchQuestionaries,
  fetchQuestionary,
  // gatherNewFiles,
  progressPopup,
  toggleProgressPopup,
  saveQuestionary,
  validateQuestionary,
  uploaderPopup,
  toggleUploaderPopup,
  yesNoPopup,
  toggleYesNoPopup,
  addComposite,
  deleteComposite,
  deleteFiles,
  // clearQuestionary,
  deleteQuestionary,
}) => {
  // console.log('questionary', questionary);
  const inputFile = useRef(null);
  /* if (!('title' in questionary)) {
      console.log('*** RETURN ***')
      return
    } */
  // const [edit, setEdit] = useState(false)
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('');
  // let fileDataLocal = null;
  let pathLocal = null;
  let fileTypes = null;

  const showFileDialog = (fileType, /* fileData, */ path) => {
    // fileDataLocal = fileData;
    // fileType += ', application/msword, application/vnd.ms-excel';
    fileTypes = fileType;
    pathLocal = path;
    inputFile.current.accept = fileType; // "image/*"
    inputFile.current.click();
  };

  const filesSelected = files => {
    // eslint-disable-next-line
    for (const item of files) {
      if (fileTypes.indexOf(item.type) >= 0) {
        addToField(pathLocal, 'fileData', {
          fileName: item.name,
          fileType: item.type,
          fileSize: item.size,
          fileId: '',
          signed: false,
          selected: false,
          file: item,
        });
      } else logger.warn('Type', item.type, 'is not valid!');
    }
  };

  return (
    <>
      <Popup width="1100px" closePopup={() => {}} borderRadius="5px">
        {'title' in questionary && (
          <Box pt="20px">
            <Box
              pt="7px"
              pb="5px"
              color="white"
              style={{
                textAlign: 'center',
                backgroundColor: editMode ? COLOR_ACTION_BLUE : '#64B57F',
                borderRadius: '0px',
              }}
            >
              {editMode ? 'Черновик' : 'Просмотр опубликованной версии'}
            </Box>
            {/* <Divider style={{ backgroundColor: COLOR_ACTION_BLUE }} /> */}
          </Box>
        )}
        {questionary.elements && (
          <Wrapper style={{ paddingTop: '25px' }}>
            <Title as="h2">{questionary.title}</Title>
            <Typography textColor="#AAAAB0" fontSize="14px" lineHeight="20px" textAlign="justify">
              {questionary.description ? questionary.description : ''}
            </Typography>

            <input
              type="file"
              id="file"
              ref={inputFile}
              multiple
              style={{ display: 'none' }}
              onInput={event => filesSelected(event.target.files)}
            />

            <FormWrapper>
              <RenderElements
                items={questionary.elements}
                editMode={editMode}
                showFileDialog={showFileDialog}
                changeField={changeField}
                addComposite={addComposite}
                deleteComposite={deleteComposite}
                deleteFiles={deleteFiles}
              />

              {editMode ? (
                <ButtonWrapper>
                  <Button
                    disabled={editSavedFlag}
                    changeByDisable
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="1"
                    testID="TEST_BUTTON_SIGN"
                    width="303px"
                    sizes={{ margin: '0 0px' }}
                    onClick={async () => {
                      await saveQuestionary(toggleProgressPopup, setProgress, setStatus);
                    }}
                  >
                    Сохранить
                  </Button>
                  <Button
                    disabled={!editStartedFlag}
                    changeByDisable
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="2"
                    testID="TEST_BUTTON_SAVE"
                    width="303px"
                    type="submit"
                    sizes={{ margin: '0 20px' }}
                    onClick={async () => {
                      if (validateQuestionary()) {
                        if (await saveQuestionary(toggleProgressPopup, setProgress, setStatus))
                          toggleUploaderPopup();
                      }
                    }}
                  >
                    Опубликовать
                  </Button>
                  {true && (
                    <Button
                      disabled={false}
                      changeByDisable
                      width="70px"
                      textColor={COLOR_ACTION_BLUE}
                      boxShadow="none"
                      border="1px solid #f15c10"
                      backgroundColor="#f65"
                      sizes={{ margin: '0 20px' }}
                      onClick={() => {
                        toggleYesNoPopup();
                        // clearQuestionary();
                      }}
                    >
                      <Img src={ICON_DELETE} />
                    </Button>
                  )}
                  {questionary.isPublic ? (
                    <Button
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex="3"
                      testID="TEST_BUTTON_CANCEL"
                      width="303px"
                      textColor={COLOR_ACTION_BLUE}
                      boxShadow="none"
                      backgroundColor="#fff"
                      sizes={{ margin: '0 0px' }}
                      onClick={async () => {
                        if (await checkIsSaved()) {
                          setEditMode(false);
                          await fetchQuestionary();
                        }
                      }}
                    >
                      Отменить
                    </Button>
                  ) : (
                    <Button
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex="3"
                      testID="TEST_BUTTON_CANCEL"
                      width="303px"
                      textColor={COLOR_ACTION_BLUE}
                      boxShadow="none"
                      backgroundColor="#fff"
                      sizes={{ margin: '0 20px' }}
                      onClick={async () => {
                        if (await checkIsSaved()) {
                          await fetchQuestionaries();
                          toggleEditQuestionaryPopup();
                        }
                      }}
                    >
                      Назад
                    </Button>
                  )}
                </ButtonWrapper>
              ) : (
                <ButtonWrapper>
                  {!publishedViewLock && (
                    <Button
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex="1"
                      testID="TEST_BUTTON_SAVE"
                      width="303px"
                      type="submit"
                      sizes={{ margin: '0 20px' }}
                      onClick={() => setEditMode(true)}
                    >
                      Редактировать
                    </Button>
                  )}
                  <Button
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="2"
                    testID="TEST_BUTTON_CANCEL"
                    width="303px"
                    textColor={COLOR_ACTION_BLUE}
                    boxShadow="none"
                    backgroundColor="#fff"
                    sizes={{ margin: '0 20px' }}
                    onClick={async () => {
                      await fetchQuestionaries();
                      toggleEditQuestionaryPopup();
                    }}
                  >
                    Назад
                  </Button>
                </ButtonWrapper>
              )}
            </FormWrapper>
          </Wrapper>
        )}
      </Popup>

      {yesNoPopup === true ? (
        <YesNoPopup
          title={
            questionary.hasPublic
              ? 'Удалить черновик и вернуться к опубликованной версии ?'
              : 'Удалить черновик ? Внимание, действие не обратимо!'
          }
          toggleYesNoPopup={toggleYesNoPopup}
          accept={async () => {
            await deleteQuestionary();
            if (questionary.hasPublic) {
              await fetchQuestionary();
            } else {
              await fetchQuestionaries();
              toggleEditQuestionaryPopup();
            }
          }}
        />
      ) : null}

      {uploaderPopup === true ? <UploaderPopup toggleUploaderPopup={toggleUploaderPopup} /> : null}

      {progressPopup === true ? (
        <ProgressPopup
          toggleProgressPopup={toggleProgressPopup}
          title={status}
          progress={progress}
        />
      ) : null}
    </>
  );
};

EditQuestionaryPopup.propTypes = {
  toggleEditQuestionaryPopup: PropTypes.func.isRequired,
  questionary: PropTypes.object,
  changeField: PropTypes.func.isRequired,
  addToField: PropTypes.func.isRequired,
  publishedViewLock: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  editStartedFlag: PropTypes.bool,
  editSavedFlag: PropTypes.bool,
  checkIsSaved: PropTypes.func.isRequired,
  fetchQuestionaries: PropTypes.func.isRequired,
  fetchQuestionary: PropTypes.func.isRequired,
  saveQuestionary: PropTypes.func,
  validateQuestionary: PropTypes.func,
  progressPopup: PropTypes.bool,
  toggleProgressPopup: PropTypes.func,
  uploaderPopup: PropTypes.bool,
  toggleUploaderPopup: PropTypes.func,
  yesNoPopup: PropTypes.bool,
  toggleYesNoPopup: PropTypes.func,
  addComposite: PropTypes.func.isRequired,
  deleteComposite: PropTypes.func.isRequired,
  deleteFiles: PropTypes.func.isRequired,
  deleteQuestionary: PropTypes.func.isRequired,
};

EditQuestionaryPopup.defaultProps = {
  questionary: {},
  saveQuestionary: () => {},
  validateQuestionary: () => {},
  progressPopup: false,
  toggleProgressPopup: () => {},
  uploaderPopup: false,
  toggleUploaderPopup: () => {},
  yesNoPopup: false,
  toggleYesNoPopup: () => {},
  editStartedFlag: false,
  editSavedFlag: true,
};

const enchance = compose(
  inject(storeNames.SharedInfoStore),
  observer,
  withProps(({ SharedInfoStore }) => ({
    questionary: SharedInfoStore.questionary.get(SharedInfoStore.formId),
    changeField: SharedInfoStore.changeField,
    addToField: SharedInfoStore.addToField,
    publishedViewLock: SharedInfoStore.publishedViewLock,
    editMode: SharedInfoStore.editMode,
    setEditMode: SharedInfoStore.setEditMode,
    editStartedFlag: SharedInfoStore.editStartedFlag,
    editSavedFlag: SharedInfoStore.editSavedFlag,
    checkIsSaved: SharedInfoStore.checkIsSaved,
    fetchQuestionaries: SharedInfoStore.fetchQuestionaries,
    fetchQuestionary: SharedInfoStore.fetchQuestionary,
    saveQuestionary: SharedInfoStore.saveQuestionary,
    validateQuestionary: SharedInfoStore.validateQuestionary,
    addComposite: SharedInfoStore.addComposite,
    deleteComposite: SharedInfoStore.deleteComposite,
    deleteFiles: SharedInfoStore.deleteFiles,
    deleteQuestionary: SharedInfoStore.deleteQuestionary,
  })),
  withBoolState('yesNoPopup', false),
  withBoolState('uploaderPopup', false),
  withBoolState('progressPopup', false),
  lifecycle({
    async componentDidMount() {
      const { SharedInfoStore, toggleEditQuestionaryPopup } = this.props;

      if (!(await SharedInfoStore.fetchQuestionary())) toggleEditQuestionaryPopup();
    },
  }),
);

export default enchance(EditQuestionaryPopup);
