import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  compose,
  withStateHandlers,
  withHandlers,
  withState,
  withProps,
  lifecycle,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AUTH_URL } from '../../constants/routes';
import { ICON_SHAPE } from '../../constants/icons';
import {
  Main,
  Wrapper,
  Title,
  AuthoForm,
  TextLink,
  ParagraphError,
  Paragraph,
  InputWrapper,
  Button,
  RegLink,
  ImgShape,
  ButtonLink,
  CommonBlock,
} from './AuthorizationStyled';
import InputField from '../../components/Inputs/InputField';
import { COLOR_WHITE, COLOR_ACTION_BLUE } from '../../constants/colors';
import storeNames from '../../stores/storeNames';
import history from '../../history';
import { toLowerCaseAndTrim } from '../../utils/validators';
import { isAuthenticated } from '../../services/session';
import { TEST_BUTTON_LOGIN } from '../../constants/testids';
import { mapErrorsByCode } from '../../helpers/errors';
import { hasRegistration, hasSimpleScoring } from '../../constants/env';
// import logger from '../../logger';

const PasswordSchema = Yup.string()
  .required('login.canNotBeEmpty') // 'Поле не может быть пустым.')
  .min(1, 'login.passVeryShort') // 'Пароль слишком короткий - Необходимо минимум 8 символов.')
  .matches(/[a-zA-Z0-9]/, 'Password can only contain Latin letters and numbers.');

const EmailOrPhoneSchema = Yup.string().required('login.canNotBeEmpty'); // 'Поле не может быть пустым.');

const Login = ({
  togglePasswordType,
  inputEmailOrPhoneData,
  setInputEmailOrPhoneData,
  formHandler,
  authorizationState,
  inputPasswordData,
  setInputPasswordData,
  passwordError,
  mailOrPhoneError,
  isBusy,
}) => {
  const { t } = useTranslation();
  return (
    <Main>
      <Wrapper>
        <Title as="h2">{t('login.auth')}</Title>
        <AuthoForm onSubmit={formHandler}>
          <InputWrapper>
            <InputField
              value={inputEmailOrPhoneData}
              name="login"
              onChange={setInputEmailOrPhoneData}
              placeholder={t('login.login')}
              margin="0 0 32px 0"
            />
            <ParagraphError>{t(mailOrPhoneError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              placeholder={t('login.password')}
              name="password"
              type={authorizationState.showPassword}
              margin="0 0 12px 0"
              value={inputPasswordData}
              onChange={setInputPasswordData}
            />
            <ImgShape src={ICON_SHAPE} onClick={togglePasswordType} />
            <ParagraphError>{t(passwordError)}</ParagraphError>
          </InputWrapper>
          <TextLink data-test-id="TEST_LINK_RESET-PASSWORD" to="/recovery">
            {t('login.forbidPass')}
          </TextLink>
          <Button
            onClick={formHandler}
            width="100%"
            textColor={COLOR_WHITE}
            backgroundColor={COLOR_ACTION_BLUE}
            type="submit"
            disabled={isBusy}
            data-test-id={TEST_BUTTON_LOGIN}
          >
            {t('login.enter')}
          </Button>
          <Paragraph>{t(hasRegistration ? 'login.noAccount' : 'login.restriction')}</Paragraph>
          {hasRegistration && (
            <RegLink data-test-id="TEST_LINK_REGISTRATION" to="/signup">
              {t('login.registering')}
            </RegLink>
          )}
        </AuthoForm>
        {hasSimpleScoring && (
          <CommonBlock>
            <ButtonLink data-test-id="TEST_LINK_SCORING" to="/sbr">
              {t('login.scoringWOreg')}
            </ButtonLink>
          </CommonBlock>
        )}
      </Wrapper>
    </Main>
  );
};

Login.propTypes = {
  isBusy: PropTypes.bool.isRequired,
  inputPasswordData: PropTypes.string,
  mailOrPhoneError: PropTypes.string,
  passwordError: PropTypes.string,
  authorizationState: PropTypes.object,
  togglePasswordType: PropTypes.func,
  inputEmailOrPhoneData: PropTypes.string,
  setInputEmailOrPhoneData: PropTypes.func,
  setInputPasswordData: PropTypes.func,
  formHandler: PropTypes.func,
};

Login.defaultProps = {
  authorizationState: undefined,
  inputPasswordData: '',
  passwordError: '',
  mailOrPhoneError: '',
  inputEmailOrPhoneData: '',
  togglePasswordType: () => {},
  setInputEmailOrPhoneData: () => {},
  setInputPasswordData: () => {},
  formHandler: () => {},
};

const enhancer = compose(
  inject(storeNames.ProfileStore),
  inject(storeNames.LanguageStore),
  observer,
  withRouter,
  withTranslation(),
  withProps(({ ProfileStore }) => ({
    getToken: ProfileStore.getToken,
    isBusy: ProfileStore.isBusy,
    fetchUser: ProfileStore.fetchUser,
  })),
  withState('passwordError', 'setPasswordError', ''),
  withState('mailOrPhoneError', 'setMailOrPhoneError', ''),
  withStateHandlers(
    {
      authorizationState: {
        showPassword: 'password',
        inputEmailOrPhoneData: '',
        inputPasswordData: '',
      },
    },
    {
      togglePasswordType: ({ authorizationState }) => () => ({
        authorizationState: {
          showPassword: `${authorizationState.showPassword === 'password' ? 'text' : 'password'}`,
        },
      }),
      setInputEmailOrPhoneData: () => value => {
        return { inputEmailOrPhoneData: value };
      },
      setInputPasswordData: () => value => {
        return { inputPasswordData: value };
      },
    },
  ),
  withHandlers(() => ({
    formHandler: ({
      LanguageStore,
      i18n,
      inputPasswordData,
      inputEmailOrPhoneData,
      setPasswordError,
      setMailOrPhoneError,
      setInputPasswordData,
      getToken,
      isBusy,
      fetchUser,
    }) => async e => {
      e.preventDefault();
      if (isBusy) return;
      try {
        await PasswordSchema.validate(inputPasswordData);
        setPasswordError('');
      } catch (error) {
        setPasswordError(error.message);
      }
      try {
        await EmailOrPhoneSchema.validate(inputEmailOrPhoneData);
        setMailOrPhoneError('');
      } catch (error) {
        setMailOrPhoneError(error.message);
      }
      // TODO: удалили  hasValue и trimValue, надо ресерч
      const password = inputPasswordData;
      const login = toLowerCaseAndTrim(inputEmailOrPhoneData);
      const { code } = await getToken({ password, login });
      if (isAuthenticated()) {
        await LanguageStore.syncLocale(i18n.language);
        const userData = await fetchUser();
        if (userData && userData.need_password_change) {
          history.replace('/change');
        } else {
          history.replace('/profile');
          return;
        }
      }
      if (code) {
        setMailOrPhoneError(mapErrorsByCode(code));
        setPasswordError(mapErrorsByCode(code));
      }
      setInputPasswordData('');
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { lang } = this.props.match.params;
      const { i18n } = this.props;
      i18n.changeLanguage(lang);
      history.replace(AUTH_URL);
    },
  }),
);

export default enhancer(Login);
