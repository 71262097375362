import API from './base';
import { getUrlToLKApi } from '../constants/api';

export default class AuthApi extends API {
  /**
   * @param {String} token
   * @param {Object} data
   * login: '',
   * password: ''
   */
  getToken(data, token = null) {
    return this.post(getUrlToLKApi('auth/login'), data, token, false);
  }

  /**
   * get customer token profile api
   * @param {Object} param0.userId - number of user
   * @param {String} token - user token
   */
  getTokenBySuperAdmin({ userId }, token = null) {
    return this.post(getUrlToLKApi(`auth/token/${userId}`), {}, token, false);
  }

  signup(data, token = null) {
    return this.post(getUrlToLKApi('auth/registration'), data, token, false);
  }

  preparePasswordReset({ email, return_url }, token = null) {
    return this.post(getUrlToLKApi('auth/passwordResets'), { email, return_url }, token, false);
  }

  /**
   * Reset and set new password
   * @param {Object} param0
   * @param {String} param0.passwordResetId - number from url param
   * @param {passwordResetCode} param0.passwordResetCode - token for reset code
   * @param {newPassword} param0.newPassword - new user password
   */
  resetPassword({ passwordResetId, passwordResetCode, newPassword }, token = null) {
    return this.put(
      getUrlToLKApi(`auth/passwordResets/${passwordResetId}`),
      { password_reset_code: passwordResetCode, new_password: newPassword },
      token,
      false,
    );
  }
}
