import React from 'react';
import { lifecycle, compose, withState, withHandlers } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import history from '../../history';
import { PopupBody, Title, Container } from './StatusStyled';
import { getStatusIcon } from '../../helpers/statusMessages';
import { getUrlObject, replaceUrl, removeUrlParams, getOrigin } from '../../helpers/url';
import { Icon } from '../../components/Pictures';
import { ParapgraphText } from '../../components/Typography/Paragraph';
import Button from '../../components/Buttons/Button';
import { STATUS_PAGE } from '../../constants/statusPageUrls';
import {
  PAYMENT_WAITING,
  UNKNOWN_ERROR,
  PAYMENT_CANCELED,
  PAYMENT_SUCCESS,
  CONFIRMATION_SUCCESS,
  EMAIL_ERROR,
  EMAIL_CONFIRMATION_WRONG_CODE,
  EMAIL_CONFIRMATION_NOT_FOUND,
  USER_NOT_FOUND,
} from '../../constants/messages';
import storeNames from '../../stores/storeNames';

/**
 * Добавил страницу для отображения различных статусов.
 * Работет через параметры в урле, после получения сразу сливает все после ?,
 * может отсылать на внешние ресуры и во внутренние, не показывается если
 * в урле не были переданы message и status параметры.
 * Use example: http://localhost:3000/status?status=success&message=%D0%9E%D0%BF%D0%BB%D0%B0%D1%82%D0%B0%20%D0%BF%D1%80%D0%BE%D1%88%D0%BB%D0%B0%20%D1%83%D1%81%D0%BF%D0%B5%D1%88%D0%BD%D0%BE!&description=%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%81%D0%BA%D0%BE%D1%80%D0%B8%D0%BD%D0%B3%D1%83%20%D0%B1%D1%83%D0%B4%D0%B5%D1%82%20%D0%B2%D1%8B%D1%81%D0%BB%D0%B0%D0%BD%20%D0%BD%D0%B0%20%D1%83%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%BD%D1%8B%D0%B9%20E-mail&actionUrl=http://localhost:3000/login
 */
const Status = ({ isLoading, status, message, description, buttonText, onReplaceHistory }) => {
  const { t } = useTranslation();
  return (
    <>
      {!isLoading && (
        <PopupBody>
          <Container>
            <Icon internal image={getStatusIcon(status)} size="88px" />
          </Container>
          <Title>{message}</Title>
          <ParapgraphText>{description}</ParapgraphText>
          <Container>
            <Button width="233px" onClick={onReplaceHistory}>
              {buttonText || t('home.toMainPage')}
            </Button>
          </Container>
        </PopupBody>
      )}
    </>
  );
};

export const StatusPage = compose(
  inject(storeNames.BalanceStore, storeNames.ProfileStore),
  // withTranslation(),
  observer,
  withState('status', 'setStatus', undefined),
  withState('actionUrl', 'setActionUrl', undefined),
  withState('buttonText', 'setButtonText', undefined),
  withState('message', 'setMessage', undefined),
  withState('description', 'setDescription', undefined),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    onReplaceHistory: ({ actionUrl }) => () => {
      replaceUrl(actionUrl);
    },
    checkUrl: ({ setStatus, setMessage, setDescription, setButtonText, setActionUrl }) => () => {
      const url = getUrlObject();
      const status = url.searchParams.get('status');
      const message = url.searchParams.get('message');
      const description = url.searchParams.get('description');
      const actionUrl = url.searchParams.get('actionUrl');
      const buttonText = url.searchParams.get('buttonText');
      localStorage.removeItem('ACCOUNT_ID');
      setStatus(status);
      setMessage(message);
      setDescription(description);
      setButtonText(buttonText);
      setActionUrl(actionUrl);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { checkUrl } = this.props;
      checkUrl();
      const url = getUrlObject();
      const status = url.searchParams.get('status');
      const message = url.searchParams.get('message');
      removeUrlParams();
      if (!status || !message) {
        history.replace('/');
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { BalanceStore, ProfileStore, setIsLoading } = this.props;
      const url = getUrlObject();
      const paymentId = url.searchParams.get('paymentId');
      const emailConfirmationId = url.searchParams.get('emailConfirmationId');
      const emailConfirmationCode = url.searchParams.get('emailConfirmationCode');
      const entity = url.searchParams.get('entity');
      const page = url.searchParams.get('page');
      const pageName = page || 'services';
      const pageNameConfirmation = page || 'profile';
      if (emailConfirmationId && entity === 'user') {
        const userId = localStorage.getItem('USER_ID');
        localStorage.removeItem('USER_ID');
        if (!userId) {
          history.replace('/');
          history.replace(STATUS_PAGE(`${getOrigin()}/${pageName}`, UNKNOWN_ERROR, 'error'));
        }
        setIsLoading(true);
        await ProfileStore.getStatusConfirmation(userId, emailConfirmationId, {
          email_confirmation_code: emailConfirmationCode,
        });
        await ProfileStore.hardFetchUser();
        setIsLoading(false);
        if (!ProfileStore.statusConfirmation) {
          history.replace('/');
          history.replace(
            STATUS_PAGE(`${getOrigin()}/${pageNameConfirmation}`, CONFIRMATION_SUCCESS, 'success'),
          );
        }
        if (
          ProfileStore.statusConfirmation === 'Validation failed (numeric string is expected)' ||
          ProfileStore.statusConfirmation === 'EMAIL_CONFIRMATION_CONFLICT'
        ) {
          history.replace('/');
          history.replace(
            STATUS_PAGE(`${getOrigin()}/${pageNameConfirmation}`, EMAIL_ERROR, 'error'),
          );
        }
        if (ProfileStore.statusConfirmation === 'EMAIL_CONFIRMATION_WRONG_CODE') {
          history.replace('/');
          history.replace(
            STATUS_PAGE(
              `${getOrigin()}/${pageNameConfirmation}`,
              EMAIL_CONFIRMATION_WRONG_CODE,
              'error',
            ),
          );
        }
        if (ProfileStore.statusConfirmation === 'EMAIL_CONFIRMATION_NOT_FOUND') {
          history.replace('/');
          history.replace(
            STATUS_PAGE(
              `${getOrigin()}/${pageNameConfirmation}`,
              EMAIL_CONFIRMATION_NOT_FOUND,
              'error',
            ),
          );
        }
        if (ProfileStore.statusConfirmation === 'USER_NOT_FOUND') {
          history.replace('/');
          history.replace(
            STATUS_PAGE(`${getOrigin()}/${pageNameConfirmation}`, USER_NOT_FOUND, 'error'),
          );
        }
      }
      if (emailConfirmationId && entity === 'customer') {
        const customerId = localStorage.getItem('USER_ID');
        localStorage.removeItem('CUSTOMER_ID');
        if (!customerId) {
          history.replace('/');
          history.replace(STATUS_PAGE(`${getOrigin()}/${pageName}`, UNKNOWN_ERROR, 'error'));
        }
        setIsLoading(true);
        await ProfileStore.getStatusCustomerConfirmation(emailConfirmationId, {
          email_confirmation_code: emailConfirmationCode,
        });
        await ProfileStore.hardFetchUser();
        setIsLoading(false);
        if (!ProfileStore.statusConsumerConfirmation) {
          history.replace('/');
          history.replace(
            STATUS_PAGE(`${getOrigin()}/${pageNameConfirmation}`, CONFIRMATION_SUCCESS, 'success'),
          );
        }
        if (
          ProfileStore.statusConsumerConfirmation ===
            'Validation failed (numeric string is expected)' ||
          ProfileStore.statusConsumerConfirmation === 'EMAIL_CONFIRMATION_CONFLICT'
        ) {
          history.replace('/');
          history.replace(
            STATUS_PAGE(`${getOrigin()}/${pageNameConfirmation}`, EMAIL_ERROR, 'error'),
          );
        }
        if (ProfileStore.statusConsumerConfirmation === 'EMAIL_CONFIRMATION_WRONG_CODE') {
          history.replace('/');
          history.replace(
            STATUS_PAGE(
              `${getOrigin()}/${pageNameConfirmation}`,
              EMAIL_CONFIRMATION_WRONG_CODE,
              'error',
            ),
          );
        }
        if (ProfileStore.statusConsumerConfirmation === 'EMAIL_CONFIRMATION_NOT_FOUND') {
          history.replace('/');
          history.replace(
            STATUS_PAGE(
              `${getOrigin()}/${pageNameConfirmation}`,
              EMAIL_CONFIRMATION_NOT_FOUND,
              'error',
            ),
          );
        }
        if (ProfileStore.statusConsumerConfirmation === 'USER_NOT_FOUND') {
          history.replace('/');
          history.replace(
            STATUS_PAGE(`${getOrigin()}/${pageNameConfirmation}`, USER_NOT_FOUND, 'error'),
          );
        }
      }
      if (paymentId) {
        const accountId = localStorage.getItem('ACCOUNT_ID');
        if (!accountId) {
          history.replace('/');
          history.replace(STATUS_PAGE(`${getOrigin()}/${pageName}`, UNKNOWN_ERROR, 'error'));
        }
        setIsLoading(true);
        const status = await BalanceStore.getStatus(accountId, paymentId);
        setIsLoading(false);
        if (status === 'waiting') {
          history.replace('/');
          history.replace(STATUS_PAGE(`${getOrigin()}/${pageName}`, PAYMENT_WAITING, 'success'));
        }
        if (!status) {
          history.replace('/');
          history.replace(STATUS_PAGE(`${getOrigin()}/${pageName}`, UNKNOWN_ERROR, 'error'));
        }
        if (status === 'succeeded') {
          history.replace('/');
          history.replace(STATUS_PAGE(`${getOrigin()}/${pageName}`, PAYMENT_SUCCESS, 'success'));
        }
        if (status === 'canceled') {
          history.replace('/');
          history.replace(STATUS_PAGE(`${getOrigin()}/${pageName}`, PAYMENT_CANCELED, 'error'));
        }
      }
    },
  }),
)(Status);

Status.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onReplaceHistory: PropTypes.func,
};

Status.defaultProps = {
  status: undefined,
  message: undefined,
  isLoading: false,
  description: undefined,
  buttonText: undefined,
  onReplaceHistory: () => {},
};
