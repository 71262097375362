import React from 'react';
import PropTypes from 'prop-types';

import * as moment from 'moment';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import { Title } from '../../Balance/BalanceStyled';
import { useStyles } from './Styles/styles';

const Pledges = ({ data }) => {
  // console.log('Entity', data);
  const { pledges } = data;
  const sc = useStyles();
  if (pledges === undefined) return <></>;
  return (
    <>
      {pledges.length ? (
        pledges.map((item, index) => (
          <Box mt={2} mb={3} sx={{ textAlign: 'left' }} key={index}>
            <Typography className={sc.pledgesTitle} variant="h5" component="div">
              <Box pr={4} sx={{ display: 'flex', justifyContent: 'left' }}>
                {moment.utc(item.contract_date).format('DD.MM.YYYY')}
              </Box>
              {item.description}
            </Typography>
            <Box mt={1} pl={14}>
              <Typography className={sc.pledgesSubject} variant="h5" component="div">
                <i>Номер контракта:&nbsp;</i> {item.contract_number}
              </Typography>
            </Box>
            <Box mt={1} pl={14}>
              <Typography className={sc.pledgesSubject} variant="h5" component="div">
                <i>Предмет залога:&nbsp;</i> {item.subject_description}
              </Typography>
            </Box>
            <Box mt={1} pl={14}>
              <Typography className={sc.pledgesSubject} variant="h5" component="div">
                <i>Срок погашения:&nbsp;</i>{' '}
                {item.maturity_date
                  ? moment.utc(item.maturity_date).format('DD.MM.YYYY')
                  : 'не определен'}
              </Typography>
            </Box>
            <Box mt={1} pl={14}>
              <Typography className={sc.pledgesSubject} variant="h5" component="div">
                <i>Залогодержатель:&nbsp;</i> ОГРН{' '}
                {item.pledgee.data !== null ? item.pledgee.data.ogrn : '??'},{' '}
                {item.pledgee.data !== null ? item.pledgee.data.full_name : '??'}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography className={sc.detailTitle} variant="h5" component="div">
            Залоги отсутствуют
          </Typography>
        </Box>
      )}
    </>
  );
};
Pledges.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Pledges;
