import styled from 'styled-components';
import Paragraph from '../../../components/Typography/Paragraph';
import { mobileMedia } from '../../../constants/styles';

export const InputColumn = styled.div`
  display: flex;
  justify-content: space-between;
  width: 47%;
  flex-wrap: wrap;
  order: ${props => (props.order ? props.order : null)};
  @media (max-width: ${mobileMedia}) {
    width: 100%;
  }
`;

export const ColumnBottom = styled(InputColumn)`
  width: 100%;
`;

export const SimpleInput = styled.div`
  width: 46%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
  }
`;

export const DateInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ImgShape = styled.img`
  position: absolute;
  cursor: pointer;
  right: 0;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

export const TextData = styled.p`
  color: #2e3238;
  font-size: 16px;
  line-height: 24px;
`;

export const Description = styled.p`
  color: #90a0b7;
  font-size: 12px;
  line-height: 19px;
`;

export const DescriptionOptional = styled(Description)`
  color: #90a0b7;
  font-size: 12px;
  line-height: 19px;
  position: absolute;
  top: 27px;
  right: 17px;
  z-index: 1;
`;

export const DateInputWrap = styled.div`
  width: 201px;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  order: ${props => (props.order ? props.order : '')};
`;

export const ColumnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1px solid #e1e1e1;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 8px;
  margin-top: 12px;
  flex-direction: column;
  margin-bottom: 25px;
  @media (max-width: ${mobileMedia}) {
    border: none;
    border-radius: 0;
    border-top: 1px solid #e1e1e1;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 5px;
  }
`;

export const TextParagraph = styled(Paragraph)``;

export const OptionalInput = styled.div`
  width: 100%;
  position: relative;
`;
