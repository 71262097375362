export const prepareValidatePassword = ({
  newPassword,
  setNewPasswordError,
  setConfirmPasswordError,
  confirmPassword,
}) => {
  // ВАЛИДАЦИЯ ПАРОЛЯ //
  let passwordIsValid = true;
  const regexValid = /^[a-zA-Z0-9]+$/;
  if (!newPassword) {
    passwordIsValid = false;
    setNewPasswordError('Введите пароль');
  } else {
    setNewPasswordError('');
    if (newPassword.length < 8) {
      passwordIsValid = false;
      setNewPasswordError('Пароль должен быть не меньше 8 символов');
    } else {
      setNewPasswordError('');
      if (!regexValid.test(newPassword)) {
        passwordIsValid = false;
        setNewPasswordError('Пароль должен состоять из цифр и символов латинского алфавита');
        setConfirmPasswordError('');
      } else {
        setNewPasswordError('');
        if (newPassword !== confirmPassword) {
          setConfirmPasswordError('Пароли не совпадают');
          passwordIsValid = false;
        } else {
          setConfirmPasswordError('');
          passwordIsValid = true;
        }
      }
    }
  }

  return passwordIsValid;
};
