import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TitleText } from '../../components/Typography/Title';
import { Btn } from '../../components/Buttons/Button';
import { ParapgraphText } from '../../components/Typography/Paragraph';
import {
  COLOR_TITLE_BLACK,
  COLOR_ACTION_BLUE,
  COLOR_BLACK_LOGO,
  COLOR_WHITE,
  COLOR_BLACK_SHADOW_SMALL,
} from '../../constants/colors';
import { mobileMedia } from '../../constants/styles';

export const Main = styled.div`
  width: 100%;
  padding: 40px 32px;
  min-height: calc(100vh - 88px);
  background: #f5f6f8;
  @media (max-width: 450px) {
    padding: 5px 5px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_TITLE_BLACK};
  padding-top: 50px;
  padding-bottom: 23px;
  line-height: 1.3;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
  @media (max-width: 450px) {
    padding-top: 18px;
  }
`;

export const AuthoForm = styled.form`
  width: 430px;
  height: 100%;
  background: ${COLOR_WHITE};
  box-shadow: 0px 0px 10px ${COLOR_BLACK_SHADOW_SMALL};
  border-radius: 8px;
  padding: 38px 32px 31px 32px;
  @media (max-width: 450px) {
    max-width: 430px;
    width: 100%;
  }
`;

export const CommonBlock = styled.div`
  width: 430px;
  background: ${COLOR_WHITE};
  box-shadow: 0px 0px 10px ${COLOR_BLACK_SHADOW_SMALL};
  border-radius: 8px;
  padding: 38px 32px 31px 32px;
  margin: 40px 0 0;
  @media (max-width: 450px) {
    max-width: 430px;
    width: 100%;
  }
`;

export const TextLink = styled(Link)`
  color: ${COLOR_ACTION_BLUE};
  margin-left: 15px;
  margin-bottom: 40px;
  display: inline-block;
`;

export const RegLink = styled(Link)`
  color: ${COLOR_ACTION_BLUE};
  text-transform: uppercase;
  display: block;
  text-align: center;
`;

export const ButtonLink = styled(RegLink)`
  border: 1px solid ${COLOR_ACTION_BLUE};
  padding: 10px;
  border-radius: 4px;
  transition: 0.15s ease;

  &:hover {
    background-color: ${COLOR_ACTION_BLUE};
    color: ${COLOR_WHITE};
  }
`;

export const Paragraph = styled(ParapgraphText)`
  color: ${COLOR_BLACK_LOGO};
  text-align: center;
  margin-bottom: 8px;
  line-height: 19px;
`;

export const Button = styled(Btn)`
  margin-bottom: 21px;
  height: auto;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ImgShape = styled.img`
  position: absolute;
  cursor: pointer;
  right: 0;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;

export const ParagraphError = styled.p`
  position: absolute;
  color: red;
  right: 0;
  font-size: 9px;
  bottom: -15px;
`;
