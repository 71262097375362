import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useMedia } from 'use-media';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Buttons/Button';
import InputField from '../../../components/Inputs/InputField';
import Popup from '../../../components/Popup/Popup';
import Paragraph from '../../../components/Typography/Paragraph';
import { COLOR_ACTION_BLUE, COLOR_LINK_BLACK } from '../../../constants/colors';
import inviteText from '../../../constants/inviteText';
import storeNames from '../../../stores/storeNames';
import { Bottom, FormWrapper, InputWrapper, Wrapper } from '../RepeatPopup/PopupStyled';
import useExtraDiscretionFormHandler from './useExtraDiscretionFormHandler';

const FormBottom = styled(Bottom)`
  button + button {
    margin-top: 20px;
  }
`;

const ExtraDiscretionModal = ({
  removeModal,
  context,
  updateInquire,
  ourOgrn,
  ourOrganization,
}) => {
  const { t } = useTranslation();
  const { score_id: id, ogrn: theirOgrn, name_full } = context;

  const [formValues, setFormValues] = useState({
    emails: [],
    text: inviteText({ theirOrganization: name_full, theirOgrn, ourOgrn, ourOrganization }),
  });

  const [errors, setErrors] = useState({ emails: '', text: '', server: '' });

  const onCloseSnackbar = () => setErrors(prev => ({ ...prev, server: '' }));

  const mobileMedia = useMedia({ maxWidth: 768 });

  const onFormChange = ({ target: { name, value } }) => {
    if (name === 'emails') {
      setFormValues(p => ({
        ...p,
        [name]: value.split(',').map(em => em.trim()),
      }));
    } else {
      setFormValues(prev => ({ ...prev, [name]: value }));
    }
  };

  const onFormKeyDown = event => {
    const {
      target: { name, value },
      key,
    } = event;

    if (name === 'emails' && key === 'Backspace' && !formValues.emails.at(-1)) {
      event.preventDefault();
      setFormValues(p => ({
        ...p,
        [name]: value
          .split(',')
          .map(em => em.trim())
          .filter(v => v),
      }));
    }
  };

  const formHandler = useExtraDiscretionFormHandler({
    formValues,
    id,
    removeModal,
    setErrors,
    updateInquire,
  });

  return (
    <Popup width="600px" closePopup={removeModal}>
      <Snackbar
        open={!!errors.server}
        onClose={onCloseSnackbar}
        message={errors.server}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      <Wrapper>
        <Paragraph
          textColor={COLOR_LINK_BLACK}
          fontSize={mobileMedia ? '24px' : '32px'}
          lineHeight="42px"
          fontWeight="300"
        >
          {t('inquiries.discretionMessage')}
        </Paragraph>
        <FormWrapper onSubmit={formHandler} onChange={onFormChange} onKeyDown={onFormKeyDown}>
          <InputWrapper>
            <Tooltip title={t('inquiries.writeSomeValidEmails')} placement="top">
              <div>
                <InputField
                  placeholder="E-mail"
                  name="emails"
                  value={formValues.emails.join(', ')}
                  errorMessage={errors.emails}
                  isValid={!errors.emails}
                />
              </div>
            </Tooltip>
          </InputWrapper>
          <InputWrapper>
            <InputField
              placeholder={t('inquiries.invitationText')}
              name="text"
              value={formValues.text}
              errorMessage={errors.text}
              isValid={!errors.text}
              textarea
            />
          </InputWrapper>
          <FormBottom>
            <Button type="submit">{t('inquiries.sendInvitation')}</Button>
            <Button
              type="button"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              onClick={removeModal}
            >
              {t('form.cancel')}
            </Button>
          </FormBottom>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

ExtraDiscretionModal.propTypes = {
  removeModal: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  updateInquire: PropTypes.func.isRequired,
  ourOgrn: PropTypes.string,
  ourOrganization: PropTypes.string,
};

ExtraDiscretionModal.defaultProps = {
  ourOgrn: '',
  ourOrganization: '',
};

export default compose(
  inject(storeNames.Modals, storeNames.InquiriesStore, storeNames.ProfileStore),
  observer,
  withProps(({ Modals, InquiriesStore, ProfileStore }) => ({
    removeModal: Modals.removeModal,
    updateInquire: InquiriesStore.updateInquire,
    ourOgrn: ProfileStore.userData.customer.ogrn,
    ourOrganization: ProfileStore.userData.customer.full_name,
  })),
)(ExtraDiscretionModal);
