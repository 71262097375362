export const getEntityStatus = status => {
  switch (status.toUpperCase()) {
    case 'ACTIVE':
      return 'Действующая';
    default:
      return status;
  }
};

export const getEntityError = (errorMessage, query) => {
  switch (errorMessage.toUpperCase()) {
    case 'ENTITY_NOT_FOUND':
      return {
        err: `По запросу "${query}" найдены 0 организаций и 0 индивидуальных предпринимателей`,
        common: 'Попробуйте изменить поисковой запрос',
      };
    default:
      return { err: '', common: '' };
  }
};
