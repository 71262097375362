import history from '../history';
import logger from '../logger';
import 'url-search-params-polyfill';
import 'url-polyfill';

/**
 * Get location href: protocol, subdomain and domain
 */
export const getOrigin = () => window.location.origin;

/**
 * Get url object.
 * Method use default url from window.location.href or wait specified url in function argument
 * @param {string} url - location.href string
 */
export const getUrlObject = (url = '') => new URL(url || window.location.href);

/**
 * Replace url from url string and check url type
 * If is external we use window, else we use local history
 * @param {string} url - string in type protocol://domain
 */
export const replaceUrl = url => {
  try {
    if (!url) {
      history.replace('/');
      return;
    }
    const resUrl = getUrlObject(url);
    const currentUrl = getUrlObject();
    if (resUrl.origin === currentUrl.origin) {
      history.replace(`${resUrl.pathname}${resUrl.search}`);
    } else {
      window.location.replace(resUrl);
    }
  } catch (ex) {
    logger.error(ex);
    history.replace('/');
  }
};

export const removeUrlParams = () => {
  const url = getUrlObject();
  history.replace(url.pathname);
};

/**
 * Return string for query string from object
 * @param {Object} data - object of key values
 */
export const createQueryString = data => {
  const filtered = new URLSearchParams(data).toString();
  return filtered;
};
