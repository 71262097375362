/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMedia } from 'use-media';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RESCORE_LOGO, ICON_CLOSE } from '../../../constants/icons';
import { withLogoutButton } from '../../../utils/ButtonHocs';
import {
  Wrapper,
  Img,
  TopContent,
  BottomContent,
  ImgClose,
  ExitButton,
  GlobalStyle,
} from './SideBarMainStyled';
import UserBar from './UserBar/UserBar';
import Navigation from './Navigation/Navigation';
import { HEADER_LOGOUT_BUTTON } from '../../../constants/testids';
import CheckBoxToggle from '../../../components/CheckBoxes/CheckBoxToggle';

import { Btn } from '../../../components/Buttons/Button';
// import { COLOR_LINK_BLACK, COLOR_WHITE } from '../../../constants/colors';

export const Button = styled(Btn)`
  margin-top: 0px
  margin-bottom: 0px;
  padding-top: 2px;
  padding-left: 5px;
  font-size: 12px;
  height: 24px;
  width: 30px;
  background: #bbbbbb;
  border-radius: 3px;
  border: 6px 0px 18px rgba(0, 0, 0, 0.06);
`;
/* const Languages = styled.header`
  display: inline-block;
  position: relative;
  height: 12px;
  width: 100%;
  display: flex;
  padding: 0px 0px 40px 0%;
  background-color: ${COLOR_WHITE};
`; */

const SideBarMain = ({ confirmed, toggleConfirmed, openMenu, toggleOpenMenu, onLogout }) => {
  const { t } = useTranslation();
  /* const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const lng = i18n.language; */
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <>
      <GlobalStyle open={openMenu ? 'open' : null} />
      <Wrapper className={confirmed ? 'hide' : openMenu ? 'open' : null}>
        {mobileMedia && <ImgClose src={ICON_CLOSE} onClick={() => toggleOpenMenu()} />}
        <TopContent className={confirmed ? 'hide' : null}>
          {!mobileMedia ? (
            <Link to="/">
              <Img src={RESCORE_LOGO} className={confirmed ? 'hide' : null} />
            </Link>
          ) : null}
          <UserBar confirmed={confirmed} />
          <Navigation confirmed={confirmed} />
        </TopContent>
        <BottomContent>
          {/* <Languages>
            <Button
              disabled={lng === 'ru'}
              textColor={lng === 'ru' ? COLOR_LINK_BLACK : ''}
              onClick={() => changeLanguage('ru')}
            >
              ru
            </Button>
            <Button
              disabled={lng === 'en'}
              textColor={lng === 'en' ? COLOR_LINK_BLACK : ''}
              onClick={() => changeLanguage('en')}
            >
              en
            </Button>
          </Languages> */}
          {!mobileMedia ? (
            <CheckBoxToggle
              checked={confirmed}
              data-test-id="TEST_INPUT_CHECKBOX"
              onChange={() => toggleConfirmed()}
            >
              {t('sidebar.сollapse')}
            </CheckBoxToggle>
          ) : null}
          {mobileMedia ? (
            <ExitButton testID={HEADER_LOGOUT_BUTTON} onClick={() => onLogout()}>
              {t('sidebar.logout')}
            </ExitButton>
          ) : null}
        </BottomContent>
      </Wrapper>
    </>
  );
};

SideBarMain.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  openMenu: PropTypes.bool.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  toggleOpenMenu: PropTypes.func.isRequired,
};

const enhancer = withLogoutButton;

export default enhancer(SideBarMain);
