import styled from 'styled-components';
import { TitleText } from '../../components/Typography/Title';
import { COLOR_TITLE_BLACK } from '../../constants/colors';
import { mobileMedia, tabletSmMedia } from '../../constants/styles';

export const Title = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-bottom: 30px;
  @media (max-width: 540px) {
    margin-bottom: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Wrapper = styled.div`
  padding-left: 32px;
  padding-right: 22px;
  @media (max-width: ${tabletSmMedia}) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    padding-left: 3px;
    padding-right: 3px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 540px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;
