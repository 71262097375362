import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { compose, withState } from 'recompose';
import { COLOR_WHITE, COLOR_BLACK_LOGO, COLOR_RED, COLOR_GRAY } from '../../constants/colors';
import { INPUT_TEST_ID } from '../../constants/testids';
import 'react-datepicker/dist/react-datepicker.css';
import { withBoolState } from '../../utils/hocs';

const InputWrap = styled.div`
  & input {
    box-sizing: border-box;
    width: 100%;
    height: ${props => (props.height ? props.height : '100%')};
    z-index: 1;
    position: relative;
    background-color: transparent;
    border: none;
    font-family: Lato;
    padding: ${props => (props.padding ? props.padding : '15px 19px')};
    font-weight: 400;
    color: ${COLOR_BLACK_LOGO};
  }
`;

const InputWrapper = styled.div`
  position: relative;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : COLOR_WHITE)};
  border: 1px solid ${props => (!props.isValid ? COLOR_RED : COLOR_GRAY)};
  width: 100%;
  border-radius: 4px;
  z-index: 3;
  & .react-datepicker-wrapper {
    width: 100%;
  }
  & .react-datepicker-popper {
    @media (max-width: 500px) {
      display: none;
    }
  }
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: Lato;
  display: flex;
  align-items: center;
  color: #90a0b7;
  display: block;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  left: 19px;
  top: 15px;
  z-index: 0;
  &.active {
    top: -10px;
    display: block;
    left: 15px;
    font-size: 12px;
    width: initial;
    padding-right: 10px;
    padding-left: 10px;
    background: ${COLOR_WHITE};
    z-index: 2;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  line-height: 1;
  margin: 8px 0 0;
  color: ${COLOR_RED};
`;

const Field = styled.div(({ margin, width }) => ({
  margin: margin || '12px 0 0 0',
  width: width || '100%',
}));

const InputDate = ({
  placeholder,
  onChange,
  isValid,
  errorMessage,
  value,
  onKeyDown,
  type,
  height,
  name,
  margin,
  padding,
  read,
  setRead,
  autocomplete,
  setAutocomplete,
  tabIndex,
  disabled,
}) => (
  <Field margin={margin}>
    <InputWrapper isValid={isValid}>
      {placeholder && <Label className={value ? 'active' : null}>{placeholder}</Label>}
      <InputWrap padding={padding} data-test-id={`${INPUT_TEST_ID}${name && name.toUpperCase()}`}>
        <DatePicker
          disabled={disabled}
          customInput={
            <MaskedInput
              type="text"
              mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            />
          }
          selected={value}
          height={height}
          type={type}
          tabIndex={tabIndex}
          name={name}
          readOnly={read}
          autoComplete={autocomplete}
          dateFormat="dd.MM.yyyy"
          placeholderText="__.__.____"
          onFocus={() => {
            setRead(false);
            setAutocomplete('off');
          }}
          onKeyDown={event => onKeyDown(event)}
          onChange={event => {
            onChange(event);
          }}
        />
      </InputWrap>
    </InputWrapper>
    <ErrorText>{errorMessage}</ErrorText>
  </Field>
);

InputDate.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  tabIndex: PropTypes.string,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  autocomplete: PropTypes.string,
  read: PropTypes.bool,
  setRead: PropTypes.func.isRequired,
  setAutocomplete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

InputDate.defaultProps = {
  value: undefined,
  name: '',
  tabIndex: '',
  placeholder: undefined,
  isValid: true,
  onChange: () => {},
  onKeyDown: () => {},
  errorMessage: '',
  type: 'text',
  autocomplete: '',
  read: true,
  height: '',
  margin: '',
  padding: '',
  disabled: false,
};

const enhancer = compose(
  withBoolState('read', true),
  withState('autocomplete', 'setAutocomplete', 'whatever'),
);

export default enhancer(InputDate);
