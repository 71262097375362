import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { COLOR_RED, COLOR_ACTION_BLUE, COLOR_WHITE } from '../../../constants/colors';

const ButtonGroupSwitch = ({ array, value, setValue }) => {
  const actionColor = (val, mark) => {
    if (value === val) return mark ? COLOR_RED : COLOR_ACTION_BLUE;
    return '';
  };
  return (
    <ButtonGroup aria-label="button group" style={{ width: '100%' }}>
      {array.map(item => (
        <Button
          key={item.value}
          color=""
          style={{
            width: '100%',
            color: value === item.value ? COLOR_WHITE : 'grey',
            backgroundColor: actionColor(item.value, item.mark),
            fontSize: '10px',
          }}
          onClick={() => {
            setValue(item.value);
          }}
          aria-label={item.label}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
ButtonGroupSwitch.propTypes = {
  array: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ButtonGroupSwitch;
