import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withState, withProps, lifecycle } from 'recompose';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import * as numeral from 'numeral';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Title, TopWrapper } from '../Balance/BalanceStyled';
// import { TabsWrapper } from './Components/Styles/styles';
import { useStyles } from './Components/Styles/styles';
import history from '../../history';

import ButtonGroupSwitch from './Components/ButtonGroupSwitch';
import EntityCard from './Components/EntityCard';
import QuadrantCard from './Components/QuadrantCard';
import EstimationBankruptcy from './Components/EstimationBankruptcy';
import EstimationCategory from './Components/EstimationCategory';
import Finance from './Components/Finance';
import LegalCases from './Components/LegalCases';
import Pledges from './Components/Pledges';
import Writs from './Components/Writs';
import StateContracts from './Components/StateContracts';
import NegativeFactors from './Components/NegativeFactors';
import RegistrationData from './Components/RegistrationData';

import storeNames from '../../stores/storeNames';
// import logger from '../../logger';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index, classes, on = true) {
  return {
    className: classes.adaptiveLabel,
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
    style: {
      minWidth: '10.45%', // '13.3%', 11.8%
      maxWidth: '10.45%', // '13.3%',
      backgroundColor: on ? '#eee' : '#fee',
      borderRadius: '8px',
      margin: '0.35%', // 0.35
    },
  };
}

const Dashboard = ({
  tabSelector,
  setTabSelector,
  inquiry,
  entityDetail,
  subscribeId,
  reestrSelector,
  setReestrSelector,
  finance,
  legalCases,
  writs,
  stateContracts,
}) => {
  const { t } = useTranslation();
  const sc = useStyles();

  const handleChange = (event, newValue) => {
    setTabSelector(newValue);
    history.push(`/dashboard/${subscribeId}/${newValue}`);
  };

  const entityCardElement = document.querySelector('#entity_card');
  const entityCardHeight = entityCardElement !== null ? entityCardElement.offsetHeight : 0;
  return (
    <>
      <TopWrapper style={{ marginBottom: '15px' }}>
        <Title as="h2">{t('dashboard.title')}</Title>
      </TopWrapper>

      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', padding: '5px' }}>
          <EntityCard entity={inquiry} entityDetail={entityDetail} />
        </div>
        <div style={{ width: '50%', padding: '5px' }}>
          <QuadrantCard data={inquiry} height={entityCardHeight} />
        </div>
      </div>

      <Tabs
        style={{ marginTop: '30px', paddingBottom: '3px', borderBottom: '1px solid #ddd' }}
        value={tabSelector}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        indicatorColor="secondary"
        textColor="primary"
      >
        <Tab
          value="estimation-bankruptcy"
          label="Вероятность банкротства"
          {...a11yProps('estimation-bankruptcy', sc)}
        />
        <Tab
          value="estimation-category"
          label="Категория платежеспособности"
          {...a11yProps('estimation-category', sc)}
        />
        <Tab
          value="finance"
          label="Финансовая информация"
          {...a11yProps('finance', sc, 'loaded' in finance)}
        />
        <Tab
          value="legal-cases"
          label="Судебные дела"
          {...a11yProps('legal-cases', sc, 'loaded' in legalCases)}
        />
        <Tab
          value="writs"
          label="Исполнительные производства"
          {...a11yProps('writs', sc, 'loaded' in writs)}
        />
        <Tab
          value="stateContracts"
          label="Гос контракты"
          {...a11yProps('stateContracts', sc, 'loaded' in stateContracts)}
        />
        <Tab value="registers" label="Реестры" {...a11yProps('registers', sc)} />
        <Tab
          value="negative-factors"
          label="Негативные факторы"
          {...a11yProps('negative-factors', sc)}
        />
        <Tab
          value="registration-info"
          label="Регистрационные данные"
          {...a11yProps('registration-info', sc)}
        />
      </Tabs>

      <TabPanel value={tabSelector} index="estimation-bankruptcy">
        <EstimationBankruptcy data={inquiry} />
      </TabPanel>

      <TabPanel value={tabSelector} index="estimation-category">
        <EstimationCategory entity={inquiry} entityDetail={entityDetail} />
      </TabPanel>

      <TabPanel value={tabSelector} index="finance">
        <Finance />
      </TabPanel>

      <TabPanel value={tabSelector} index="legal-cases">
        <LegalCases />
      </TabPanel>

      <TabPanel value={tabSelector} index="writs">
        <Writs />
      </TabPanel>

      <TabPanel value={tabSelector} index="stateContracts">
        <StateContracts />
      </TabPanel>

      <TabPanel value={tabSelector} index="registers">
        <ButtonGroupSwitch
          array={[
            { label: 'Залоги', value: 'zalogs' },
            { label: 'Сертификаты', value: 'certs' },
            { label: 'Интеллектуальная собственность', value: 'intellect' },
            { label: 'Лизинг', value: 'lizing' },
          ]}
          value={reestrSelector}
          setValue={setReestrSelector}
        />
        {reestrSelector === 'zalogs' && <Pledges data={entityDetail} />}
      </TabPanel>

      <TabPanel value={tabSelector} index="negative-factors">
        <NegativeFactors entityDetail={entityDetail} />
      </TabPanel>

      <TabPanel value={tabSelector} index="registration-info">
        <RegistrationData data={entityDetail} />
      </TabPanel>
    </>
  );
};
Dashboard.propTypes = {
  tabSelector: PropTypes.string.isRequired,
  setTabSelector: PropTypes.func.isRequired,
  reestrSelector: PropTypes.string.isRequired,
  setReestrSelector: PropTypes.func.isRequired,
  inquiry: PropTypes.object,
  entityDetail: PropTypes.object,
  subscribeId: PropTypes.string.isRequired,
  finance: PropTypes.object.isRequired,
  legalCases: PropTypes.object.isRequired,
  writs: PropTypes.object.isRequired,
  stateContracts: PropTypes.object.isRequired,
};
Dashboard.defaultProps = {
  inquiry: {},
  entityDetail: {},
};

const enhance = compose(
  inject(storeNames.DashboardStore),
  inject(storeNames.DialogStore),
  observer,
  withProps(({ DashboardStore }) => ({
    inquiry: DashboardStore.inquiry,
    getEntity: DashboardStore.getEntity,
    entityDetail: DashboardStore.entityDetail,
    getEntityDetail: DashboardStore.getEntityDetail,
    subscribeId: useParams().id,
    requestedTab: useParams().tab,
    finance: DashboardStore.finance,
    legalCases: DashboardStore.legalCases,
    writs: DashboardStore.writs,
    stateContracts: DashboardStore.stateContracts,
  })),
  withState('tabSelector', 'setTabSelector', 'estimation-bankruptcy'),
  withState('reestrSelector', 'setReestrSelector', 'zalogs'),
  lifecycle({
    async componentDidMount() {
      const { DashboardStore, DialogStore, subscribeId, requestedTab, setTabSelector } = this.props;

      if (requestedTab !== undefined) setTabSelector(requestedTab);

      DashboardStore.clear();
      DialogStore.loader(true);
      await DashboardStore.getEntity(subscribeId);
      await DashboardStore.getEntityDetail(subscribeId);

      setTimeout(async () => {
        await DashboardStore.syncFinance();
      });

      setTimeout(async () => {
        await DashboardStore.syncWritsYearList();
        await DashboardStore.updateWrits();
      });

      setTimeout(async () => {
        await DashboardStore.updateStateContracts();
      });

      setTimeout(async () => {
        await DashboardStore.getLegalCasesSummary();
        await DashboardStore.getLegalCases();
      }, 250);

      DialogStore.loader(false);
    },
    componentWillUnmount() {
      const { DashboardStore } = this.props;
      DashboardStore.breakTheLoading();
    },
  }),
);

export default enhance(Dashboard);
