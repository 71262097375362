import styled from 'styled-components';
import { mobileMedia } from '../../../constants/styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
`;

export const Text = styled.p`
  font-weight: 500;
  font-family: Lato;
  font-size: 12px;
  line-height: 18px;
  color: #5c6470;
`;

export const WelcomeForm = styled.form`
  width: 100%;
  max-width: 558px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    margin-top: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  z-index: 1;
  position: relative;
  @media (max-width: ${mobileMedia}) {
    margin-top: 20px;
    margin-left: 0;
  }
`;
