import API from './base';
import { SCORING_STATUS_AFTER_PAYMENT } from '../constants/statusPageUrls';

export default class ScoringApi extends API {
  /**
   * @param {String} token
   * @param {Object} data
   * email: '',
   * psrn: ''
   */
  startBillingBeforeScore(data, redirectUrl = null, token = null) {
    const _redirectUrl = redirectUrl === null ? SCORING_STATUS_AFTER_PAYMENT() : redirectUrl;
    return this.post(
      `billing/initAcquiringForRescore?redirect_url=${_redirectUrl}`,
      data,
      token,
      false,
    );
  }
}
