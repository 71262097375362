import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, lifecycle, withProps, withHandlers } from 'recompose';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import storeNames from '../../../stores/storeNames';
import { Acts } from '../DocumentsMockData';
import { Wrapper, Item, Left, Right, TableButton } from './TableStyled';
import Parapgraph from '../../../components/Typography/Paragraph';

const TableActs = ({
  acts,
  itemsLength,
  setItemsLength,
  setItemId,
  hasMoreItems,
  setHasMoreItems,
}) => {
  let items = acts.slice(0, itemsLength);
  const fetchMoreData = () => {
    items = [...items, ...acts.slice(itemsLength, setItemsLength(itemsLength + 20))];
    if (itemsLength > items.length) {
      setHasMoreItems(false);
    }
  };
  return (
    <Wrapper>
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => fetchMoreData()}
        hasMore={hasMoreItems}
        style={{ overflow: 'auto' }}
      >
        {items &&
          items.map(item => (
            <Item key={item.id}>
              <Left>
                <Parapgraph textColor="#192A3E" fontSize="14px">
                  Счёт за оказанные услуги №{item.number}
                </Parapgraph>
              </Left>
              <Right>
                <TableButton
                  data-test-id="TEST_BUTTON_DOWNLOAD"
                  onClick={() => {
                    setItemId(item.id);
                  }}
                >
                  Скачать
                </TableButton>
                <Parapgraph textColor="#90A0B7" fontSize="14px">
                  {`${item.format}, ${item.size}`}
                </Parapgraph>
              </Right>
            </Item>
          ))}
      </InfiniteScroll>
    </Wrapper>
  );
};

TableActs.propTypes = {
  acts: PropTypes.array,
  itemsLength: PropTypes.number.isRequired,
  setItemsLength: PropTypes.func.isRequired,
  setItemId: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool,
  setHasMoreItems: PropTypes.func,
};

TableActs.defaultProps = {
  acts: [],
  hasMoreItems: undefined,
  setHasMoreItems: () => {},
};

const enhance = compose(
  inject(storeNames.DocumentsStore),
  observer,
  withProps(({ DocumentsStore }) => ({
    acts: DocumentsStore.acts,
  })),
  withState('offset', 'setOffset', 0),
  withState('itemsLength', 'setItemsLength', 20),
  withState('hasMoreItems', 'setHasMoreItems', true),
  withHandlers(() => ({
    fetchAccounts: () => async () => {},
  })),
  lifecycle({
    async componentDidMount() {
      const { DocumentsStore } = this.props;
      DocumentsStore.setDocumentsActs(Acts);
    },
    componentWillUnmount() {
      const { DocumentsStore } = this.props;
      DocumentsStore.removeDocumentsActs();
    },
  }),
);

export default enhance(TableActs);
