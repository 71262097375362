import React from 'react';
import styled from 'styled-components';
import { withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import { COLOR_GRAY } from '../../constants/colors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxTable = styled.div`
  border-radius: 2px;
  min-width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 23px;
  border: 2px solid ${COLOR_GRAY};
  justify-content: center;
  outline: none;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background: ${COLOR_GRAY};
    border-radius: 100%;
    left: 1px;
    top: 1px;
  }
`;

const CheckboxTableActive = styled.div`
  border-radius: 2px;
  min-width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 23px;
  border: 2px solid #06739a;
  justify-content: center;
  outline: none;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background: #06739a;
    border-radius: 100%;
    left: 17px;
    top: 1px;
  }
`;

const Content = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  margin-left: 14px;
  color: #90a0b7;
`;

const ContentActive = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  margin-left: 14px;
  color: #323c47;
`;

const CheckBoxTable = ({ children, onChange, onKeyPress, checked }) => (
  <Wrapper onClick={onChange} data-test-id="BLOCKED">
    {checked ? (
      <CheckboxTableActive tabIndex={0} onKeyPress={onKeyPress} />
    ) : (
      <CheckboxTable tabIndex={0} onKeyPress={onKeyPress} />
    )}
    {checked ? <ContentActive>{children}</ContentActive> : <Content>{children}</Content>}
  </Wrapper>
);

CheckBoxTable.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]).isRequired,
};

CheckBoxTable.defaultProps = {
  checked: false,
  onChange: () => {},
};

const enhance = withHandlers(() => ({
  onKeyPress: ({ onChange }) => e => {
    if (e.charCode === 13 || e.keyCode === 13) {
      onChange();
    }
  },
}));

export default enhance(CheckBoxTable);
