import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';
import Popup from '../../../components/Popup/Popup';
import { Wrapper, TextWrapper, TextWrap, ButtonWrapper } from './PopupStyled';
import Paragraph from '../../../components/Typography/Paragraph';
import {
  COLOR_LINK_BLACK,
  COLOR_BLACK_LOGO,
  COLOR_RED,
  COLOR_ACTION_BLUE,
} from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';

const RepeatPopup = ({ toggleRepeatPopup, buttonHandler }) => {
  const { t } = useTranslation();
  return (
    <Popup width="600px" closePopup={toggleRepeatPopup}>
      <Wrapper>
        <Paragraph textColor={COLOR_LINK_BLACK} fontSize="32px" lineHeight="42px" fontWeight="300">
          {t('inquiries.monitoringRepeat')}
        </Paragraph>
        <TextWrapper>
          <Paragraph textColor={COLOR_RED} fontSize="18px" fontWeight="500">
            {t('inquiries.monitoringDescription1')}
          </Paragraph>
          <TextWrap>
            <Paragraph textColor={COLOR_BLACK_LOGO} fontSize="18px" fontWeight="500">
              {t('inquiries.monitoringDescription2')}
            </Paragraph>
          </TextWrap>
        </TextWrapper>
        <ButtonWrapper>
          <Button
            data-test-id="TEST_BUTTON_WRITE"
            height="42px"
            lineHeight="15px"
            width="245px"
            onClick={() => buttonHandler()}
          >
            {t('inquiries.debitAndStart')}
          </Button>
          <Button
            testID="TEST_BUTTON_CANCEL"
            width="233px"
            textColor={COLOR_ACTION_BLUE}
            boxShadow="none"
            backgroundColor="#fff"
            type="submit"
            height="42px"
            lineHeight="15px"
            sizes={{ margin: '0 20px' }}
            onClick={() => toggleRepeatPopup()}
          >
            {t('form.cancel')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Popup>
  );
};

RepeatPopup.propTypes = {
  toggleRepeatPopup: PropTypes.func,
  buttonHandler: PropTypes.func,
};

RepeatPopup.defaultProps = {
  toggleRepeatPopup: () => {},
  buttonHandler: () => {},
};

const enchance = compose(
  withHandlers(() => ({
    buttonHandler: ({ toggleRepeatPopup }) => () => {
      toggleRepeatPopup();
    },
  })),
);

export default enchance(RepeatPopup);
