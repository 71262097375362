import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Title from '../../components/Typography/Title';
import Parapgraph from '../../components/Typography/Paragraph';
import Button from '../../components/Buttons/Button';
import Input from '../../components/Inputs/Input';
import storeNames from '../../stores/storeNames';
import ICON_CLOSE from '../../assets/img/icons/close.svg';

import {
  Main,
  Wrapper,
  Welcome,
  WelcomeText,
  WelcomeForm,
  ButtonWrapper,
  WelcomeFormError,
  ImgClose,
  WelcomeTextBox,
  TextBox,
  ErrorCard,
  ErrorCardErr,
  ErrorCardCommon,
  ErrorCardClose,
} from './HomeStyled';
import { trimValue } from '../../utils/validators';
import { withHandlePressEnter, withRequestInputState } from '../../utils/hocs';
import { TEST_START_SEARCH_ENTITY } from '../../constants/testids';

const Home = ({
  inputData,
  setInputData,
  formHandler,
  entityState,
  setEntityState,
  handlePressEnter,
}) => {
  const { t } = useTranslation();
  return (
    <Main>
      <Wrapper>
        <Welcome>
          <WelcomeText>
            <Title textColor="#333">{t('home.title')}</Title>
          </WelcomeText>
          <WelcomeForm>
            <Input
              placeholder={t('home.innOgrn')}
              name="ogrn_or_inn_search"
              value={inputData}
              isValid={entityState.isValid}
              onChange={setInputData}
              onKeyDown={handlePressEnter}
            />
            <ButtonWrapper>
              <Button
                minWidth="150px"
                testID={TEST_START_SEARCH_ENTITY}
                onClick={formHandler}
                disabled={!entityState.isValid}
              >
                {t('home.find')}
              </Button>
            </ButtonWrapper>
          </WelcomeForm>
          <WelcomeFormError>
            {entityState.error && entityState.error.code !== String(0) && (
              <ErrorCard>
                <ErrorCardClose onClick={() => setEntityState({ message: '', code: '0' }, '')}>
                  <ImgClose src={ICON_CLOSE} />
                </ErrorCardClose>
                <ErrorCardErr>{entityState.error.message}</ErrorCardErr>
                <ErrorCardCommon>{entityState.error.common}</ErrorCardCommon>
              </ErrorCard>
            )}
          </WelcomeFormError>
        </Welcome>
        <WelcomeTextBox>
          <TextBox>
            <Parapgraph textAlign="justify">{t('home.description')}</Parapgraph>
          </TextBox>
        </WelcomeTextBox>
      </Wrapper>
    </Main>
  );
};

Home.propTypes = {
  entityState: PropTypes.object,
  inputData: PropTypes.string,
  setInputData: PropTypes.func,
  formHandler: PropTypes.func,
  setEntityState: PropTypes.func,
  handlePressEnter: PropTypes.func,
};

Home.defaultProps = {
  inputData: '',
  entityState: undefined,
  setInputData: () => {},
  formHandler: () => {},
  setEntityState: () => {},
  handlePressEnter: () => {},
};

const enhancer = compose(
  inject(storeNames.EntityStore),
  observer,
  withRequestInputState,
  withHandlers(() => ({
    formHandler: ({
      inputData,
      history,
      EntityStore,
      setEntityState,
      setInputData,
      entityState,
    }) => async () => {
      if (!entityState.isValid || EntityStore.isBusy) return false;
      await EntityStore.fetchEntity(trimValue(inputData));
      const { errors } = EntityStore;
      if (errors && errors.length) {
        const [error] = errors;
        setInputData('');
        setEntityState(error, inputData);
        return false;
      }
      history.push(`/result?data=${inputData}`);
      return true;
    },
  })),
  withHandlePressEnter,
);

export default enhancer(Home);
