import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withHandlers } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import ActionButtons from '../ActionButtons';
import storeNames from '../../../stores/storeNames';
import ActivityTd from '../ActivityTd';
import DateTd from '../DateTd';
import {
  Wrapper,
  TableContainer,
  Tr,
  TrTop,
  Th,
  Td,
  TdWrapper,
  TdTop,
  TdBottom,
  Thead,
  Tbody,
  ThDate,
} from './TableStyled';
import { withActiveValue } from '../../../utils/hocs';

const Table = ({
  inquiries,
  fetchMoreData,
  hasMoreItems,
  accountValue,
  inquiriesLoading,
  setItemId,
  setNewInquiries,
  toggleInquiriesPopup,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {inquiries.length > 0 && (
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => fetchMoreData()}
          hasMore={hasMoreItems}
          style={{ overflow: 'auto' }}
        >
          <TableContainer>
            <Thead>
              <TrTop>
                <Th width="60px">{t('inquiries.status')}</Th>
                <ThDate width="200px">{t('inquiries.date')}</ThDate>
                <Th>{t('inquiries.organization')}</Th>
              </TrTop>
            </Thead>
            <Tbody>
              {inquiries.map(item => (
                <Tr key={item.score_id}>
                  <ActivityTd item={item} />
                  <DateTd item={item} />
                  <Td>
                    <TdWrapper>
                      <TdTop>{item.name_full}</TdTop>
                      <TdBottom>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          title={t('inquiries.openOnRBC')}
                          href={`https://companies.rbc.ru/id/${item.ogrn}-slug`}
                        >
                          {item.inn}&nbsp;/&nbsp;{item.ogrn}
                        </a>
                      </TdBottom>
                    </TdWrapper>
                  </Td>
                  <ActionButtons
                    item={item}
                    accountValue={accountValue}
                    setItemId={setItemId}
                    setNewInquiries={setNewInquiries}
                    toggleInquiriesPopup={toggleInquiriesPopup}
                  />
                </Tr>
              ))}
            </Tbody>
          </TableContainer>
        </InfiniteScroll>
      )}
      {(inquiriesLoading && t('inquiries.dataRefreshInProcess')) ||
        (!inquiriesLoading && inquiries.length === 0 && t('inquiries.noRequestHistory'))}
    </Wrapper>
  );
};

Table.propTypes = {
  inquiries: PropTypes.array,
  accountValue: PropTypes.object,
  fetchMoreData: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  inquiriesLoading: PropTypes.bool,
  setItemId: PropTypes.func.isRequired,
  setNewInquiries: PropTypes.func.isRequired,
  toggleInquiriesPopup: PropTypes.func.isRequired,
};

Table.defaultProps = {
  inquiries: [],
  fetchMoreData: () => {},
  hasMoreItems: undefined,
  inquiriesLoading: false,
  accountValue: {},
};

const enhance = compose(
  inject(storeNames.InquiriesStore),
  observer,
  withActiveValue(false, false, false, false, false, true),
  withHandlers(() => ({
    fetchMoreData: ({ InquiriesStore, page, setPage }) => async () => {
      await InquiriesStore.fetchInquiries({ page });
      setPage(page + 1);
    },
    fetch: ({ InquiriesStore, setPage, inputData }) => async () => {
      await InquiriesStore.fetchInquiries({ page: 1, term: inputData });
      setPage(2);
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { fetch } = this.props;
      const $button = document.querySelector('[data-test-id=TEST_ID_INQUIRIES_SEARCH]');
      if (!$button) return;
      document
        .querySelector('[data-test-id=TEST_ID_INQUIRIES_SEARCH]')
        .addEventListener('click', fetch);
    },
  }),
);

export default enhance(Table);
