import React from 'react';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Text,
  CheckBoxWrapper,
  WelcomeForm,
  ButtonWrapper,
} from '../../Customers/TopCustomersNavigation/TopCustomersNavigationStyled';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import Input from '../../../components/Inputs/Input';
import Button from '../../../components/Buttons/Button';
import { withHandlePressEnter } from '../../../utils/hocs';

const TopAccountsNavigation = ({
  confirmed,
  toggleConfirmed,
  inputData,
  setInputData,
  handlePressEnter,
  formHandler,
}) => (
  <Wrapper>
    <CheckBoxWrapper>
      <CheckBox testID="TEST_CHECKBOX" checked={confirmed} onChange={() => toggleConfirmed()}>
        <Text>Показывать только активные счета</Text>
      </CheckBox>
    </CheckBoxWrapper>
    <WelcomeForm onSubmit={formHandler}>
      <Input
        placeholder="Поиск счетов по ИНН, ОГРН, наименованию и E-mail"
        value={inputData}
        name="inputData"
        onChange={setInputData}
        onKeyDown={handlePressEnter}
      />
      <ButtonWrapper>
        <Button minWidth="150px" testID="TEST_ID_ACCOUNTS_SEARCH">
          Найти
        </Button>
      </ButtonWrapper>
    </WelcomeForm>
  </Wrapper>
);

TopAccountsNavigation.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  inputData: PropTypes.string,
  handlePressEnter: PropTypes.func,
  setInputData: PropTypes.func,
  formHandler: PropTypes.func,
};

TopAccountsNavigation.defaultProps = {
  inputData: '',
  setInputData: () => {},
  handlePressEnter: () => {},
  formHandler: () => {},
};

const enhancer = compose(
  withHandlers(() => ({
    formHandler: () => e => {
      if (e) e.preventDefault();
    },
  })),
  withHandlePressEnter,
);

export default enhancer(TopAccountsNavigation);
