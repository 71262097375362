import styled from 'styled-components';

export const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  margin-bottom: 40px;
  align-items: baseline;
  @media (max-width: 960px) {
    justify-content: flex-start;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const WrapperDate = styled.div`
  display: flex;
  margin-left: 25px;
  @media (max-width: 960px) {
    margin-left: 0px;
  }
`;

export const Text = styled.p`
  font-family: Lato;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #5c6470;
`;

export const DateInputWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 960px) {
    margin-top: 20px;
    margin-bottom: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  z-index: 1;
  position: relative;
  @media (max-width: 960px) {
    margin-left: 0px;
  }
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody``;

export const TrTop = styled.tr`
  border-bottom: 2px solid #dadada;
`;
export const Tr = styled.tr`
  border-bottom: 1px solid #dadada;
`;

export const TableWrapper = styled.div``;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-y: scroll;
  min-width: 755px;
`;

export const Th = styled.th`
  font-size: 13px;
  line-height: 19px;
  color: #828282;
  text-align: left;
  font-weight: 600;
  font-family: Lato;
  padding-bottom: 11px;
`;

export const Td = styled.td`
  height: 57px;
  color: #828282;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  font-family: Lato;
  text-overflow: ellipsis;
  max-width: 160px;
  padding-right: 15px;
  font-weight: 500;
`;

export const TdName = styled(Td)`
  color: #334d6e;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  font-family: Lato;
`;

export const TdCount = styled(Td)`
  color: #333333;
`;

export const SpanLogin = styled.p`
  font-weight: 500;
  display: inline;
  font-family: Lato;
`;
