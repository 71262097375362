import styled from 'styled-components';
import { COLOR_ACTION_BLUE } from '../../../constants/colors';
import { ICON_FILE } from '../../../constants/icons';

export const Wrapper = styled.div``;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #c2cfe0;
`;

export const Left = styled.div``;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const TableButton = styled.button`
  position: relative;
  color: ${COLOR_ACTION_BLUE};
  border: none;
  background: none;
  font-weight: 600;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  outline: none;
  padding-left: 30px;
  margin-right: 19px;
  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background: url(${ICON_FILE});
    left: 0;
  }
`;
