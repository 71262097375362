import styled from 'styled-components';
import { ICON_SCORING, ICON_RUBLE, ICON_MONITORING } from '../../../../constants/icons';
import { COLOR_WHITE } from '../../../../constants/colors';
import { mobileMedia } from '../../../../constants/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding-bottom: 14px;
  border-bottom: 1px solid #ebeff2;
  &.hide {
    border-bottom: none;
  }
  @media (max-height: 700px) {
    margin-top: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    margin-top: 8px;
    padding-bottom: 10px;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  margin-left: 24px;
  &.hide {
    margin-left: 12px;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-height: 700px) {
    margin-bottom: 12px;
  }
  @media (max-width: ${mobileMedia}) {
    flex-direction: row;
    margin-bottom: 6px;
    &.hide {
      margin-left: 52px;
    }
  }
`;

export const Img = styled.img`
  display: flex;
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  margin-right: 16px;
  &.hide {
    margin-right: 0;
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  margin-right: 16px;
  background-color: #eee;
  &.hide {
    margin-right: 0;
  }
  @media (max-width: ${mobileMedia}) {
    &.hide {
      margin-right: 16px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  &.hide {
    display: none;
  }
  @media (max-width: ${mobileMedia}) {
    &.hide {
      display: block;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 85px;
  &.hide {
    display: none;
  }
  @media (max-width: 1300px) {
    margin-left: 24px;
  }
  @media (max-width: ${mobileMedia}) {
    margin-left: 86px;
    flex-direction: row;
    &.hide {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const UserItem = styled.div`
  color: #334d6e;
  font-weight: 500;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  margin-bottom: 6px;
  position: relative;
  &:before {
    content: '';
    position: relative;
    width: 14px;
    height: 15px;
    background: url(${ICON_SCORING}) no-repeat;
    left: 0;
    top: -1px;
    color: ${COLOR_WHITE};
    display: flex;
    margin-right: 6px;
    align-items: center;
    flex-shrink: 0;
  }
  @keyframes tooltips-vert {
    to {
      opacity: 0.9;
      transform: translate(0, -50%);
    }
  }
  &:hover {
    &:after {
      content: '${props => props.hint}';
      position: absolute;
      top: 9px;
      left: -44px;
      background: #fff;
      z-index: 50;
      width: auto;
      height: 20px;
      font-size: 11px;
      border-right-width: 0;
      border-left-color: #333;
      animation: tooltips-vert 300ms ease-out forwards;
    }
  }
  @media (max-width: ${mobileMedia}) {
    margin-right: 12px;
    font-size: 14px;
    &:hover {
      &:after {
        display: none;
      }
    }
  }
`;

export const ScoringItem = styled(UserItem)`
  &:hover {
    &:after {
      left: -56px;
    }
  }
`;

export const MonitoringItem = styled(UserItem)`
  &:before {
    background: url(${ICON_RUBLE}) no-repeat;
  }
`;

export const RubleItem = styled(UserItem)`
  &:before {
    background: url(${ICON_MONITORING}) no-repeat;
  }
  &:hover {
    &:after {
      content: 'Мониторы';
      left: -67px;
    }
  }
`;
