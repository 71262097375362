import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, /* withState, lifecycle, */ withProps } from 'recompose';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { COLOR_BLACK_LOGO, COLOR_LINK_BLACK, COLOR_WHITE, COLOR_RED } from '../../constants/colors';
import { offsetLg } from '../../constants/offset';
import RESCORE_TECH_LOGO from '../../assets/img/rescore_tech_logo.jpg';
import RESCORE_TECH_LOGO_EN from '../../assets/img/rescore_tech_logo_en.jpg';
import { RESCORE_LOGO } from '../../constants/icons';
import { mobileMedia } from '../../constants/styles';
import TextButton from '../Buttons/TextButton';

import { Btn } from '../Buttons/Button';
import storeNames from '../../stores/storeNames';

export const Button = styled(Btn)`
  margin-top: 0px
  margin-bottom: 0px;
  padding-top: 2px;
  padding-left: 12%;
  font-size: 12px;
  height: 24px;
  width: 50%;
`;
/* const Languages = styled.header`
  width: 100%;
  height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: right;
  padding: 6px 0px 0px 53%;
  background-color: ${COLOR_WHITE};
`; */

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px ${offsetLg} 26px;
  background-color: ${COLOR_WHITE};
`;

const NavigationContainer = styled.nav`
  display: flex;
  justify-content: flex-start;
`;

const NavigationList = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -30px;
`;

const NavigationItem = styled.li`
  margin: 0 30px;
`;

export const AuthList = styled(NavigationList)`
  margin: 0;
  flex-wrap: wrap;
`;

export const AuthItem = styled(NavigationItem)`
  margin: 0 4px;
`;

const RouterButton = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${COLOR_LINK_BLACK};
`;

export const Logo = styled.div`
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  color: ${COLOR_BLACK_LOGO};
  display: inline-block;
  white-space: nowrap;
`;

export const LogoPre = styled.span`
  color: ${COLOR_RED};
`;

const Img = styled.img`
  display: block;
  max-width: 150px;
  @media (max-width: ${mobileMedia}) {
    max-width: 120px;
  }
`;

// logo, menu, auth menu
export const Nav = () => {
  const nav = [
    { link: '/services', name: 'Услуги' },
    { link: '/methodic', name: 'Методика рассчета' },
    { link: '/faq', name: 'Вопросы и ответы' },
    { link: '/about', name: 'О сервисе' },
  ];
  return (
    <NavigationContainer>
      <NavigationList>
        {nav.map((item, index) => (
          <NavigationItem key={index}>
            <RouterButton to={item.link}>{item.name}</RouterButton>
          </NavigationItem>
        ))}
      </NavigationList>
    </NavigationContainer>
  );
};

const Header = ({ openSelector }) => {
  const { i18n } = useTranslation();
  /* const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  }; */
  const lng = i18n.language;
  // const auth = [{ link: '/login', name: 'Вход' }, { link: '/signup', name: 'Регистрация' }];
  return (
    <Wrapper>
      <Link to="/">
        <Img src={lng === 'ru' ? RESCORE_TECH_LOGO : RESCORE_TECH_LOGO_EN} />
        {/* <Logo>
          <LogoPre>RE:</LogoPre>Score
        </Logo> */}
      </Link>
      {/* <Nav />
      <AuthList>
        {auth.map((item, index) => (
          <AuthItem key={index}>
            <RouterButton to={item.link}>{item.name}</RouterButton>
          </AuthItem>
        ))}
      </AuthList> */}
      <div>
        <Link to="/">
          <Img src={RESCORE_LOGO} />
        </Link>
        <div style={{ marginTop: '14px', position: 'absolute', right: '23px' }}>
          <TextButton
            onClick={async () => {
              await openSelector();
            }}
            text="Change language"
          />
        </div>
        {/* <Languages>
          <Button
            disabled={lng === 'ru'}
            textColor={lng === 'ru' ? COLOR_LINK_BLACK : ''}
            onClick={() => changeLanguage('ru')}
          >
            ru
          </Button>
          <Button
            disabled={lng === 'en'}
            textColor={lng === 'en' ? COLOR_LINK_BLACK : ''}
            onClick={() => changeLanguage('en')}
          >
            en
          </Button>
        </Languages> */}
      </div>
    </Wrapper>
  );
};

Header.propTypes = {
  openSelector: PropTypes.func.isRequired,
};

const enhance = compose(
  inject(storeNames.LanguageStore),
  observer,
  withProps(({ LanguageStore }) => ({
    openSelector: LanguageStore.openSelector,
  })),
);
export default enhance(Header);
