import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Title } from '../../Balance/BalanceStyled';

import { useStyles } from './Styles/styles';

const EstimationCategory = ({ entityDetail }) => {
  const sc = useStyles();
  return (
    <>
      <Box mb={1} sx={{ textAlign: 'center' }}>
        <Title>Негативные факторы:</Title>
      </Box>

      <Box mb={1} sx={{ textAlign: 'center' }}>
        <Table sx={{ maxWidth: '650px' }} aria-label="simple table">
          <TableBody>
            {'assessments' in entityDetail &&
              entityDetail.assessments.negativeFactors.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {}}
                >
                  <TableCell className={sc.tableBodyRoot} component="th" scope="row">
                    {item.description}
                  </TableCell>
                  <TableCell className={sc.tableBodyRoot} align="right">
                    {item.value ? (
                      <span style={{ color: '#F7685B' }}>Фактор негативный</span>
                    ) : (
                      <span style={{ color: '#2ED47A' }}>Проверка пройдена</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
EstimationCategory.propTypes = {
  entityDetail: PropTypes.object.isRequired,
};

export default EstimationCategory;
