import { ALLOW_ALL_ROLE } from '../constants/roles';

/**
 * Get status bby allowed and user roles
 * @param {Object} param0
 * @param {Array} param0.allowedTypes - user roles, array of string
 * @param {String} param0.userType - allowed roles, array of string
 */
export const getAccessStatusByUserTypes = ({ userType, allowedTypes }) => {
  if (!userType) return false;
  if (allowedTypes.includes(ALLOW_ALL_ROLE)) return true;
  const status = allowedTypes.includes(userType);
  return status;
};
