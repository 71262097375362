import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import storeNames from '../../stores/storeNames';
import { SelectWrapper, Tabs, TabsItem, Title, TopWrapper, Wrapper } from './ServicesStyled';
import { RoleRender } from '../../components/RoleComponents';
import { CUSTOMER_ACCOUNTANT, CUSTOMER_ADMIN_ROLE } from '../../constants/roles';
import { withAccounts, withActiveValue, withBoolState, withUserData } from '../../utils/hocs';
import Select from '../../components/Select/Select';
import Statistic from './Statistic/Statistic';
import Table from './Table/Table';
import ReplenishmentPopup from '../Popups/ReplenishmentPopup';
import CreateTariffPopup from './Popups/CreateTariffPopup';
import DeleteTariffPopup from './Popups/DeleteTariffPopup';
import PurshaseAgreementPopup from './Popups/PurshaseAgreementPopup';
import PurchasePopup from './Popups/PurchasePopup';
import PurchaseServices from './PurchaseServices/PurchaseServices';
import ActiveServices from './ActiveServices/ActiveServices';
import historyRedirect from '../../history';
import PromoPopup from './Popups/PromoPopup';

const Services = ({
  accountValue,
  setAccountValue,
  servicesHistory,
  handleActive,
  handlePurchase,
  handleHistory,
  openReplenishmentBalancePopup,
  openCreatePopup,
  toggleOpenCreatePopup,
  toggleOpenDeletePopup,
  toggleOpenPurshaseAgreementPopup,
  openPurshaseAgreementPopup,
  openDeletePopup,
  openPurchasePopup,
  openPromoPopup,
  toggleOpenReplenishmentBalancePopup,
  toggleOpenPromoPopup,
  toggleOpenPurchasePopup,
  setTariffId,
  tariffId,
  accounts,
  stats,
  activeTariffs,
  history,
  hasMoreItems,
  isUser,
  isOnlyCustomerAdmin,
  userData,
  userIsLoading,
  tariffSelectValue,
  setTariffSelectValue,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {userData && userData.id && !userIsLoading && !isOnlyCustomerAdmin && (
        <Wrapper>
          <TopWrapper>
            <Title as="h2">{t('services.accountServices')}</Title>
            <RoleRender allowedRoles={[CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT]}>
              {accountValue.id && accounts.length > 1 ? (
                <SelectWrapper>
                  <Select
                    placeholder={t('services.mainAccount')}
                    name="accountValue"
                    value={accountValue}
                    setValue={setAccountValue}
                    options={accounts && accounts}
                  />
                </SelectWrapper>
              ) : null}
            </RoleRender>
          </TopWrapper>
          <Statistic
            // monitoring={stats.monitors || 0}
            scoring={stats.scores || 0}
            money={accountValue.money}
            accountId={accountValue.id}
            toggleOpenReplenishmentBalancePopup={toggleOpenReplenishmentBalancePopup}
            toggleOpenPromoPopup={toggleOpenPromoPopup}
          />
          <Tabs>
            <TabsItem
              className={servicesHistory === 'ActiveServices' ? 'active' : null}
              onClick={handleActive}
              data-test-id="TEST_TAB_ACTIVE_SERVICES"
            >
              {t('services.activeServices')}
            </TabsItem>
            {!isUser && (
              <TabsItem
                className={servicesHistory === 'Purchase' ? 'active' : null}
                onClick={handlePurchase}
                data-test-id="TEST_TAB_PURCHASE_SERVICES"
              >
                {t('services.servicesBuying')}
              </TabsItem>
            )}
            <TabsItem
              className={servicesHistory === 'History' ? 'active' : null}
              onClick={handleHistory}
              data-test-id="TEST_TAB_HISTORY"
            >
              {t('services.servicesUsingHistory')}
            </TabsItem>
          </Tabs>
          {servicesHistory === 'ActiveServices' ? (
            <ActiveServices activeTariffs={!activeTariffs ? [] : activeTariffs} />
          ) : null}
          {servicesHistory === 'Purchase' ? (
            <PurchaseServices
              toggleOpenPurchasePopup={toggleOpenPurchasePopup}
              toggleOpenCreatePopup={toggleOpenCreatePopup}
              toggleOpenDeletePopup={toggleOpenDeletePopup}
              toggleOpenPurshaseAgreementPopup={toggleOpenPurshaseAgreementPopup}
              setTariffId={setTariffId}
            />
          ) : null}
          {servicesHistory === 'History' ? (
            <Table hasMoreItems={hasMoreItems} history={!history ? [] : history} />
          ) : null}
        </Wrapper>
      )}
      {userData &&
        userData.id &&
        !userIsLoading &&
        isOnlyCustomerAdmin &&
        historyRedirect.replace('/profile')}
      {openReplenishmentBalancePopup ? (
        <ReplenishmentPopup
          toggleOpenReplenishmentBalancePopup={toggleOpenReplenishmentBalancePopup}
          accountValue={accountValue}
        />
      ) : null}
      {openCreatePopup ? (
        <CreateTariffPopup toggleOpenCreatePopup={toggleOpenCreatePopup} tariffId={tariffId} />
      ) : null}
      {openDeletePopup ? (
        <DeleteTariffPopup toggleOpenDeletePopup={toggleOpenDeletePopup} tariffId={tariffId} />
      ) : null}
      {openPurshaseAgreementPopup ? (
        <PurshaseAgreementPopup
          toggleOpenPurshaseAgreementPopup={toggleOpenPurshaseAgreementPopup}
          tariffId={tariffId}
          tariffSelectValue={tariffSelectValue}
          setTariffSelectValue={setTariffSelectValue}
        />
      ) : null}
      {openPurchasePopup ? (
        <PurchasePopup
          tariffId={tariffId}
          accountId={accountValue.id}
          money={accountValue.money}
          toggleOpenPurchasePopup={toggleOpenPurchasePopup}
        />
      ) : null}
      {openPromoPopup ? <PromoPopup toggleOpenPromoPopup={toggleOpenPromoPopup} /> : null}
    </>
  );
};

Services.propTypes = {
  servicesHistory: PropTypes.string,
  accountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setAccountValue: PropTypes.func.isRequired,
  handleActive: PropTypes.func,
  handlePurchase: PropTypes.func,
  handleHistory: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  toggleOpenReplenishmentBalancePopup: PropTypes.func.isRequired,
  toggleOpenPromoPopup: PropTypes.func.isRequired,
  toggleOpenPurchasePopup: PropTypes.func.isRequired,
  toggleOpenCreatePopup: PropTypes.func.isRequired,
  toggleOpenDeletePopup: PropTypes.func.isRequired,
  toggleOpenPurshaseAgreementPopup: PropTypes.func.isRequired,
  openReplenishmentBalancePopup: PropTypes.bool.isRequired,
  userIsLoading: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  isUser: PropTypes.bool.isRequired,
  openPurchasePopup: PropTypes.bool.isRequired,
  openCreatePopup: PropTypes.bool.isRequired,
  openPurshaseAgreementPopup: PropTypes.bool.isRequired,
  isOnlyCustomerAdmin: PropTypes.bool.isRequired,
  openPromoPopup: PropTypes.bool.isRequired,
  openDeletePopup: PropTypes.bool.isRequired,
  setTariffId: PropTypes.func.isRequired,
  tariffId: PropTypes.any,
  accounts: PropTypes.array,
  stats: PropTypes.object,
  activeTariffs: PropTypes.array,
  history: PropTypes.array,
  setTariffSelectValue: PropTypes.func.isRequired,
  tariffSelectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

Services.defaultProps = {
  servicesHistory: '',
  accountValue: '',
  tariffId: undefined,
  userIsLoading: false,
  hasMoreItems: undefined,
  accounts: [],
  history: [],
  activeTariffs: [],
  stats: {},
  handleActive: () => {},
  handlePurchase: () => {},
  handleHistory: () => {},
  tariffSelectValue: '',
};

const enchance = compose(
  inject(storeNames.ServicesStore, storeNames.AccountStore),
  withActiveValue(true, true, true, false, false, false),
  observer,
  withProps(({ ServicesStore }) => ({
    history: ServicesStore.history,
    stats: ServicesStore.stats,
    activeTariffs: ServicesStore.activeTariffs,
    servicesHistory: ServicesStore.servicesTab,
    hasMoreItems: ServicesStore.hasMoreItems,
  })),
  withAccounts,
  withUserData,
  withBoolState('openReplenishmentBalancePopup', false),
  withBoolState('openPurchasePopup', false),
  withBoolState('openCreatePopup', false),
  withBoolState('openPurshaseAgreementPopup', false),
  withState('tariffSelectValue', 'setTariffSelectValue', ''),
  withBoolState('openDeletePopup', false),
  withBoolState('openPromoPopup', false),
  withState('tariffId', 'setTariffId', ''),
  withHandlers(({ ServicesStore }) => ({
    handleActive: () => () => {
      ServicesStore.setServicesTab('ActiveServices');
    },
    handlePurchase: () => () => {
      ServicesStore.setServicesTab('Purchase');
    },
    handleHistory: () => () => {
      ServicesStore.setServicesTab('History');
    },
  })),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const { AccountStore, accountValue, ServicesStore } = prevProps;
      const account = AccountStore.accounts.find(item => item.id === accountValue.id);
      if (account) {
        if (this.props.accountValue !== accountValue) {
          const newAccount = AccountStore.accounts.find(
            item => item.id === this.props.accountValue.id,
          );
          await ServicesStore.fetchStats(newAccount.id);
          await ServicesStore.fetchHistory({ offset: 0 }, newAccount.id);
          await ServicesStore.fetchActiveTariffs(newAccount.id);
        }
      }
      if (account) {
        if (accountValue.money !== account.money) {
          AccountStore.addAccountValue({
            ...account,
            text: account.full_name,
            value: account.id,
          });
        }
      }
    },
  }),
);

export default enchance(Services);
