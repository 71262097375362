import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Paragraph = styled.p`
  position: relative;
  color: red;
  left: 0;
  font-size: ${props => (props.fontSize ? props.fontSize : '9px')};
  bottom: ${props => (props.bottom ? props.bottom : '0')};
  top: ${props => (props.top ? props.top : '-4px')};
  height: 8px;
`;

const ParagraphError = ({ as = 'p', children, top, bottom, fontSize }) => (
  <Paragraph top={top} bottom={bottom} as={as} fontSize={fontSize}>
    {children}
  </Paragraph>
);

ParagraphError.propTypes = {
  as: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  fontSize: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
};

ParagraphError.defaultProps = {
  as: 'p',
  top: '',
  bottom: '',
  fontSize: '',
  children: '',
};

export default ParagraphError;
