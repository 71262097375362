import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Popup from '../../../components/Popup/Popup';
import { Wrapper, TextEmail, InputWrapper } from '../RepeatPopup/PopupStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import Paragraph from '../../../components/Typography/Paragraph';
import { COLOR_LINK_BLACK, COLOR_BLACK_LOGO } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import InputField from '../../../components/Inputs/InputField';
import { withEmailState } from '../../../utils/FormHocs';
import storeNames from '../../../stores/storeNames';

const ReportPopup = ({
  toggleReportPopup,
  buttonHandler,
  email,
  emailValid,
  inputSetStore,
  inputHandlerEmail,
  emailError,
}) => {
  const { t } = useTranslation();
  return (
    <Popup width="600px" closePopup={toggleReportPopup}>
      <Wrapper>
        <Paragraph textColor={COLOR_LINK_BLACK} fontSize="32px" lineHeight="42px" fontWeight="300">
          {t('inquiries.reportSending')}
        </Paragraph>
        <TextEmail>
          <Paragraph textColor={COLOR_BLACK_LOGO} fontSize="18px" fontWeight="500">
            {t('inquiries.writeEmailFirst')} <br /> {t('inquiries.writeEmailSecond')}
          </Paragraph>
        </TextEmail>
        <InputWrapper>
          <InputField
            placeholder="example@domain.ru"
            name="email"
            value={email}
            isValid={emailValid}
            onChange={value => {
              inputSetStore(value, inputHandlerEmail);
            }}
          />
          <ParagraphError>{t(emailError)}</ParagraphError>
        </InputWrapper>
        <Button
          testID="TEST_BUTTON_SEND"
          height="42px"
          lineHeight="15px"
          width="151px"
          onClick={() => buttonHandler()}
        >
          {t('inquiries.send')}
        </Button>
      </Wrapper>
    </Popup>
  );
};

ReportPopup.propTypes = {
  toggleReportPopup: PropTypes.func,
  buttonHandler: PropTypes.func,
  email: PropTypes.string,
  emailError: PropTypes.string,
  emailValid: PropTypes.bool,
  inputSetStore: PropTypes.func,
  inputHandlerEmail: PropTypes.func,
};

ReportPopup.defaultProps = {
  email: '',
  emailError: '',
  emailValid: false,
  toggleReportPopup: () => {},
  buttonHandler: () => {},
  inputSetStore: () => {},
  inputHandlerEmail: () => {},
};

const enchance = compose(
  inject(storeNames.CustomerStore),
  observer,
  withEmailState,
  withHandlers(() => ({
    buttonHandler: ({ id, toggleReportPopup, emailValid, CustomerStore }) => () => {
      if (emailValid) {
        console.log(id);
        console.log(CustomerStore.email);
        toggleReportPopup();
      }
    },
    inputSetStore: ({ CustomerStore }) => (value, inputHandler) => {
      inputHandler(value);
      CustomerStore.setCustomersEmail(value);
    },
  })),
);

export default enchance(ReportPopup);
