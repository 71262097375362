import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import logger from '../../../logger';

const YearIntervalSelector = ({ yearArray, yearFrom, setYearFrom, yearTo, setYearTo, change }) => {
  // const [yearFrom, setYearFrom] = React.useState(yearArray[0]);
  // const [yearTo, setYearTo] = React.useState('');
  const handleChangeFrom = event => {
    setYearFrom(event.target.value);
    change(event.target.value, yearTo);
  };
  const handleChangeTo = event => {
    setYearTo(event.target.value);
    change(yearFrom, event.target.value);
  };
  logger.debug('====>', yearArray, yearFrom, yearTo);
  return (
    <div>
      <FormControl
        variant="outlined"
        error={yearFrom > yearTo}
        style={{ marginBottom: '10px', width: '50%', paddingRight: '10px' }}
      >
        <InputLabel id="demo-simple-select-standard-label1">Начало периода</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label1"
          id="demo-simple-select-standard1"
          value={yearFrom !== 0 ? yearFrom : ''}
          onChange={handleChangeFrom}
          label="Начало периода"
        >
          {yearArray.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        error={yearFrom > yearTo}
        style={{ marginBottom: '10px', width: '50%' }}
      >
        <InputLabel id="demo-simple-select-filled-label2">Конец периода</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label2"
          id="demo-simple-select-filled2"
          value={yearTo !== 0 ? yearTo : ''}
          onChange={handleChangeTo}
          label="Конец периода"
        >
          {yearArray.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
YearIntervalSelector.propTypes = {
  yearArray: PropTypes.array.isRequired,
  yearFrom: PropTypes.number.isRequired,
  setYearFrom: PropTypes.func.isRequired,
  yearTo: PropTypes.number.isRequired,
  setYearTo: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default YearIntervalSelector;
