import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.grey[100],
    fontWeight: theme.typography.fontWeightBold,
  },
}))(TableCell);

const ActionButtonsPopover = ({ item, open, requestStatuses, anchorEl }) => {
  const { t } = useTranslation();
  const { id } = item;

  if (!requestStatuses) return null;

  return (
    <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end">
      <TableContainer component={Paper} elevation={10}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('inquiries.name')}</StyledTableCell>
              <StyledTableCell align="right">{t('inquiries.accessGranted')}</StyledTableCell>
              <StyledTableCell align="right">{t('inquiries.formSigned')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestStatuses.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.form.title}
                </TableCell>
                <TableCell align="right">{row.granted ? t('form.yes') : t('form.no')}</TableCell>
                <TableCell align="right">
                  {row.form.published ? t('form.yes') : t('form.no')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Popper>
  );
};

ActionButtonsPopover.propTypes = {
  item: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  requestStatuses: PropTypes.array,
  anchorEl: PropTypes.string,
};

ActionButtonsPopover.defaultProps = {
  requestStatuses: [],
  anchorEl: '',
};

export default ActionButtonsPopover;
