import styled from 'styled-components';
import { COLOR_ACTION_BLUE } from '../../../../constants/colors';
import RadioInput from '../../../../components/Inputs/RadioInput';

export const Wrapper = styled.div`
  max-width: 650px;
`;

export const Content = styled.div`
  margin-top: 10px;
`;
export const InputsWrapper = styled.div`
  margin-top: 12px;
`;
export const CurrentPassword = styled.div`
  display: flex;
  align-items: center;
`;
export const RadioButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 64px;
`;
export const InputWrapper = styled.div`
  position: relative;
  max-width: 302px;
  width: 100%;
`;

export const RadioButtonWrap = styled.div`
  margin-right: 35px;
`;

export const ImgShape = styled.img`
  position: absolute;
  cursor: pointer;
  right: 0;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;
export const TextLink = styled.button`
  color: ${COLOR_ACTION_BLUE};
  margin-left: 29px;
  display: inline-block;
  font-size: 16px;
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
`;

export const RadioButton = styled(RadioInput)``;

export const ParagraphError = styled.p`
  position: absolute;
  color: red;
  left: 0;
  font-size: 9px;
  bottom: -17px;
`;
