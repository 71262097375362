import { observable, decorate, action } from 'mobx';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';
import { mapErrorsByCode } from '../helpers/errors';

export default class UserStore {
  constructor(context) {
    this.context = context;
  }

  users = [];

  errorMessages = undefined;

  errorMessagesPassword = undefined;

  isBusy = false;

  hasMoreItems = true;

  sort = '';

  order = '';

  addSortAndOrder = value => {
    if (value === this.sort && this.order === 'ASC') {
      this.order = 'DESC';
    } else {
      this.sort = value;
      this.order = 'ASC';
    }
  };

  deleteOrder = () => {
    this.order = '';
  };

  setUsersStore = async state => {
    this.isBusy = true;
    this.users = [...this.users, ...state];
    this.isBusy = false;
  };

  clearUsers = () => {
    this.users = [];
  };

  getCustomer = async userId => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.users.getCustomer({ customerId, userId }, getToken());
      if (!response.message || !response.code) {
        this.users = [response];
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.users;
  };

  blockUser = async (id, status) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.users.deleteUser(customerId, id, { blocked: status }, getToken());
      if (!response.message || !response.code) {
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.users;
  };

  changePasswordUser = async (newPassword, id) => {
    this.isBusy = true;
    this.errorMessagesPassword = undefined;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.customers.updateNewUserPassword(
        customerId,
        id,
        newPassword,
        getToken(),
      );
      if (response.message || response.code) {
        this.errorMessagesPassword = response.message || response.code;
      } else {
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return { error: this.errorMessagesPassword };
  };

  createNewCustomerUser = async user => {
    this.isBusy = true;
    this.errorMessages = undefined;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.users.createCustomerUser(customerId, user, getToken());
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(response.validationErrors || response.code),
          type: 'error',
        });
        this.errorMessages = response.message || response.code;
      } else {
        logger.info(response);
        await this.fetchMyUsers({ qnt: 20, offset: 0 });
        /* if (!this.users.find(item => item.id === response.id)) {
          this.users = [...this.users, response];
        } */
        this.users = [];
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'users.userCreatedSuccess',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return { error: this.errorMessages };
  };

  editCustomerUser = async (user, id) => {
    this.isBusy = true;
    this.errorMessages = undefined;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.users.editCustomerUser(customerId, id, user, getToken());
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(response.validationErrors || response.code),
          type: 'error',
        });
        this.errorMessages = response.message || response.code;
      } else {
        logger.info(response);
        const users = this.users.map(item => {
          return item.id === id ? user : item;
        });
        this.users = users;
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'users.userUpdated',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return { error: this.errorMessages };
  };

  fetchMyUsers = async ({ qnt = 15, offset = 0, q }) => {
    if (this.isBusy) {
      return this.users;
    }
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.users.getCustomerUsers(
        { customerId, qnt, offset, q, sort: this.sort, order: this.order },
        getToken(),
      );
      if (!response.message || !response.code) {
        if (!offset) {
          this.users = response;
        } else {
          this.users = [...this.users, ...response];
        }
        if (response.length < qnt || !response.length) {
          this.hasMoreItems = false;
        } else {
          this.hasMoreItems = true;
        }
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.users;
  };
}

decorate(UserStore, {
  sort: observable,
  order: observable,
  user: observable,
  errorMessages: observable,
  errorMessagesPassword: observable,
  hasMoreItems: observable,
  users: observable,
  isBusy: observable,
  setUsersStore: action,
  fetchMyUsers: action,
});
