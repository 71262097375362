import styled from 'styled-components';
import { COLOR_WHITE, COLOR_RED } from '../../constants/colors';
import { mobileMedia } from '../../constants/styles';

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 32px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

export const ImgClose = styled.img``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_WHITE};
  position: relative;
  border-radius: 4px;
`;

export const Welcome = styled.div`
  width: 100%;
  min-height: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

export const WelcomeText = styled.div`
  width: 100%;
  max-width: 600px;
  text-align: center;
  position: relative;
  z-index: 1;
`;

export const WelcomeTextBox = styled.div`
  width: 100%;
  max-width: 920px;
  margin: 0 auto 54px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const TextBox = styled.div`
  width: 100%;
  margin: 0 15px;
`;

export const WelcomeForm = styled.div`
  width: 100%;
  max-width: 580px;
  margin: 43px 0 0;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
  }
`;

export const WelcomeFormError = styled.div`
  width: 100%;
  max-width: 540px;
  position: relative;
  z-index: 1;
  min-height: 18px;
  font-size: 14px;
  margin: 8px 0;
  color: ${COLOR_RED};
`;

export const ButtonWrapper = styled.div`
  margin-left: -8px;
  display: flex;
  z-index: 1;
  position: relative;
  @media (max-width: ${mobileMedia}) {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

export const ErrorCard = styled.div`
  background-color: ${COLOR_WHITE};
  /* box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.25); */
  border-radius: 4px;
  margin: 8px 0;
  padding: 14px 24px 18px;
  position: relative;
`;

export const ErrorCardErr = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${COLOR_RED};
  margin: 0;
  padding-right: 40px;
`;

export const ErrorCardCommon = styled.p`
  font-weight: 500;
  font-size: 13px;
  font-family: Lato;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #707683;
  margin: 7px 0 0;
`;

export const ErrorCardClose = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
`;
