import API from './base';
import { getToken } from '../helpers';
import { getUrlToApi } from '../constants/api';

export default class DiscretionApi extends API {
  getDiscretion(data) {
    return this.post(getUrlToApi('discretion/v1/discretion/search'), data, getToken(), false);
  }

  getNewDiscretion(data) {
    return this.post(
      getUrlToApi(`discretion/v1/discretion/${data.discretionId}/report`),
      {},
      getToken(),
      false,
    );
  }

  setDiscretion(data) {
    return this.post(getUrlToApi('discretion/v1/discretion'), data, getToken(), false);
  }
}
