import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, /* withHandlers, */ withProps, lifecycle } from 'recompose';
import * as moment from 'moment';
import storeNames from '../../../../stores/storeNames';
import Popup from '../../../../components/Popup/Popup';
import Typography from '../../../../components/Typography/Paragraph';
import Button from '../../../../components/Buttons/Button';
import {
  Title,
  Indent,
  //  Shift,
  Wrapper,
  FormWrapper,
  //  InputWrapper,
  //  InputWrapperNotes,
  //  TopText,
  //  TextWrapper,
  //  DateInputWrap,
  //  DateWrapper,
  ButtonWrapper,
  //  DateContent,
  //  TextArea,
  //  Description,
  TableContainer,
} from '../SharedInfoPopupStyled';
import {
  Tr,
  TrTop,
  Th,
  Td,
  ImageTd,
  // ActivityTd,
  //  Img,
  Thead,
  Tbody,
  //  TdButton,
  //  ButtonEdit,
  //  CheckBoxWrapper,
  //  TableButton,
  //  Text,
} from '../../Table/TableStyled';
import { withBoolState } from '../../../../utils/hocs';
import { COLOR_ACTION_BLUE } from '../../../../constants/colors';
import ProgressPopup from '../../../Popups/ProgressPopup';
import { ICON_PDF_FILE, ICON_WORD_FILE, ICON_EXCEL_FILE } from '../../../../constants/icons';

const UploaderPopup = ({
  questionary,
  toggleUploaderPopup,
  cryptoIsPluginInstalled,
  cryptoIsProviderInstalled,
  cryptoCertificates,
  //  formHandler,
  gatherFiles,
  getGatherFiles,
  // saveQuestionary,
  saveSignatures,
  // progress,
  // files,
  // filesList
  progressPopup,
  toggleProgressPopup,
}) => {
  const [certIndex, setCertIndex] = useState(-1);
  const [filesList /* , setFilesList */] = useState(getGatherFiles());
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('');

  const gatherFilesCallback = useCallback(async () => {
    await gatherFiles(toggleProgressPopup, setProgress, setStatus);
  }, [gatherFiles, toggleProgressPopup]);
  useEffect(() => {
    gatherFilesCallback();
  }, [gatherFilesCallback]);

  const mimeToIcon = (fileType, fileData) => {
    switch (fileType) {
      case 'application/pdf':
        return ICON_PDF_FILE;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return ICON_WORD_FILE;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return ICON_EXCEL_FILE;
      default:
        return URL.createObjectURL(fileData);
    }
  };

  return (
    <>
      <Popup width="900px" closePopup={toggleUploaderPopup} borderRadius="5px">
        <Wrapper>
          <Title as="h2">
            Создание электронной подписи и публикация анкеты
            <Typography textColor="#AAAAB0" fontSize="14px" lineHeight="20px" textAlign="center">
              {questionary.title}
            </Typography>
          </Title>

          {cryptoIsPluginInstalled && cryptoIsProviderInstalled && (
            <FormWrapper>
              <TableContainer>
                <Thead>
                  <TrTop>
                    <Th width="50%">Имя файла</Th>
                    <Th width="15%">Размер</Th>
                    <Th width="15%">Идентификатор</Th>
                    <Th />
                  </TrTop>
                </Thead>
                <Tbody>
                  {filesList &&
                    filesList.map((item, index) => (
                      <Tr key={item.fileName + index}>
                        <Td>{item.fileName}</Td>
                        <Td>{item.fileSize}</Td>
                        <Td>{item.fileId /* signed */}</Td>
                        <ImageTd>
                          {item.fileType !== 'application/json' && 'fileData' in item && (
                            <img
                              align="right"
                              pointer="true"
                              height="48px"
                              src={mimeToIcon(item.fileType, item.fileData)}
                              alt={item.fileName}
                            />
                          )}
                        </ImageTd>
                      </Tr>
                    ))}
                </Tbody>
              </TableContainer>

              <Indent />
              <Typography textColor="#AAAAB0" fontSize="20px" lineHeight="20px" textAlign="center">
                Выберите сертификат для подписи:
              </Typography>

              <Indent />
              <TableContainer>
                <Thead>
                  <TrTop>
                    <Th width="25%">Издатель</Th>
                    <Th width="25%">Владелец</Th>
                    <Th width="20%">Выдан</Th>
                    <Th width="20%">Действителен до</Th>
                    {/* <Th width="10%">Валидность</Th> */}
                  </TrTop>
                </Thead>
                <Tbody>
                  {cryptoCertificates &&
                    cryptoCertificates.map((item, index) => (
                      <Tr
                        key={index}
                        onClick={() => setCertIndex(index)}
                        mark={certIndex === index}
                        pointer
                      >
                        <Td>{item.issuerNamePretty}</Td>
                        <Td>{item.subjectNamePretty}</Td>
                        <Td>{moment(item.validFrom).format('DD.MM.YYYY HH:mm')}</Td>
                        <Td>{moment(item.validTo).format('DD.MM.YYYY HH:mm')}</Td>
                        {/* <Td>{item.organization.valid ? 'Валидный' : 'Нет'}</Td> */}
                      </Tr>
                    ))}
                </Tbody>
              </TableContainer>
            </FormWrapper>
          )}

          {!cryptoIsPluginInstalled && (
            <Typography
              margin="40px 0px 20px 0px"
              textColor="#EA0A0A"
              fontSize="20px"
              lineHeight="20px"
              textAlign="center"
            >
              Плагин цифровой подписи не установлен. &nbsp;
              <a
                href="https://www.cryptopro.ru/products/cades/plugin"
                rel="noopener noreferrer"
                target="_blank"
              >
                Кликните сюда, чтобы установить
              </a>
            </Typography>
          )}
          {cryptoIsPluginInstalled && !cryptoIsProviderInstalled && (
            <Typography
              margin="0px 0px 20px 0px"
              textColor="#EA0A0A"
              fontSize="18px"
              lineHeight="18px"
              textAlign="right"
            >
              Крипто-провайдер не установлен. &nbsp;
              <a
                href="https://www.cryptopro.ru/products/cades/plugin"
                rel="noopener noreferrer"
                target="_blank"
              >
                Кликните сюда, чтобы установить
              </a>
            </Typography>
          )}

          <ButtonWrapper>
            {cryptoIsPluginInstalled && cryptoIsProviderInstalled && certIndex >= 0 && (
              <Button
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="6"
                testID="TEST_BUTTON_SAVE"
                width="303px"
                type="submit"
                sizes={{ margin: '0 20px' }}
                onClick={async () => {
                  await saveSignatures(
                    cryptoCertificates[certIndex].thumbprint,
                    toggleProgressPopup,
                    setProgress,
                    setStatus,
                  );
                  setCertIndex(-1);
                }}
                disabled={certIndex < 0}
              >
                Подписать
              </Button>
            )}
            <Button
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="6"
              testID="TEST_BUTTON_CANCEL"
              width="303px"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleUploaderPopup()}
            >
              Назад
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </Popup>

      {progressPopup === true ? (
        <ProgressPopup
          toggleProgressPopup={toggleProgressPopup}
          title={status}
          progress={progress}
        />
      ) : null}
    </>
  );
};

UploaderPopup.propTypes = {
  toggleUploaderPopup: PropTypes.func.isRequired,
  cryptoIsPluginInstalled: PropTypes.bool.isRequired,
  cryptoIsProviderInstalled: PropTypes.bool.isRequired,
  cryptoCertificates: PropTypes.array.isRequired,
  questionary: PropTypes.object,
  gatherFiles: PropTypes.func.isRequired,
  getGatherFiles: PropTypes.func.isRequired,
  saveSignatures: PropTypes.func.isRequired,
  progressPopup: PropTypes.bool,
  toggleProgressPopup: PropTypes.func,
};

UploaderPopup.defaultProps = {
  questionary: {},
  progressPopup: false,
  toggleProgressPopup: () => {},
};

const enchance = compose(
  inject(storeNames.SharedInfoStore),
  inject(storeNames.DialogStore),
  observer,
  withProps(({ SharedInfoStore }) => ({
    cryptoIsPluginInstalled: SharedInfoStore.cryptoIsPluginInstalled,
    cryptoIsProviderInstalled: SharedInfoStore.cryptoIsProviderInstalled,
    cryptoCertificates: SharedInfoStore.cryptoCertificates,
    questionary: SharedInfoStore.questionary.get(SharedInfoStore.formId),
    gatherFiles: SharedInfoStore.gatherFiles,
    getGatherFiles: SharedInfoStore.getGatherFiles,
    saveSignatures: SharedInfoStore.saveSignatures,
  })),
  withBoolState('progressPopup', false),
  lifecycle({
    async componentDidMount() {
      // moment.locale('ru');
      const { SharedInfoStore } = this.props;
      await SharedInfoStore.cryptoGetInfo();
      await SharedInfoStore.cryptoGetCertificates();
    },
  }),
);

export default enchance(UploaderPopup);
