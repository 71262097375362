import { action, decorate, observable } from 'mobx';
import * as moment from 'moment';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';
import {
  // COLOR_ACTION_BLUE,
  COLOR_RED,
  COLOR_GREEN,
  // COLOR_YELLOW,
  COLOR_PURPLE,
  COLOR_GRAY,
} from '../constants/colors';

export default class DashboardStore {
  constructor(context, dialog) {
    this.context = context;
    this.dialog = dialog;
  }

  clear = () => {
    this.inquiry = {};
    this.entityDetail = {};
    this.finance = {};
    this.financeFNS = {};
    this.legalCases = {};
    this.legalCasesSummary = {};
    this.legalCaseSelector = 'bankruptcy';
    this.writs = {};
    this.writsSelector = 'opened';
    this.stateContracts = {};
    api.dashboard.reset();
  };

  breakTheLoading = () => {
    api.dashboard.break();
  };

  inquiry = {};

  getEntity = async id => {
    this.inquiry = await api.inquiries.getInquiryById({ id });
  };

  entityDetail = {};

  getEntityDetail = async scoreId => {
    const { ogrn } = this.inquiry;
    const requests = await Promise.allSettled([
      api.dashboard.getOrganization(ogrn, getToken()),
      api.dashboard.getStatusDictionary(getToken()),
      api.dashboard.getOkved(ogrn, getToken()),
      api.dashboard.getFounders(ogrn, getToken()),
      api.dashboard.getPersons(ogrn, getToken()),
      api.dashboard.getAddress(ogrn, getToken()),
      api.dashboard.getTaxOrgHistory(ogrn, getToken()),
      api.dashboard.getPledges(ogrn, getToken()),
      api.dashboard.getStateContracts(ogrn, getToken()),
      api.dashboard.getAssessments(scoreId, getToken()),
      api.dashboard.getInterpreterDescriptionDictionary(getToken()),
      api.dashboard.getInterpreterPathDictionary(getToken()),
      api.dashboard.getQuadrantDescriptionDictionary(getToken()),
      api.dashboard.getNegativeFactors(scoreId, getToken()),
      api.dashboard.getNegativeFactorsDictionary(getToken()),
    ]);

    // logger.debug('+++++++++++'); // , requests);
    logger.debug(this.inquiry);
    // logger.debug(requests);

    const statusText = requests[1].value.status.find(
      item => item.id === requests[0].value.main_status,
    ).description;

    const main_okved = requests[2].value.okved.find(item => item.is_main === true);

    const { founders } = requests[3].value;
    const pathes = ['person_ru', 'organization_ru', 'pif_ru', 'state_ru', 'organization_foreign'];
    const amount = pathes.reduce(
      (val, key) => val + founders[key].reduce((val2, item) => val2 + item.authorized_capital, 0),
      0,
    );
    pathes.forEach(key => {
      founders[key] = founders[key].map(item => ({
        ...item,
        percent: `${((item.authorized_capital / amount) * 100).toFixed(1)}%`,
      }));
    });

    const { persons } = requests[4].value;

    const { address } = requests[5].value;

    const { tax_org_history } = requests[6].value;

    const pledges = requests[7].value.data;

    const stateContracts = requests[8].value.data;

    const assessments =
      'value' in requests[9]
        ? requests[9].value
        : [{ key: 'INTERPRETER_CODE', value: '' }, { key: 'QUADRANT_CODE', value: '' }];
    const interpreterDescriptionDictionary = requests[10].value;
    const interpreterPathDictionary = requests[11].value;
    const quadrantDescriptionDictionary = requests[12].value;

    const INTERPRETER_CODE = assessments
      .find(item => item.key === 'INTERPRETER_CODE')
      .value.split(';')[0];
    const QUADRANT_CODE = assessments.find(item => item.key === 'QUADRANT_CODE').value;

    const interpreterDescription = INTERPRETER_CODE
      ? interpreterDescriptionDictionary.items.find(item => item.key === INTERPRETER_CODE).value
      : '';
    const interpreterPath = INTERPRETER_CODE
      ? interpreterPathDictionary.items.find(item => item.key === INTERPRETER_CODE).value
      : [];
    const quadrantDescription = QUADRANT_CODE
      ? quadrantDescriptionDictionary.items.find(item => item.key === QUADRANT_CODE).value
      : [];

    const negativeFactors = 'value' in requests[13] ? requests[13].value : [];
    const negativeFactorsDictionary = requests[14].value.items;

    negativeFactors.forEach((item, index) => {
      const finded = negativeFactorsDictionary.find(item2 => item2.key === item.key);
      negativeFactors[index].description = finded ? finded.description : '';
    });

    /* logger.debug('INTERPRETER_CODE: ', INTERPRETER_CODE);
    logger.debug('QUADRANT_CODE: ', QUADRANT_CODE);
    logger.debug('interpreterDescription: ', interpreterDescription);
    logger.debug('interpreterPath: ', interpreterPath);
    logger.debug('quadrantDescription: ', quadrantDescription);
    logger.debug('negativeFactors: ', negativeFactors); */

    this.entityDetail = {
      ...requests[0].value,
      main_status_description: statusText,
      okved: requests[2].value.okved,
      main_okved,
      founders,
      persons,
      address,
      tax_org_history,
      pledges,
      stateContracts,
      assessments: {
        interpreterDescription,
        interpreterPath,
        quadrantDescription,
        negativeFactors,
      },
    };

    if (stateContracts.length !== 0) {
      const firstYear = new Date(stateContracts[0].contract_date).getFullYear();
      const lastYear = new Date(
        stateContracts[stateContracts.length - 1].contract_date,
      ).getFullYear();
      // this.fixYearList(firstYear, lastYear);

      this.stateContractsYearList = this.generateYearList(firstYear, lastYear);
      const { yearFrom, yearTo } = this.getLast3Years(this.stateContractsYearList);
      this.stateContractsYearFrom = yearFrom;
      this.stateContractsYearTo = yearTo;
    }

    // logger.debug('entityDetail:', this.entityDetail);
  };

  /* getSubscribe = async id => {
    logger.debug(
      await api.inquiries.getSubscribeExtend(
        id,
        this.context.AccountStore.accountValue.rescore_account_token,
      ),
    );
  }; */

  financeTypesList = [
    { id: '0710001' },
    { id: '0710002' },
    { id: '0710003' },
    { id: '0710004' },
    { id: '0710005' },
  ];

  financeDigestFields = {};

  finance = {};

  financeFNS = {};

  financeYearList = [];

  financeYearFrom = 0;

  financeYearTo = 0;

  financeSelector = '';

  financeYearSetFrom = yearFrom => {
    this.financeYearFrom = yearFrom;
  };

  financeYearSetTo = yearTo => {
    this.financeYearTo = yearTo;
  };

  financeSelectorSet = state => {
    this.financeSelector = state;
  };

  syncFinance = async () => {
    const digest = await api.dashboard.getFinanceDigest(getToken());
    // logger.debug('FINANCE DIGEST !!!');
    // logger.debug(digest);
    // eslint-disable-next-line no-useless-escape
    const subtitleRegexp = /.*\/ *(.*)/;
    digest.fields.forEach(item => {
      let result;
      const index = this.financeTypesList.findIndex(item2 => {
        const regexp = `.*${item2.id}.*?(?= |/)`;
        // logger.debug(regexp);
        result = item.full_title.match(regexp);
        // logger.debug('res', result);
        return result !== null;
      });
      if (index >= 0) {
        if (!('title' in this.financeTypesList[index]))
          // eslint-disable-next-line prefer-destructuring
          this.financeTypesList[index].title = result[0];
        // logger.debug(this.financeTypesList);
        if (!(this.financeTypesList[index].id in this.financeDigestFields))
          this.financeDigestFields[this.financeTypesList[index].id] = {};
        // logger.debug(item.full_title, subtitleRegexp);
        const subtitleResult = item.full_title.match(subtitleRegexp);
        // logger.debug(subtitleResult);
        this.financeDigestFields[this.financeTypesList[index].id][item.accounting_field_id] = {
          title: item.title.trim(),
          path: item.full_title.split('/').map(name => name.trim()),
          subtitle: subtitleResult !== null && index === 3 ? subtitleResult[1] : '',
          accounting_row_num: item.accounting_row_num,
          parent_field_id: item.parent_field_id,
        };
        // logger.debug(this.financeDigestFields);
      }
    });
    // logger.debug(this.financeTypesList);
    // logger.debug('financeDigestFields', this.financeDigestFields);

    const financeYearList = (await api.dashboard.getFinanceBalanceList(
      this.inquiry.ogrn,
      getToken(),
    )).reports.map(item => item.year);

    this.financeYearList = this.generateYearList(
      financeYearList[0],
      financeYearList[financeYearList.length - 1],
    );

    const { yearFrom, yearTo } = this.getLast3Years(this.financeYearList);
    this.financeYearFrom = yearFrom;
    this.financeYearTo = yearTo;
    this.financeSelector = this.financeTypesList[0].id;
    // console.log('YEAR FROM:', yearFrom, ', YEAR TO:', yearTo);
    await this.getFinance(yearFrom, yearTo);
    await this.getFinanceFNS(yearFrom, yearTo);

    // logger.debug(this.financeYearList);
    return this.financeYearList;
  };

  getFinanceTypes = () => this.financeTypesList;

  getFinance = async (yearFrom, yearTo) => {
    // logger.debug(yearFrom, yearTo);
    if (yearFrom > yearTo) return null;
    // logger.debug('\n\n\n\n\n\n\n getFinance', JSON.stringify(this.financeDigestFields), JSON.stringify(this.financeTypesList));
    if (Object.keys(this.financeDigestFields).length === 0) return null;
    // logger.debug(yearFrom, yearTo);
    // const financeArray = [];
    const finance = {};

    const financeRawFetches = [];
    for (let year = yearFrom; year <= yearTo; year += 1) {
      // eslint-disable-next-line no-await-in-loop
      financeRawFetches.push(
        api.dashboard.getFinanceBalanceExt(this.inquiry.ogrn, year, getToken()),
      );
    }
    let financeRawArray;
    try {
      financeRawArray = await Promise.all(financeRawFetches);
    } catch (err) {
      logger.debug(err.toString());
      financeRawArray = [];
    }
    logger.debug('FIN:', financeRawArray);

    // financeArray.push(financeRaw);

    // eslint-disable-next-line
    for (const financeRaw of financeRawArray) {
      const { year } = financeRaw;
      // eslint-disable-next-line
      for (const typeItem of this.financeTypesList) {
        if (!(typeItem.id in finance)) finance[typeItem.id] = {};
        financeRaw.values.forEach(item => {
          if (item.accounting_field_id in this.financeDigestFields[typeItem.id]) {
            /* if (!(this.financeDigestFields[typeItem.id].path[1] in finance[typeItem.id])) { */
            // finance[typeItem.id][this.financeDigestFields[typeItem.id].path[1]][0];
            /* const inner = {
              title: this.financeDigestFields[typeItem.id][item.accounting_field_id].title,
              rowNum: this.financeDigestFields[typeItem.id][item.accounting_field_id]
                .accounting_row_num,
              values: [],
            }; */
            let chain = finance[typeItem.id]; // [this.financeDigestFields[typeItem.id]];
            for (
              let i = 1;
              i < this.financeDigestFields[typeItem.id][item.accounting_field_id].path.length;
              i += 1
            ) {
              // if (this.financeDigestFields[typeItem.id][item.accounting_field_id].accounting_row_num === null) continue;
              if (
                !(this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i] in chain)
              ) {
                chain[this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i]] = {};
              }
              chain =
                chain[this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i]];
              if (
                this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i] ===
                this.financeDigestFields[typeItem.id][item.accounting_field_id].title
              )
                break;
            }
            // дополнительные ветки из .subtitle
            if (this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle !== '') {
              if (
                !(this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle in chain)
              ) {
                chain[
                  this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle
                ] = {};
              }
              chain =
                chain[this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle];
            }
            if (!('values' in chain)) {
              // chain.title = this.financeDigestFields[typeItem.id][item.accounting_field_id].title;
              chain.rowNum = this.financeDigestFields[typeItem.id][
                item.accounting_field_id
              ].accounting_row_num;
              chain.values = [];
            }
            if (chain.values.length === 0 || chain.values[chain.values.length - 1].year !== year) {
              chain.values.push({
                year,
                rowNum: item.accounting_row_num,
                value: item.field_value,
              });
            }
            /* } */
            /* if (!(item.accounting_field_id in finance[typeItem.id])) {
              finance[typeItem.id][item.accounting_field_id] = { values: [] };
              finance[typeItem.id][item.accounting_field_id].title = this.financeDigestFields[
                typeItem.id
              ][item.accounting_field_id].title;
              finance[typeItem.id][item.accounting_field_id].subtitle = this.financeDigestFields[
                typeItem.id
              ][item.accounting_field_id].subtitle;
              finance[typeItem.id][item.accounting_field_id].rowNum = this.financeDigestFields[
                typeItem.id
              ][item.accounting_field_id].accounting_row_num;
            }
            finance[typeItem.id][item.accounting_field_id].values.push({
              year,
              rowNum: item.accounting_row_num,
              value: item.field_value,
            }); */
          }
        });
      }
    }
    finance.loaded = true;
    this.finance = finance;
    logger.debug('>FINANCE<', finance);
    return finance;
  };

  getFinanceFNS = async (yearFrom, yearTo) => {
    if (yearFrom > yearTo) return null;
    const finance = {};

    const metaFNS = [
      {
        key: 'revenue_expense',
        title: 'Информация о доходах/расходах',
        type: 'branch',
        childs: [
          { key: 'revenue', title: 'Доходы', type: 'number' },
          { key: 'expense', title: 'Расходы', type: 'number' },
          // { key: 'date_doc_fns', title: 'Дата публикации', type: 'date' },
        ],
      },
      {
        key: 'employee_count',
        title: 'Информация о численности сотрудников',
        type: 'branch',
        childs: [
          { key: 'employee_avg_number', title: 'Среднесписочная численность', type: 'number' },
          // { key: 'date_doc_fns', title: 'Дата публикации', type: 'date' },
        ],
      },
      {
        key: 'taxes',
        title: 'Уплаченные налоги',
        type: 'array',
        params: {
          title: 'name_of_tax',
          value: 'tax',
          date: 'date_doc_fns',
          dateTitle: 'Дата публикации',
        },
      },
    ];

    const financeRawFetches = [];
    for (let year = yearFrom; year <= yearTo; year += 1) {
      // eslint-disable-next-line no-await-in-loop
      financeRawFetches.push(
        api.dashboard.getFinanceBalanceFNS(this.inquiry.ogrn, year, getToken()),
      );
    }
    const financeRawArray = await Promise.all(financeRawFetches).catch(reason => {
      logger.debug(reason);
    });
    if (!financeRawArray) return {};
    // logger.debug('FNS:', financeRawArray);

    // eslint-disable-next-line
    for (const financeRaw of financeRawArray) {
      // eslint-disable-next-line
      for (const meta of metaFNS) {
        if (!(meta.title in finance)) finance[meta.title] = {};
        if (meta.type === 'branch') {
          // eslint-disable-next-line
          for (const child of meta.childs) {
            // eslint-disable-next-line no-continue
            if (financeRaw[meta.key] !== null) {
              if (!(child.title in finance[meta.title])) {
                finance[meta.title][child.title] = {};
                finance[meta.title][child.title].values = [];
              }
              finance[meta.title][child.title].values.push({
                year: financeRaw.year,
                rowNum: null,
                value:
                  child.type === 'date'
                    ? moment(financeRaw[meta.key][child.key]).format('DD.MM.YYYY')
                    : financeRaw[meta.key][child.key],
              });
            }
          }
        }

        if (meta.type === 'array') {
          if (financeRaw[meta.key] !== null) {
            // eslint-disable-next-line
            for (const item of financeRaw[meta.key]) {
              if (!(meta.params.title in finance[meta.title])) {
                finance[meta.title][item[meta.params.title]] = {};
                finance[meta.title][item[meta.params.title]].values = [];
              }
              finance[meta.title][item[meta.params.title]].values.push({
                year: financeRaw.year,
                rowNum: null,
                value: item[meta.params.value],
              });
            }
          }
        }
      }
    }
    this.financeFNS = finance;
    logger.debug(finance);
    return finance;
  };

  generateYearList = (_first, _last) => {
    const first = _first < _last ? _first : _last;
    const last = _first < _last ? _last : _first;

    const yearlist = [];
    for (let year = first; year <= last; year += 1) yearlist.push(year);
    return yearlist;
  };

  getLast3Years = yearList => {
    if (yearList.length === 0) {
      const yearTo = new Date().getFullYear();
      yearList.push(yearTo - 2);
      yearList.push(yearTo - 1);
      yearList.push(yearTo);
    }
    const yearFrom = yearList[yearList.length >= 3 ? yearList.length - 3 : 0];
    const yearTo = yearList[yearList.length - 1];
    return { yearFrom, yearTo };
  };

  legalCasesSummary = {
    bankruptcy: [],
    plaintiff: [],
    defendant: [],
  };

  legalCases = {
    bankruptcy: {
      years: [],
      details: [],
    },
    plaintiff: {
      years: [],
      details: [],
    },
    defendant: {
      years: [],
      details: [],
    },
  };

  legalCaseSelector = 'bankruptcy';

  setLegalCaseSelector = value => {
    this.legalCaseSelector = value;
  };

  getLegalCasesSummary = async () => {
    const myOGRN = this.inquiry.ogrn;
    const summary = await api.dashboard.getLegalCasesSummary(myOGRN, getToken());
    // logger.debug('LEGAL CASE SUMMARY:', summary);

    const legalCasesSummary = {
      bankruptcy: [],
      plaintiff: [],
      defendant: [],
    };

    legalCasesSummary.plaintiff = summary
      .map(item => {
        return {
          year: item.year,
          quantity: Number(item.plaintiff_count),
          amount: { руб: Number(item.plaintiff_amount_sum) },
        };
      })
      .filter(item => item.quantity > 0 && item.year);

    legalCasesSummary.defendant = summary
      .map(item => {
        return {
          year: item.year,
          quantity: Number(item.defendant_count),
          amount: { руб: Number(item.defendant_amount_sum) },
        };
      })
      .filter(item => item.quantity > 0 && item.year);

    legalCasesSummary.plaintiffQuantity = legalCasesSummary.plaintiff.reduce(
      (prev, item) => prev + item.quantity,
      0,
    );

    legalCasesSummary.defendantQuantity = legalCasesSummary.defendant.reduce(
      (prev, item) => prev + item.quantity,
      0,
    );

    legalCasesSummary.loaded = true;

    this.legalCasesSummary = legalCasesSummary;
  };

  getLegalCases = async () => {
    const myOGRN = this.inquiry.ogrn;
    const receive = await api.dashboard.getLegalCases(myOGRN, 1, getToken());
    // logger.debug('receive', receive);

    const pagesQuantity = receive.meta.total_pages;
    const legalCasesData = [...receive.data];
    /* for (let i = 2; i <= pagesQuantity; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await api.dashboard.getLegalCases(myOGRN, i, getToken());
      // eslint-disable-next-line
      for (const item of data) legalCasesData.push(item);
    } */

    const fetches = [];
    for (let i = 2; i <= pagesQuantity; i += 1) {
      fetches.push(api.dashboard.getLegalCases(myOGRN, i, getToken()));
    }
    const rawPages = await Promise.all(fetches).catch(reason => {
      logger.debug(reason);
    });
    if (!rawPages) return;

    // eslint-disable-next-line
    for (const dataPage of rawPages) for (const item of dataPage.data) legalCasesData.push(item);

    // logger.debug('LEGAL:', legalCasesData);

    // fix year list, if finance years is empty
    /* if (legalCasesData.length !== 0) {
      const firstYear = new Date(legalCasesData[0].bring_date).getFullYear();
      const lastYear = new Date(legalCasesData[legalCasesData.length - 1].bring_date).getFullYear();
      this.fixYearList(firstYear, lastYear);
    } */

    const legalCases = {
      bankruptcy: {
        years: [],
        details: [],
      },
      plaintiff: {
        years: [],
        details: [],
      },
      defendant: {
        years: [],
        details: [],
      },
    };

    const currencyToSign = currency => {
      if (currency === 'RUB') return 'руб';
      return currency;
    };

    const caseDry = legalcase => {
      return {
        number: legalcase.case_number,
        type: 'case_type' in legalcase ? legalcase.case_type.case_type_description : '',
        category: legalcase.case_category.case_category_description,
        opponents: legalcase.participants.top,
        /* .filter(item => {
          return item.ogrn !== myOGRN;
        }), */
        opponentsLength: legalcase.participants.count.total,
        caseId: legalcase.case_id,
        amount: {
          currency: currencyToSign(legalcase.amount.currency),
          value: legalcase.amount.value,
        },
        date: legalcase.bring_date,
        court: legalcase.court_instance.court_instance_description,
        isActive: legalcase.is_active,
        result: legalcase.case_result.case_result_description,
      };
    };

    const caseYearsSort = legalDetails => {
      const years = [];
      // eslint-disable-next-line
      for (const item of legalDetails) {
        const year = new Date(item.date).getFullYear();
        const index = years.findIndex(item2 => item2.year === year);
        if (index < 0) {
          const element = { year, quantity: 1, amount: {} };
          element.amount[item.amount.currency] = item.amount.value;
          years.push(element);
        } else {
          years[index].quantity += 1;
          years[index].amount[item.amount.currency] += item.amount.value;
        }
      }
      return years;
    };

    // eslint-disable-next-line
    for (const legalcase of legalCasesData) {
      // if (legalcase.case_type.case_type_id === 'Bankrupt')
      if (legalcase.roles.includes('DBT')) legalCases.bankruptcy.details.push(caseDry(legalcase));
      if (legalcase.roles.includes('PLT')) legalCases.plaintiff.details.push(caseDry(legalcase));
      if (legalcase.roles.includes('DEF')) legalCases.defendant.details.push(caseDry(legalcase));
    }

    /* legalCases.bankruptcy.years = caseYearsSort(legalCases.bankruptcy.details);
    legalCases.plaintiff.years = caseYearsSort(legalCases.plaintiff.details);
    legalCases.defendant.years = caseYearsSort(legalCases.defendant.details); */

    this.legalCasesSummary.bankruptcy = caseYearsSort(legalCases.bankruptcy.details);
    legalCases.loaded = true;
    this.legalCases = legalCases;

    // logger.debug('legalCases:', legalCases);
    // logger.debug('legalCasesSummary:', this.legalCasesSummary);
    // return this.legalCases;
  };

  showOpponents = async (opponents, length, caseId, filter = '') => {
    let opponentsList = opponents;
    if (length > 10) {
      const pages = Math.ceil(length / 100);
      const fetches = [];
      for (let i = 1; i <= pages; i += 1) {
        fetches.push(api.dashboard.getLegalCaseParticipants(caseId, i, getToken()));
      }
      this.dialog.loader(true);
      const rawPages = await Promise.all(fetches);
      this.dialog.loader(false);
      opponentsList = [];
      // eslint-disable-next-line
      for (const dataPage of rawPages) for (const item of dataPage.data) opponentsList.push(item);
    }
    opponentsList = opponentsList.filter(item => filter === item.type || filter === '');

    const model = [];
    const typeToName = type => {
      if (type === 'PLT') return 'Истец';
      if (type === 'DEF') return 'Ответчик';
      if (type === '3RD') return 'Третье лицо';
      if (type === 'DBT') return 'Должник';
      return 'Неизвестно';
    };
    const typeToColor = type => {
      if (type === 'PLT') return COLOR_GREEN;
      if (type === 'DEF') return COLOR_RED;
      if (type === '3RD') return COLOR_PURPLE;
      if (type === 'DBT') return COLOR_RED;
      return COLOR_GRAY;
    };
    // eslint-disable-next-line
    for (const item of opponentsList) {
      model.push({
        type: 'LABEL',
        weight: this.entityDetail.ogrn === item.ogrn ? 'bold' : '',
        color: this.entityDetail.ogrn === item.ogrn ? typeToColor(item.type) : '',
        label: `${typeToName(item.type)}: ${item.ogrn !== null ? item.ogrn : ''} - ${
          item.original_full_name
        }`,
      });
    }
    const buttons = [
      // eslint-disable-next-line
      { label: 'Ответчик', color: filter === 'DEF' ? 'PLAIN_UNDERLINE' : 'PLAIN', event: 'DEF', action: () => {} },
      // eslint-disable-next-line
      { label: 'Должник', color: filter === 'DBT' ? 'PLAIN_UNDERLINE' : 'PLAIN', event: 'DBT', action: () => {} },
      // eslint-disable-next-line
      { label: 'Истец', color: filter === 'PLT' ? 'PLAIN_UNDERLINE' : 'PLAIN', event: 'PLT', action: () => {} },
      // eslint-disable-next-line
      { label: 'Третья сторона', color: filter === '3RD' ? 'PLAIN_UNDERLINE' : 'PLAIN', event: '3RD', action: () => {} },
    ];
    const ret = await this.dialog.show(/* 'Другие' */ 'Участники процесса:', {
      width: '1200px',
      height: '90%',
      model,
      buttonsTop: buttons,
      cancelText: 'Закрыть',
    });
    if (ret) await this.showOpponents(opponents, length, caseId, filter === ret ? '' : ret);
  };

  /* okved = {};

  mainOkved = {};

  getOkved = async () => {
    const myOGRN = this.inquiry.ogrn;
    this.okved = (await api.dashboard.getkved(myOGRN, getToken())).okved;
    logger.debug('OKVED', this.okved);

    // eslint-disable-next-line
    for (const item of this.okved) {
      if (item.is_main === true) this.mainOkved = item;
    }
    return this.mainOkved;
  }; */

  writs = {
    data: { active: [], finished: [] },
  };

  writsYearList = [];

  writsYearFrom = 0;

  writsYearTo = 0;

  writsSelector = 'opened';

  writsYearSetFrom = yearFrom => {
    this.writsYearFrom = yearFrom;
  };

  writsYearSetTo = yearTo => {
    this.writsYearTo = yearTo;
  };

  setWritsSelector = value => {
    this.writsSelector = value;
  };

  syncWritsYearList = async () => {
    const { ogrn } = this.inquiry;
    const token = getToken();
    const { data, meta } = await api.dashboard.getWrits(ogrn, 1, 1, null, null, token);
    logger.debug('syncWritsYearList', data, meta);
    if (data.length !== 0) {
      const yearLast = new Date(data[0].processing_date).getFullYear();
      const first = (await api.dashboard.getWrits(ogrn, meta.total_pages, 1, null, null, token))
        .data[0];
      const yearFirst = new Date(first.processing_date).getFullYear();
      this.writsYearList = this.generateYearList(yearFirst, yearLast);
      const { yearFrom, yearTo } = this.getLast3Years(this.writsYearList);
      this.writsYearFrom = yearFrom;
      this.writsYearTo = yearTo;
    }
  };

  updateWrits = async (/* yearFrom, yearTo */) => {
    if (this.writsYearFrom > this.writsYearTo) return null;
    // logger.debug(yearFrom, yearTo);
    // const writs = [];

    logger.debug('YEAR FROM TO:', this.writsYearFrom, this.writsYearTo);

    const dateFrom = new Date(this.writsYearFrom, 0, 1);
    const dateTo = new Date(this.writsYearTo + 1, 0, 1);

    logger.debug('DATE FROM TO:', dateFrom, dateTo);

    const receive = await api.dashboard.getWrits(
      this.inquiry.ogrn,
      1,
      100,
      dateFrom.toISOString(),
      dateTo.toISOString(),
      getToken(),
    );
    // logger.debug('receive', receive);

    const pagesQuantity = receive.meta.total_pages;
    const writs = [...receive.data];

    const writsRawFetches = [];

    for (let page = 2; page <= pagesQuantity; page += 1) {
      // eslint-disable-next-line no-await-in-loop
      writsRawFetches.push(
        api.dashboard.getWrits(
          this.inquiry.ogrn,
          page,
          100,
          dateFrom.toISOString(),
          dateTo.toISOString(),
          getToken(),
        ),
      );
    }
    const writsRawArray = await Promise.all(writsRawFetches).catch(reason => {
      logger.debug(reason);
    });
    if (!writsRawArray) return {};
    // logger.debug('WRITS:', writsRawArray);

    // eslint-disable-next-line
    for (const raw of writsRawArray) {
      // writs = [...writs, ...raw.data];
      // eslint-disable-next-line
      for (const item of raw.data) {
        writs.push(item);
      }
    }
    logger.debug('WRITS:', writs);

    const writsData = writs.sort(
      (a, b) => Date.parse(a.processing_date) - Date.parse(b.processing_date),
    );
    const writsActive = writsData.filter(item => item.is_active === true);
    const writsFinished = writsData.filter(item => item.is_active === false);

    this.writs = {
      data: { active: writsActive, finished: writsFinished },
      loaded: true,
    };

    return this.writs;
  };

  stateContracts = {
    data: [],
    statistic: [],
  };

  stateContractsYearList = [];

  stateContractsYearFrom = 0;

  stateContractsYearTo = 0;

  /* stateContractsSelector = 'customer'; // customer/supplier

  stateContractsSetSelector = value => {
    this.stateContractsSelector = value;
  }; */

  stateContractsYearSetFrom = yearFrom => {
    this.stateContractsYearFrom = yearFrom;
  };

  stateContractsYearSetTo = yearTo => {
    this.stateContractsYearTo = yearTo;
  };

  updateStateContracts = async () => {
    if (this.stateContractsYearFrom > this.stateContractsYearTo) return null;

    logger.debug('YEAR FROM TO:', this.stateContractsYearFrom, this.stateContractsYearTo);

    const dateFrom = new Date(this.stateContractsYearFrom, 0, 1);
    const dateTo = new Date(this.stateContractsYearTo + 1, 0, 1);

    logger.debug('DATE FROM TO:', dateFrom, dateTo);

    const stateContracts = this.entityDetail.stateContracts.filter(item => {
      const itemDate = new Date(item.contract_date);
      return itemDate >= dateFrom && itemDate <= dateTo;
    });
    logger.debug('STATE CONTRACTS:', stateContracts);

    const statistic = [
      { title: 'Всего контрактов', values: [] },
      { title: 'Сумма контрактов (руб)', values: [] },
      /* { title: 'Завершено контрактов', values: [] },
      { title: 'Исполнение прекращено', values: [] }, */
    ];

    const years = [];
    for (let year = this.stateContractsYearFrom; year <= this.stateContractsYearTo; year += 1)
      years.push(year);

    years.forEach(year => {
      const selected = stateContracts.filter(
        item => new Date(item.contract_date).getFullYear() === year,
      );
      statistic[0].values.push({
        year,
        value: selected.length,
      });
      statistic[1].values.push({
        year,
        value: selected.reduce((sum, item) => {
          return sum + item.amount;
        }, 0),
      });
    });

    this.stateContracts = {
      data: stateContracts.sort(
        (a, b) => Date.parse(a.contract_date) - Date.parse(b.contract_date),
      ),
      statistic,
      loaded: true,
    };

    return this.stateContracts;
  };
}

decorate(DashboardStore, {
  clear: action,
  entity: observable,
  getEntity: action,
  entityDetail: observable,
  getEntityDetail: action,
  // getSubscribe: action,

  syncFinance: action,
  getFinance: action,
  getFinanceFNS: action,
  finance: observable,
  financeFNS: observable,
  // financeTypesList: observable,
  // financeYears: observable,
  financeYearList: observable,
  financeYearFrom: observable,
  financeYearTo: observable,
  financeYearSetFrom: action,
  financeYearSetTo: action,
  // getLast3Years: action,
  financeSelector: observable,
  financeSelectorSet: action,
  // financeDigestFields: observable,

  legalCasesSummary: observable,
  getLegalCasesSummary: action,
  legalCases: observable,
  getLegalCases: action,
  showOpponents: action,
  legalCaseSelector: observable,
  setLegalCaseSelector: action,

  writs: observable,
  updateWrits: action,
  writsSelector: observable,
  setWritsSelector: action,
  writsYearList: observable,
  syncWritsYearList: action,
  writsYearFrom: observable,
  writsYearTo: observable,
  writsYearSetFrom: action,
  writsYearSetTo: action,

  stateContracts: observable,
  updateStateContracts: action,
  // stateContractsSelector: observable,
  // stateContractsSetSelector: action,
  stateContractsYearList: observable,
  stateContractsYearFrom: observable,
  stateContractsYearTo: observable,
  stateContractsYearSetFrom: action,
  stateContractsYearSetTo: action,
});
