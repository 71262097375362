import React /* , { useState } */ from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';

import * as moment from 'moment';
// import logger from '../../../logger';
import { useStyles } from './Styles/styles';
import { numSeparator, prettyAddress, upperCaseFirst } from './Utils/utils';
// import { props } from 'lodash/fp';

const WritsTable = ({ writs, type, loaded }) => {
  const sc = useStyles();
  const selectAcvive = type === 'opened';
  // const showedList = writs.filter(item => item.is_active === selectAcvive);
  const showedList = selectAcvive ? writs.active : writs.finished;
  // const [year, setYear] = useState(legalCases.years.length ? legalCases.years[0].year : null);
  /* return (
    <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography className={sc.detailTitle} variant="h5" component="div">
        В разработке...
      </Typography>
    </Box>
  ); */
  return loaded ? (
    <Box mb={12} mt={2}>
      {showedList.length === 0 ? (
        <Box
          mt={2}
          pl={2}
          className={sc.detailTitle}
          style={{ color: '#2dd45b', fontWeight: 'bold' }}
        >
          {writs.active.length === 0 && writs.finished.length === 0
            ? 'Исполнительные производства отсутствуют'
            : `${
                writs.active.length === 0 ? 'Открытые' : 'Закрытые'
              } исполнительные производства за выбранный период отсутствуют`}
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={sc.tableHeader} style={{ width: '10%' }}>
                  Номер производства
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '20%' }}>
                  Предмет исполнения
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '10%' }}>
                  Дата возбуждения
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '10%' }}>
                  Сумма выплат
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '20%' }}>
                  Отделение РОСП
                </TableCell>
                <TableCell className={sc.tableHeader} style={{ width: '10%' }}>
                  Состояние
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showedList.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className={sc.tableBodyRoot}>{item.writ_number}</TableCell>
                  <TableCell className={sc.tableBodyRoot}>
                    {upperCaseFirst(item.execution_type)}
                  </TableCell>
                  <TableCell className={sc.tableBodyRoot}>
                    {moment.utc(item.processing_date).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell className={sc.tableBodyRoot}>
                    {numSeparator(item.amount)} руб
                  </TableCell>
                  <TableCell className={sc.tableBodyRoot}>
                    {prettyAddress(item.bailiff_service)}
                  </TableCell>
                  <TableCell className={sc.tableBodyRoot}>
                    {item.is_active ? 'В процессе' : 'Завершено'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  ) : (
    <Box mt={4} pl={2} className={sc.detailTitle} style={{ fontWeight: 'bold' }}>
      Ожидание загрузки...
    </Box>
  );
};
WritsTable.propTypes = {
  // empty: PropTypes.string,
  writs: PropTypes.object,
  type: PropTypes.string.isRequired,
  // countBack: PropTypes.func,
  loaded: PropTypes.bool.isRequired,
};
WritsTable.defaultProps = {
  // empty: null,
  writs: {}, // { years: [], details: [] },
  // countBack: () => {},
};

export default WritsTable;
