import API from './base';
import { getUrlToLKApi } from '../constants/api';
import { createQueryString } from '../helpers/url';

export default class AccountsApi extends API {
  getAccounts({ customerId, qnt = 20, offset = 0, sort = '', order = '', q = '' }, token = null) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/accounts?${createQueryString({ qnt, offset, q, sort, order })}`,
      ),
      token,
      false,
    );
  }

  getAccount({ customerId, accountId }, token = null) {
    return this.get(getUrlToLKApi(`customers/${customerId}/accounts/${accountId}`), token, false);
  }

  downloadCSV(token = null) {
    return this.get(getUrlToLKApi(`customers/all/accounts/csvDownloadUrl`), token, false);
  }

  getAllAccounts(
    { qnt = 20, offset = 0, sort = '', order = '', q = '', loadRelations = 'customer,users' },
    token = null,
  ) {
    return this.get(
      getUrlToLKApi(
        `customers/all/accounts?${createQueryString({
          qnt,
          offset,
          q,
          sort,
          order,
          loadRelations,
        })}`,
      ),
      token,
      false,
    );
  }

  createAccount(customerId, data, token = null) {
    return this.post(getUrlToLKApi(`customers/${customerId}/accounts`), data, token, false);
  }

  editAccount(customerId, accountId, data, token = null) {
    return this.put(
      getUrlToLKApi(`customers/${customerId}/accounts/${accountId}`),
      data,
      token,
      false,
    );
  }

  deleteAccount(customerId, accountId, data = {}, token = null) {
    return this.put(
      getUrlToLKApi(`customers/${customerId}/accounts/${accountId}/setBlocked`),
      data,
      token,
      false,
    );
  }
}
