import * as moment from 'moment';
import API from './base';
import { getUrlToLKApi } from '../constants/api';
import { createQueryString } from '../helpers/url';

export default class BalanceApi extends API {
  getPurchases(
    {
      customerId,
      qnt = 20,
      offset = 0,
      dateFrom,
      dateTo,
      loadRelations = 'tariff, user',
      order = 'desc',
      sort = 'id',
    },
    accountId,
    token = null,
  ) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/accounts/${accountId}/purchases?${createQueryString({
          qnt,
          offset,
          order,
          sort,
          loadRelations,
          from_date: dateFrom ? moment(dateFrom).toISOString() : '',
          to_date: dateTo
            ? moment(dateTo)
                .add(1, 'd')
                .toISOString()
            : '',
        })}`,
      ),
      token,
      false,
    );
  }

  getPayments(
    { customerId, qnt = 20, offset = 0, dateFrom, dateTo, sort = '', order = 'desc' },
    accountId,
    token = null,
  ) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/accounts/${accountId}/payments?${createQueryString({
          qnt,
          order,
          sort,
          offset,
          from_date: dateFrom ? moment(dateFrom).toISOString() : '',
          to_date: dateTo
            ? moment(dateTo)
                .add(1, 'd')
                .toISOString()
            : '',
        })}`,
      ),
      token,
      false,
    );
  }

  getActs(customerId, accountId, purchaseId, token = null) {
    return this.get(
      getUrlToLKApi(
        `customers/${customerId}/accounts/${accountId}/purchases/${purchaseId}/actDownloadUrl`,
      ),
      token,
      false,
    );
  }

  balanceAgreement(paymentId, data, token = null) {
    return this.put(getUrlToLKApi(`payments/${paymentId}`), data, token, false);
  }

  getPaymentStatus({ customerId, accountId, paymentId }, token = null) {
    return this.get(
      getUrlToLKApi(`customers/${customerId}/accounts/${accountId}/payments/${paymentId}`),
      token,
      false,
    );
  }
}
