import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import { Button, ClickAwayListener } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import history from '../../../history';
import Paragraph from '../../../components/Typography/Paragraph';
import { hasDiscretion } from '../../../constants/env';
import ExtraDiscretionDashboard from '../ExtraDiscretionDashboard';
import ExtraDiscretionReport from '../ExtraDiscretionReport';
import NewExtraDiscretionButton from '../NewExtraDiscretionButton';
import ReportsLinks from '../ReportsLinks';
import { TableButton, TableButtonRepeat, Td } from '../Table/TableStyled';
import ActionButtonsPopover from './ActionButtonsPopover';
import ExtraDiscretionButton from './ExtraDiscretionButton';
import api from '../../../api';

const StyledActionButtons = styled.div`
  display: flex;
  flex-direction: column;

  & > button + button {
    margin-top: 8px;
  }
`;

const ActionButtonsTd = styled(Td)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 5px;
  padding-right: 5px;

  > button,
  div + button,
  div {
    margin-left: 5px;
  }

  > * {
    width: 20ch;
  }
`;

const ActionButtons = ({
  item,
  accountValue,
  setItemId,
  toggleInquiriesPopup,
  setNewInquiries,
}) => {
  const { t } = useTranslation();
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const buttonRef = useRef();

  const [requestStatuses, setRequestStatuses] = useState(null);

  useEffect(() => {
    if (!item.loading && item.discretion) {
      api.inquiryStatus
        .getInquiryStatus(get(item, 'ogrn', ''))
        .then(({ requests }) => setRequestStatuses(requests));
    }
  }, [item]);

  if (item.loading)
    return (
      <Td rowspan="4">
        <Paragraph textColor="#C2CFE0" fontSize="16px">
          {t('inquiries.requestInProcess')}
        </Paragraph>
      </Td>
    );

  const dashboardHandler = () => {
    if (item && item.score_id) {
      // logger.debug('ITEM', item);
      history.push(`/dashboard/${item.score_id}`); // ${item.entity.id}`); ${item.initialCode1}
      /* if (accountValue && accountValue.rescore_account_token) {
        localStorage.setItem('token', accountValue.rescore_account_token);
        window.open(`${getOrigin()}/dashboard/#/subscriptions/${item.entity.id}/show`, '_blank');
      } */
    }
  };

  const repeatHandler = () => {
    setItemId(item.score_id);
    toggleInquiriesPopup();
    setNewInquiries(false);
  };

  const toggleStatusPopover = () => {
    setPopupIsOpen(p => !p);
  };

  const onStatusPopoverClose = () => {
    setPopupIsOpen(false);
  };

  const requestStatusColor = (requestStatuses || []).every(
    ({ granted, form: { published } }) => granted === true && published === true,
  )
    ? green[500]
    : yellow[700];

  return (
    <ClickAwayListener onClickAway={onStatusPopoverClose}>
      <ActionButtonsTd>
        {!!item.discretion && (
          <>
            <Button
              variant="outlined"
              onClick={toggleStatusPopover}
              ref={buttonRef}
              size="small"
              endIcon={<ErrorOutlineIcon style={{ color: requestStatusColor }} />}
            >
              {t('inquiries.requestProgress')}
            </Button>
            <ActionButtonsPopover
              item={item}
              open={popupIsOpen}
              anchorEl={buttonRef.current}
              onStatusPopoverClose={onStatusPopoverClose}
              requestStatuses={requestStatuses}
            />
          </>
        )}

        {hasDiscretion && (
          <>
            <NewExtraDiscretionButton item={item} />
            <ExtraDiscretionButton item={item} />
            <StyledActionButtons>
              <ExtraDiscretionDashboard item={item} />
              <ExtraDiscretionReport item={item} />
            </StyledActionButtons>
          </>
        )}

        <StyledActionButtons>
          <TableButton data-test-id="TEST_BUTTON_DASHBOARD" onClick={dashboardHandler}>
            {t('inquiries.dashboard')}
          </TableButton>
          <ReportsLinks state={item.state} reports={item.reports} />
          {!accountValue.blocked && (
            <TableButtonRepeat data-test-id="TEST_BUTTON_REPLAY" onClick={repeatHandler}>
              {t('inquiries.repeat')}
            </TableButtonRepeat>
          )}
        </StyledActionButtons>
      </ActionButtonsTd>
    </ClickAwayListener>
  );
};

ActionButtons.propTypes = {
  item: PropTypes.object,
  accountValue: PropTypes.object,
  setItemId: PropTypes.func,
  toggleInquiriesPopup: PropTypes.func,
  setNewInquiries: PropTypes.func,
};

ActionButtons.defaultProps = {
  item: {},
  accountValue: {},
  setItemId: () => {},
  toggleInquiriesPopup: () => {},
  setNewInquiries: () => {},
};

export default ActionButtons;
