import API from './base';

export default class EntityApi extends API {
  getEntityById(token, id) {
    return this.get(`api/static/v1/entity/${id}`, token);
  }

  getEntityAddress(token, id) {
    return this.get(`api/static/v1/entity/${id}/address`, token);
  }
}
