import { observable, decorate } from 'mobx';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';
import { mapErrorsByCode } from '../helpers/errors';

export default class ServicesStore {
  constructor(context) {
    this.context = context;
  }

  tariff = {};

  isBusy = false;

  isBusyMyTariffs = false;

  servicesData = {};

  servicesTab = 'ActiveServices';

  servicesTariffs = [];

  basket = {};

  stats = {};

  activeTariffs = [];

  history = [];

  hasMoreItems = true;

  dateFromServices = undefined;

  dateToServices = undefined;

  errors = null;

  setServicesStore(state) {
    this.servicesData = { ...this.servicesData, ...state };
  }

  setServicesTariffStore(state) {
    this.tariff = { ...this.tariff, ...state };
  }

  addDateServices = ({ dateFrom, dateTo }) => {
    this.dateFromServices = dateFrom;
    this.dateToServices = dateTo;
  };

  setServicesTab(value) {
    this.servicesTab = value;
  }

  clearStats = () => {
    this.stats = {};
    this.activeTariffs = [];
    this.history = [];
    this.servicesTab = 'ActiveServices';
  };

  createNewTariff = async tariff => {
    try {
      const response = await api.services.createTariff(tariff, getToken());
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'services.errorHasOccurred',
          type: 'error',
        });
        logger.info(response);
      } else {
        await this.fetchMyTariffs();
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'services.newTariffAdded',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    }
  };

  editTariff = async (tariffId, tariff) => {
    try {
      const response = await api.services.editTariff(tariffId, tariff, getToken());
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'services.errorHasOccurred',
          type: 'error',
        });
        logger.info(response);
      } else {
        await this.fetchMyTariffs();
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'services.tariffUpdated',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    }
  };

  activatePromo = async code => {
    try {
      await api.promocodes.activate(code);
      window.location.reload();
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteTariff = async tariffId => {
    try {
      const response = await api.services.deleteTariff(tariffId, getToken());
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'services.errorHasOccurred',
          type: 'error',
        });
        logger.info(response);
      } else {
        await this.fetchMyTariffs();
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'services.tariffDeleted',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    }
  };

  buyTariffAgreement = async (paymentId, data) => {
    this.errors = null;
    try {
      const response = await api.services.buyTariffAgreement(paymentId, data, getToken());
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(response.code, response.message),
          type: 'error',
        });
        this.errors = response.message || response.code;
        logger.info(response);
      } else {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'services.tariffPurchased',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    }
    return this.errors;
  };

  setServicesBasket = async (accountId, data) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.services.buyTariff(customerId, accountId, data, getToken());
      if (!response.message || !response.code) {
        logger.info(response);
        await this.context.AccountStore.fetchMyAccounts({ qnt: 0, offset: 0 });
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
      this.context.IndicatorsStore.addErrorIndicators({
        message: 'services.purchaseCompleted',
        type: 'success',
      });
    }
  };

  setServicesTariffs(value) {
    this.servicesTariffs = [...value];
  }

  fetchMyTariffs = async () => {
    this.isBusyMyTariffs = true;
    try {
      const response = await api.services.getTariffs(getToken());
      if (!response.message || !response.code) {
        this.servicesTariffs = response;
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusyMyTariffs = false;
    }
    return this.servicesTariffs;
  };

  fetchStats = async accountId => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.services.getStats(customerId, accountId, getToken());
      if (!response.message || !response.code) {
        this.stats = response;
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.stats;
  };

  fetchActiveTariffs = async accountId => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.services.getActiveTariffs(customerId, accountId, getToken());
      if (!response.message || !response.code) {
        this.activeTariffs = response;
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.activeTariffs;
  };

  fetchHistory = async ({ qnt = 15, offset = 0, dateFrom, dateTo }, accountId) => {
    if (this.isBusy) {
      return this.history;
    }
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.services.getHistory(
        { customerId, qnt, offset, dateFrom, dateTo },
        accountId,
        getToken(),
      );
      if (!response.message || !response.code) {
        if (!offset) {
          this.history = response;
        } else {
          this.history = [...this.history, ...response];
        }
        if (response.length < qnt || !response.length) {
          this.hasMoreItems = false;
        } else {
          this.hasMoreItems = true;
        }
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.history;
  };
}
decorate(ServicesStore, {
  stats: observable,
  dateFromServices: observable,
  dateToServices: observable,
  hasMoreItems: observable,
  history: observable,
  errors: observable,
  activeTariffs: observable,
  servicesTab: observable,
  basket: observable,
  isBusy: observable,
  isBusyMyTariffs: observable,
  tariff: observable,
  servicesTariffs: observable,
  servicesData: observable,
});
