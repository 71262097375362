import { getI18n } from 'react-i18next';
import { getOrigin } from '../helpers/url';
import {
  PAYMENT_SUCCESS,
  SCROTING_RESULT_WILL_BE_SEND,
  FINE,
  RECOVERY_SENDED,
  NEW_PASSWORD_SETTED,
  SCORING_SUCCESS,
  CONFIRMATION_SUCCESS,
} from './messages';

export const getUrl = () => `${getOrigin() || 'https://service01.rescore.online'}`;

export const SCORING_STATUS_AFTER_PAYMENT = () => {
  const i18n = getI18n();
  return `${getUrl()}/status?status=success&message=${i18n.t(PAYMENT_SUCCESS)}&description=${i18n.t(
    SCROTING_RESULT_WILL_BE_SEND,
  )}&buttonText=${i18n.t(FINE)}`;
};

export const STATUS_AFTER_PAYMENT = (actionUrl, page) => {
  const i18n = getI18n();
  return `/status?status=success&message=${i18n.t(PAYMENT_SUCCESS)}&buttonText=${i18n.t(
    FINE,
  )}&actionUrl=${actionUrl}${page && `&page=${page}`}`;
};

export const STATUS_CONFIRMATION = (actionUrl, page) => {
  const i18n = getI18n();
  return `/status?status=success&message=${i18n.t(CONFIRMATION_SUCCESS)}&buttonText=${i18n.t(
    FINE,
  )}&actionUrl=${actionUrl}${page && `&page=${page}`}`;
};

export const STATUS_AFTER_Confirmation = (actionUrl, page) => {
  const i18n = getI18n();
  return `/status?status=success&message=${i18n.t(CONFIRMATION_SUCCESS)}&buttonText=${i18n.t(
    FINE,
  )}&actionUrl=${actionUrl}${page && `&page=${page}`}`;
};

export const STATUS_AFTER_SCORING = actionUrl => {
  const i18n = getI18n();
  return `/status?status=success&message=${i18n.t(SCORING_SUCCESS)}&description=${i18n.t(
    SCROTING_RESULT_WILL_BE_SEND,
  )}&buttonText=${i18n.t(FINE)}&actionUrl=${actionUrl}`;
};

export const STATUS_AFTER_SCORING_ERROR = (actionUrl, message) =>
  `/status?status=error&message=${message}&buttonText=Назад&actionUrl=${actionUrl}`;

export const STATUS_RECOVERY_TOKEN_SENDED = actionUrl => {
  const i18n = getI18n();
  return `/status?status=success&message=${i18n.t(RECOVERY_SENDED)}&buttonText=${i18n.t(
    FINE,
  )}&actionUrl=${actionUrl}`;
};

export const STATUS_NEW_PASSWORD_SETTED = actionUrl => {
  const i18n = getI18n();
  return `/status?status=success&message=${i18n.t(NEW_PASSWORD_SETTED)}&buttonText=${i18n.t(
    FINE,
  )}&actionUrl=${actionUrl}`;
};

export const STATUS_PASSWORD_RESET_ALREADY_SUCCEED = (actionUrl, message) =>
  `/status?status=error&message=${message}&buttonText=Назад&actionUrl=${actionUrl}`;

export const STATUS_PAGE = (actionUrl, message, status = 'success') => {
  const i18n = getI18n();
  return `/status?status=${status}&message=${i18n.t(
    message,
  )}&buttonText=Назад&actionUrl=${actionUrl}`;
};
