import styled from 'styled-components';
import { TitleText } from '../../components/Typography/Title';
import {
  COLOR_TITLE_BLACK,
  COLOR_BLACK_LOGO,
  COLOR_LINK_BLACK,
  COLOR_RED,
  COLOR_ACTION_BLUE,
} from '../../constants/colors';
import RadioInput from '../../components/Inputs/RadioInput';
import { mobileMedia, tabletSmMedia } from '../../constants/styles';

export const Title = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-right: 20px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Wrapper = styled.div`
  padding-left: 32px;
  padding-right: 22px;
  position: relative;
  @media (max-width: ${tabletSmMedia}) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: ${mobileMedia}) {
    padding-left: 3px;
    padding-right: 3px;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 55px;
`;

export const Subtitle = styled.div`
  color: ${COLOR_BLACK_LOGO};
  font-family: Lato;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 22px;
`;

export const RadioButton = styled(RadioInput)`
  color: #192a3e;
  font-weight: 600;
  margin-right: 10px;
`;

export const RadioWrapper = styled.div`
  max-width: 750px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;
  padding-left: 33px;
  margin-bottom: 40px;
`;

export const RadioWrapperAddress = styled.div`
  margin-bottom: 50px;
`;

export const RadioText = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 12px;
  line-height: 18px;
  font-family: Lato;
  font-weight: 500;
  margin-top: 10px;
`;

export const RadioRedText = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-family: Lato;
  font-weight: 500;
  color: ${COLOR_RED};
`;

export const RadioRedBottomText = styled(RadioRedText)`
  margin-top: 7px;
`;

export const Tabs = styled.div`
  border-bottom: 1px solid #c2cfe0;
  margin-bottom: 24px;
`;

export const TabsItem = styled.button`
  outline: none;
  color: ${COLOR_ACTION_BLUE};
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-family: Lato;
  padding: 0;
  padding-bottom: 15px;
  margin-right: 48px;
  position: relative;
  &.active {
    color: #334d6e;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 4px;
      background: #334d6e;
      bottom: 0;
      left: 0;
    }
  }
`;
