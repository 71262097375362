import API from './base';
import { getUrlToLKApi } from '../constants/api';

export default class ProfileApi extends API {
  fetchUser(token = null) {
    return this.get(getUrlToLKApi('auth/me'), token, false);
  }

  getStatusConfirmation({ customerId, userId, emailConfirmationId }, data, token = null) {
    return this.put(
      getUrlToLKApi(
        `customers/${customerId}/users/${userId}/emailConfirmations/${emailConfirmationId}`,
      ),
      data,
      token,
      false,
    );
  }

  confirmationEmail(customerId, userId, data, token = null) {
    return this.post(
      getUrlToLKApi(`customers/${customerId}/users/${userId}/emailConfirmations`),
      data,
      token,
      false,
    );
  }

  getStatusCustomerConfirmation({ customerId, emailConfirmationId }, data, token = null) {
    return this.put(
      getUrlToLKApi(`customers/${customerId}/emailConfirmations/${emailConfirmationId}`),
      data,
      token,
      false,
    );
  }

  confirmationCustomerEmail(customerId, data, token = null) {
    return this.post(
      getUrlToLKApi(`customers/${customerId}/emailConfirmations`),
      data,
      token,
      false,
    );
  }
}
