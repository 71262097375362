import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withProps, lifecycle } from 'recompose';
// import { useTranslation } from 'react-i18next';

// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import { Title } from '../../Balance/BalanceStyled';

import YearIntervalSelector from './YearIntervalSelector';
import ButtonGroupSwitch from './ButtonGroupSwitch';
import FinanceTable from './FinanceTable';

import storeNames from '../../../stores/storeNames';
// import { useStyles } from './Styles/styles';
// import logger from '../../../logger';

const Finance = ({
  loader,
  financeSelector,
  setFinanceSelector,
  financeTypesList,
  yearList,
  yearFrom,
  setYearFrom,
  yearTo,
  setYearTo,
  finance,
  getFinance,
  financeFNS,
  getFinanceFNS,
}) => {
  // const { t } = useTranslation();
  // const sc = useStyles();
  return (
    <>
      <YearIntervalSelector
        yearArray={yearList}
        yearFrom={yearFrom}
        setYearFrom={setYearFrom}
        yearTo={yearTo}
        setYearTo={setYearTo}
        change={async (_yearFrom, _yearTo) => {
          if (_yearTo > _yearFrom) {
            loader(true);
            await getFinance(_yearFrom, _yearTo);
            await getFinanceFNS(_yearFrom, _yearTo);
            loader(false);
          }
        }}
      />
      {'title' in financeTypesList[0] && (
        <>
          <ButtonGroupSwitch
            array={[
              ...financeTypesList.map(item => {
                return { label: item.title, value: item.id };
              }),
              { label: 'Данные от ФНС', value: 'fns' },
            ]}
            value={financeSelector}
            setValue={setFinanceSelector}
          />

          {finance && (
            <FinanceTable
              data={financeSelector === 'fns' ? financeFNS : finance[financeSelector]}
              yearFrom={yearFrom}
              yearTo={yearTo}
            />
          )}
        </>
      )}
    </>
  );
};
Finance.propTypes = {
  loader: PropTypes.func.isRequired,
  financeSelector: PropTypes.string.isRequired,
  setFinanceSelector: PropTypes.func.isRequired,
  financeTypesList: PropTypes.array.isRequired,
  yearList: PropTypes.array.isRequired,
  yearFrom: PropTypes.number.isRequired,
  setYearFrom: PropTypes.func.isRequired,
  yearTo: PropTypes.number.isRequired,
  setYearTo: PropTypes.func.isRequired,

  finance: PropTypes.object.isRequired,
  getFinance: PropTypes.func.isRequired,

  financeFNS: PropTypes.object.isRequired,
  getFinanceFNS: PropTypes.func.isRequired,
};
Finance.defaultProps = {};

const enhance = compose(
  inject(storeNames.DashboardStore),
  inject(storeNames.DialogStore),
  observer,
  withProps(({ DashboardStore, DialogStore }) => ({
    loader: DialogStore.loader,
    inquiry: DashboardStore.inquiry,
    finance: DashboardStore.finance,
    financeFNS: DashboardStore.financeFNS,
    getFinance: DashboardStore.getFinance,
    getFinanceFNS: DashboardStore.getFinanceFNS,
    financeTypesList: DashboardStore.getFinanceTypes(),
    yearList: DashboardStore.financeYearList,
    yearFrom: DashboardStore.financeYearFrom,
    yearTo: DashboardStore.financeYearTo,
    setYearFrom: DashboardStore.financeYearSetFrom,
    setYearTo: DashboardStore.financeYearSetTo,
    financeSelector: DashboardStore.financeSelector,
    setFinanceSelector: DashboardStore.financeSelectorSet,
  })),
  lifecycle({}),
);

export default enhance(Finance);
