import styled from 'styled-components';
import { TitleText } from '../../../components/Typography/Title';
import { COLOR_TITLE_BLACK, COLOR_LINK_BLACK, COLOR_ACTION_BLUE } from '../../../constants/colors';
import { mobileMedia, tabletMedia } from '../../../constants/styles';

export const Title = styled(TitleText)`
  font-size: 32px;
  text-align: center;
  color: ${COLOR_TITLE_BLACK};
  line-height: 1.3;
  margin-bottom: 25px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Indent = styled.div`
  margin: ${props => (props.top ? props.top : '30px')} 0px 10px;
`;

export const Shift = styled.div`
  margin-left: ${props => (props.tab ? `${props.tab * 40}px` : null)};
`;

export const Wrapper = styled.div`
  padding: 43px 50px 54px;
  @media (max-width: ${mobileMedia}) {
    padding: 43px 25px 54px;
  }
`;

export const FormWrapper = styled.div`
  margin-top: ${props => (props.top ? props.top : '10px')};
  margin-bottom: ${props => (props.bottom ? props.bottom : '0px')};
  /* margin-top: a10pax; */
  display: ${props => (props.flex ? 'flex' : '')};
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const DateContent = styled.div`
  width: 190px;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};
`;

export const InputWrapperNotes = styled.div`
  position: relative;
  width: 100%;
`;

export const DateInputWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  @media (max-width: ${mobileMedia}) {
    font-size: 15px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 97%;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 8px;
  @media (max-width: ${mobileMedia}) {
    width: 100%;
  }
`;

export const TopText = styled.p`
  color: ${COLOR_LINK_BLACK};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 13px;
  margin-top: 13px;
`;

export const Description = styled.p`
  color: #90a0b7;
  font-size: 12px;
  line-height: 19px;
  margin-left: 15px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    margin-top: 35px;
    align-items: center;
    button {
      margin-top: 20px;
    }
  }
`;

export const TextArea = styled.textarea`
  margin-top: 05px;
  width: 100%;
  max-width: 100%;
  min-height: ${props => (props.lines ? `${38 + props.lines * 18}px` : null)};
  padding: 18px 14px;
  border-radius: 4px;
  border: 1px solid #abb1ba;
`;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Tabs = styled.div`
  border-bottom: 1px solid #c2cfe0;
  margin-bottom: 30px;
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
  @media (max-width: 960px) {
    margin-bottom: 20px;
  }
`;

export const TabsItem = styled.button`
  outline: none;
  color: ${COLOR_ACTION_BLUE};
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-family: Lato;
  padding: 0;
  padding-bottom: 15px;
  margin-right: 48px;
  position: relative;
  &.active {
    color: #334d6e;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 4px;
      background: #334d6e;
      bottom: 0;
      left: 0;
    }
  }
  @media (max-width: ${tabletMedia}) {
    margin-right: 50px;
    font-size: 22px;
  }
  @media (max-width: ${mobileMedia}) {
    font-size: 18px;
    padding-bottom: 8px;
    margin-right: 16px;
  }
`;
