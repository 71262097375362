export const ALLOW_ALL_ROLE = 'allowAll';
export const CUSTOMER_ADMIN_ROLE = 'CUSTOMER_ADMIN';
export const SUPER_ADMIN_ROLE = 'SUPER_ADMIN';
export const CUSTOMER_ACCOUNTANT = 'CUSTOMER_ACCOUNTANT';
export const CUSTOMER_USER = 'CUSTOMER_USER';

export const CUSTOMER_ROLES = [
  { text: 'users.admin', value: CUSTOMER_ADMIN_ROLE },
  { text: 'users.accountant', value: CUSTOMER_ACCOUNTANT },
  { text: 'users.user', value: CUSTOMER_USER },
];

export const SUPER_ADMINS_ROLES = [
  { text: 'users.superAdmin', value: SUPER_ADMIN_ROLE },
  { text: 'users.admin', value: CUSTOMER_ADMIN_ROLE },
  { text: 'users.accountant', value: CUSTOMER_ACCOUNTANT },
  { text: 'users.user', value: CUSTOMER_USER },
];
