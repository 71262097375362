import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useMedia } from 'use-media';
import { hasValue } from '../../../utils/validators';
import InputPhone from '../../../components/Inputs/InputPhone';
import {
  Content,
  InputColumn,
  InputWrapper,
  TextWrapper,
  Description,
  OptionalInput,
  ImgWrap,
  Img,
  Button,
} from './ProfileUsersStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import InputField from '../../../components/Inputs/InputField';
import { withUserData } from '../../../utils/hocs';
import { withInitializedUserDataInputs } from '../../../utils/initFormHocs';
import InputDate from '../../../components/Inputs/InputDate';
import Typography from '../../../components/Typography/Paragraph';
import { ICON_EMAIL_VERIFID, ICON_EMAIL_NOVERIFID } from '../../../constants/icons';
import { STATUS_CONFIRMATION, getUrl } from '../../../constants/statusPageUrls';
import { getUrlObject } from '../../../helpers/url';
// import PersonalAccounts from './PersonalAccounts/PersonalAccounts';

const Individual = ({
  birth_date,
  patronymic,
  name,
  surname,
  email,
  phone,
  actualAddress,
  inputSetStore,
  inputHandlerSurname,
  inputHandlerName,
  inputHandlerPatronymic,
  inputHandlerEmail,
  inputHandlerPhone,
  inputHandlerActualAddress,
  // deleteAccountHandler,
  // addAccountHandler,
  surnameValid,
  nameValid,
  emailValid,
  phoneValid,
  actualAddressValid,
  inputHandlerDate,
  surnameError,
  login,
  loginValid,
  inputHandlerLogin,
  loginError,
  nameError,
  emailError,
  phoneError,
  actualAddressError,
  isAdmin,
  userData,
  handlerConfirmation,
}) => {
  const { t } = useTranslation();
  const mobMedia = useMedia({ maxWidth: 768 });
  return (
    <>
      {!mobMedia && (
        <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
          {t('profile.personalData')}
        </Typography>
      )}
      <Content>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="1"
              placeholder={t('reg.surname')}
              name="surname"
              value={surname}
              isValid={surnameValid}
              onChange={value => {
                inputSetStore(value, inputHandlerSurname, 'sname');
              }}
            />
            <ParagraphError>{t(surnameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="2"
              placeholder={t('reg.name')}
              type="text"
              name="name"
              value={name}
              isValid={nameValid}
              onChange={value => {
                inputSetStore(value, inputHandlerName, 'fname');
              }}
            />
            <ParagraphError>{t(nameError)}</ParagraphError>
          </InputWrapper>
          <OptionalInput>
            <TextWrapper>
              <Description>{t('reg.optional')}</Description>
            </TextWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="3"
              placeholder={t('reg.patronymic')}
              name="patronymic"
              value={patronymic}
              onChange={value => {
                inputSetStore(value, inputHandlerPatronymic, 'mname');
              }}
            />
          </OptionalInput>
        </InputColumn>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="5"
              placeholder={t('recovery.emailAddress')}
              name="email"
              value={email}
              isValid={emailValid}
              onChange={value => {
                inputSetStore(value, inputHandlerEmail, 'email');
              }}
            />
            <ImgWrap>
              <Img src={userData.email_confirmed ? ICON_EMAIL_VERIFID : ICON_EMAIL_NOVERIFID} />
            </ImgWrap>
            <ParagraphError>{t(emailError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            {!userData.email_confirmed && (
              <Button
                style={{ marginTop: '14px', marginBottom: '14px' }}
                type="submit"
                data-test-id="TEST_BUTTON_CONFORMATION"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="7"
                onClick={e => handlerConfirmation(e)}
              >
                {t('profile.confirmEmail')}
              </Button>
            )}
          </InputWrapper>
          <InputWrapper>
            <InputPhone
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="6"
              placeholder={t('reg.phone')}
              name="phone"
              value={phone}
              isValid={phoneValid}
              onChange={value => {
                inputSetStore(value, inputHandlerPhone, 'phone');
              }}
            />
            <ParagraphError>{t(phoneError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              placeholder={t('login.login')}
              name="login"
              value={login}
              isValid={loginValid}
              onChange={value => {
                inputSetStore(value, inputHandlerLogin, 'login');
              }}
            />
            <ParagraphError>{t(loginError)}</ParagraphError>
          </InputWrapper>
          {!isAdmin && (
            <OptionalInput>
              <TextWrapper>
                <Description>{t('reg.optional')}</Description>
              </TextWrapper>
              <InputDate
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="7"
                name="birth_date"
                value={birth_date}
                onChange={value => {
                  inputSetStore(value, inputHandlerDate, 'birth_date');
                }}
              />
            </OptionalInput>
          )}
        </InputColumn>
        {!isAdmin && (
          <>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="4"
                placeholder={t('reg.actualAddress')}
                name="actualAddress"
                value={actualAddress}
                isValid={actualAddressValid}
                onChange={value => {
                  inputSetStore(value, inputHandlerActualAddress, 'post_address');
                }}
              />
              <ParagraphError>{t(actualAddressError)}</ParagraphError>
            </InputWrapper>
            {/* <PersonalAccounts
          deleteAccountHandler={deleteAccountHandler}
          addAccountHandler={addAccountHandler}
        /> */}
          </>
        )}
      </Content>
    </>
  );
};

Individual.propTypes = {
  birth_date: PropTypes.any,
  patronymic: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  actualAddress: PropTypes.string,
  surnameError: PropTypes.string,
  nameError: PropTypes.string,
  emailError: PropTypes.string,
  phoneError: PropTypes.string,
  actualAddressError: PropTypes.string,
  inputHandlerSurname: PropTypes.func,
  inputHandlerName: PropTypes.func,
  inputSetStore: PropTypes.func,
  inputHandlerEmail: PropTypes.func,
  inputHandlerPhone: PropTypes.func,
  inputHandlerDate: PropTypes.func,
  inputHandlerPatronymic: PropTypes.func,
  // deleteAccountHandler: PropTypes.func,
  // addAccountHandler: PropTypes.func,
  inputHandlerActualAddress: PropTypes.func,
  surnameValid: PropTypes.bool,
  userData: PropTypes.object,
  emailValid: PropTypes.bool,
  phoneValid: PropTypes.bool,
  actualAddressValid: PropTypes.bool,
  nameValid: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  login: PropTypes.string,
  loginValid: PropTypes.bool,
  handlerConfirmation: PropTypes.func,
  inputHandlerLogin: PropTypes.func,
  loginError: PropTypes.string,
};

Individual.defaultProps = {
  birth_date: undefined,
  name: '',
  surname: '',
  patronymic: '',
  email: '',
  login: '',
  phone: '',
  nameError: '',
  loginError: '',
  emailError: '',
  userData: {},
  phoneError: '',
  actualAddressError: '',
  actualAddress: '',
  surnameError: '',
  inputHandlerSurname: () => {},
  inputHandlerName: () => {},
  inputSetStore: () => {},
  inputHandlerEmail: () => {},
  inputHandlerActualAddress: () => {},
  inputHandlerPatronymic: () => {},
  inputHandlerPhone: () => {},
  inputHandlerDate: () => {},
  handlerConfirmation: () => {},
  inputHandlerLogin: () => {},
  // deleteAccountHandler: () => {},
  // addAccountHandler: () => {},
  surnameValid: false,
  emailValid: false,
  phoneValid: false,
  actualAddressValid: false,
  nameValid: false,
  loginValid: false,
};

const enhance = compose(
  withUserData,
  withInitializedUserDataInputs,
  withHandlers(({ ProfileStore }) => ({
    handlerConfirmation: () => async e => {
      e.preventDefault();
      const collbackUrl = getUrlObject();
      const data = {
        return_url: `${getUrl()}${STATUS_CONFIRMATION(collbackUrl.href, 'profile')}`,
      };
      ProfileStore.confirmationEmail(data, ProfileStore.userData.id);
      localStorage.setItem('USER_ID', ProfileStore.userData.id);
    },
  })),
  lifecycle({
    componentDidUpdate() {
      const { emailValid, setIsValidIndividual, login } = this.props;
      if (emailValid && hasValue(login)) {
        setIsValidIndividual(true);
      } else {
        setIsValidIndividual(false);
      }
    },
  }),
);

export default enhance(Individual);
