import React from 'react';
import { compose, withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Wrapper,
  CheckBoxWrapper,
  WelcomeForm,
  ButtonWrapper,
} from './TopInquiriesNavigationStyled';
import Input from '../../../components/Inputs/Input';
import Button from '../../../components/Buttons/Button';
import { withHandlePressEnter } from '../../../utils/hocs';

const TopInquiriesNavigation = ({ inputData, setInputData, handlePressEnter, formHandler }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CheckBoxWrapper />
      <WelcomeForm onSubmit={formHandler}>
        <Input
          placeholder={t('inquiries.findForInnOgrnAndName')}
          value={inputData}
          name="inputData"
          onChange={setInputData}
          onKeyDown={handlePressEnter}
        />
        <ButtonWrapper>
          <Button minWidth="150px" testID="TEST_ID_INQUIRIES_SEARCH">
            {t('inquiries.find')}
          </Button>
        </ButtonWrapper>
      </WelcomeForm>
    </Wrapper>
  );
};

TopInquiriesNavigation.propTypes = {
  inputData: PropTypes.string,
  handlePressEnter: PropTypes.func,
  setInputData: PropTypes.func,
  formHandler: PropTypes.func,
};

TopInquiriesNavigation.defaultProps = {
  inputData: '',
  setInputData: () => {},
  handlePressEnter: () => {},
  formHandler: () => {},
};

const enhancer = compose(
  withHandlers(() => ({
    formHandler: () => e => {
      if (e) e.preventDefault();
    },
  })),
  withHandlePressEnter,
);

export default enhancer(TopInquiriesNavigation);
