import styled from 'styled-components';
import { Btn } from '../../../components/Buttons/Button';
import { COLOR_ACTION_BLUE, COLOR_WHITE } from '../../../constants/colors';
import { mobileMedia } from '../../../constants/styles';

export const Wrapper = styled.div`
  padding: 45px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mobileMedia}) {
    padding: 15px 30px;
  }
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Lato;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
`;

export const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Bottom = styled.div`
  margin-top: 40px;
  width: 100%;
  @media (max-width: ${mobileMedia}) {
    margin-top: 20px;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 26px;
  margin-bottom: 72px;
  text-align: center;
`;

export const TextWrap = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const TextEmail = styled.div`
  margin-top: 30px;
  text-align: center;
  margin-bottom: 19px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 20px;
    }
  }
`;

export const ButtonDisabled = styled(Btn)`
  padding: 11px;
  font-size: 16px;
  font-weight: 500;
  font-family: Lato;
  margin: 0 auto;
  border: 1px solid ${props => (props.disabled ? '#C2CFE0' : COLOR_ACTION_BLUE)};
  background-color: ${props => (props.disabled ? '#C2CFE0' : COLOR_ACTION_BLUE)};
  color: ${props => (props.disabled ? '#90A0B7' : COLOR_WHITE)};
  cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
`;
