import * as numeral from 'numeral';

export { DefaultLayout } from './DefaultLayout';
export { MainLayout } from './MainLayout/MainLayout';

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
});
numeral.locale('ru');
