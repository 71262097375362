import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { COLOR_WHITE, COLOR_BLACK_LOGO, COLOR_RED, COLOR_GRAY } from '../../constants/colors';
import { checkPhoneNumber } from '../../utils/validators';
import { INPUT_TEST_ID } from '../../constants/testids';
import { ICON_REQ } from '../../constants/icons';

const InputWrap = styled.div`
  & > input {
    box-sizing: border-box;
    width: 100%;
    height: ${props => (props.height ? props.height : '100%')};
    z-index: 1;
    position: relative;
    background-color: transparent;
    border: none;
    padding: 15px 19px;
    font-weight: 400;
    font-family: Lato;
    color: ${COLOR_BLACK_LOGO};
  }
`;

const InputWrapper = styled.div`
  position: relative;
  background: ${props =>
    props.isRequired
      ? `${
          props.backgroundColor ? props.backgroundColor : COLOR_WHITE
        } url(${ICON_REQ}) no-repeat calc(100% - 15px) center`
      : COLOR_WHITE};
  border: 1px solid ${props => (!props.isValid ? COLOR_RED : COLOR_GRAY)};
  width: 100%;
  border-radius: 4px;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #90a0b7;
  font-family: Lato;
  display: block;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  left: 19px;
  top: 15px;
  z-index: 0;
  &.active {
    top: -10px;
    display: block;
    left: 15px;
    font-size: 12px;
    width: initial;
    padding-right: 10px;
    padding-left: 10px;
    background: ${COLOR_WHITE};
    z-index: 2;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  line-height: 1;
  margin: 8px 0 0;
  color: ${COLOR_RED};
`;

const Field = styled.div(({ margin, width }) => ({
  margin: margin || '12px 0 0 0',
  width: width || '100%',
}));

const Input = ({
  placeholder,
  onChange,
  isValid,
  errorMessage,
  value,
  onKeyDown,
  type,
  height,
  name,
  margin,
  tabIndex,
  isRequired,
  disabled,
}) => (
  <Field margin={margin}>
    <InputWrapper isValid={isValid} isRequired={isRequired}>
      {placeholder && <Label className={value ? 'active' : null}>{placeholder}</Label>}
      <InputWrap>
        <InputMask
          value={value}
          mask="+7 (999) 999-99-99"
          maskChar=" "
          height={height}
          type={type}
          disabled={disabled}
          tabIndex={tabIndex}
          name={name}
          data-test-id={`${INPUT_TEST_ID}${name && name.toUpperCase()}`}
          onKeyDown={event => onKeyDown(event)}
          onChange={event => onChange(checkPhoneNumber(event.target.value))}
        />
      </InputWrap>
    </InputWrapper>
    <ErrorText>{errorMessage}</ErrorText>
  </Field>
);

Input.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  tabIndex: PropTypes.string,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
};

Input.defaultProps = {
  value: '',
  name: '',
  placeholder: undefined,
  isValid: true,
  isRequired: false,
  disabled: false,
  onChange: () => {},
  onKeyDown: () => {},
  errorMessage: '',
  type: 'text',
  height: '',
  tabIndex: '',
  margin: '',
};

export default Input;
