import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose, withHandlers, withState, lifecycle, withProps } from 'recompose';
import storeNames from '../../../stores/storeNames';
import Popup from '../../../components/Popup/Popup';
import Paragraph from '../../../components/Typography/Paragraph';
import { getUrlObject } from '../../../helpers/url';
import ParagraphError from '../../../components/Typography/ParagraphError';
import {
  Wrapper,
  InputWrapper,
  FormWrapper,
  Title,
  ButtonWrapper,
  MethodsWrapper,
  CardMethod,
  Img,
  TariffName,
  TariffContainer,
  Top,
  Scorings,
  ScoringRight,
  ScoringTop,
  ScoringTime,
  ScoringScore,
  MonitoringWrapper,
  Monitoring,
  MonitoringScore,
  MonitoringRight,
  MonitoringTop,
  MonitoringTime,
  Bottom,
  Price,
  Controls,
  Minus,
  Plus,
  Count,
  Result,
  ResultPrice,
  ScoringWrapper,
} from './PopupsStyled';
import { STATUS_AFTER_PAYMENT, getUrl } from '../../../constants/statusPageUrls';
import { COLOR_BLACK_LOGO, COLOR_ACTION_BLUE } from '../../../constants/colors';
import { withReasonState } from '../../../utils/FormHocs';
import Button from '../../../components/Buttons/Button';
import {
  ICON_CARD,
  ICON_BRIEFCASE,
  ICON_CARD_ACTIVE,
  ICON_BRIEFCASE_ACTIVE,
  ICON_SCORE,
  ICON_SCORE_ACTIVE,
} from '../../../constants/icons';

const PurchasePopup = ({
  toggleOpenPurchasePopup,
  formHandler,
  setCardMethod,
  cardMethod,
  tariffs,
  tariffId,
  handlerMinus,
  handlerPlus,
  qnt,
  cardError,
  accountId,
}) => {
  const { t } = useTranslation();
  const items = tariffs.filter(el => el.id === tariffId);
  return (
    <Popup
      width="740px"
      boxShadow="0px 7px 48px rgba(0, 0, 0, 0.08);"
      borderRadius="4px"
      closePopup={toggleOpenPurchasePopup}
    >
      <Wrapper>
        <Title as="h2">{t('services.serviceBuying')}</Title>
        {items &&
          items.map(item => {
            return (
              <FormWrapper key={item.id} onSubmit={e => formHandler(e, item.id)}>
                <TariffName>{item.full_name}</TariffName>
                <TariffContainer>
                  <Top>
                    <ScoringWrapper>
                      {item.scores > 0 ? (
                        <Scorings>
                          <ScoringScore>{item.scores * qnt}</ScoringScore>
                          <ScoringRight>
                            <ScoringTop>
                              {item.scores === 1 ? t('services.scoring') : t('services.scorings')}
                            </ScoringTop>
                            <ScoringTime>
                              {item.scores_ttl >= 1000000
                                ? t('services.indefinitely')
                                : t('services.forNumberDays', { number: item.scores_ttl })}
                            </ScoringTime>
                          </ScoringRight>
                        </Scorings>
                      ) : (
                        t('services.scoringNotIncludedInTariff')
                      )}
                    </ScoringWrapper>
                    <MonitoringWrapper>
                      {item.monitors > 0 ? (
                        <Monitoring>
                          <MonitoringScore>{item.monitors * qnt}</MonitoringScore>
                          <MonitoringRight>
                            <MonitoringTop>{t('services.monitoringDays')}</MonitoringTop>
                            <MonitoringTime>
                              {item.monitors_ttl >= 1000000
                                ? t('services.indefinitely')
                                : t('services.forNumberDays', { number: item.monitors_ttl })}
                            </MonitoringTime>
                          </MonitoringRight>
                        </Monitoring>
                      ) : (
                        t('services.monitoringNotIncludedInTariff')
                      )}
                    </MonitoringWrapper>
                  </Top>
                  <Bottom>
                    <Price>{`${item.price} ₽`}</Price>
                    <Controls>
                      <Minus data-test-id="TEST_BUTTON_MINUS" onClick={() => handlerMinus(qnt)} />
                      <Count>{qnt}</Count>
                      <Plus data-test-id="TEST_BUTTON_PLUS" onClick={() => handlerPlus(qnt)} />
                    </Controls>
                    <Result>
                      {t('services.total')} <ResultPrice>{`${item.price * qnt} ₽`}</ResultPrice>
                    </Result>
                  </Bottom>
                </TariffContainer>
                <InputWrapper>
                  <Paragraph
                    textColor={COLOR_BLACK_LOGO}
                    fontSize="18px"
                    fontWeight="500"
                    textAlign="center"
                  >
                    {t('services.selectPaymentMethod')}
                  </Paragraph>
                  <MethodsWrapper>
                    <CardMethod
                      className={cardMethod === 'PersonalAccount' ? 'active' : null}
                      onClick={() => accountId && setCardMethod('PersonalAccount')}
                      data-test-id="TEST_BUTTON_PERSONAL"
                      disabled={!accountId}
                    >
                      <Img
                        src={
                          cardMethod === 'PersonalAccount' ? ICON_BRIEFCASE_ACTIVE : ICON_BRIEFCASE
                        }
                      />
                      <Paragraph
                        textColor={COLOR_BLACK_LOGO}
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="24px"
                        textAlign="center"
                      >
                        {t('services.withdrawalFromAccount')}
                      </Paragraph>
                    </CardMethod>
                    <CardMethod
                      className={cardMethod === 'Card' ? 'active' : null}
                      onClick={() => accountId && setCardMethod('Card')}
                      data-test-id="TEST_BUTTON_CARD_SERVICES"
                      disabled={!accountId}
                    >
                      <Img src={cardMethod === 'Card' ? ICON_CARD_ACTIVE : ICON_CARD} />
                      <Paragraph
                        textColor={COLOR_BLACK_LOGO}
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="24px"
                        textAlign="center"
                      >
                        {t('services.purchaseByCard')}
                      </Paragraph>
                    </CardMethod>
                    <CardMethod
                      className={cardMethod === 'Counter' ? 'active' : null}
                      onClick={() => setCardMethod('Counter')}
                      data-test-id="TEST_BUTTON_COUNTER"
                    >
                      <Img src={cardMethod === 'Counter' ? ICON_SCORE_ACTIVE : ICON_SCORE} />
                      <Paragraph
                        textColor={COLOR_BLACK_LOGO}
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="24px"
                        textAlign="center"
                      >
                        {t('services.invoiceForPayment')}
                      </Paragraph>
                    </CardMethod>
                  </MethodsWrapper>
                  <ParagraphError bottom="-30px" fontSize="12px">
                    {cardError}
                  </ParagraphError>
                </InputWrapper>
                <ButtonWrapper>
                  <Button
                    testID="TEST_BUTTON_SEND"
                    width="233px"
                    type="submit"
                    disabled={!accountId}
                    onClick={e => accountId && formHandler(e, item.price)}
                  >
                    {t('services.pay')}
                  </Button>
                  <Button
                    testID="TEST_BUTTON_CANCEL"
                    width="233px"
                    textColor={COLOR_ACTION_BLUE}
                    boxShadow="none"
                    backgroundColor="#fff"
                    type="submit"
                    sizes={{ margin: '0 0 0 20px' }}
                    onClick={() => toggleOpenPurchasePopup()}
                  >
                    {t('form.cancel')}
                  </Button>
                </ButtonWrapper>
              </FormWrapper>
            );
          })}
      </Wrapper>
    </Popup>
  );
};

PurchasePopup.propTypes = {
  toggleOpenPurchasePopup: PropTypes.func.isRequired,
  formHandler: PropTypes.func,
  cardMethod: PropTypes.string,
  tariffs: PropTypes.array,
  setCardMethod: PropTypes.func,
  handlerMinus: PropTypes.func,
  handlerPlus: PropTypes.func,
  tariffId: PropTypes.number,
  qnt: PropTypes.number,
  cardError: PropTypes.string,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PurchasePopup.defaultProps = {
  cardMethod: '',
  tariffs: [],
  tariffId: undefined,
  cardError: '',
  accountId: '',
  qnt: 1,
  formHandler: () => {},
  setCardMethod: () => {},
  handlerMinus: () => {},
  handlerPlus: () => {},
};

const enhancer = compose(
  inject(
    storeNames.ServicesStore,
    storeNames.IndicatorsStore,
    storeNames.BalanceStore,
    storeNames.AccountStore,
  ),
  observer,
  withTranslation(),
  withProps(({ ServicesStore }) => ({
    tariffs: ServicesStore.servicesTariffs,
  })),
  withReasonState,
  withState('cardMethod', 'setCardMethod', 'PersonalAccount'),
  withState('cardError', 'setCardError', undefined),
  withState('qnt', 'setQnt', 1),
  withHandlers(({ money }) => ({
    handlerMinus: ({ setQnt, tariffs, tariffId, setCardError, setCardMethod }) => qnt => {
      let newQnt = qnt;
      const items = tariffs.filter(el => el.id === tariffId);

      if (qnt > 1) {
        newQnt = qnt - 1;
        setQnt(newQnt);
      }
      if (items[0].price * newQnt <= money) {
        setCardError('');
        setCardMethod('PersonalAccount');
      }
    },
    handlerPlus: ({ setQnt }) => qnt => {
      const newQnt = qnt + 1;

      setQnt(newQnt);
    },
    formHandler: ({
      toggleOpenPurchasePopup,
      ServicesStore,
      IndicatorsStore,
      qnt,
      tariffId,
      tariffs,
      cardMethod,
      setCardMethod,
      setCardError,
      accountId,
      BalanceStore,
      t,
    }) => async (e, price) => {
      e.preventDefault();
      const items = tariffs.filter(el => el.id === tariffId);
      if (cardMethod === 'Counter') {
        const data = {
          amount: Number(price * qnt),
          is_return: false,
          redirect_url: ``,
          returned_payment_id: null,
          with_contract: true,
          with_purchase: true,
          purchase_qnt: qnt,
          purchase_tariff_id: tariffId,
        };
        await BalanceStore.buyCard(accountId, data);
        if (BalanceStore.contract !== '') {
          const link = document.createElement('a');
          link.href = BalanceStore.contract;
          link.download = 'contract.docx';
          link.click();
          await BalanceStore.deleteContract();
          IndicatorsStore.addErrorIndicators({
            message: t('services.invoiceDownloaded'),
            type: 'success',
          });
          toggleOpenPurchasePopup();
        } else {
          IndicatorsStore.addErrorIndicators({
            message: t('services.errorHasOccurred'),
            type: 'error',
          });
        }
      }
      if (cardMethod === 'PersonalAccount' && items[0].price * qnt <= money) {
        const data = {
          qnt,
          tariff_id: tariffId,
        };

        await ServicesStore.setServicesBasket(accountId, data);
        toggleOpenPurchasePopup();
      }
      if (cardMethod === 'PersonalAccount' && items[0].price * qnt >= money) {
        setCardError(t('services.notEnoughFundsOnTheAccount'));
        setCardMethod('Card');
      }
      if (cardMethod === 'Card') {
        const collbackUrl = getUrlObject();
        const data = {
          amount: Number(price * qnt),
          is_return: false,
          redirect_url: `${getUrl()}${STATUS_AFTER_PAYMENT(collbackUrl.href)}`,
          returned_payment_id: null,
          with_purchase: true,
          purchase_qnt: qnt,
          purchase_tariff_id: tariffId,
        };
        setCardError('');
        localStorage.setItem('ACCOUNT_ID', accountId);

        await BalanceStore.buyCard(accountId, data);
        window.location.href = BalanceStore.buyUrl.yk_confirmation_url;
      }
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { ServicesStore, setCardMethod, accountId, setCardError, i18n } = this.props;
      const { fetchMyTariffs } = ServicesStore;
      await fetchMyTariffs();
      if (!accountId) {
        setCardMethod('');
        setCardError(i18n.t('services.noAvailableAccounts'));
      }
    },
  }),
);

export default enhancer(PurchasePopup);
