import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withProps, lifecycle } from 'recompose';

import YearIntervalSelector from './YearIntervalSelector';
import ButtonGroupSwitch from './ButtonGroupSwitch';
import WritsTable from './WritsTable';

import storeNames from '../../../stores/storeNames';

const Writs = ({
  loader,
  stateSelector,
  setStateSelector,
  writs,
  updateWrits,
  yearList,
  yearFrom,
  yearTo,
  setYearFrom,
  setYearTo,
}) => {
  return (
    <>
      {yearList.length !== 0 && (
        <>
          <YearIntervalSelector
            yearArray={yearList}
            yearFrom={yearFrom}
            setYearFrom={setYearFrom}
            yearTo={yearTo}
            setYearTo={setYearTo}
            change={async (_yearFrom, _yearTo) => {
              if (_yearTo >= _yearFrom) {
                loader(true);
                await updateWrits();
                loader(false);
              }
            }}
          />
          <ButtonGroupSwitch
            array={[
              {
                // eslint-disable-next-line no-irregular-whitespace
                label: `Открытые (${'data' in writs && writs.data.active.length})`,
                value: 'opened',
              },
              {
                // eslint-disable-next-line no-irregular-whitespace
                label: `Закрытые (${'data' in writs && writs.data.finished.length})`,
                value: 'closed',
              },
            ]}
            value={stateSelector}
            setValue={setStateSelector}
          />
        </>
      )}
      <WritsTable writs={writs.data} type={stateSelector} loaded={'loaded' in writs} />
    </>
  );
};
Writs.propTypes = {
  loader: PropTypes.func.isRequired,
  writs: PropTypes.object.isRequired,
  updateWrits: PropTypes.func.isRequired,
  stateSelector: PropTypes.string.isRequired,
  setStateSelector: PropTypes.func.isRequired,
  yearList: PropTypes.array.isRequired,
  yearFrom: PropTypes.number.isRequired,
  yearTo: PropTypes.number.isRequired,
  setYearFrom: PropTypes.func.isRequired,
  setYearTo: PropTypes.func.isRequired,
};
Writs.defaultProps = {};

const enhance = compose(
  inject(storeNames.DashboardStore),
  inject(storeNames.DialogStore),
  observer,
  withProps(({ DashboardStore, DialogStore }) => ({
    loader: DialogStore.loader,
    inquiry: DashboardStore.inquiry,
    writs: DashboardStore.writs,
    updateWrits: DashboardStore.updateWrits,
    stateSelector: DashboardStore.writsSelector,
    setStateSelector: DashboardStore.setWritsSelector,
    yearList: DashboardStore.writsYearList,
    yearFrom: DashboardStore.writsYearFrom,
    setYearFrom: DashboardStore.writsYearSetFrom,
    yearTo: DashboardStore.writsYearTo,
    setYearTo: DashboardStore.writsYearSetTo,
  })),
  lifecycle({}),
);

export default enhance(Writs);
