import { observable, decorate } from 'mobx';

export default class RegistrationStore {
  constructor(context) {
    this.context = context;
  }

  signUpForm = {};

  clearRegistrationStore() {
    this.signUpForm = {};
  }

  setRegistrationStore(state) {
    this.signUpForm = { ...this.signUpForm, ...state };
  }

  signUp = payload => {
    console.log(payload);
  };
}

decorate(RegistrationStore, {
  signUpForm: observable,
});
