import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose, withProps } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  Wrapper,
  Top,
  Avatar,
  Content,
  Bottom,
  ScoringItem,
  MonitoringItem,
  // RubleItem,
} from './UserBarStyled';
import Paragraph from '../../../../components/Typography/Paragraph';
import { withUserData, withFetchUserInDidMount, withActiveValue } from '../../../../utils/hocs';

const UserBar = ({ confirmed, userData, userIsLoading, accountValue, stats, t }) => (
  <Wrapper className={confirmed ? 'hide' : null}>
    {userData && userData.id && !userIsLoading && (
      <>
        <Top className={confirmed ? 'hide' : null}>
          {/* <Img src={ICON_AVATAR} className={confirmed ? 'hide' : null} /> */}
          <Avatar className={confirmed ? 'hide' : null}>
            {userData.fname && userData.fname[0]} {userData.sname && `${userData.sname[0]}`}
          </Avatar>
          <Content className={confirmed ? 'hide' : null}>
            <Paragraph fontSize="14px" textColor="#192a3e" lineHeight="26px">
              {userData.fname && userData.fname} {userData.sname && `${userData.sname[0]}.`}
            </Paragraph>
            <Paragraph fontSize="11px" lineHeight="19px" textColor="#90A0B7">
              {userData.email || ''}
            </Paragraph>
          </Content>
        </Top>
        {accountValue.id && (
          <Bottom className={confirmed ? 'hide' : null}>
            <ScoringItem hint={t('sidebar.scorings')}>{stats.scores || '0'}</ScoringItem>
            {/* <RubleItem>{stats.monitors || '0'}</RubleItem> */}
            <MonitoringItem hint={t('sidebar.money')}>{accountValue.money || 0}</MonitoringItem>
          </Bottom>
        )}
      </>
    )}
  </Wrapper>
);

UserBar.propTypes = {
  userIsLoading: PropTypes.bool,
  confirmed: PropTypes.bool.isRequired,
  userData: PropTypes.object.isRequired,
  stats: PropTypes.object,
  accountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  t: PropTypes.func.isRequired,
};

UserBar.defaultProps = {
  userIsLoading: false,
  accountValue: '',
  stats: {},
};

const enhance = compose(
  withTranslation(),
  withFetchUserInDidMount,
  withUserData,
  withActiveValue(false, true, false, false, false, false),
  observer,
  withProps(({ ServicesStore }) => ({
    stats: ServicesStore.stats,
  })),
);

export default enhance(UserBar);
