import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TariffWrapper } from '../ServicesStyled';
import Tariff from './Tariff/Tariff';

const ActiveServices = ({ activeTariffs }) => {
  const { t } = useTranslation();
  return (
    <TariffWrapper>
      {activeTariffs.length > 0
        ? activeTariffs.map(item => (
            <Tariff
              key={item.id}
              fromTime={item.datetime}
              fullName={item.tariff.full_name}
              qnt={item.qnt}
              scoresToTime={item.fuels.to_time}
              scoresAll={item.tariff.scores * item.qnt}
              monitorsToTime={item.monitors_to_time}
              monitorsAll={item.tariff.monitors * item.qnt}
              monitorsTtl={item.tariff.monitors_ttl}
              scoresTtl={item.tariff.scores_ttl}
              fuels={item.fuels}
            />
          ))
        : t('services.noActiveServices')}
    </TariffWrapper>
  );
};

ActiveServices.propTypes = {
  activeTariffs: PropTypes.array,
};

ActiveServices.defaultProps = {
  activeTariffs: [],
};

export default ActiveServices;
