import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose, withProps } from 'recompose';
import storeNames from '../../stores/storeNames';

const ModalsProvider = ({ modals }) => {
  if (!modals.length) return null;

  const [{ Component, context }] = modals;

  return <Component context={context} />;
};

ModalsProvider.propTypes = {
  modals: PropTypes.array.isRequired,
};

export default compose(
  inject(storeNames.Modals),
  observer,
  withProps(({ Modals }) => ({
    modals: Modals.modals,
  })),
)(ModalsProvider);
