import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';

import { COLOR_WHITE, COLOR_BLACK_LOGO, COLOR_RED, COLOR_GRAY } from '../../constants/colors';
import { INPUT_TEST_ID } from '../../constants/testids';
import { withBoolState } from '../../utils/hocs';
import { ICON_REQ } from '../../constants/icons';

const Inpt = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: ${props => (props.height ? props.height : '100%')};
  z-index: 1;
  position: relative;
  background-color: transparent;
  border: none;
  padding: 15px 19px;
  font-weight: 400;
  color: ${COLOR_BLACK_LOGO};
  resize: ${({ textarea }) => (textarea ? 'vertical' : 'both')};
`;

const InputWrapper = styled.div`
  position: relative;
  border: 1px solid ${props => (!props.isValid ? COLOR_RED : COLOR_GRAY)};
  width: 100%;
  border-radius: 4px;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : COLOR_WHITE)};
  &:before {
    display: ${props => (props.isRequired ? `block` : 'none')};
    content: '';
    width: 6px;
    height: 6px;
    right: 16px;
    top: 21px;
    position: absolute;
    background: ${props => (props.isRequired ? `url(${ICON_REQ}) no-repeat` : '')};
  }
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  color: #90a0b7;
  display: block;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  left: 19px;
  top: 15px;
  z-index: 0;
  &.active {
    top: -10px;
    display: block;
    left: 15px;
    font-size: 12px;
    width: initial;
    padding-right: 10px;
    padding-left: 10px;
    background: ${COLOR_WHITE};
    z-index: 2;
  }
`;

const Input = ({
  autocomplete,
  backgroundColor,
  disabled,
  height,
  isRequired,
  isValid,
  name,
  onChange,
  onKeyDown,
  placeholder,
  read,
  setAutocomplete,
  setRead,
  tabIndex,
  textarea,
  type,
  value,
}) => (
  <InputWrapper isValid={isValid} isRequired={isRequired}>
    {placeholder && <Label className={value ? 'active' : null}>{placeholder}</Label>}
    <Inpt
      tabIndex={tabIndex}
      value={value}
      height={height}
      type={type}
      disabled={disabled}
      readOnly={read}
      autoComplete={autocomplete}
      onFocus={() => {
        setRead(false);
        setAutocomplete('whatever');
      }}
      name={name}
      data-test-id={`${INPUT_TEST_ID}${name && name.toUpperCase()}`}
      backgroundColor={backgroundColor}
      onKeyDown={event => onKeyDown(event)}
      onChange={event => onChange(event.target.value)}
      as={textarea ? 'textarea' : 'input'}
      textarea={textarea}
      rows={12}
    />
  </InputWrapper>
);

Input.propTypes = {
  autocomplete: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  read: PropTypes.bool,
  setAutocomplete: PropTypes.func.isRequired,
  setRead: PropTypes.func.isRequired,
  tabIndex: PropTypes.string,
  textarea: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  autocomplete: '',
  backgroundColor: undefined,
  disabled: false,
  height: '',
  isRequired: false,
  isValid: true,
  name: '',
  onChange: () => {},
  onKeyDown: () => {},
  placeholder: undefined,
  read: true,
  tabIndex: '',
  textarea: false,
  type: 'text',
  value: '',
};
const enhancer = compose(
  withBoolState('read', true),
  withState('autocomplete', 'setAutocomplete', 'off'),
);
export default enhancer(Input);
