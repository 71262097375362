import { withState, compose, withHandlers } from 'recompose';
import { inject, observer } from 'mobx-react';
import * as Yup from 'yup';
import {
  validateInn,
  validateBik,
  validateKpp,
  validateOgrn,
  validateOgrnip,
} from './entity-validators';
import storeNames from '../stores/storeNames';

const EmailSchema = Yup.string()
  .required('form.writeEmail')
  .email('form.writeValidEmail');
// eslint-disable-next-line no-useless-escape
// const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
const PhoneSchema = Yup.string();
// .matches(phoneRegExp, 'Номер телефона не действителен');
const OgrNameSchema = Yup.string().required('input.orgName');
const LegalAddressSchema = Yup.string().required('input.legalAddress');
const ActualRegistrationAddressSchema = Yup.string().required('input.regAddress');
const ActualAddressSchema = Yup.string().required('form.writeActualAddress');
const ContactPersonSchema = Yup.string().required('input.contactPerson');
const BankSchema = Yup.string().required('input.bankName');
const SurnameSchema = Yup.string().required('input.surname');
const NameAccountSchema = Yup.string().required('accounts.enterAccountName');
const PatronymicSchema = Yup.string().required('input.patronymic');
const NameSchema = Yup.string().required('input.name');
const NameTariffSchema = Yup.string().required('services.writeTariffName');
const LoginSchema = Yup.string().required('input.login');

export const withEmailState = compose(
  withState('email', 'setEmail', undefined),
  withState('emailError', 'setEmailError', undefined),
  withState('emailValid', 'setEmailValid', true),
  withHandlers({
    inputHandlerEmail: ({ setEmail, setEmailError, setEmailValid }) => async value => {
      setEmail(value);
      try {
        await EmailSchema.validate(value);
        setEmailValid(true);
        setEmailError('');
      } catch (error) {
        setEmailError(error.message);
        setEmailValid(false);
      }
    },
  }),
);

export const withCustomerEmailState = compose(
  withState('customerEmail', 'setCustomerEmail', undefined),
  withState('customerEmailError', 'setCustomerEmailError', undefined),
  withState('customerEmailValid', 'setCustomerEmailValid', true),
  withHandlers({
    inputHandlerCustomerEmail: ({
      setCustomerEmail,
      setCustomerEmailError,
      setCustomerEmailValid,
    }) => async value => {
      setCustomerEmail(value);
      try {
        await EmailSchema.validate(value);
        setCustomerEmailValid(true);
        setCustomerEmailError('');
      } catch (error) {
        setCustomerEmailError(error.message);
        setCustomerEmailValid(false);
      }
    },
  }),
);

export const withPhoneState = compose(
  withState('phone', 'setPhone', undefined),
  withState('phoneError', 'setPhoneError', undefined),
  withState('phoneValid', 'setPhoneValid', true),
  withHandlers({
    inputHandlerPhone: ({ setPhone, setPhoneError, setPhoneValid }) => async value => {
      setPhone(value);
      try {
        await PhoneSchema.validate(value);
        setPhoneError('');
        setPhoneValid(true);
      } catch (error) {
        setPhoneError(error.message);
        setPhoneValid(false);
      }
    },
  }),
);

export const withOgrNameState = compose(
  withState('ogrName', 'setOgrName', undefined),
  withState('ogrNameError', 'setOgrNameError', undefined),
  withState('ogrNameValid', 'setOgrNameValid', true),
  withHandlers({
    inputHandlerOgrName: ({ setOgrName, setOgrNameError, setOgrNameValid }) => async value => {
      setOgrName(value);
      try {
        await OgrNameSchema.validate(value);
        setOgrNameError('');
        setOgrNameValid(true);
      } catch (error) {
        setOgrNameError(error.message);
        setOgrNameValid(false);
      }
    },
  }),
);

export const withRsState = compose(
  withState('rs', 'setRs', undefined),
  withState('rsError', 'setRsError', undefined),
  withState('rsValid', 'setRsValid', true),
  withHandlers({
    inputHandlerRs: ({ setRs, setRsError, setRsValid }) => async value => {
      const reg = /\D/g;
      if (value.length > 0 && value.length < 20) {
        setRs(value);
        setRsError('input.need20Digits');
        setRsValid(false);
        if (reg.test(value)) {
          setRsError('input.needOnlyDigits');
          setRsValid(false);
        }
      }
      if (value.length === 0) {
        setRsError('');
        setRsValid(true);
      }
      if (value.length === 20) {
        setRs(value);
        if (reg.test(value)) {
          setRsError('input.needOnlyDigits');
          setRsValid(false);
        } else {
          setRsError('');
          setRsValid(true);
        }
      }
    },
  }),
);

export const withKppState = compose(
  withState('kpp', 'setKpp', undefined),
  withState('kppError', 'setKppError', { code: '0', message: '' }),
  withState('kppValid', 'setKppValid', true),
  withHandlers({
    inputHandlerKpp: ({ setKpp, kppError, setKppError, setKppValid }) => async value => {
      setKpp(value);
      const result = validateKpp(value, kppError);
      setKppError({ code: '', message: kppError.message });
      setKppValid(false);
      if (result === true) {
        setKppError({ code: '', message: '' });
        setKppValid(true);
      }
    },
  }),
);

export const withInnState = compose(
  withState('inn', 'setInn', undefined),
  withState('innError', 'setInnError', { code: '', message: '' }),
  withState('innValid', 'setInnValid', true),
  withHandlers({
    inputHandlerInn: ({ setInn, innError, setInnError, setInnValid }) => value => {
      setInn(value);
      const result = validateInn(value, innError);
      setInnError({ code: '', message: innError.message });
      setInnValid(false);
      if (result === true) {
        setInnError({ code: '', message: '' });
        setInnValid(true);
      }
    },
  }),
);

export const withKsState = compose(
  withState('ks', 'setKs', undefined),
  withState('ksError', 'setKsError', undefined),
  withState('ksValid', 'setKsValid', true),
  withHandlers({
    inputHandlerKs: ({ setKs, setKsError, setKsValid }) => async value => {
      const reg = /\D/g;
      if (value.length < 20) {
        setKs(value);
        setKsError('input.need20Digits');
        setKsValid(false);
        if (reg.test(value)) {
          setKsError('input.needOnlyDigits');
          setKsValid(false);
        }
      }
      if (value.length === 0) {
        setKsError('');
        setKsValid(true);
      }
      if (value.length === 20) {
        setKs(value);
        if (reg.test(value)) {
          setKsError('input.needOnlyDigits');
          setKsValid(false);
        } else {
          setKsError('');
          setKsValid(true);
        }
      }
    },
  }),
);

export const withCurrentPasswordState = compose(
  withState('currentPassword', 'setCurrentPassword', undefined),
  withHandlers({
    inputHandlerCurrentPassword: ({ setCurrentPassword }) => async value => {
      setCurrentPassword(value);
    },
  }),
);

export const withConfirmPasswordState = compose(
  withState('confirmPassword', 'setConfirmPassword', undefined),
  withHandlers({
    inputHandlerConfirmPassword: ({ setConfirmPassword }) => async value => {
      setConfirmPassword(value);
    },
  }),
);

export const withNewPasswordState = compose(
  withState('newPassword', 'setNewPassword', undefined),
  withHandlers({
    inputHandlerNewPassword: ({ setNewPassword }) => async value => {
      setNewPassword(value);
    },
  }),
);

export const withContactPersonState = compose(
  withState('contactPerson', 'setContactPerson', undefined),
  withState('contactPersonError', 'setContactPersonError', undefined),
  withState('contactPersonValid', 'setContactPersonValid', true),
  withHandlers({
    inputHandlerContactPerson: ({
      setContactPerson,
      setContactPersonValid,
      setContactPersonError,
    }) => async value => {
      setContactPerson(value);
      try {
        await ContactPersonSchema.validate(value);
        setContactPersonError('');
        setContactPersonValid(true);
      } catch (error) {
        setContactPersonError(error.message);
        setContactPersonValid(false);
      }
    },
  }),
);

export const withLegalAddressState = compose(
  withState('legalAddress', 'setLegalAddress', undefined),
  withState('legalAddressError', 'setLegalAddressError', undefined),
  withState('legalAddressValid', 'setLegalAddressValid', true),
  withHandlers({
    inputHandlerLegalAddress: ({
      setLegalAddress,
      setLegalAddressValid,
      setLegalAddressError,
    }) => async value => {
      setLegalAddress(value);
      try {
        await LegalAddressSchema.validate(value);
        setLegalAddressError('');
        setLegalAddressValid(true);
      } catch (error) {
        setLegalAddressError(error.message);
        setLegalAddressValid(false);
      }
    },
  }),
);

export const withBankState = compose(
  withState('bank_name', 'setBank', undefined),
  withState('bankError', 'setBankError', undefined),
  withState('bankValid', 'setBankValid', true),
  withHandlers({
    inputHandlerBank: ({ setBank, setBankError, setBankValid }) => async value => {
      setBank(value);
      try {
        await BankSchema.validate(value);
        setBankError('');
        setBankValid(true);
      } catch (error) {
        setBankError(error.message);
        setBankValid(false);
      }
    },
  }),
);

export const withActualRegistrationAddressState = compose(
  withState('actualRegistrationAddress', 'setActualRegistrationAddress', undefined),
  withState('actualRegistrationAddressError', 'setActualRegistrationAddressError', undefined),
  withState('actualRegistrationAddressValid', 'setActualRegistrationAddressValid', true),
  withHandlers({
    inputHandlerActualRegistrationAddress: ({
      setActualRegistrationAddress,
      setActualRegistrationAddressError,
      setActualRegistrationAddressValid,
    }) => async value => {
      setActualRegistrationAddress(value);
      try {
        await ActualRegistrationAddressSchema.validate(value);
        setActualRegistrationAddressError('');
        setActualRegistrationAddressValid(true);
      } catch (error) {
        setActualRegistrationAddressError(error.message);
        setActualRegistrationAddressValid(false);
      }
    },
  }),
);

export const withSurnameState = compose(
  withState('surname', 'setSurname', undefined),
  withState('surnameError', 'setSurnameError', undefined),
  withState('surnameValid', 'setSurnameValid', true),
  withHandlers({
    inputHandlerSurname: ({ setSurname, setSurnameError, setSurnameValid }) => async value => {
      setSurname(value);
      try {
        await SurnameSchema.validate(value);
        setSurnameValid(true);
        setSurnameError('');
      } catch (error) {
        setSurnameValid(false);
        setSurnameError(error.message);
      }
    },
  }),
);

export const withNameState = compose(
  withState('name', 'setName', undefined),
  withState('nameError', 'setNameError', undefined),
  withState('nameValid', 'setNameValid', true),
  withHandlers({
    inputHandlerName: ({ setName, setNameError, setNameValid }) => async value => {
      setName(value);
      try {
        await NameSchema.validate(value);
        setNameValid(true);
        setNameError('');
      } catch (error) {
        setNameValid(false);
        setNameError(error.message);
      }
    },
  }),
);

export const withNameTariffState = compose(
  withState('nameTariff', 'setNameTariff', undefined),
  withState('nameTariffError', 'setNameTariffError', undefined),
  withState('nameTariffValid', 'setNameTariffValid', true),
  withHandlers({
    inputHandlerNameTariff: ({
      setNameTariff,
      setNameTariffError,
      setNameTariffValid,
    }) => async value => {
      setNameTariff(value);
      try {
        await NameTariffSchema.validate(value);
        setNameTariffValid(true);
        setNameTariffError('');
      } catch (error) {
        setNameTariffValid(false);
        setNameTariffError(error.message);
      }
    },
  }),
);

export const withNameAccount = compose(
  withState('nameAccount', 'setNameAccount', undefined),
  withState('nameAccountError', 'setNameAccountError', undefined),
  withState('nameAccountValid', 'setNameAccountValid', true),
  withHandlers({
    inputHandlerNameAccount: ({
      setNameAccount,
      setNameAccountError,
      setNameAccountValid,
    }) => async value => {
      setNameAccount(value);
      try {
        await NameAccountSchema.validate(value);
        setNameAccountValid(true);
        setNameAccountError('');
      } catch (error) {
        setNameAccountValid(false);
        setNameAccountError(error.message);
      }
    },
  }),
);

export const withPatronymicState = compose(
  withState('patronymic', 'setPatronymic', undefined),
  withState('patronymicError', 'setPatronymicError', undefined),
  withState('patronymicValid', 'setPatronymicValid', true),
  withHandlers({
    inputHandlerPatronymic: ({
      setPatronymic,
      setPatronymicError,
      setPatronymicValid,
    }) => async value => {
      setPatronymic(value);
      try {
        await PatronymicSchema.validate(value);
        setPatronymicError('');
        setPatronymicValid(true);
      } catch (error) {
        setPatronymicError(error.message);
        setPatronymicValid(false);
      }
    },
  }),
);

export const withMoneyState = compose(
  withState('money', 'setMoney', undefined),
  withState('moneyError', 'setMoneyError', undefined),
  withState('moneyValid', 'setMoneyValid', true),
  withHandlers({
    inputHandlerMoney: ({ setMoney, setMoneyError, setMoneyValid }) => async value => {
      const reg = /\D/g;
      setMoney(value);
      if (reg.test(value)) {
        setMoneyError('input.needOnlyDigits');
        setMoneyValid(false);
      } else {
        setMoneyError('');
        setMoneyValid(true);
      }
    },
  }),
);

export const withScoresTtlState = compose(
  withState('scoresTtl', 'setScoresTtl', undefined),
  withState('scoresTtlError', 'setScoresTtlError', undefined),
  withState('scoresTtlValid', 'setScoresTtlValid', true),
  withHandlers({
    inputHandlerScoresTtl: ({
      setScoresTtl,
      setScoresTtlError,
      setScoresTtlValid,
    }) => async value => {
      const reg = /\D/g;
      setScoresTtl(value);
      if (reg.test(value)) {
        setScoresTtlError('input.needOnlyDigits');
        setScoresTtlValid(false);
      } else {
        setScoresTtlError('');
        setScoresTtlValid(true);
      }
    },
  }),
);

export const withAgreementNumberState = compose(
  withState('agreementNumber', 'setAgreementNumber', undefined),
  withState('agreementNumberError', 'setAgreementNumberError', undefined),
  withState('agreementNumberValid', 'setAgreementNumberValid', true),
  withHandlers({
    inputHandlerAgreementNumber: ({
      setAgreementNumber,
      setAgreementNumberError,
      setAgreementNumberValid,
    }) => async value => {
      const reg = /\D/g;
      setAgreementNumber(value);
      if (reg.test(value)) {
        setAgreementNumberError('input.needOnlyDigits');
        setAgreementNumberValid(false);
      } else {
        setAgreementNumberError('');
        setAgreementNumberValid(true);
      }
    },
  }),
);

export const withIdTariffState = compose(
  withState('idTariff', 'setIdTariff', undefined),
  withState('idTariffError', 'setIdTariffError', undefined),
  withState('idTariffValid', 'setIdTariffValid', true),
  withHandlers({
    inputHandlerIdTariff: ({ setIdTariff, setIdTariffError, setIdTariffValid }) => async value => {
      const reg = /\D/g;
      setIdTariff(value);
      if (reg.test(value)) {
        setIdTariffError('input.needOnlyDigits');
        setIdTariffValid(false);
      } else {
        setIdTariffError('');
        setIdTariffValid(true);
      }
    },
  }),
);

export const withPromocodeState = compose(
  withState('promocodeValue', 'setPromocodeValue', undefined),
  withState('promocodeValueError', 'setPromocodeValueError', undefined),
  withState('promocodeValueValid', 'setPromocodeValueValid', true),
  withHandlers({
    inputHandlerPromocodeValue: ({
      setPromocodeValue,
      setPromocodeValueError,
      setPromocodeValueValid,
    }) => async value => {
      setPromocodeValue(value);
      if (!value || value.length <= 0) {
        setPromocodeValueError('input.required');
        setPromocodeValueValid(false);
      } else {
        setPromocodeValueError('');
        setPromocodeValueValid(true);
      }
    },
  }),
);

export const withCountTariffState = compose(
  withState('countTariff', 'setCountTariff', undefined),
  withState('countTariffError', 'setCountTariffError', undefined),
  withState('countTariffValid', 'setCountTariffValid', true),
  withHandlers({
    inputHandlerCountTariff: ({
      setCountTariff,
      setCountTariffError,
      setCountTariffValid,
    }) => async value => {
      const reg = /\D/g;
      setCountTariff(value);
      if (reg.test(value)) {
        setCountTariffError('input.needOnlyDigits');
        setCountTariffValid(false);
      } else {
        setCountTariffError('');
        setCountTariffValid(true);
      }
    },
  }),
);

export const withScoresCoastState = compose(
  withState('scoresCoast', 'setScoresCoast', undefined),
  withState('scoresCoastError', 'setScoresCoastError', undefined),
  withState('scoresCoastValid', 'setScoresCoastValid', true),
  withHandlers({
    inputHandlerScoresCoast: ({
      setScoresCoast,
      setScoresCoastError,
      setScoresCoastValid,
    }) => async value => {
      const reg = /\D/g;
      setScoresCoast(value);
      if (reg.test(value)) {
        setScoresCoastError('input.needOnlyDigits');
        setScoresCoastValid(false);
      } else {
        setScoresCoastError('');
        setScoresCoastValid(true);
      }
    },
  }),
);

export const withScoresCountState = compose(
  withState('scoresCount', 'setScoresCount', undefined),
  withState('scoresCountError', 'setScoresCountError', undefined),
  withState('scoresCountValid', 'setScoresCountValid', true),
  withHandlers({
    inputHandlerScoresCount: ({
      setScoresCount,
      setScoresCountError,
      setScoresCountValid,
    }) => async value => {
      const reg = /\D/g;
      setScoresCount(value);
      if (reg.test(value)) {
        setScoresCountError('input.needOnlyDigits');
        setScoresCountValid(false);
      } else {
        setScoresCountError('');
        setScoresCountValid(true);
      }
    },
  }),
);

export const withNotesState = compose(
  withState('notes', 'setNotes', undefined),
  withHandlers({
    inputHandlerNotes: ({ setNotes }) => async event => {
      if (typeof event === 'string') {
        setNotes(event);
      } else {
        setNotes(event.target.value);
      }
    },
  }),
);

export const withLoginState = compose(
  withState('login', 'setLogin', undefined),
  withState('loginError', 'setLoginError', undefined),
  withState('loginValid', 'setLoginValid', true),
  withHandlers({
    inputHandlerLogin: ({ setLogin, setLoginError, setLoginValid }) => async value => {
      setLogin(value);
      try {
        await LoginSchema.validate(value);
        setLoginValid(true);
        setLoginError('');
      } catch (error) {
        setLoginError(error.message);
        setLoginValid(false);
      }
    },
  }),
);

export const withDateState = compose(
  withState('birth_date', 'setDate', undefined),
  withHandlers({
    inputHandlerDate: ({ setDate }) => async value => {
      setDate(value);
    },
  }),
);

export const withDateFromState = compose(
  withState('dateFrom', 'setDateFrom', undefined),
  withHandlers({
    inputHandlerDateFrom: ({ setDateFrom }) => async value => {
      setDateFrom(value);
    },
  }),
);

export const withDateToState = compose(
  withState('dateTo', 'setDateTo', undefined),
  withHandlers({
    inputHandlerDateTo: ({ setDateTo }) => async value => {
      setDateTo(value);
    },
  }),
);

export const withReasonState = compose(
  withState('reason', 'setReason', undefined),
  withHandlers({
    inputHandlerReason: ({ setReason }) => async event => {
      setReason(event.target.value);
    },
  }),
);

export const withActualAddressState = compose(
  withState('actualAddress', 'setActualAddress', undefined),
  withState('actualAddressError', 'setActualAddressError', undefined),
  withState('actualAddressValid', 'setActualAddressValid', true),
  withHandlers({
    inputHandlerActualAddress: ({
      setActualAddress,
      setActualAddressError,
      setActualAddressValid,
    }) => async value => {
      setActualAddress(value);
      try {
        await ActualAddressSchema.validate(value);
        setActualAddressError('');
        setActualAddressValid(true);
      } catch (error) {
        setActualAddressError(error.message);
        setActualAddressValid(false);
      }
    },
  }),
);

export const withBikState = compose(
  withState('bik', 'setBik', undefined),
  withState('bikError', 'setBikError', { code: '0', message: '' }),
  withState('bikValid', 'setBikValid', true),
  withHandlers({
    inputHandlerBik: ({ setBik, bikError, setBikError, setBikValid }) => async value => {
      setBik(value);
      const result = validateBik(value, bikError);
      setBikError({ code: '', message: bikError.message });
      setBikValid(false);
      if (result === true) {
        setBikError({ code: '', message: '' });
        setBikValid(true);
      }
    },
  }),
);

export const withOgrnState = compose(
  withState('ogrn', 'setOgrn', undefined),
  withState('ogrnError', 'setOgrnError', { code: '0', message: '' }),
  withState('ogrnValid', 'setOgrnValid', true),
  withHandlers({
    inputHandlerOgrn: ({ setOgrn, ogrnError, setOgrnError, setOgrnValid }) => async value => {
      setOgrn(value);
      const result = validateOgrn(value, ogrnError);
      setOgrnError({ code: '', message: ogrnError.message });
      setOgrnValid(false);
      if (result === true) {
        setOgrnError({ code: '', message: '' });
        setOgrnValid(true);
      }
    },
  }),
);

/**
 *  NOT USED IGRN and OGRNIP in one component!!!
 * */
export const withOgrnipState = compose(
  withState('ogrnip', 'setOgrnip', undefined),
  withState('ogrnipError', 'setOgrnipError', { code: '0', message: '' }),
  withState('ogrnipValid', 'setOgrnipValid', true),
  withHandlers({
    inputHandlerOgrnip: ({
      setOgrnip,
      ogrnipError,
      setOgrnipError,
      setOgrnipValid,
    }) => async value => {
      setOgrnip(value);
      const result = validateOgrnip(value, ogrnipError);
      setOgrnipError({ code: '', message: ogrnipError.message });
      setOgrnipValid(false);
      if (result === true) {
        setOgrnipError({ code: '', message: '' });
        setOgrnipValid(true);
      }
    },
  }),
);

export const withInputSetStore = compose(
  inject(storeNames.RegistrationStore),
  withHandlers({
    inputSetStore: ({ RegistrationStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      RegistrationStore.setRegistrationStore({ [`${name}`]: value });
    },
  }),
  observer,
);

export const withInputSetServicesStore = compose(
  inject(storeNames.ServicesStore),
  withHandlers({
    inputSetServicesStore: ({ ServicesStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      ServicesStore.setServicesStore({ [`${name}`]: value });
    },
  }),
  observer,
);

export const withInputSetInquiriesStore = compose(
  inject(storeNames.InquiriesStore),
  withHandlers({
    inputSetStore: ({ InquiriesStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      InquiriesStore.setInquiriesStore({ [`${name}`]: value });
    },
  }),
  observer,
);

export const withServicesSetStore = compose(
  inject(storeNames.ServicesStore),
  withHandlers({
    inputSetStore: ({ ServicesStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      ServicesStore.setServicesStore({ [`${name}`]: value });
    },
  }),
  observer,
);

export const withProfileInputSetStore = compose(
  inject(storeNames.ProfileStore),
  withHandlers({
    inputSetStore: ({ ProfileStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      ProfileStore.setProfileStore({ [`${name}`]: value });
    },
  }),
  observer,
);

export const withBalanceInputSetStore = compose(
  inject(storeNames.BalanceStore),
  withHandlers({
    inputSetStore: ({ BalanceStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      BalanceStore.setBalanceStore({ [`${name}`]: value });
    },
  }),
  observer,
);

export const withInputsetInquiriesStore = compose(
  inject(storeNames.CustomerStore),
  withHandlers({
    inputSetStore: ({ CustomerStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      CustomerStore.setInquiriesStore({ [`${name}`]: value });
    },
  }),
  observer,
);
