import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLOR_WHITE, COLOR_ACTION_BLUE } from '../../constants/colors';

export const Btn = styled.button`
  font-weight: 600;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '19px')};
  text-align: center;
  font-family: Lato;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  box-shadow: ${props =>
    props.boxShadow ? props.boxShadow : '0px 4px 10px rgba(16, 156, 241, 0.24)'};
  border-radius: ${props => (props.underline ? '0px' : '4px')};
  padding: ${props => (props.padding ? props.padding : '14px')};
  height: ${props => (props.height ? props.height : 'inherit')};
  cursor: pointer;
  display: block;
  border: ${props =>
    (props.underline && '0px') ||
    (props.disabled && '1px solid #C2CFE0') ||
    (props.border ? props.border : '1px solid #109cf1')};
  border-bottom: ${props =>
    (!props.underline && '') ||
    (props.disabled && '1px solid #C2CFE0') ||
    (props.border ? props.border : '1px solid #109cf1')};
  box-sizing: border-box;
  margin: ${props => (props.sizes && props.sizes.margin ? props.sizes.margin : '0')};
  width: ${props => (props.width ? props.width : '100%')};
  min-width: ${props => (props.minWidth ? props.minWidth : '0px')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
  background-color: ${props =>
    (props.changeByDisable && props.disabled && '#C2CFE0') ||
    (props.backgroundColor ? props.backgroundColor : COLOR_ACTION_BLUE)};
  color: ${props =>
    (props.changeByDisable && props.disabled && '#90A0B7') ||
    (props.textColor ? props.textColor : COLOR_WHITE)};
`;

const Button = ({
  children,
  textColor,
  backgroundColor,
  minWidth,
  type,
  fontSize,
  fontFamily,
  maxWidth,
  width,
  sizes,
  onClick,
  disabled,
  changeByDisable,
  testID,
  boxShadow,
  lineHeight,
  height,
  tabIndex,
  border,
  underline,
  padding,
}) => (
  <Btn
    textColor={textColor}
    backgroundColor={backgroundColor}
    minWidth={minWidth}
    maxWidth={maxWidth}
    fontSize={fontSize}
    boxShadow={boxShadow}
    height={height}
    sizes={sizes}
    border={border}
    padding={padding}
    underline={underline}
    type={type}
    width={width}
    lineHeight={lineHeight}
    onClick={onClick}
    disabled={disabled}
    tabIndex={tabIndex}
    fontFamily={fontFamily}
    changeByDisable={changeByDisable}
    data-test-id={testID}
  >
    {children}
  </Btn>
);

Button.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.string,
  border: PropTypes.string,
  underline: PropTypes.bool,
  padding: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  fontSize: PropTypes.string,
  boxShadow: PropTypes.string,
  fontFamily: PropTypes.string,
  lineHeight: PropTypes.string,
  tabIndex: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  changeByDisable: PropTypes.bool,
  sizes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  testID: undefined,
  type: undefined,
  textColor: undefined,
  border: undefined,
  underline: false,
  padding: undefined,
  backgroundColor: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  fontSize: undefined,
  boxShadow: undefined,
  tabIndex: '',
  fontFamily: undefined,
  lineHeight: undefined,
  height: undefined,
  width: undefined,
  sizes: undefined,
  disabled: false,
  changeByDisable: false,
  onClick: () => {},
};

export default Button;
