import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { isAuthenticated } from '../../services/session';
import { withUserRoles, withUserTypes } from '../../utils/hocs';
import { getAccessStatus } from '../../helpers/roles';
import { getAccessStatusByUserTypes } from '../../helpers/userTypes';

const RoleRenderComponent = ({
  allowedRoles,
  allowedTypes,
  userType,
  userRoles,
  checkType,
  children,
}) => {
  if (isAuthenticated() && allowedRoles && userRoles) {
    const hasRole = getAccessStatus({ allowedRoles, userRoles });
    const hasAccess = getAccessStatusByUserTypes({ allowedTypes, userType });
    if (hasRole && !checkType) {
      return <>{children}</>;
    }

    if (checkType && hasRole && hasAccess) {
      return <>{children}</>;
    }
  }
  return <></>;
};

RoleRenderComponent.propTypes = {
  allowedRoles: PropTypes.array,
  allowedTypes: PropTypes.array,
  userType: PropTypes.string,
  userRoles: PropTypes.array,
  checkType: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
};

RoleRenderComponent.defaultProps = {
  allowedRoles: ['allowAll'],
  allowedTypes: ['allowAll'],
  checkType: false,
  userRoles: undefined,
  userType: undefined,
  children: undefined,
};

/**
 * Wait allowedRoles array with rolesname or something content and wait userRoles, default allowAll value
 */
export const RoleRender = compose(
  withUserRoles,
  withUserTypes,
)(RoleRenderComponent);
