import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ICON_PDF_FILE } from '../../../constants/icons';
import { TableButton } from '../Table/TableStyled';

const ExtraDiscretionReport = ({ item }) => {
  const { t } = useTranslation();
  const { discretion } = item;

  if (!discretion) return null;

  const { allow_pdf_download, pdf_url } = discretion;

  if (!allow_pdf_download) return null;

  const extraDiscretionReportHandler = () => {
    window.open(`${pdf_url}`, '_blank');
  };

  return (
    <TableButton onClick={extraDiscretionReportHandler} icon={ICON_PDF_FILE}>
      {t('inquiries.reportDO')}
    </TableButton>
  );
};

ExtraDiscretionReport.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ExtraDiscretionReport;
