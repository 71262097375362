import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { fabric } from 'fabric';
import logger from '../../../logger';
import { useStyles } from './Styles/styles';

const drawQuadrant = (scaleX, scaleY, scaleWidth, scaleHeight, data) => {
  const color = '#ddd';
  const bgColor = '#fbfbfb';
  const textColor = '#888';

  const rules = {
    A: '#73d94f',
    B: '#00bef6',
    C: '#fec100',
    D: '#fe0000',
  };

  const canva = new fabric.StaticCanvas('quadrant');
  canva.setDimensions({
    width: Math.floor(scaleWidth * scaleX),
    height: Math.floor(scaleHeight * scaleY),
  });
  canva.setBackgroundColor(bgColor);

  const pointXarray = [
    { tres: 20, point: 15, n: 0 },
    { tres: 50, point: 45, n: 0 },
    { tres: 70, point: 70, n: 1 },
    { tres: 90, point: 90, n: 1 },
    { tres: 100, point: 110, n: 1 },
  ];
  const pointYarray = [
    { cat: 'CA1', point: 10, n: 0 },
    { cat: 'CA2', point: 30, n: 0 },
    { cat: 'CA3', point: 50, n: 0 },
    { cat: 'CA4', point: 75, n: 2 },
    { cat: 'CA5', point: 105, n: 2 },
  ];

  let pointX = 0;
  let pointY = 0;
  let rectId = 0;
  logger.debug('Quadrant>', /* JSON.stringify(data) */ scaleY, scaleHeight);
  if (data && 'bankruptcy' in data && data.bankruptcy) {
    const { bankruptcy, category } = data;
    if (bankruptcy || category) {
      const pointObjX = pointXarray.find(item => bankruptcy < item.tres);
      const pointObjY = pointYarray.find(item => category === item.cat);
      pointX = 30 + pointObjX.point;
      pointY = 20 + pointObjY.point;
      rectId = pointObjX.n + pointObjY.n;
    }
  }

  const shapes = {
    lineV: { type: 'line', values: [0, 0, 0, 20] },
    lineH: { type: 'line', values: [0, 0, 30, 0] },
    rect: { type: 'rect', values: [60, 60] },
    labelX: { type: 'text', values: [9, 'center', 'Arial'] },
    labelY: { type: 'text', values: [12, 'center', 'Arial'] },
    zoneLabel: {
      type: 'text',
      values: [115, 'bottom', 'Arial', 700],
      color: '#aaa',
      opacity: 0.6,
      shiftY: 25,
    },
    msg: { type: 'text', values: [32, 'center', 'Arial'], opacity: 0.4 },
    target: { type: 'circle', values: [11], color: 'orange' },
  };

  const positions =
    'bankruptcy' in data && data.bankruptcy
      ? [
          { name: 'lineV', pos: [30, 0] },
          { name: 'lineV', pos: [60, 0] },
          { name: 'lineV', pos: [90, 0] },
          { name: 'lineV', pos: [120, 0] },
          { name: 'lineH', pos: [0, 20] },
          { name: 'lineH', pos: [0, 40] },
          { name: 'lineH', pos: [0, 60] },
          { name: 'lineH', pos: [0, 80] },
          { name: 'lineH', pos: [0, 110] },
          { name: 'rect', pos: [30, 20], color: rectId === 0 ? rules.A : null },
          { name: 'rect', pos: [90, 20], color: rectId === 1 ? rules.B : null },
          { name: 'rect', pos: [30, 80], color: rectId === 2 ? rules.C : null },
          { name: 'rect', pos: [90, 80], color: rectId === 3 ? rules.D : null },
          { name: 'labelX', pos: [45, 10], text: '< 25%' },
          { name: 'labelX', pos: [75, 10], text: '> 25% & < 50%' },
          { name: 'labelX', pos: [105, 10], text: '> 50% & < 75%' },
          { name: 'labelX', pos: [135, 10], text: '> 75% & < 100%' },
          { name: 'labelY', pos: [15, 30], text: 'Категория 1' },
          { name: 'labelY', pos: [15, 50], text: 'Категория 2' },
          { name: 'labelY', pos: [15, 70], text: 'Категория 3' },
          { name: 'labelY', pos: [15, 95], text: 'Категория 4' },
          { name: 'labelY', pos: [15, 125], text: 'Категория 5' },
          { name: 'zoneLabel', pos: [31, 80], text: 'A', color: rectId === 0 ? 'white' : null },
          { name: 'zoneLabel', pos: [89, 80], text: 'B', color: rectId === 1 ? 'white' : null },
          { name: 'zoneLabel', pos: [30, 139.5], text: 'C', color: rectId === 2 ? 'white' : null },
          { name: 'zoneLabel', pos: [89, 139.5], text: 'D', color: rectId === 3 ? 'white' : null },
          { name: 'target', pos: [pointX, pointY] },
        ]
      : [{ name: 'msg', pos: [75, 70], text: 'Ожидание данных' /* 'Скоринг не завершен' */ }];

  // eslint-disable-next-line
  for (const item of positions) {
    const shape = shapes[item.name];
    if (shape.type === 'line') {
      const line = new fabric.Line(shape.values, {
        left: Math.floor(item.pos[0] * scaleX),
        top: Math.floor(item.pos[1] * scaleY),
        strokeWidth: 1 / scaleY,
        stroke:
          // eslint-disable-next-line no-nested-ternary
          'color' in item && item.color ? item.color : 'color' in shape ? shape.color : color,
        scaleX,
        scaleY,
      });
      canva.add(line);
    }
    if (shape.type === 'rect') {
      const rect = new fabric.Rect({
        left: Math.floor(item.pos[0] * scaleX),
        top: Math.floor(item.pos[1] * scaleY),
        width: shape.values[0],
        height: shape.values[1],
        strokeWidth: 1 / scaleY,
        stroke: bgColor,
        // eslint-disable-next-line no-nested-ternary
        fill: 'color' in item && item.color ? item.color : 'color' in shape ? shape.color : color,
        scaleX,
        scaleY,
      });
      canva.add(rect);
    }
    if (shape.type === 'circle') {
      const circle = new fabric.Circle({
        left: Math.floor(item.pos[0] * scaleX - shape.values[0]),
        top: Math.floor(item.pos[1] * scaleY - shape.values[0]),
        radius: shape.values[0],
        strokeWidth: 2, // 1 / scaleY,
        stroke: bgColor,
        // eslint-disable-next-line no-nested-ternary
        fill: 'color' in item && item.color ? item.color : 'color' in shape ? shape.color : color,
        /* scaleX,
        scaleY, */
      });
      canva.add(circle);
    }
    if (shape.type === 'text') {
      const text = new fabric.Text(item.text, {
        left: Math.floor(item.pos[0] * scaleX),
        top: Math.floor(item.pos[1] * scaleY),
        fontSize: shape.values.length > 0 ? shape.values[0] : '',
        fontFamily: shape.values.length > 2 ? shape.values[2] : '',
        fontWeight: shape.values.length > 3 ? shape.values[3] : '',
        fill:
          // eslint-disable-next-line no-nested-ternary
          'color' in item && item.color ? item.color : 'color' in shape ? shape.color : textColor,
        opacity: 'opacity' in shape ? shape.opacity : 1,
        /* scaleX,
        scaleY, */
      });
      if (shape.values.length > 1 && shape.values[1] === 'center') {
        text.set({
          left: Math.floor(text.left - text.width / 2),
          top: Math.floor(text.top - text.height / 2),
        });
      }
      if (shape.values.length > 1 && shape.values[1] === 'bottom') {
        text.set({ top: text.top - text.height + ('shiftY' in shape ? shape.shiftY : 0) });
      }
      canva.add(text);
    }
  }
};

const QuadrantCard = ({ height, data }) => {
  const classes = useStyles();
  // const [oldSize, setOldSize] = React.useState({ width: 0, height: 0 });
  const [scale, setScale] = React.useState({ x: 1, y: 1 });
  // const canvas = React.useRef(null);
  const scaleWidth = 150;
  const scaleHeight = 140;

  const quadrantCardElement = document.querySelector('#quadrant_card');
  const width = quadrantCardElement !== null ? quadrantCardElement.clientWidth : 0;

  logger.debug('render quadrant card');

  /* const resize = () => {
    const quadrantCardElement = document.querySelector('#quadrant_card');
    const width = quadrantCardElement !== null ? quadrantCardElement.clientWidth : 0;

    if (width !== oldSize.width || height !== oldSize.height) {
      logger.debug('resized:', width, height);
      // canvas.current.width = width;
      // canvas.current.height = height;
      // logger.debug('canvas.current.clientWidth', canvas.current.width);
      setScale({ x: width / scaleWidth, y: height / scaleHeight });
      setOldSize({ width, height });
    }
  }; */

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      // setScale({ x: scale.x + 1, y: scale.y });
      const quadrantCardElement2 = document.querySelector('#quadrant_card');
      const width2 = quadrantCardElement2 !== null ? quadrantCardElement2.clientWidth : 0;
      const height2 = quadrantCardElement2 !== null ? quadrantCardElement2.clientHeight : 0;
      logger.debug('resized:', width2, height2);
      setScale({ x: width2 / scaleWidth, y: height2 / scaleHeight });
    });
  }, []);

  React.useEffect(() => {
    // if (width !== oldSize.width || height !== oldSize.height) {
    logger.debug('resized2:', width, height);
    setScale({ x: width / scaleWidth, y: height / scaleHeight });
    // setOldSize({ width, height });
    // }
  }, [/* oldSize.width, oldSize.height, */ width, height]);

  React.useEffect(() => drawQuadrant(scale.x, scale.y, scaleWidth, scaleHeight, data), [
    scale,
    data,
  ]);

  return (
    <Card className={classes.root} variant="outlined" style={{ height: `${height}px` }}>
      <CardContent id="quadrant_card" style={{ padding: 0 }}>
        <canvas id="quadrant" />
      </CardContent>
    </Card>
  );
};
QuadrantCard.propTypes = {
  height: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
};

export default QuadrantCard;
