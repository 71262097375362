import {
  ALLOW_ALL_ROLE,
  SUPER_ADMIN_ROLE,
  CUSTOMER_ACCOUNTANT,
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_USER,
} from '../constants/roles';

/**
 * Get status bby allowed and user roles
 * @param {Object} param0
 * @param {Array} param0.userRoles - user roles, array of string
 * @param {Array} param0.allowedRoles - allowed roles, array of string
 */
export const getAccessStatus = ({ userRoles, allowedRoles }) => {
  if (!userRoles) return false;
  if (allowedRoles.includes(ALLOW_ALL_ROLE)) return true;
  const status = userRoles.reduce((acc, role) => {
    if (allowedRoles.includes(role)) {
      return true;
    }
    return acc;
  }, false);
  return status;
};

export const isAdmin = ({ userRoles }) => {
  if (!userRoles) return false;
  return userRoles.includes(SUPER_ADMIN_ROLE);
};

export const isUser = ({ userRoles }) => {
  if (!userRoles) return false;
  if (
    userRoles.includes(SUPER_ADMIN_ROLE) ||
    userRoles.includes(CUSTOMER_ADMIN_ROLE) ||
    userRoles.includes(CUSTOMER_ACCOUNTANT)
  ) {
    return false;
  }
  return true;
};

export const isOnlyCustomerAdmin = ({ userRoles }) => {
  if (!userRoles) return false;
  if (
    userRoles.includes(CUSTOMER_ADMIN_ROLE) &&
    !(
      userRoles.includes(SUPER_ADMIN_ROLE) ||
      userRoles.includes(CUSTOMER_ACCOUNTANT) ||
      userRoles.includes(SUPER_ADMIN_ROLE) ||
      userRoles.includes(CUSTOMER_USER)
    )
  ) {
    return true;
  }
  return false;
};

export const isCustomerAdmin = ({ userRoles }) => {
  if (!userRoles) return false;
  if (userRoles.includes(CUSTOMER_ADMIN_ROLE)) {
    return true;
  }
  return false;
};

export const isUserRole = ({ userRoles }) => {
  if (!userRoles) return false;
  if (userRoles.includes(CUSTOMER_USER)) {
    return true;
  }
  return false;
};

export const getRoleName = role => {
  switch (role) {
    case SUPER_ADMIN_ROLE:
      return 'Суперадмин';
    case CUSTOMER_ACCOUNTANT:
      return 'Бухгалтер';
    case CUSTOMER_ADMIN_ROLE:
      return 'Администратор';
    default:
      return '';
  }
};
