import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, lifecycle, withProps } from 'recompose';
import { observer, inject } from 'mobx-react';
import * as moment from 'moment';
import Badge from '@material-ui/core/Badge';
import {
  Wrapper,
  TableContainer,
  Tr,
  TrTop,
  Th,
  Td,
  ActivityTd,
  Img,
  Thead,
  Tbody,
  //  TdButton,
  //  ButtonEdit,
  //  CheckBoxWrapper,
  TableButton,
  //  Text,
} from './TableStyled';
import storeNames from '../../../stores/storeNames';
import {
  //  ICON_ACTIVITY,
  ICON_SUCCEEDED,
  // ICON_TABLE_RECTANGLE,
  //  ICON_SEARCH,
} from '../../../constants/icons';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.3),
    },
  },
}));

const Table = ({ questionaries, setFormId, toggleEditQuestionaryPopup, toggleAccessPopup }) => {
  const items = questionaries;
  const classes = useStyles();
  return (
    <Wrapper>
      <TableContainer>
        <Thead>
          <TrTop>
            <Th
              width="40%"
              onClick={() => {
                /* (items.length > 1 ? addFilter('full_name') : null) */
              }}
            >
              Название анкеты
            </Th>
            <Th
              width="220px"
              onClick={() => {
                /* (items.length > 1 ? addFilter('created_at') : null) */
              }}
            >
              Дата изменения
            </Th>
            <Th width="120px">Публикация </Th>
            {/* <Th width="150px">Валидность до</Th> */}
            <Th width="10%" />
            <Th width="10%" />
          </TrTop>
        </Thead>
        <Tbody>
          {items &&
            items.map((item, index) => {
              const relevance = moment().diff(item.publishTime, 'days') > item.relevancePeriod;
              return (
                <Tr key={item.id}>
                  <Td
                    pointer
                    onClick={() => {
                      setFormId(item.id, item.hasPublic && item.hasDraft);
                      toggleEditQuestionaryPopup();
                    }}
                  >
                    {index + 1} - {item.title}
                  </Td>
                  <Td>
                    {item.changeTime === null
                      ? ''
                      : `${moment(item.changeTime).format('DD.MM.YYYY - H:mm:ss')}`}
                  </Td>
                  {/* <Td>
                      {item.from_time && item.to_time && (
                        <>
                          {moment(item.from_time).format('DD.MM.YYYY')} &#8212;{' '}
                          {moment(item.to_time).format('DD.MM.YYYY')}
                        </>
                      )}
                      {!item.from_time && item.to_time && <>&#8212; {item.to_time}</>}
                      {!item.to_time && item.from_time && <>{item.from_time} &#8212;</>}
                      {!item.to_time && !item.from_time && <>&#8212;</>}
                      </Td> */}
                  {/* <Td>{item.subscribed ? 'Да' : 'Нет'}</Td> */}
                  <ActivityTd
                    className={item.hasPublic ? 'hint' : ''}
                    hint={
                      relevance
                        ? `Прошло более ${item.relevancePeriod} дней со дня публикации`
                        : 'Опубликована успешно'
                    }
                  >
                    {item.hasPublic && (
                      <Img
                        style={{
                          filter: relevance ? 'hue-rotate(270deg) saturate(2) opacity(0.8)' : '',
                        }}
                        src={ICON_SUCCEEDED}
                      />
                    )}
                  </ActivityTd>
                  {/* <Td style={{ color: `${item.isValid ? '' : 'red'}` }}>
                    {item.isValid
                      ? `${moment(item.validTo).format('DD.MM.YYYY - H:mm:ss')}`
                      : 'Просрочен'}
                    </Td> */}
                  {/* <Td>{item.money ? item.money.toLocaleString('ru-Ru') : 0}</Td> */}
                  <Td width="60px">
                    {item.hasDraft && (
                      <TableButton
                        data-test-id="TEST_BUTTON_EDIT"
                        onClick={() => {
                          // setItemId(item.id);
                          setFormId(item.id, false);
                          toggleEditQuestionaryPopup();
                          /* setItemId(item.id);
                            history.push('/balance'); */
                        }}
                      >
                        Черновик
                      </TableButton>
                    )}
                  </Td>

                  {/* <TableButton data-test-id="TEST_BUTTON_EDIT" onClick={() => {}}>
                    Просмотр публикации
                  </TableButton> */}

                  <Td className={classes.root}>
                    <Badge color="secondary" badgeContent={item.accessRequestCount}>
                      <TableButton
                        style={{ paddingRight: '15px' }}
                        data-test-id="TEST_BUTTON_EDIT"
                        onClick={() => {
                          setFormId(item.id);
                          // setFormTitle(item.title);
                          toggleAccessPopup();
                        }}
                      >
                        Доступ
                      </TableButton>
                    </Badge>
                  </Td>
                  {/* <Td width="60px">
                      <TableButton
                        data-test-id="TEST_BUTTON_INQUIRIES"
                        onClick={() => {
                          setItemId(item.id);
                          history.push('/services');
                        }}
                      >
                        Услуги
                      </TableButton>
                    </Td> */}
                  {/* (userType === CUSTOMER_LEGAL_ENTITY && isCustomerAdmin && (
                      <>
                        <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE]}>
                          <TdButton>
                            <ButtonEdit
                              data-test-id="TEST_BUTTON_EDIT"
                              onClick={() => {
                                setItemId(item.id);
                                toggleEditSharedInfoPopup();
                              }}
                            >
                              edit
                            </ButtonEdit>
                          </TdButton>
                        </RoleRender>
                        <Td width="130px">
                          <CheckboxTable checked={!item.blocked} onChange={() => deleteAccount(item)}>
                            {!item.blocked ? 'Активный' : 'Неактивный'}
                          </CheckboxTable>
                        </Td>
                      </>
                    )) ||
                            null */}
                </Tr>
              );
            })}
        </Tbody>
      </TableContainer>
      {/* </InfiniteScroll> */}
    </Wrapper>
  );
};

Table.propTypes = {
  questionaries: PropTypes.any,
  setFormId: PropTypes.func.isRequired,
  // setFormTitle: PropTypes.func.isRequired,
  toggleEditQuestionaryPopup: PropTypes.func.isRequired,
  toggleAccessPopup: PropTypes.func.isRequired,
  /* accounts: PropTypes.any,
  confirmed: PropTypes.bool.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  toggleEditSharedInfoPopup: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  setItemId: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool,
  isCustomerAdmin: PropTypes.bool.isRequired,
  fetchAccounts: PropTypes.func,
  addFilter: PropTypes.func,
  deleteAccount: PropTypes.func,
  account: PropTypes.any, */
};

Table.defaultProps = {
  questionaries: undefined,
  // setFormId: () => {},
  /* accounts: undefined,
  account: undefined,
  hasMoreItems: true,
  fetchAccounts: () => {},
  deleteAccount: () => {},
  addFilter: () => {}, */
};

const enhance = compose(
  inject(storeNames.SharedInfoStore), // storeNames.ProfileStore),
  observer,
  // withFetchUserInDidMount,
  // withUserData,
  // withState('init', 'setInit', false),
  // withState('load', 'setLoad', false),
  // withState('oneOffset', 'setOneOffset', 0),
  withProps(({ SharedInfoStore }) => ({
    questionaries: SharedInfoStore.questionaries,
    setFormId: SharedInfoStore.setFormId,
    // setFormTitle: SharedInfoStore.setFormTitle,
  })),
  /* withHandlers(({ blockAccount, fetchScrollMyAccounts }) => ({
    addFilter: ({ addSortAndOrder, offset, setOffset, setLoad }) => async value => {
      addSortAndOrder(value);
      setLoad(false);
      const qnt = offset >= 15 ? offset : 15;
      const accounts = await fetchScrollMyAccounts({ qnt, offset: 0 });
      setOffset(accounts.length);
      setLoad(true);
    },
    fetchAccounts: ({ setOffset, offset, oneOffset, init }) => async () => {
      if (!init || oneOffset) return;
      const accounts = await fetchScrollMyAccounts({ offset });
      setOffset(accounts.length);
    },
    deleteAccount: ({ setOffset, offset, setLoad }) => async item => {
      setLoad(false);
      await blockAccount(item.id, !item.blocked);
      const qnt = offset >= 15 ? offset : 15;
      const accounts = await fetchScrollMyAccounts({ qnt, offset: 0 });
      setOffset(accounts.length);
      setLoad(true);
    },
  })), */
  lifecycle({
    async componentDidMount() {
      const {
        SharedInfoStore /* , offset, setOffset, ProfileStore, setOneOffset, setInit */,
      } = this.props;
      await SharedInfoStore.fetchQuestionaries();
      /* const { fetchScrollMyAccounts, fetchMyAccount } = AccountStore;
      const user = await ProfileStore.fetchUser();
      const userRoles = (user && user.roles && user.roles.map(item => item.role_name)) || undefined;

      const hasUser = !getAccessStatus({
        userRoles,
        allowedRoles: [CUSTOMER_ADMIN_ROLE, SUPER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT],
      });

      if ((user.customer_id === 0 && hasUser) || hasUser) {
        if (user.account_id !== null) {
          await fetchMyAccount(user.account_id);
          setOneOffset(1);
        }
      } else {
        const accounts = await fetchScrollMyAccounts({ qnt: 15, offset });
        setOffset(accounts.length);
      } */
      // setInit(true);
    },
    componentWillUnmount() {
      /* const { AccountStore } = this.props;
      AccountStore.addSortAndOrder('');
      AccountStore.deleteOrder(); */
      const { SharedInfoStore } = this.props;
      SharedInfoStore.clearQuestionaries();
    },
  }),
);

export default enhance(Table);
