import { action, decorate, observable } from 'mobx';

export default class Modals {
  modals = [];

  addModal(Component, context) {
    this.modals = [...this.modals, { Component, context }];
  }

  removeModal() {
    this.modals.splice(-1);
    this.modals = [...this.modals];
  }
}

decorate(Modals, {
  modals: observable,
  addModal: action.bound,
  removeModal: action.bound,
});
