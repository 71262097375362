import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose, withHandlers } from 'recompose';
import { useMedia } from 'use-media';
import Popup from '../../../components/Popup/Popup';
import storeNames from '../../../stores/storeNames';
import { ButtonWrapperCreate, FormWrapper, InputWrapperCreate, Wrapper } from './PopupsStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import Paragraph from '../../../components/Typography/Paragraph';
import InputField from '../../../components/Inputs/InputField';
import { hasValue } from '../../../utils/validators';
import { COLOR_ACTION_BLUE, COLOR_LINK_BLACK } from '../../../constants/colors';
import { withInputSetStore, withPromocodeState } from '../../../utils/FormHocs';
import Button from '../../../components/Buttons/Button';

const ReplenishmentAgreementPopup = ({
  toggleOpenPromoPopup,
  formHandler,
  inputSetStore,
  promocodeValue,
  promocodeValueError,
  promocodeValueValid,
  inputHandlerPromocodeValue,
}) => {
  const { t } = useTranslation();
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Popup width="600px" closePopup={toggleOpenPromoPopup}>
      <Wrapper>
        <Paragraph
          textColor={COLOR_LINK_BLACK}
          fontSize={mobileMedia ? '24px' : '32px'}
          lineHeight="42px"
          fontWeight="300"
          textAlign="center"
          margin="0 0 20px 0"
        >
          {t('services.promoTitle')}
        </Paragraph>
        <FormWrapper onSubmit={formHandler}>
          <InputWrapperCreate>
            <InputField
              placeholder={t('services.promoCode')}
              value={promocodeValue}
              name="promocodeValue"
              isValid={promocodeValueValid}
              onChange={value => {
                inputSetStore(value, inputHandlerPromocodeValue, 'promocodeValue');
              }}
            />
            <ParagraphError>{t(promocodeValueError)}</ParagraphError>
          </InputWrapperCreate>
          <ButtonWrapperCreate>
            <Button width="303px" type="submit" sizes={{ margin: '0 20px' }}>
              {t('form.activate')}
            </Button>
            <Button
              width="303px"
              type="submit"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleOpenPromoPopup()}
            >
              {t('form.cancel')}
            </Button>
          </ButtonWrapperCreate>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

ReplenishmentAgreementPopup.propTypes = {
  toggleOpenPromoPopup: PropTypes.func.isRequired,
  formHandler: PropTypes.func,
  inputSetStore: PropTypes.func,
  promocodeValue: PropTypes.string,
  promocodeValueValid: PropTypes.bool,
  inputHandlerPromocodeValue: PropTypes.func,
  promocodeValueError: PropTypes.string,
};

ReplenishmentAgreementPopup.defaultProps = {
  formHandler: () => {},
  inputSetStore: () => {},
  promocodeValue: '',
  promocodeValueValid: false,
  inputHandlerPromocodeValue: () => {},
  promocodeValueError: '',
};

const enhancer = compose(
  inject(storeNames.ServicesStore),
  observer,
  withTranslation(),
  withInputSetStore,
  withPromocodeState,
  withHandlers(() => ({
    formHandler: ({
      ServicesStore,
      toggleOpenPromoPopup,
      promocodeValue,
      setPromocodeValueError,
      setPromocodeValueValid,
      promocodeValueValid,
      t,
    }) => async e => {
      // const { t } = useTranslation();
      e.preventDefault();

      if (!promocodeValue) {
        setPromocodeValueError(t('input.required'));
        setPromocodeValueValid(false);
      } else {
        setPromocodeValueValid(true);
        setPromocodeValueError('');
      }

      if (hasValue(promocodeValue) && promocodeValueValid) {
        await ServicesStore.activatePromo(promocodeValue);
        if (!ServicesStore.errors) {
          toggleOpenPromoPopup();
        }
      }
    },
  })),
);

export default enhancer(ReplenishmentAgreementPopup);
