import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from './Input';
import { COLOR_RED } from '../../constants/colors';

const Field = styled.div(({ margin, width }) => ({
  margin: margin || '12px 0 0 0',
  width: width || '100%',
}));

export const ErrorText = styled.p`
  font-size: 12px;
  line-height: 1;
  margin: 8px 0 0;
  color: ${COLOR_RED};
`;

const InputField = ({
  backgroundColor,
  placeholder,
  margin,
  onChange,
  isValid,
  value,
  errorMessage,
  onKeyDown,
  name,
  type,
  width,
  tabIndex,
  disabled,
  isRequired,
  textarea,
}) => (
  <Field margin={margin}>
    <Input
      tabIndex={tabIndex}
      placeholder={placeholder}
      backgroundColor={backgroundColor}
      value={value}
      type={type}
      name={name}
      isValid={isValid}
      onChange={onChange}
      onKeyDown={onKeyDown}
      disabled={disabled}
      width={width}
      isRequired={isRequired}
      textarea={textarea}
    />
    <ErrorText>{errorMessage}</ErrorText>
  </Field>
);

InputField.propTypes = {
  backgroundColor: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabIndex: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  textarea: PropTypes.bool,
};

InputField.defaultProps = {
  value: '',
  name: '',
  tabIndex: '',
  width: '',
  margin: undefined,
  errorMessage: '',
  backgroundColor: undefined,
  placeholder: undefined,
  isRequired: false,
  isValid: true,
  disabled: false,
  onChange: () => {},
  onKeyDown: () => {},
  type: 'text',
  textarea: false,
};

export default InputField;
