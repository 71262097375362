import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withProps, lifecycle } from 'recompose';

// import YearIntervalSelector from './YearIntervalSelector';
import ButtonGroupSwitch from './ButtonGroupSwitch';
import LegalCaseTable from './LegalCaseTable';

import storeNames from '../../../stores/storeNames';

const LegalCases = ({
  // loader,
  stateSelector,
  setStateSelector,
  legalCases,
  legalCasesSummary,
  showOpponents,
  // yearList,
  // yearFrom,
  // yearTo,
  // setYearFrom,
  // setYearTo,
}) => {
  return (
    <>
      {'loaded' in legalCasesSummary && (
        <ButtonGroupSwitch
          array={[
            {
              label: 'Иски о несостоятельности',
              value: 'bankruptcy',
              mark: legalCases && legalCases.bankruptcy && legalCases.bankruptcy.details.length,
            },
            {
              label: `Судебные дела, где компания ответчик ${
                'defendantQuantity' in legalCasesSummary
                  ? `(${legalCasesSummary.defendantQuantity})`
                  : ''
              }`,
              value: 'defendant',
            },
            {
              label: `Судебные дела, где компания истец ${
                'plaintiffQuantity' in legalCasesSummary
                  ? `(${legalCasesSummary.plaintiffQuantity})`
                  : ''
              }`,
              value: 'plaintiff',
            },
          ]}
          value={stateSelector}
          setValue={setStateSelector}
        />
      )}
      <LegalCaseTable
        loaded={'loaded' in legalCases}
        title={null /* stateSelector */}
        empty={
          stateSelector === 'bankruptcy'
            ? 'Открытое дело о банкротстве в качестве должника - отсутствует'
            : null
        }
        legalCases={legalCases[stateSelector]}
        legalCasesSummary={legalCasesSummary[stateSelector]}
        showOpponents={showOpponents}
        titleColor={
          stateSelector === 'bankruptcy' &&
          'bankruptcy' in legalCases &&
          legalCases.bankruptcy.years.length !== 0
            ? '#f00'
            : ''
        }
      />
    </>
  );
};
LegalCases.propTypes = {
  // loader: PropTypes.func.isRequired,
  legalCases: PropTypes.object.isRequired,
  legalCasesSummary: PropTypes.object.isRequired,
  stateSelector: PropTypes.string.isRequired,
  setStateSelector: PropTypes.func.isRequired,
  showOpponents: PropTypes.func.isRequired,
  // yearList: PropTypes.array.isRequired,
  // yearFrom: PropTypes.number.isRequired,
  // yearTo: PropTypes.number.isRequired,
  // setYearFrom: PropTypes.func.isRequired,
  // setYearTo: PropTypes.func.isRequired,
};
LegalCases.defaultProps = {};

const enhance = compose(
  inject(storeNames.DashboardStore),
  inject(storeNames.DialogStore),
  observer,
  withProps(({ DashboardStore, DialogStore }) => ({
    loader: DialogStore.loader,
    inquiry: DashboardStore.inquiry,
    legalCases: DashboardStore.legalCases,
    legalCasesSummary: DashboardStore.legalCasesSummary,
    stateSelector: DashboardStore.legalCaseSelector,
    setStateSelector: DashboardStore.setLegalCaseSelector,
    showOpponents: DashboardStore.showOpponents,
    // yearList: DashboardStore.stateContractsYearList,
    // yearFrom: DashboardStore.stateContractsYearFrom,
    // setYearFrom: DashboardStore.stateContractsYearSetFrom,
    // yearTo: DashboardStore.stateContractsYearTo,
    // setYearTo: DashboardStore.stateContractsYearSetTo,
  })),
  lifecycle({}),
);

export default enhance(LegalCases);
