import { observable, decorate } from 'mobx';

export default class DocumentsStore {
  constructor(context) {
    this.context = context;
  }

  acts = [];

  accounts = [];

  setDocumentsActs(state) {
    this.acts = [...this.acts, ...state];
  }

  removeDocumentsActs() {
    this.acts = [];
  }
}

decorate(DocumentsStore, {
  acts: observable,
  accounts: observable,
});
