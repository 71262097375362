import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import api from '../../../api';

const useExtraDiscretionFormHandler = ({
  formValues,
  setErrors,
  id,
  updateInquire,
  removeModal,
}) => {
  const { t } = useTranslation();
  return async event => {
    event.preventDefault();

    try {
      await Yup.object({
        text: Yup.string().required(t('inquiries.writeMessage')),
        emails: Yup.array(Yup.string().email(t('inquiries.writeValidEmail'))).min(
          1,
          t('inquiries.writeSomeValidEmails'),
        ),
      }).validate(formValues);

      setErrors({ emails: '', text: '', server: '' });

      const discretion = await api.discretion.setDiscretion({
        score_id: id,
        invitation_text: formValues.text,
        client_email: formValues.emails.join(';'),
      });

      if (discretion.code === 'VALIDATION_ERROR') {
        // eslint-disable-next-line no-throw-literal
        throw { validationErrors: discretion.errors };
      } else if (discretion.statusCode >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw { serverError: discretion.code };
      }

      updateInquire(id, { discretion });

      removeModal();
    } catch ({ message, path, validationErrors, serverError }) {
      if (validationErrors) {
        validationErrors.forEach(({ property }) => {
          if (property === 'client_email') {
            setErrors(p => ({ ...p, emails: t('inquiries.emailValidationError') }));
          }

          if (property === 'invitation_text') {
            setErrors(p => ({ ...p, text: t('inquiries.messageValidationError') }));
          }
        });
      } else if (serverError) {
        setErrors(p => ({ ...p, server: serverError }));
      } else {
        setErrors({ [path]: message });
      }
    }
  };
};

export default useExtraDiscretionFormHandler;
