import styled from 'styled-components';
import { COLOR_GREEN, COLOR_RED } from '../../../constants/colors';
import { ICON_REPEAT, ICON_FILE } from '../../../constants/icons';

export const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  margin-bottom: 40px;
  align-items: baseline;
  @media (max-width: 960px) {
    justify-content: flex-start;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const WrapperDate = styled.div`
  display: flex;
  margin-left: 25px;
  @media (max-width: 960px) {
    margin-left: 0px;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-family: Lato;
  font-size: 12px;
  line-height: 18px;
  color: #5c6470;
`;

export const DateInputWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 960px) {
    margin-top: 20px;
    margin-bottom: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  z-index: 1;
  position: relative;
  @media (max-width: 960px) {
    margin-left: 0px;
  }
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody``;

export const TrTop = styled.tr`
  border-bottom: 2px solid #dadada;
`;
export const Tr = styled.tr`
  border-bottom: 1px solid #dadada;
`;

export const TableWrapper = styled.div``;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  min-width: 855px;
`;

export const Th = styled.th`
  font-size: 13px;
  line-height: 19px;
  color: #828282;
  text-align: left;
  font-weight: 600;
  font-family: Lato;
  padding-bottom: 11px;
  &.active {
    color: #109cf1;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Td = styled.td`
  height: 57px;
  color: #828282;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  padding-right: 15px;
`;

export const TdReplenishment = styled(Td)`
  color: ${COLOR_GREEN};
  &.pending {
    color: #f2c94c;
  }
  &.canceled {
    color: #90a0b7;
  }
`;

export const TdDebit = styled(Td)`
  color: ${COLOR_RED};
`;

export const TdDescription = styled(Td)`
  color: #333333;
`;

export const TdButtonRepeat = styled.button`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url(${ICON_REPEAT}) no-repeat;
  border: none;
  outline: none;
`;

export const TdButtonReport = styled.button`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url(${ICON_FILE}) no-repeat;
  border: none;
  outline: none;
`;

export const Img = styled.img``;

export const TdButton = styled(Td)`
  max-width: 30px;
  padding-right: 5px;
`;

export const ButtonEdit = styled.button`
  font-size: 0;
  background: url(${ICON_FILE}) no-repeat center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
`;
