import styled from 'styled-components';
import { COLOR_LINK_BLACK } from '../../constants/colors';
import { TitleText } from '../../components/Typography/Title';
import { Paragraph, Main } from '../Login/AuthorizationStyled';
import { tabletMedia, mobileMedia } from '../../constants/styles';

export const Title = styled(TitleText)`
  color: ${COLOR_LINK_BLACK};
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  margin: 0 0 8px;
  @media (max-width: ${mobileMedia}) {
    text-align: center;
    font-size: 24px;
  }
`;

export const Par = styled(Paragraph)`
  font-size: 18px;
  @media (max-width: ${tabletMedia}) {
    font-size: 14px;
    margin-bottom: 0px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const PopupBody = styled(Main)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BottomOffset = styled.div`
  margin: 0 0 20px;
  @media (max-width: ${tabletMedia}) {
    margin: 0 0 40px;
  }
`;

export const BottomOffsetBig = styled.div`
  margin: 0 0 50px;
`;
