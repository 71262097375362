import { getI18n } from 'react-i18next';
import { COMMON_ERRORS } from '../constants/errors';

/**
 * Get Error text
 * @param {String} code - text code of error
 */
export const mapErrorsByCode = (code, errs = COMMON_ERRORS) => {
  const i18n = getI18n();
  if (typeof code === 'object') {
    return i18n.t(
      errs[code[0].constraints.isPhoneNumber] ||
        errs[code[0].constraints.isEmail] ||
        'messages.UNKNOWN_ERROR',
    );
  }
  return i18n.t(errs[code] || 'messages.UNKNOWN_ERROR');
};
