import styled from 'styled-components';
import { COLOR_LINK_BLACK, COLOR_RED, COLOR_ACTION_BLUE } from '../../../constants/colors';
import { tabletSmMedia, mobileMedia } from '../../../constants/styles';
import { ICON_BUTTON_DELETE, ICON_BUTTON_EDIT } from '../../../constants/icons';

export const Wrapper = styled.div``;

export const Item = styled.div`
  max-width: 760px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 32px;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 33px 32px 32px 32px;
  @media (max-width: ${tabletSmMedia}) {
    flex-direction: column;
  }
  @media (max-width: ${mobileMedia}) {
    padding: 16px 24px 22px 24px;
  }
`;

export const Name = styled.div`
  width: 30%;
  font-weight: 600;
  font-size: 18px;
  color: ${COLOR_LINK_BLACK};
  font-family: Lato;
  margin-right: 30px;
  flex-shrink: 0;
  @media (max-width: ${tabletSmMedia}) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ScoringWrapper = styled.div`
  font-family: Lato;
  font-weight: 500;
  width: 32%;
  flex-shrink: 0;
  color: #90a0b7;
  font-size: 18px;
  line-height: 18px;
  @media (max-width: ${tabletSmMedia}) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Scoring = styled.div`
  display: flex;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  @media (max-width: ${mobileMedia}) {
    margin-top: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  @media (max-width: 960px) {
    margin-bottom: 20px;
  }
`;

export const ButtonDelete = styled.button`
  font-size: 0;
  background: url(${ICON_BUTTON_DELETE}) no-repeat center -6px;
  margin-right: 8px;
  width: 48px;
  height: 48px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ButtonEdit = styled.button`
  font-size: 0;
  background: url(${ICON_BUTTON_EDIT}) no-repeat center -6px;
  margin-right: 8px;
  width: 48px;
  height: 48px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ScoringScore = styled.div`
  color: ${COLOR_RED};
  font-size: 40px;
  font-family: Lato;
  line-height: 42px;
  font-weight: 700;
  margin-right: 7px;
`;

export const ScoringRight = styled.div``;

export const ScoringTop = styled.div`
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: ${COLOR_LINK_BLACK};
`;

export const ScoringTime = styled.div`
  font-family: Lato;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #707683;
`;

export const MonitoringWrapper = styled.div`
  font-family: Lato;
  font-weight: 500;
  width: 35%;
  flex-shrink: 0;
  color: #90a0b7;
  font-size: 18px;
  line-height: 18px;
  @media (max-width: ${tabletSmMedia}) {
    width: 100%;
  }
`;

export const Monitoring = styled.div`
  display: flex;
  align-items: center;
`;

export const MonitoringScore = styled.div`
  color: ${COLOR_ACTION_BLUE};
  font-size: 40px;
  font-family: Lato;
  font-weight: 500;
  line-height: 42px;
  margin-right: 8px;
`;

export const MonitoringRight = styled.div``;

export const MonitoringTop = styled.div`
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: ${COLOR_LINK_BLACK};
`;

export const MonitoringTime = styled.div`
  font-family: Lato;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #707683;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #f2f2f2;
  padding: 16px 32px 16px 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  @media (max-width: ${mobileMedia}) {
    justify-content: space-between;
    padding: 16px 24px 16px 24px;
  }
`;

export const BottomAdmins = styled(Bottom)`
  @media (max-width: ${mobileMedia}) {
    flex-direction: column;
    padding: 16px 24px 16px 24px;
  }
`;

export const Price = styled.div`
  margin-right: 60px;
  color: ${COLOR_LINK_BLACK};
  font-family: Lato;
  font-weight: 500;
  font-size: 18px;
`;

export const Span = styled.div`
  display: inline-block;
  font-weight: 700;
  margin-left: 7px;
  @media (max-width: ${mobileMedia}) {
    margin-left: 0px;
  }
`;
