import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import { withBoolState } from '../../utils/hocs';
import { ICON_SELECT_ARROW } from '../../constants/icons';
import { INPUT_TEST_ID } from '../../constants/testids';
import {
  COLOR_LINK_BLACK,
  COLOR_WHITE,
  COLOR_ACTION_BLUE,
  COLOR_GRAY,
} from '../../constants/colors';
import CHECKED_ICON from '../../assets/img/icons/checked.svg';

const SelectWrapper = styled.div`
  width: 100%;
  border: 1px solid ${COLOR_GRAY};
  font-family: Lato;
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px;
  appearance: none;
  margin: ${props => (props.margin ? props.margin : '12px 0 8px 0')};
  position: relative;
  color: #90a0b7;
  cursor: pointer;
  &.open {
    color: ${COLOR_LINK_BLACK};
  }
`;

const Wrapper = styled.div`
  &::after {
    position: absolute;
    width: 16px;
    height: 16px;
    pointer-events: none;
    content: '';
    background: url(${ICON_SELECT_ARROW});
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Option = styled.div`
  color: ${COLOR_LINK_BLACK};
  padding: 15px 19px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background: ${COLOR_ACTION_BLUE};
    color: ${COLOR_WHITE};
  }
`;

const Checkbox = styled.div`
  background-color: ${COLOR_WHITE};
  border: 2px solid ${COLOR_GRAY};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-right: 12px;
`;

const Img = styled.img``;

const OptionWrapper = styled.div`
  position: absolute;
  display: none;
  top: 100%;
  left: -1px;
  border: 1px solid ${COLOR_GRAY};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  right: -1px;
  background: ${COLOR_WHITE};
  z-index: 10;
  overflow: auto;
  max-height: 385px;
  &.open {
    display: block;
  }
`;

const PlaceHolder = styled.div`
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  left: 19px;
  margin-top: -6px;
  color: #90a0b7;
  background: ${COLOR_WHITE};
  z-index: 2;
  padding-left: 10px;
  padding-right: 10px;
`;

const Value = styled.div``;

class Select extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (!this.ref.current) return;
    const isOutside = !this.ref.current.contains(e.target);
    const { setOpenState } = this.props;

    if (isOutside) {
      setOpenState(false);
    }
  };

  handleSet = item => {
    const { value, setValue, multiply } = this.props;
    let current;
    if (multiply && value.length) {
      current = value.map(val => {
        if (val.value === item.value && !val.checked) {
          return { ...val, checked: true };
        }
        if (val.value === item.value && val.checked) {
          return { ...val, checked: false };
        }
        return val;
      });
    }
    setValue(current || item);
  };

  toggleSelect = () => {
    const { openState, multiply, setOpenState } = this.props;
    if (openState) {
      if (multiply) return;
      setOpenState(false);
    } else {
      setOpenState(true);
    }
  };

  render() {
    const {
      placeholder,
      options,
      openState,
      setOpenState,
      value,
      name,
      multiply,
      tabIndex,
      readonly,
    } = this.props;
    let showValue;

    if (typeof value === 'string') {
      showValue = value;
    } else {
      showValue = value.text;

      if (multiply && value.length) {
        showValue = value.reduce(
          (acc, item) => (item.checked && `${acc.length ? `${acc}, ` : acc}${item.text}`) || acc,
          '',
        );
      }
    }

    const filteredOptions =
      (multiply &&
        [...options, ...value].reduce((acc, item) => {
          let index;
          // eslint-disable-next-line array-callback-return, consistent-return
          const hasItem = options.find((opt, i) => {
            if (opt.value === item.value) {
              index = i;
              return opt;
            }
          });
          if (hasItem) {
            const opt = acc[index];
            acc[index] = { ...opt, ...item };
            return acc;
          }
          return [...acc, item];
        }, [])) ||
      options;
    return (
      <>
        <Wrapper
          tabIndex={tabIndex}
          ref={this.ref}
          onClick={() => {
            if (!readonly) this.toggleSelect();
          }}
        >
          {showValue && <PlaceHolder>{placeholder}</PlaceHolder>}
          <SelectWrapper
            data-test-id={`${INPUT_TEST_ID}${name && name.toUpperCase()}`}
            className={(showValue && 'open') || null}
          >
            <Value
              onClick={() => {
                if (!readonly) setOpenState(!openState);
              }}
            >
              {showValue || placeholder}
            </Value>
            <OptionWrapper className={openState ? 'open' : null}>
              {filteredOptions.map((item, i) => (
                <Option key={i} onClick={() => this.handleSet(item)}>
                  {multiply && <Checkbox>{item.checked && <Img src={CHECKED_ICON} />}</Checkbox>}
                  {item.text || item}
                </Option>
              ))}
            </OptionWrapper>
          </SelectWrapper>
        </Wrapper>
      </>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  multiply: PropTypes.bool,
  name: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  tabIndex: PropTypes.string,
  openState: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

Select.defaultProps = {
  options: [],
  value: '',
  name: '',
  tabIndex: '',
  multiply: false,
  readonly: false,
};

const enhance = compose(
  withState('ref', 'setRef', React.createRef()),
  withBoolState('openState', false),
  // withHandlers(() => ({
  //   handleClick: ({ setOpenState, ...rest }) => e => {
  //     console.log(rest);
  //     const isOutside = !ref.current.contains(e.target);

  //     if (isOutside) {
  //       setOpenState(false);
  //     }
  //   },
  // })),
  // lifecycle({
  //   componentDidMount() {
  //     const { handleClick } = this.props;
  //     document.addEventListener('click', handleClick);
  //   },
  //   componentWillUnmount() {
  //     const { handleClick } = this.props;
  //     document.removeEventListener('click', handleClick);
  //   },
  // }),
);
export default enhance(Select);
