import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { compose, withStateHandlers, lifecycle, withProps } from 'recompose';
import { withFetchUserInDidMount, withUserData } from '../../utils/hocs';
import { Wrapper, Title } from './CustomersStyled';
import TopCustomersNavigation from './TopCustomersNavigation/TopCustomersNavigation';
import Table from './Table/Table';
import storeNames from '../../stores/storeNames';
import history from '../../history';
import Paragraph from '../../components/Typography/Paragraph';

const Customers = ({ userData, userIsLoading, isAdmin, inputData, setInputData, count }) => (
  <Wrapper>
    {userData && userData.id && !userIsLoading && isAdmin && (
      <>
        <Title as="h2">Клиенты</Title>
        <Paragraph>Всего клиентов: {count && count.count}</Paragraph>
        <TopCustomersNavigation inputData={inputData} setInputData={setInputData} />
        <Table setInputData={setInputData} inputData={inputData} />
      </>
    )}
    {userData && userData.id && !userIsLoading && !isAdmin && history.replace('/profile')}
  </Wrapper>
);

Customers.propTypes = {
  userData: PropTypes.object.isRequired,
  userIsLoading: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  inputData: PropTypes.string,
  count: PropTypes.any,
  setInputData: PropTypes.func,
};

Customers.defaultProps = {
  inputData: '',
  setInputData: () => {},
  userIsLoading: false,
  count: 0,
};

const enhance = compose(
  inject(storeNames.CustomerStore),
  observer,
  withProps(({ CustomerStore }) => ({
    count: CustomerStore.count,
  })),
  withFetchUserInDidMount,
  withUserData,
  withStateHandlers(
    ({ inputValue = '' }) => ({
      inputData: inputValue,
    }),
    {
      setInputData: () => value => ({
        inputData: value,
      }),
    },
  ),
  lifecycle({
    async componentDidMount() {
      const { CustomerStore } = this.props;
      CustomerStore.getCount();
    },
  }),
);

export default enhance(Customers);
