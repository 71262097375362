import { makeStyles, styled } from '@material-ui/core/styles';
// import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import { COLOR_ACTION_BLUE /* , COLOR_WHITE */ } from '../../../../constants/colors';

export const useStyles = makeStyles(theme => ({
  palette: {
    primary: {
      main: '#03a9f4',
    },
    secondary: {
      main: '#f44336',
    },
  },
  root: {
    // maxWidth: '100%',
    width: '100%',
    borderRadius: '6px',
    /* flexGrow: 1,
    backgroundColor: theme.palette.background.paper, */
  },
  title: {
    backgroundColor: COLOR_ACTION_BLUE,
    color: '#FFF',
  },
  titleFullName: {
    color: '#EEE',
  },
  body: {
    backgroundColor: '#FBFBFB',
    // padding: theme.spacing(2),
  },
  indent: {
    padding: theme.spacing(1, 0),
  },
  tableHeader: {
    fontWeight: 'bold',
    // fontStyle: 'italic',
    paddingBottom: 0,
    marginBottom: 0,
    color: 'grey',
    maxWidth: '50%',
    whiteSpace: 'nowrap',
  },
  tableBodyRoot: {
    // fontWeight: 'bold',
    color: 'grey',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  tableBodyWide: {
    // fontWeight: 'bold',
    color: 'grey',
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '70px',
  },
  tableBody: {
    color: '#999',
    // backgroundColor: '#EEE',
  },
  tableBodyLast: {
    color: '#88B',
  },
  tableTitle: {
    backgroundColor: '#EEE',
    textAlign: 'center',
    letterSpacing: '0.03em',
    fontFamily: 'Lato',
    // fontSize: '1.30rem',
  },
  detailTitle: {
    color: '#888',
    fontVariant: 'all-petite-caps',
    textAlign: 'center',
    fontSize: '22px',
  },
  link: {
    color: '#03a9f4',
    cursor: 'pointer',
  },
  adaptiveLabel: {
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 600px)']: {
      fontSize: '5px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 1000px)']: {
      fontSize: '7px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 1250px)']: {
      fontSize: '8px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 1400px)']: {
      fontSize: '9px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 1600px)']: {
      fontSize: '9px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 1800px)']: {
      fontSize: '11px',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width: 2000px)']: {
      fontSize: '12px',
    },
    // eslint-disable-next-line no-useless-computed-key
    /* ['@media (min-width: 2500px)']: {
      fontSize: '14px',
    }, */
  },
  pledgesTitle: {
    display: 'flex',
    color: '#555',
    fontVariant: 'all-petite-caps',
    textAlign: 'left',
    fontSize: '22px',
  },
  pledgesSubject: {
    color: '#999',
    fontVariant: '',
    textAlign: 'left',
    fontSize: '16px',
  },
}));

export const TabsWrapper = styled(Tabs)`
  .adaptiveLabel {
    @media (min-width: 600px) {
      font-size: 6px;
    }
    @media (min-width: 1000px) {
      font-size: 8px;
    }
    @media (min-width: 1250px) {
      font-size: 10px;
    }
    @media (min-width: 1400px) {
      font-size: 11px;
    }
    @media (min-width: 1600px) {
      font-size: 12px;
    }
    @media (min-width: 1800px) {
      font-size: 13px;
    }
    @media (min-width: 2000px) {
      font-size: 14px;
    }
  }
`;
