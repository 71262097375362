import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withProps, lifecycle } from 'recompose';

import YearIntervalSelector from './YearIntervalSelector';
// import ButtonGroupSwitch from './ButtonGroupSwitch';
import StateContractsTable from './StateContractsTable';

import storeNames from '../../../stores/storeNames';

const StateContracts = ({
  loader,
  // stateSelector,
  // setStateSelector,
  stateContracts,
  updateStateContracts,
  yearList,
  yearFrom,
  yearTo,
  setYearFrom,
  setYearTo,
}) => {
  return (
    <>
      {yearList.length !== 0 && (
        <YearIntervalSelector
          yearArray={yearList}
          yearFrom={yearFrom}
          setYearFrom={setYearFrom}
          yearTo={yearTo}
          setYearTo={setYearTo}
          change={async (_yearFrom, _yearTo) => {
            if (_yearTo >= _yearFrom) {
              loader(true);
              await updateStateContracts();
              loader(false);
            }
          }}
        />
      )}
      {/* <ButtonGroupSwitch
        array={[
          { label: 'Заказчик', value: 'customer' },
          { label: 'Поставщик', value: 'supplier' },
        ]}
        value={stateSelector}
        setValue={setStateSelector}
      /> */}
      <StateContractsTable
        stateContracts={stateContracts.data}
        statistic={stateContracts.statistic}
        yearFrom={yearFrom}
        yearTo={yearTo}
      />
    </>
  );
};
StateContracts.propTypes = {
  loader: PropTypes.func.isRequired,
  stateContracts: PropTypes.object.isRequired,
  updateStateContracts: PropTypes.func.isRequired,
  // stateSelector: PropTypes.string.isRequired,
  // setStateSelector: PropTypes.func.isRequired,
  yearList: PropTypes.array.isRequired,
  yearFrom: PropTypes.number.isRequired,
  yearTo: PropTypes.number.isRequired,
  setYearFrom: PropTypes.func.isRequired,
  setYearTo: PropTypes.func.isRequired,
};
StateContracts.defaultProps = {};

const enhance = compose(
  inject(storeNames.DashboardStore),
  inject(storeNames.DialogStore),
  observer,
  withProps(({ DashboardStore, DialogStore }) => ({
    loader: DialogStore.loader,
    inquiry: DashboardStore.inquiry,
    stateContracts: DashboardStore.stateContracts,
    updateStateContracts: DashboardStore.updateStateContracts,
    // stateSelector: DashboardStore.stateContractsSelector,
    // setStateSelector: DashboardStore.stateContractsSetSelector,
    yearList: DashboardStore.stateContractsYearList,
    yearFrom: DashboardStore.stateContractsYearFrom,
    setYearFrom: DashboardStore.stateContractsYearSetFrom,
    yearTo: DashboardStore.stateContractsYearTo,
    setYearTo: DashboardStore.stateContractsYearSetTo,
  })),
  lifecycle({}),
);

export default enhance(StateContracts);
