import API from './base';
import { getUrlToApi } from '../constants/api';

export default class DashboardApi extends API {
  /* createUser(data, token = null) {
    return this.post(getUrlToLKApi('auth/registration'), data, token, false);
  }

  getCount(token = null) {
    return this.get(getUrlToLKApi(`customers/count`), token, false);
  } */

  getOrganization(innOrOgrn, token = null) {
    return this.get(getUrlToApi(`static/v1/entity/${innOrOgrn}`), token, false);
  }

  getFounders(innOrOgrn, token = null) {
    return this.get(getUrlToApi(`static/v1/entity/${innOrOgrn}/founders`), token, false, false);
  }

  getPersons(innOrOgrn, token = null) {
    return this.get(getUrlToApi(`static/v1/entity/${innOrOgrn}/persons`), token, false, false);
  }

  getAddress(innOrOgrn, token = null) {
    return this.get(getUrlToApi(`static/v1/entity/${innOrOgrn}/address`), token, false, false);
  }

  getTaxOrgHistory(innOrOgrn, token = null) {
    return this.get(
      getUrlToApi(`static/v1/entity/${innOrOgrn}/tax-org/history`),
      token,
      false,
      false,
    );
  }

  getFinanceDigest(token = null) {
    return this.get(
      getUrlToApi(`static/v1/accounting-field`),
      token,
      false,
      false,
      /// 'https://app.rescore.online',
    );
    // return this.get(`/api/static/v1/accounting-field`, token, false, 'https://app.rescore.online');
  }

  getFinanceBalanceList(innOrOgrn, token = null) {
    return this.get(
      getUrlToApi(`static/v1/accounting-report/${innOrOgrn}`),
      token,
      false,
      false,
      /// 'https://app.rescore.online',
    );
  }

  getFinanceBalanceExt(innOrOgrn, year, token = null) {
    return this.get(
      getUrlToApi(`static/v1/accounting-report/${innOrOgrn}/${year}/aggregated`),
      token,
      false,
      false,
      /// 'https://app.rescore.online',
    );
  }

  getFinanceBalanceFNS(innOrOgrn, year, token = null) {
    return this.get(
      getUrlToApi(`static/v1/opendata-fns/${innOrOgrn}/${year}`),
      token,
      false,
      false,
      /// 'https://app.rescore.online',
    );
  }

  getLegalCases(innOrOgrn, page = 1, token = null) {
    return this.get(
      getUrlToApi(`scoring/v1/data/legal-case/search/${innOrOgrn}?size=100&page=${page}`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getLegalCaseParticipants(id, page = 1, token = null) {
    return this.get(
      getUrlToApi(`scoring/v1/data/legal-case/${id}/participants?size=100&page=${page}`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getLegalCasesSummary(innOrOgrn, token = null) {
    return this.get(
      getUrlToApi(`scoring/v1/legacy/legal-case/${innOrOgrn}/summary`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getStatusDictionary(token = null) {
    return this.get(getUrlToApi(`static/v1/dictionary/entity-status`), token, false, false);
  }

  getOkved(ogrn, token = null) {
    return this.get(getUrlToApi(`static/v1/entity/${ogrn}/okved`), token, false, false);
  }

  /* getSubscribeExtend(id, token = null) {
    return this.get(
      `${id}?expand=true`, // https://api.debtster.ru/7c54273a-5ba9-4060-a68f-9f122632ba52?expand=true
      token,
      false,
      false,
      'https://api.debtster.ru',
    );
  } */

  getPledges(innOrOgrn, token = null) {
    return this.get(
      getUrlToApi(`scoring/v1/legacy/pledges/${innOrOgrn}/as-pledger`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getStateContracts(innOrOgrn, token = null) {
    return this.get(
      getUrlToApi(`scoring/v1/legacy/state-contracts/${innOrOgrn}`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  // scoring/v1/legacy/writs/{ogrn}?page=1&size=100&processing_date_from=2017-01-15T00:00:00.000Z&processing_date_to=2020-08-15T00:00:00.000Z
  getWrits(ogrn, page = 1, size = 100, date_from = null, date_to = null, token = null) {
    let url = null;
    if (date_from === null) {
      url = getUrlToApi(`scoring/v1/legacy/writs/${ogrn}?page=${page}&size=${size}`);
    } else {
      url = getUrlToApi(
        `scoring/v1/legacy/writs/${ogrn}?page=${page}&size=${size}&processing_date_from=${date_from}&processing_date_to=${date_to}`,
      );
    }
    return this.get(
      url,
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getNegativeFactors(score_id, token = null) {
    return this.get(
      getUrlToApi(`scoring/v2/service/score/${score_id}/negative-factors`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getNegativeFactorsDictionary(token = null) {
    return this.get(
      getUrlToApi(`scoring/v2/dictionary/negative-factors/ru`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getAssessments(score_id, token = null) {
    return this.get(
      getUrlToApi(`scoring/v2/service/score/${score_id}/assessments`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getInterpreterDescriptionDictionary(token = null) {
    return this.get(
      getUrlToApi(`scoring/v2/dictionary/interpreter-description/ru`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getInterpreterPathDictionary(token = null) {
    return this.get(
      getUrlToApi(`scoring/v2/dictionary/interpreter-path/ru`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }

  getQuadrantDescriptionDictionary(token = null) {
    return this.get(
      getUrlToApi(`scoring/v2/dictionary/quadrant-description/ru`),
      token,
      false,
      false,
      // 'https://app.rescore.online',
    );
  }
}
