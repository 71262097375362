import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, /* withHandlers, */ withProps, lifecycle } from 'recompose';
// import * as moment from 'moment';
import { makeStyles /* useTheme, */ /* withStyles */ } from '@material-ui/core/styles';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Badge from '@material-ui/core/Badge';
// import ListItemText from '@material-ui/core/ListItemText';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
// import InputBase from '@material-ui/core/InputBase';

import storeNames from '../../../../stores/storeNames';
import Popup from '../../../../components/Popup/Popup';
import InputField from '../../../../components/Inputs/InputField';
import Typography from '../../../../components/Typography/Paragraph';
import Button from '../../../../components/Buttons/Button';
/* import { getAccessStatus } from '../../../../helpers/roles';
import { hasValue } from '../../../../utils/validators';
import { RoleRender } from '../../../../components/RoleComponents'; */
import {
  Title,
  Indent,
  //  Shift,
  Wrapper,
  FormWrapper,
  InputWrapper,
  //  InputWrapperNotes,
  //  TopText,
  //  TextWrapper,
  //  DateInputWrap,
  //  DateWrapper,
  // ButtonWrapper,
  //  DateContent,
  //  TextArea,
  //  Description,
  TableContainer,
  //  ButtonSmall,
  Tabs,
  TabsItem,
} from '../SharedInfoPopupStyled';
import {
  Tr,
  TrTop,
  Th,
  Tds,
  //  ActivityTd,
  //  Img,
  Thead,
  Tbody,
  //  TdButton,
  //  ButtonEdit,
  //  CheckBoxWrapper,
  TableButton,
  //  Text,
} from '../../Table/TableStyled';
import logger from '../../../../logger';
// import Select from '../../../../components/Select/Select';
// import ParagraphError from '../../../../components/Typography/ParagraphError';
// import { withBoolState } from '../../../../utils/hocs';
// import { withPasswordChangeType, withAccounts, withUserData } from '../../../../utils/hocs';
// import { COLOR_ACTION_BLUE } from '../../../../constants/colors';
/* import {
  withInputSetStore,
  withDateFromState,
  withDateToState,
  withMoneyState,
  withNotesState,
  //withNameAccount,
} from '../../../../utils/FormHocs'; */
/* import {
  SUPER_ADMIN_ROLE,
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_ACCOUNTANT,
} from '../../../../constants/roles'; */
/* import InputDate from '../../../../components/Inputs/InputDate';
import { InputColumn } from '../../../Registration/RegistrationUsers/RegistrationUsersStyled'; */
// import { ICON_SUCCEEDED } from '../../../../constants/icons';
// import CheckboxTable from '../../../../components/CheckBoxes/CheckBoxTable';
// import CheckBox from '../../../../components/CheckBoxes/CheckBox';
// import ProgressPopup from '../../../Popups/ProgressPopup';

// import { makeStyles } from '@material-ui/core/styles';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Box from '@material-ui/core/Box';

// eslint-disable-next-line
// import * as CryptoPro from '../../../../constants/cadesplugin_api';

/* const TableInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 0,
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #ced4da',
    fontSize: 16,
    width: '90%',
    padding: '20px 26px 20px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 0,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.0rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase); */

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '0px 0px', // 7 0 theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
    // cursor: 'pointer',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
  },
  chip: {
    margin: '2px 2px',
    cursor: 'pointer',
  },
  /* noLabel: {
    marginTop: theme.spacing(3),
  }, */
}));

/* const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: '20%',
      left: '0px',
      right: '0px',
      maxHeight: ITEM_HEIGHT * 11.5 + ITEM_PADDING_TOP,
      width: 770,
    },
  },
}; */

const AccessTable = ({
  /* */
  formId,
  questionaries,
  sharedList,
  setSharedList,
  fieldName,
  AllowAccessHandler,
  DenyAccessHandler,
  findedMode,
  selectForms,
  /* companyItem,
  companyFieldName,
  setCompanyItem,
  setCompanyFieldName, */
}) => {
  // logger.debug('sharedList', sharedList);

  const applyFormArray = (array, index) => {
    setSharedList(oldList => {
      const newList = [...oldList];
      // eslint-disable-next-line
      // for (let item of newList) item.disable = true;
      // newList[index].disable = false;
      newList[index][fieldName] = array;
      // newList[index].changed = true;
      return newList;
    });
  };

  const updateAccess = item => {
    // logger.debug(index, item);

    AllowAccessHandler({ ...item, requests: 'requests' in item ? item.requests : null });
  };

  const allowAccess = item => {
    // logger.debug(index, item);
    if (formId !== '') {
      logger.debug('item.accesses', item.accesses);
      AllowAccessHandler({
        ...item,
        accesses: item.accesses !== null ? [...item.accesses, formId] : [formId],
        requests: item.requests.length >= 2 ? item.requests.filter(i => i !== formId) : null,
      });
    } else {
      AllowAccessHandler({
        ...item,
        accesses:
          item.accesses !== null ? [...item.accesses, ...item.requests] : [...item.requests],
        requests: null,
      });
    }
  };

  const disallowAccess = item => {
    if (formId !== '') {
      AllowAccessHandler({ ...item, accesses: item.accesses.filter(i => i !== formId) });
    }
  };

  const denyAccess = item => {
    if (formId !== '') {
      AllowAccessHandler({
        ...item,
        requests: item.requests.length >= 2 ? item.requests.filter(i => i !== formId) : null,
      });
    } else if (item.accesses !== null && item.accesses.length !== 0) {
      AllowAccessHandler({ ...item, requests: null });
    } else {
      // logger.debug(index, item);
      DenyAccessHandler(item);
    }
  };

  const classes = useStyles();

  return (
    <TableContainer>
      <Thead>
        <TrTop>
          <Th width="20%">ИНН</Th>
          <Th width="20%">ОГРН</Th>
          <Th width="35%">Компания</Th>
          <Th width="30%">Доступ</Th>
        </TrTop>
      </Thead>
      <Tbody>
        {sharedList &&
          sharedList.map((item, index) => {
            if (item[fieldName] === null) return false;
            // if (item[fieldName].length === 0) return false;
            if (formId !== '' && item[fieldName].indexOf(formId) < 0) return false;
            return (
              <Tr key={index} disabled={item.disable} style={{ height: '70px' }}>
                <Tds>{item.customer.inn}</Tds>
                <Tds>{'ogrn' in item.customer ? item.customer.ogrn : ''}</Tds>
                <Tds>{item.customer.name}</Tds>
                <Tds
                  style={{ cursor: formId === '' ? 'pointer' : '' }}
                  onClick={async () => {
                    if (formId !== '') return;
                    const type = findedMode ? 'finded' : fieldName;
                    const res = await selectForms(item[fieldName], type);
                    if (res.result === true) {
                      applyFormArray(res.array, index);
                      allowAccess(item);
                    } else if (res.result === 'REJECT') {
                      denyAccess(item);
                    } else if (res.result === 'APPLY') {
                      applyFormArray(res.array, index);
                      updateAccess(item);
                    }
                  }}
                >
                  <FormControl className={classes.formControl}>
                    {(() => {
                      // logger.debug('renderValue', selected);
                      if (item[fieldName].length === 0) return 'Выберите формы';
                      const arr = [];
                      for (let i = 0; i < questionaries.length; i += 1) {
                        if (item[fieldName].indexOf(questionaries[i].id) > -1) {
                          arr.push(i + 1);
                        }
                      }
                      if (questionaries.length === 1) {
                        if (findedMode) return 'Отсутствует';
                        if (fieldName === 'accesses') return arr.length ? 'Разрешен' : 'Запрещен';
                        return arr.length ? 'Запрошен' : 'Отклонен';
                      }
                      return (
                        <div className={classes.chips}>
                          {arr.length !== 0 &&
                            arr.map(value => (
                              <Chip
                                disabled={item.disable}
                                variant="outlined"
                                size="small"
                                key={value}
                                label={value}
                                className={classes.chip}
                              />
                            ))}
                        </div>
                      );
                    })()}
                  </FormControl>
                </Tds>
                <Tds>
                  {fieldName === 'requests' && (
                    <>
                      {item.requests.length !== 0 && (
                        <TableButton
                          style={{ paddingBottom: '7px' }}
                          disabled={item.disable}
                          testID=""
                          onClick={() => allowAccess(item)}
                        >
                          Предоставить
                        </TableButton>
                      )}
                      {!findedMode && (
                        <TableButton
                          style={{ paddingTop: '5px' }}
                          disabled={item.disable}
                          testID=""
                          onClick={() => denyAccess(item)}
                        >
                          Отклонить
                        </TableButton>
                      )}
                    </>
                  )}
                  {fieldName === 'accesses' && (
                    <>
                      {item.accesses.length === 0 && (
                        <TableButton
                          style={{ padding: '5px' }}
                          disabled={item.disable}
                          testID=""
                          onClick={() => denyAccess(item)}
                        >
                          Удалить
                        </TableButton>
                      )}
                      {formId !== '' && item.accesses.length !== 0 && (
                        <TableButton
                          style={{ padding: '5px' }}
                          disabled={item.disable}
                          testID=""
                          onClick={() => disallowAccess(item)}
                        >
                          Запретить
                        </TableButton>
                      )}
                      {item.accesses.length !== 0 && 'changed' in item && item.changed && (
                        <TableButton
                          style={{ padding: '5px' }}
                          disabled={item.disable}
                          testID=""
                          onClick={() => updateAccess(item)}
                        >
                          Применить
                        </TableButton>
                      )}
                      {!('requests' in item) && item.accesses.length !== 0 && !('changed' in item) && (
                        <TableButton
                          style={{ padding: '5px' }}
                          disabled={item.disable}
                          testID=""
                          onClick={() => updateAccess(item)}
                        >
                          Добавить
                        </TableButton>
                      )}
                    </>
                  )}
                </Tds>
              </Tr>
            );
          })}
      </Tbody>
    </TableContainer>
  );
};

AccessTable.propTypes = {
  formId: PropTypes.string.isRequired,
  questionaries: PropTypes.array.isRequired,
  sharedList: PropTypes.array,
  setSharedList: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  AllowAccessHandler: PropTypes.func,
  DenyAccessHandler: PropTypes.func,
  findedMode: PropTypes.bool,
  selectForms: PropTypes.func,
  /* companyItem: PropTypes.object.isRequired,
  companyFieldName: PropTypes.string.isRequired,
  setCompanyItem: PropTypes.func.isRequired,
  setCompanyFieldName: PropTypes.func.isRequired, */
};

AccessTable.defaultProps = {
  sharedList: [],
  AllowAccessHandler: () => {},
  DenyAccessHandler: () => {},
  findedMode: false,
  selectForms: () => {},
};

const AccessPopup = ({
  // questionary,
  toggleAccessPopup,
  formId,
  formTitle,
  questionaries,
  // getAccessList,
  fetchAccess,
  setAccess,
  delAccess,
  findedOrganization,
  findOrganization,
  clearOrganization,
  // companyItem,
  // companyFieldName,
  // setCompanyItem,
  // setCompanyFieldName,
  selectForms,
}) => {
  // const [requested, setRequested] = useState(false);
  const [findString, setFindString] = useState();
  const [sharedList, setSharedList] = useState([]);
  const [findedList, setFindedList] = useState([]);
  const [hasCompany, setHasCompany] = useState(false);
  const [accessTab, setAccessTab] = useState('accesses');

  const updateAccess = async () => {
    setSharedList(await fetchAccess());
  };

  const getAccessListCallback = useCallback(async () => {
    setSharedList(await fetchAccess());
  }, [fetchAccess]);
  useEffect(() => {
    getAccessListCallback();
  }, [getAccessListCallback]);

  const addCompany = async company => {
    logger.debug('addCompany', company);
    /* const list = JSON.parse(JSON.stringify(sharedList));
    list.push(newOrg);
    setSharedList(list); */
    await setAccess(company);
    await updateAccess();
    clearOrganization();
  };

  const setCompany = async company => {
    await setAccess(company);
    await updateAccess();
  };

  const deleteCompany = async company => {
    await delAccess(company);
    await updateAccess();
  };

  const fillFindedList = finded => {
    logger.debug('=>', finded);
    logger.debug(sharedList);
    const list = [
      ...sharedList.filter(i => i.customer.inn === finded.inn && i.customer.ogrn === finded.ogrn),
    ];
    logger.debug('list', list);
    // const list = [];
    if (list.length === 0) {
      setHasCompany(false);
      const org = {
        // entity_id: finded.entity_id,
        customer: {
          inn: finded.inn,
          ogrn: finded.ogrn,
          name: finded.name_short || finded.name_full,
        },
        accesses: null,
        requests: formId === '' ? [] : [formId],
      };
      list.push(org);
    } else if (formId !== '') {
      setHasCompany(false);
      if (list[0].requests === null) list[0].requests = [formId];
      else if (list[0].requests.filter(i => i === formId).length === 0) {
        list[0].requests = [...list[0].requests, formId];
      } else {
        setHasCompany(true);
      }
    } else {
      setHasCompany(true);
      // list[0].requests = null; // [];
    }
    logger.debug(formId, list);
    setFindedList(list);
  };

  const rowCount = fieldName => {
    const viewedList = Object.keys(findedOrganization).length !== 0 ? findedList : sharedList;
    return viewedList.reduce(
      (count, item) =>
        count +
        (item[fieldName] !== null &&
        item[fieldName].length !== 0 &&
        (formId === '' || item[fieldName].indexOf(formId) > -1)
          ? 1
          : 0),
      0,
    );
  };

  return (
    <>
      <Popup
        width="1000px"
        closePopup={() => {
          // setAccess(sharedList);
          toggleAccessPopup();
        }}
        borderRadius="5px"
      >
        <Wrapper>
          <Title as="h2">
            Предоставление доступа {formId === '' ? 'к формам' : 'к форме:'}
            <Typography textColor="#AAAAB0" fontSize="19px" lineHeight="26px" textAlign="center">
              {formTitle}
            </Typography>
          </Title>

          <FormWrapper flex bottom="20px">
            <InputWrapper width="75%">
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="2"
                margin="0"
                placeholder="Введите ИНН или ОГРН"
                type="text"
                name="name"
                value={findString}
                onChange={value => {
                  setFindString(value);
                }}
              />
            </InputWrapper>
            <Button
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex=""
              testID="TEST_BUTTON_SAVE"
              width="23%"
              type=""
              sizes={{ margin: '0px 0px 10px 0' }}
              lineHeight="0px"
              onClick={async () => {
                if (findString) fillFindedList(await findOrganization(findString));
              }}
            >
              Найти
            </Button>
          </FormWrapper>

          {Object.keys(findedOrganization).length !== 0 && !hasCompany ? (
            <>
              <Indent>
                <Typography textColor="#AAAAB0" fontSize="20px" lineHeight="26px" textAlign="left">
                  Найденные компании
                </Typography>
              </Indent>
              <FormWrapper>
                <AccessTable
                  formId={formId}
                  questionaries={questionaries}
                  sharedList={findedList}
                  setSharedList={setFindedList}
                  fieldName="requests"
                  AllowAccessHandler={addCompany}
                  DenyAccessHandler={clearOrganization}
                  findedMode
                  selectForms={selectForms}
                />
              </FormWrapper>
            </>
          ) : (
            <>
              <Tabs>
                <TabsItem
                  data-test-id="TEST_TAB_CURRENT_ACCESS"
                  className={accessTab === 'accesses' ? 'active' : null}
                  onClick={() => setAccessTab('accesses')}
                >
                  Текущие доступы {`(${rowCount('accesses')})`}
                </TabsItem>
                <TabsItem
                  data-test-id="TEST_TAB_ACCESS_REQUEST"
                  className={accessTab === 'requests' ? 'active' : null}
                  onClick={() => setAccessTab('requests')}
                >
                  Запросы на доступ {`(${rowCount('requests')})`}
                </TabsItem>
              </Tabs>

              {accessTab === 'accesses' && (
                <>
                  {Object.keys(findedOrganization).length !== 0 && (
                    <Indent>
                      <Typography
                        textColor="#AAAAB0"
                        fontSize="20px"
                        lineHeight="26px"
                        textAlign="left"
                      >
                        (отфильтровано)
                      </Typography>
                    </Indent>
                  )}
                  <FormWrapper>
                    <AccessTable
                      formId={formId}
                      questionaries={questionaries}
                      sharedList={
                        Object.keys(findedOrganization).length !== 0 ? findedList : sharedList
                      }
                      setSharedList={
                        Object.keys(findedOrganization).length !== 0 ? setFindedList : setSharedList
                      }
                      fieldName="accesses"
                      AllowAccessHandler={setCompany}
                      DenyAccessHandler={deleteCompany}
                      selectForms={selectForms}
                    />
                  </FormWrapper>
                </>
              )}

              {accessTab === 'requests' && (
                <>
                  {Object.keys(findedOrganization).length !== 0 && (
                    <Indent>
                      <Typography
                        textColor="#AAAAB0"
                        fontSize="20px"
                        lineHeight="26px"
                        textAlign="left"
                      >
                        (отфильтровано)
                      </Typography>
                    </Indent>
                  )}
                  <FormWrapper>
                    <AccessTable
                      formId={formId}
                      questionaries={questionaries}
                      sharedList={
                        Object.keys(findedOrganization).length !== 0 ? findedList : sharedList
                      }
                      setSharedList={
                        Object.keys(findedOrganization).length !== 0 ? setFindedList : setSharedList
                      }
                      fieldName="requests"
                      AllowAccessHandler={setCompany}
                      DenyAccessHandler={deleteCompany}
                      selectForms={selectForms}
                    />
                  </FormWrapper>
                </>
              )}
            </>
          )}
        </Wrapper>
      </Popup>
    </>
  );
};

AccessPopup.propTypes = {
  // companyItem: PropTypes.object,
  // companyFieldName: PropTypes.string,
  // setCompanyItem: PropTypes.func,
  // setCompanyFieldName: PropTypes.func,
  toggleAccessPopup: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  questionaries: PropTypes.array.isRequired,
  // getAccessList: PropTypes.func.isRequired,
  fetchAccess: PropTypes.func.isRequired,
  setAccess: PropTypes.func.isRequired,
  delAccess: PropTypes.func.isRequired,
  findedOrganization: PropTypes.object.isRequired,
  findOrganization: PropTypes.func.isRequired,
  clearOrganization: PropTypes.func.isRequired,
  selectForms: PropTypes.func.isRequired,
};

AccessPopup.defaultProps = {
  // companyItem: {},
  // companyFieldName: '',
  // setCompanyItem: () => {},
  // setCompanyFieldName: () => {},
};

const enchance = compose(
  inject(storeNames.SharedInfoStore),
  observer,
  withProps(({ SharedInfoStore }) => ({
    formId: SharedInfoStore.formId,
    formTitle: SharedInfoStore.formTitle,
    questionaries: SharedInfoStore.questionariesShadow,
    // getAccessList: SharedInfoStore.getAccessList,
    fetchAccess: SharedInfoStore.fetchAccess,
    setAccess: SharedInfoStore.setAccess,
    delAccess: SharedInfoStore.delAccess,
    findedOrganization: SharedInfoStore.findedOrganization,
    findOrganization: SharedInfoStore.findOrganization,
    clearOrganization: SharedInfoStore.clearOrganization,
    // companyItem: SharedInfoStore.companyItem,
    // companyFieldName: SharedInfoStore.companyFieldName,
    // setCompanyItem: SharedInfoStore.setCompanyItem,
    // setCompanyFieldName: SharedInfoStore.setCompanyFieldName,
    selectForms: SharedInfoStore.selectForms,
  })),
  lifecycle({
    async componentDidMount() {
      const { SharedInfoStore } = this.props;
      SharedInfoStore.clearOrganization();
      // SharedInfoStore.fetchAccess();
    },
  }),
);

export default enchance(AccessPopup);
