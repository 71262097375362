import React /* , { useRef, useState } */ from 'react';
import PropTypes from 'prop-types';
import { compose, /* withHandlers, withState, withProps, */ lifecycle } from 'recompose';
import Box from '@material-ui/core/Box';
import { Title } from './PopupsStyled';
// import { /* makeStyles, */ withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Typography from '../../components/Typography/Paragraph';
import {
  COLOR_ACTION_BLUE,
  COLOR_WHITE,
  /* , COLOR_RED, COLOR_TITLE_BLACK */
} from '../../constants/colors';
import Button from '../../components/Buttons/Button';
import Popup from '../../components/Popup/Popup';

const YesNoPopup = ({ title, toggleYesNoPopup, accept, cancel }) => {
  return (
    <Popup width="600px" closePopup={toggleYesNoPopup} borderRadius="5px">
      <Box px={4} py={3}>
        <Box display="flex" justifyContent="flex-start">
          <Title as="h2" align="left" width="550px">
            {title}
          </Title>
          {/* <Typography fontSize="24px" lineHeight="26px" textColor={COLOR_TITLE_BLACK}>
            {title}
          </Typography> */}
        </Box>

        <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" pt={2}>
          <Button
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="1"
            testID="TEST_BUTTON_CANCEL"
            width="100px"
            textColor={COLOR_WHITE}
            boxShadow="none"
            backgroundColor="#f51"
            border="1px solid #f40"
            sizes={{ margin: '0 20px' }}
            onClick={() => {
              accept();
              toggleYesNoPopup();
            }}
          >
            Да
          </Button>
          <Button
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="2"
            testID="TEST_BUTTON_CANCEL"
            width="100px"
            textColor={COLOR_ACTION_BLUE}
            boxShadow="none"
            backgroundColor="#fff"
            sizes={{ margin: '0 0px' }}
            onClick={() => {
              cancel();
              toggleYesNoPopup();
            }}
          >
            Нет
          </Button>
        </Box>
      </Box>
    </Popup>
  );
};

YesNoPopup.propTypes = {
  title: PropTypes.string,
  toggleYesNoPopup: PropTypes.func.isRequired,
  accept: PropTypes.func,
  cancel: PropTypes.func,
};

YesNoPopup.defaultProps = {
  title: '',
  accept: () => {},
  cancel: () => {},
};

const enchance = compose(
  lifecycle({
    async componentDidMount() {
      // const {
      // } = this.props;
    },
  }),
);

export default enchance(YesNoPopup);
