import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLOR_WHITE } from '../../constants/colors';
import { tabletMedia } from '../../constants/styles';

const PopupOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
`;

const PopupWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
`;

const PopupContainer = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`;

const PopupContent = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  width: 100%;
`;

const PopupForm = styled.div`
  width: 100%;
  max-width: ${props => (props.width ? props.width : '960px')};
  background: ${COLOR_WHITE};
  z-index: 2;
  text-align: left;
  z-index: 1044;
  margin: 3% auto;
  position: relative;
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : 'none')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  @media (max-width: ${tabletMedia}) {
    width: 98%;
  }
`;

const Popup = ({ children, closePopup, width, boxShadow, borderRadius }) => (
  <>
    <PopupWrapper>
      <PopupContainer>
        <PopupContent>
          <PopupForm width={width} borderRadius={borderRadius} boxShadow={boxShadow}>
            {children}
          </PopupForm>
          <PopupOverlay onClick={() => closePopup()} />
        </PopupContent>
      </PopupContainer>
    </PopupWrapper>
  </>
);

Popup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  closePopup: PropTypes.func.isRequired,
  width: PropTypes.string,
  boxShadow: PropTypes.string,
  borderRadius: PropTypes.string,
};

Popup.defaultProps = {
  width: '',
  boxShadow: '',
  borderRadius: '',
  children: '',
};

export default Popup;
