import styled from 'styled-components';

export const VerticalFlexContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const tabletMedia = '1310px';

export const tabletSmMedia = '1100px';

export const tabletMdMedia = '960px';

export const mobileMedia = '768px';
