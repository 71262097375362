import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  COLOR_WHITE,
  COLOR_LINK_BLACK,
  COLOR_BLACK_LOGO,
  COLOR_ACTION_BLUE,
} from '../../constants/colors';
import { TitleText } from '../../components/Typography/Title';
import { ParapgraphText } from '../../components/Typography/Paragraph';
import { ErrorText } from '../../components/Inputs/InputField';

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 32px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_WHITE};
  position: relative;
  border-radius: 4px 4px 0 0;
  padding: 0 15px;
  min-height: calc(100vh - 170px);
`;

export const WrapperContainer = styled.div`
  width: 100%;
  max-width: 920px;
  display: flex;
  flex-direction: column;
`;

export const WrrapperBox = styled.div`
  width: 100%;
  max-width: 684px;
  margin: 54px auto;
  display: flex;
  flex-direction: column;
`;

export const TitlePage = styled(TitleText)`
  font-size: 32px;
  color: ${COLOR_LINK_BLACK};
`;

export const Paragraph = styled(ParapgraphText)`
  color: ${COLOR_LINK_BLACK};
  font-weight: 500;
  font-size: 18px;
`;

export const Card = styled.article`
  background-color: #fdfdfd;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 34px 19px;
  margin: 34px 0 0;
`;

export const Field = styled.div`
  margin: 15px 0;
`;

export const Key = styled(Paragraph)`
  font-size: 14px;
  line-height: 18px;
`;

export const Value = styled(Key)``;

export const FlexSpaceBetween = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ButtonWrapper = styled(FlexSpaceBetween)`
  align-items: center;
  margin: 34px -17px;
`;

export const ErrorTextTransformed = styled(ErrorText)`
  margin-left: 20px;
`;

export const ButtonWrapperWithError = styled(ButtonWrapper)`
  width: 100%;
  justify-content: flex-start;
`;

export const AfterCard = styled(FlexSpaceBetween)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 353px;
  width: 100%;
`;

export const AfterCardContainer = styled(FlexSpaceBetween)`
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 34px 0 0;
`;

export const AfterCardHint = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${COLOR_BLACK_LOGO};
  margin: 20px 0 0 37px;
`;

export const BottomCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 12px 0;
`;

export const TextLink = styled(Link)`
  color: ${COLOR_ACTION_BLUE};
`;

export const Text = styled.p`
  font-weight: 500;
  font-family: Lato;
  font-size: 12px;
  line-height: 18px;
  color: #5c6470;
`;

export const CheckBoxContrainer = styled.div`
  margin: 0 0 32px;
`;
