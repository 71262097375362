import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose, withHandlers, withState } from 'recompose';
import * as moment from 'moment';
import storeNames from '../../../../stores/storeNames';
import Popup from '../../../../components/Popup/Popup';
import InputField from '../../../../components/Inputs/InputField';
import Button from '../../../../components/Buttons/Button';
import { hasValue } from '../../../../utils/validators';
import {
  Title,
  Wrapper,
  FormWrapper,
  InputWrapper,
  InputWrapperNotes,
  TopText,
  DateInputWrap,
  DateWrapper,
  ButtonWrapper,
  DateContent,
  TextArea,
} from '../AccountPopupStyled';
import { COLOR_ACTION_BLUE } from '../../../../constants/colors';
import ParagraphError from '../../../../components/Typography/ParagraphError';
import { withPasswordChangeType, withAccounts, withUserData } from '../../../../utils/hocs';
import {
  withInputSetStore,
  withDateFromState,
  withDateToState,
  withMoneyState,
  withNotesState,
  withNameAccount,
} from '../../../../utils/FormHocs';
import InputDate from '../../../../components/Inputs/InputDate';
import { getAccessStatus } from '../../../../helpers/roles';
import { SUPER_ADMIN_ROLE } from '../../../../constants/roles';
import { InputColumn } from '../../../Registration/RegistrationUsers/RegistrationUsersStyled';

const AddAccountPopup = ({
  nameAccountValid,
  nameAccount,
  money,
  moneyValid,
  inputHandlerMoney,
  moneyError,
  nameAccountError,
  inputHandlerNameAccount,
  dateFrom,
  dateTo,
  notes,
  inputHandlerNotes,
  inputSetStore,
  toggleAddAccountPopup,
  formHandler,
  inputHandlerDateTo,
  inputHandlerDateFrom,
  dateValueError,
  userRoles,
}) => {
  const { t } = useTranslation();
  return (
    <Popup closePopup={toggleAddAccountPopup}>
      <Wrapper>
        <Title as="h2">{t('accounts.accountAddition')}</Title>
        <FormWrapper onSubmit={formHandler}>
          <InputColumn>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="1"
                placeholder={t('accounts.name')}
                value={nameAccount}
                name="nameAccount"
                isValid={nameAccountValid}
                onChange={value => {
                  inputSetStore(value, inputHandlerNameAccount, 'nameAccount');
                }}
              />
              <ParagraphError>{t(nameAccountError)}</ParagraphError>
            </InputWrapper>
            <InputWrapper>
              <InputField
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="2"
                placeholder={t('balance.amount')}
                type="text"
                name="money"
                value={money}
                isValid={moneyValid}
                onChange={value => {
                  inputSetStore(value, inputHandlerMoney, 'money');
                }}
                disabled={
                  !getAccessStatus({
                    userRoles,
                    allowedRoles: [SUPER_ADMIN_ROLE],
                  })
                }
              />
              <ParagraphError>{t(moneyError)}</ParagraphError>
            </InputWrapper>
          </InputColumn>
          <InputColumn>
            <InputWrapper>
              <TopText>{t('accounts.validityPeriod')}</TopText>
            </InputWrapper>
            <DateWrapper>
              <DateInputWrap>
                <DateContent>
                  <InputDate
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="4"
                    type="date"
                    name="dateFrom"
                    value={dateFrom}
                    onChange={value => {
                      inputSetStore(value, inputHandlerDateFrom, 'dateFrom');
                    }}
                  />
                </DateContent>
                —
                <DateContent>
                  <InputDate
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="5"
                    type="date"
                    name="dateTo"
                    value={dateTo}
                    onChange={value => {
                      inputSetStore(value, inputHandlerDateTo, 'dateTo');
                    }}
                  />
                </DateContent>
              </DateInputWrap>
              <ParagraphError>{t(dateValueError)}</ParagraphError>
            </DateWrapper>
          </InputColumn>
          <InputWrapperNotes>
            <TextArea
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="3"
              placeholder={t('accounts.note')}
              name="notes"
              value={notes}
              onChange={event => {
                inputSetStore(event, inputHandlerNotes, 'notes');
              }}
            />
          </InputWrapperNotes>
          <ButtonWrapper>
            <Button
              testID="TEST_BUTTON_ADD"
              width="303px"
              type="submit"
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="7"
              sizes={{ margin: '0 20px' }}
            >
              {t('form.add')}
            </Button>
            <Button
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="6"
              testID="TEST_BUTTON_CANCEL"
              width="303px"
              type="submit"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleAddAccountPopup()}
            >
              {t('form.cancel')}
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

AddAccountPopup.propTypes = {
  toggleAddAccountPopup: PropTypes.func.isRequired,
  inputSetStore: PropTypes.func,
  formHandler: PropTypes.func,
  nameAccount: PropTypes.string,
  nameAccountError: PropTypes.string,
  money: PropTypes.string,
  moneyError: PropTypes.string,
  nameAccountValid: PropTypes.bool,
  moneyValid: PropTypes.bool,
  inputHandlerMoney: PropTypes.func,
  inputHandlerNameAccount: PropTypes.func,
  notes: PropTypes.string,
  inputHandlerNotes: PropTypes.func,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  inputHandlerDateFrom: PropTypes.func,
  dateValueError: PropTypes.string,
  inputHandlerDateTo: PropTypes.func,
  userRoles: PropTypes.array,
};

AddAccountPopup.defaultProps = {
  dateTo: '',
  nameAccount: '',
  money: '0',
  moneyError: '',
  nameAccountError: '',
  notes: '',
  dateValueError: '',
  dateFrom: '',
  nameAccountValid: false,
  moneyValid: false,
  userRoles: [],
  inputSetStore: () => {},
  inputHandlerNameAccount: () => {},
  formHandler: () => {},
  inputHandlerNotes: () => {},
  inputHandlerMoney: () => {},
  inputHandlerDateTo: () => {},
  inputHandlerDateFrom: () => {},
};

const enchance = compose(
  inject(storeNames.CustomerStore, storeNames.AccountStore),
  observer,
  withTranslation(),
  withInputSetStore,
  withDateFromState,
  withDateToState,
  withMoneyState,
  withNotesState,
  withNameAccount,
  withPasswordChangeType,
  withAccounts,
  withUserData,
  withState('dateValueError', 'setDateValueError', ''),
  withHandlers(({ dateValueError }) => ({
    formHandler: ({
      nameAccount,
      money,
      moneyValid,
      notes,
      setNameAccountError,
      AccountStore,
      toggleAddAccountPopup,
      setNameAccountValid,
      dateFrom,
      setDateValueError,
      dateTo,
      offset,
      t,
    }) => async e => {
      // const { t } = useTranslation();
      e.preventDefault();

      const dateFromValue = dateFrom && moment(dateFrom).format('YYYY-MM-DD');
      const dateToValue = dateTo && moment(dateTo).format('YYYY-MM-DD');

      if ((dateFrom && !dateTo) || (!dateFrom && dateTo)) {
        setDateValueError(t('accounts.enterBothDates'));
        return;
      }

      const dateFromSec = new Date(dateFrom).getTime();
      const dateToSec = new Date(dateTo).getTime();

      if (dateFromSec > dateToSec) {
        setDateValueError(t('accounts.datesOrderError'));
        return;
      }
      setDateValueError('');

      if (!nameAccount) {
        setNameAccountError(t('accounts.enterAccountName'));
        setNameAccountValid(false);
      } else {
        setNameAccountValid(true);
        setNameAccountError('');
      }

      if (!dateValueError && hasValue(nameAccount) && moneyValid) {
        const newAccount = {
          full_name: nameAccount || '',
          notes,
        };

        if (Number(money)) {
          newAccount.money = Number(money);
        }

        if (dateFromValue) {
          newAccount.from_time = dateFromValue;
        }
        if (dateToValue) {
          newAccount.to_time = dateToValue;
        }
        await AccountStore.createNewCustomerAccount(newAccount);
        await AccountStore.fetchScrollMyAccounts({ qnt: offset, offset: 0 });
        toggleAddAccountPopup();
      }
    },
  })),
);

export default enchance(AddAccountPopup);
