import React from 'react';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import {
  Wrapper,
  CheckBoxWrapper,
  WelcomeForm,
  ButtonWrapper,
} from './TopCustomersNavigationStyled';
import Input from '../../../components/Inputs/Input';
import Button from '../../../components/Buttons/Button';
import { withHandlePressEnter } from '../../../utils/hocs';

const TopCustomersNavigation = ({ inputData, setInputData, handlePressEnter, formHandler }) => (
  <Wrapper>
    <CheckBoxWrapper />
    <WelcomeForm onSubmit={formHandler}>
      <Input
        placeholder="Поиск по ИНН, ОГРН, наименованию и E-mail"
        value={inputData}
        name="inputData"
        onChange={setInputData}
        onKeyDown={handlePressEnter}
      />
      <ButtonWrapper>
        <Button minWidth="150px" testID="TEST_ID_CUSTOMERS_SEARCH">
          Найти
        </Button>
      </ButtonWrapper>
    </WelcomeForm>
  </Wrapper>
);

TopCustomersNavigation.propTypes = {
  inputData: PropTypes.string,
  handlePressEnter: PropTypes.func,
  setInputData: PropTypes.func,
  formHandler: PropTypes.func,
};

TopCustomersNavigation.defaultProps = {
  inputData: '',
  setInputData: () => {},
  handlePressEnter: () => {},
  formHandler: () => {},
};

const enhancer = compose(
  withHandlers(() => ({
    formHandler: () => e => {
      if (e) e.preventDefault();
    },
  })),
  withHandlePressEnter,
);

export default enhancer(TopCustomersNavigation);
