import { observable, decorate } from 'mobx';
import api from '../api';
import logger from '../logger';

export default class RecoveryStore {
  constructor(context) {
    this.context = context;
  }

  errors = null;

  errorCode = null;

  isBusy = false;

  /**
   * @param {Object} obj
   * @param {String} obj.email - Profile URL
   * @param {String} obj.returnUrl - Return URL
   */
  prepareRecovery = async ({ email = null, returnUrl }, fn = () => {}) => {
    this.errorCode = null;
    this.isBusy = true;
    try {
      const response = await api.auth.preparePasswordReset({
        email,
        return_url: returnUrl,
      });
      logger.info(response);
      if (response.message || response.code) {
        this.errorCode = response.code;
        throw new Error(response.code);
      } else {
        fn();
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }

    return this.errorCode;
  };

  /**
   * Reset and set new password
   * @param {Object} param0
   * @param {String} param0.passwordResetId - number from url param
   * @param {passwordResetCode} param0.passwordResetCode - token for reset code
   * @param {newPassword} param0.newPassword - new user password
   */
  resetAndSetNewPassword = async (
    { passwordResetId, passwordResetCode, newPassword },
    fn = () => {},
  ) => {
    this.isBusy = true;
    try {
      const response = await api.auth.resetPassword({
        passwordResetId,
        passwordResetCode,
        newPassword,
      });
      logger.info(response);
      if (response.code || response.message) {
        this.errorCode = response.code;
        throw new Error(response.code);
      } else {
        fn();
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }

    return this.errorCode;
  };
}

decorate(RecoveryStore, {
  errors: observable,
  errorCode: observable,
  isBusy: observable,
});
