import API from './base';
import { getUrlToApi } from '../constants/api';
// import { createQueryString } from '../helpers/url';

export default class DisclosureApi extends API {
  /* createUser(data, token = null) {
    return this.post(getUrlToLKApi('auth/registration'), data, token, false);
  }

  getCount(token = null) {
    return this.get(getUrlToLKApi(`customers/count`), token, false);
  } */

  getQuestionaries(token = null) {
    return this.get(getUrlToApi(`disclosure/forms`), token, false);
  }

  getQuestionary(formId, token = null, getPublished = false) {
    return this.get(
      getUrlToApi(`disclosure/forms/${formId}${getPublished ? '?public=1' : ''}`),
      token,
      false,
    );
  }

  deleteQuestionary(formId, token = null) {
    return this.delete(getUrlToApi(`disclosure/forms/${formId}`), {}, token, false);
  }

  sendData(formId, data, files, token = null, progressCallback = () => {}, method = 'POST') {
    const formData = new FormData();
    if (data !== null) formData.append('data', JSON.stringify(data));
    // eslint-disable-next-line
    for (const file of files) {
      formData.append(/* 'file' +*/ file.id, file, file.name);
    }
    return this.sendMultipart(
      method,
      getUrlToApi(`disclosure/forms/${formId}`),
      formData,
      token,
      false,
      progressCallback,
    );
  }

  sendSignFiles(formId, data, files, token = null, progressCallback = () => {}) {
    return this.sendData(formId, data, files, token, progressCallback, 'PATCH');
  }

  downloadFiles(fileId, token = null) {
    return this.get(getUrlToApi(`disclosure/file/data/${fileId}`), token, false, true);
  }

  getOrganization(innOrOgrn, token = null) {
    return this.get(getUrlToApi(`static/v1/entity/${innOrOgrn}`), token, false);
  }

  fetchAccessList(formId, token = null) {
    // if (formId) return this.get(getUrlToApi(`disclosure/access/form/${formId}`), token, false);
    return this.get(getUrlToApi(`disclosure/access/forms`), token, false);
  }

  saveAccess(ogrn, inn, accessArray, token = null) {
    return this.put(
      getUrlToApi(`disclosure/access/customers/${ogrn}:${inn}`),
      accessArray,
      token,
      false,
    );
  }

  deleteAccess(ogrn, inn, token = null) {
    return this.put(getUrlToApi(`disclosure/access/customers/${ogrn}:${inn}`), [], token, false);
  }

  fetchAccessRequestCount(token = null) {
    return this.get(getUrlToApi(`disclosure/access/requests`), token, false);
  }
}
