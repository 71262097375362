import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withHandlers } from 'recompose';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useMedia } from 'use-media';
import { hasValue } from '../../../utils/validators';
import InputPhone from '../../../components/Inputs/InputPhone';
import { Content, InputColumn, InputWrapper, ImgWrap, Img, Button } from './ProfileUsersStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import InputField from '../../../components/Inputs/InputField';
import { withUserData } from '../../../utils/hocs';
import { getAccessStatus } from '../../../helpers/roles';
import { withInitializedUserDataInputs } from '../../../utils/initFormHocs';
import Typography from '../../../components/Typography/Paragraph';
import { SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE } from '../../../constants/roles';
import { ICON_EMAIL_VERIFID, ICON_EMAIL_NOVERIFID } from '../../../constants/icons';
import { STATUS_CONFIRMATION, getUrl } from '../../../constants/statusPageUrls';
import { getUrlObject } from '../../../helpers/url';
// import PersonalAccounts from './PersonalAccounts/PersonalAccounts';

const IndividualEntrepreneur = ({
  bik,
  bank_name,
  rs,
  ks,
  bikValid,
  bankValid,
  rsValid,
  ksValid,
  email,
  ogrnip,
  inputHandlerEmail,
  emailError,
  surname,
  surnameValid,
  patronymicValid,
  emailValid,
  phoneValid,
  nameValid,
  innValid,
  actualRegistrationAddressValid,
  surnameError,
  bikError,
  bankError,
  rsError,
  ksError,
  phone,
  inputHandlerPhone,
  phoneError,
  inputHandlerSurname,
  ogrnipValid,
  patronymic,
  patronymicError,
  ogrnipError,
  inputHandlerOgrnip,
  inputHandlerPatronymic,
  customerEmailError,
  inputHandlerCustomerEmail,
  customerEmailValid,
  customerEmail,
  inputSetCustomerStore,
  inn,
  innError,
  inputHandlerInn,
  name,
  nameError,
  inputSetStore,
  inputHandlerBik,
  inputHandlerBank,
  inputHandlerRs,
  inputHandlerKs,
  inputHandlerName,
  actualRegistrationAddress,
  actualRegistrationAddressError,
  inputHandlerActualRegistrationAddress,
  handlerConsumerConfirmation,
  userRoles,
  userData,
  handlerConfirmation,
  login,
  loginValid,
  inputHandlerLogin,
  loginError,
  // deleteAccountHandler,
  // addAccountHandler,
}) => {
  const { t } = useTranslation();
  const mobMedia = useMedia({ maxWidth: 768 });
  const isDisabled = !getAccessStatus({
    userRoles,
    allowedRoles: [SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE],
  });
  const isDisabledInnOgrn = !getAccessStatus({
    userRoles,
    allowedRoles: [SUPER_ADMIN_ROLE],
  });
  return (
    <>
      {!mobMedia && (
        <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
          {t('profile.personalData')}
        </Typography>
      )}
      <Content>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="1"
              placeholder={t('recovery.emailAddress')}
              name="email"
              disabled
              value={email}
              isValid={emailValid}
              onChange={value => {
                inputSetStore(value, inputHandlerEmail, 'email');
              }}
            />
            <ImgWrap>
              <Img src={userData.email_confirmed ? ICON_EMAIL_VERIFID : ICON_EMAIL_NOVERIFID} />
            </ImgWrap>
            <ParagraphError>{t(emailError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            {!userData.email_confirmed && (
              <Button
                type="submit"
                data-test-id="TEST_BUTTON_CONFORMATION"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="2"
                onClick={e => handlerConfirmation(e)}
              >
                {t('profile.confirmEmail')}
              </Button>
            )}
          </InputWrapper>
        </InputColumn>
        <InputColumn>
          <InputWrapper>
            <InputField
              placeholder={t('login.login')}
              name="login"
              disabled
              value={login}
              isValid={loginValid}
              onChange={value => {
                inputSetStore(value, inputHandlerLogin, 'login');
              }}
            />
            <ParagraphError>{t(loginError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
      </Content>
      {!mobMedia && (
        <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
          {t('profile.ieData')}
        </Typography>
      )}
      <Content>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="3"
              placeholder={t('reg.ogrnip')}
              name="ogrn"
              value={ogrnip}
              isValid={ogrnipValid}
              disabled={isDisabledInnOgrn}
              onChange={value => {
                if (value.length < 16) {
                  inputSetStore(value, inputHandlerOgrnip, 'ogrn');
                }
              }}
            />
            <ParagraphError>{t(ogrnipError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="4"
              placeholder={t('reg.inn')}
              name="inn"
              value={inn}
              isValid={innValid}
              disabled={isDisabledInnOgrn}
              onChange={value => {
                if (value.length < 13) {
                  inputSetStore(value, inputHandlerInn, 'inn');
                }
              }}
            />
            <ParagraphError>{t(innError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="5"
              placeholder={t('reg.surname')}
              value={surname}
              name="surname"
              disabled={isDisabled}
              isValid={surnameValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerSurname, 'contact_person_sname');
              }}
            />
            <ParagraphError>{t(surnameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="6"
              placeholder={t('reg.name')}
              name="name"
              disabled={isDisabled}
              value={name}
              isValid={nameValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerName, 'contact_person_fname');
              }}
            />
            <ParagraphError>{t(nameError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="7"
              placeholder={t('reg.patronymic')}
              name="patronymic"
              value={patronymic}
              disabled={isDisabled}
              isValid={patronymicValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerPatronymic, 'contact_person_mname');
              }}
            />
            <ParagraphError>{t(patronymicError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="8"
              placeholder={t('recovery.emailAddress')}
              name="customerEmail"
              disabled={isDisabled}
              value={customerEmail}
              isValid={customerEmailValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerCustomerEmail, 'email');
              }}
            />
            <ImgWrap>
              <Img
                src={userData.customer.email_confirmed ? ICON_EMAIL_VERIFID : ICON_EMAIL_NOVERIFID}
              />
            </ImgWrap>
            <ParagraphError>{t(customerEmailError)}</ParagraphError>
          </InputWrapper>
          {!isDisabled && (
            <InputWrapper>
              {!userData.customer.email_confirmed && (
                <Button
                  type="submit"
                  data-test-id="TEST_BUTTON_CONFORMATION"
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex="9"
                  onClick={e => handlerConsumerConfirmation(e)}
                >
                  {t('profile.confirmEmail')}
                </Button>
              )}
            </InputWrapper>
          )}
          <InputWrapper>
            <InputPhone
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="10"
              placeholder={t('reg.phone')}
              name="phone"
              disabled={isDisabled}
              value={phone}
              isValid={phoneValid}
              onChange={value => {
                inputSetCustomerStore(value, inputHandlerPhone, 'phone');
              }}
            />
            <ParagraphError>{t(phoneError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="12"
              placeholder={t('reg.bik')}
              name="bik"
              disabled={isDisabled}
              value={bik}
              isValid={bikValid}
              onChange={value => {
                if (value.length < 10) {
                  inputSetStore(value, inputHandlerBik, 'bik');
                }
              }}
            />
            <ParagraphError>{t(bikError.message)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="13"
              placeholder={t('reg.bankName')}
              name="bank_name"
              disabled={isDisabled}
              value={bank_name}
              isValid={bankValid}
              onChange={value => {
                inputSetStore(value, inputHandlerBank, 'bank_name');
              }}
            />
            <ParagraphError>{t(bankError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="14"
              placeholder={t('reg.bankAccount')}
              name="rs"
              value={rs}
              disabled={isDisabled}
              isValid={rsValid}
              onChange={value => {
                if (value.length < 21) {
                  inputSetStore(value, inputHandlerRs, 'rs');
                }
              }}
            />
            <ParagraphError>{t(rsError)}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="15"
              placeholder={t('reg.correspondentAccount')}
              name="ks"
              disabled={isDisabled}
              value={ks}
              isValid={ksValid}
              onChange={value => {
                if (value.length < 21) {
                  inputSetStore(value, inputHandlerKs, 'ks');
                }
              }}
            />
            <ParagraphError>{t(ksError)}</ParagraphError>
          </InputWrapper>
        </InputColumn>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="11"
            placeholder={t('reg.actualRegAddress')}
            name="post_address"
            disabled={isDisabled}
            isValid={actualRegistrationAddressValid}
            value={actualRegistrationAddress}
            onChange={value => {
              inputSetCustomerStore(value, inputHandlerActualRegistrationAddress, 'post_address');
            }}
          />
          <ParagraphError>{t(actualRegistrationAddressError)}</ParagraphError>
        </InputWrapper>
        {/* <PersonalAccounts
      deleteAccountHandler={deleteAccountHandler}
      addAccountHandler={addAccountHandler}
    /> */}
      </Content>
    </>
  );
};

IndividualEntrepreneur.propTypes = {
  bik: PropTypes.string,
  login: PropTypes.string,
  bank_name: PropTypes.string,
  rs: PropTypes.string,
  ks: PropTypes.string,
  surname: PropTypes.string,
  actualRegistrationAddress: PropTypes.string,
  name: PropTypes.string,
  surnameError: PropTypes.string,
  email: PropTypes.string,
  patronymic: PropTypes.string,
  ogrnip: PropTypes.string,
  inn: PropTypes.string,
  phone: PropTypes.string,
  phoneError: PropTypes.string,
  customerEmailError: PropTypes.string,
  inputHandlerCustomerEmail: PropTypes.func,
  customerEmailValid: PropTypes.bool,
  customerEmail: PropTypes.string,
  inputSetCustomerStore: PropTypes.func,
  nameError: PropTypes.string,
  actualRegistrationAddressError: PropTypes.string,
  emailError: PropTypes.string,
  bikError: PropTypes.object,
  bankError: PropTypes.string,
  loginError: PropTypes.string,
  rsError: PropTypes.string,
  ksError: PropTypes.string,
  patronymicError: PropTypes.string,
  innError: PropTypes.object,
  userData: PropTypes.object,
  userRoles: PropTypes.array,
  ogrnipError: PropTypes.object,
  ogrnipValid: PropTypes.bool,
  loginValid: PropTypes.bool,
  surnameValid: PropTypes.bool,
  patronymicValid: PropTypes.bool,
  emailValid: PropTypes.bool,
  bikValid: PropTypes.bool,
  bankValid: PropTypes.bool,
  rsValid: PropTypes.bool,
  ksValid: PropTypes.bool,
  phoneValid: PropTypes.bool,
  innValid: PropTypes.bool,
  actualRegistrationAddressValid: PropTypes.bool,
  nameValid: PropTypes.bool,
  inputHandlerEmail: PropTypes.func,
  inputHandlerOgrnip: PropTypes.func,
  inputSetStore: PropTypes.func,
  inputHandlerBik: PropTypes.func,
  inputHandlerLogin: PropTypes.func,
  inputHandlerBank: PropTypes.func,
  inputHandlerRs: PropTypes.func,
  inputHandlerKs: PropTypes.func,
  handlerConfirmation: PropTypes.func,
  inputHandlerInn: PropTypes.func,
  inputHandlerPhone: PropTypes.func,
  inputHandlerSurname: PropTypes.func,
  inputHandlerPatronymic: PropTypes.func,
  inputHandlerActualRegistrationAddress: PropTypes.func,
  inputHandlerName: PropTypes.func,
  handlerConsumerConfirmation: PropTypes.func,
  // deleteAccountHandler: PropTypes.func,
  // addAccountHandler: PropTypes.func,
};

IndividualEntrepreneur.defaultProps = {
  bik: '',
  bank_name: '',
  rs: '',
  login: '',
  ks: '',
  surname: '',
  ogrnip: '',
  inn: '',
  customerEmailError: '',
  inputHandlerCustomerEmail: () => {},
  customerEmailValid: false,
  customerEmail: '',
  name: '',
  actualRegistrationAddress: '',
  patronymic: '',
  email: '',
  phone: '',
  surnameError: '',
  nameError: '',
  actualRegistrationAddressError: '',
  emailError: '',
  loginError: '',
  patronymicError: '',
  phoneError: '',
  innError: undefined,
  ogrnipError: undefined,
  bikError: undefined,
  bankError: '',
  rsError: '',
  ksError: '',
  userRoles: [],
  userData: {},
  ogrnipValid: false,
  surnameValid: false,
  patronymicValid: false,
  emailValid: false,
  loginValid: false,
  actualRegistrationAddressValid: false,
  phoneValid: false,
  innValid: false,
  bikValid: false,
  bankValid: false,
  rsValid: false,
  ksValid: false,
  nameValid: false,
  inputHandlerSurname: () => {},
  inputHandlerActualRegistrationAddress: () => {},
  inputHandlerEmail: () => {},
  inputHandlerInn: () => {},
  inputSetStore: () => {},
  inputHandlerOgrnip: () => {},
  inputHandlerPatronymic: () => {},
  inputHandlerPhone: () => {},
  inputSetCustomerStore: () => {},
  inputHandlerBik: () => {},
  inputHandlerBank: () => {},
  inputHandlerRs: () => {},
  inputHandlerLogin: () => {},
  handlerConfirmation: () => {},
  inputHandlerKs: () => {},
  inputHandlerName: () => {},
  handlerConsumerConfirmation: () => {},
  // deleteAccountHandler: () => {},
  // addAccountHandler: () => {},
};

const enhance = compose(
  withUserData,
  withInitializedUserDataInputs,
  withHandlers(({ ProfileStore }) => ({
    handlerConfirmation: () => async e => {
      e.preventDefault();
      const collbackUrl = getUrlObject();
      const data = {
        return_url: `${getUrl()}${STATUS_CONFIRMATION(collbackUrl.href, 'profile')}&entity=user`,
      };
      ProfileStore.confirmationEmail(data, ProfileStore.userData.id);
      localStorage.setItem('USER_ID', ProfileStore.userData.id);
    },
    handlerConsumerConfirmation: () => async e => {
      e.preventDefault();
      const collbackUrl = getUrlObject();
      const data = {
        return_url: `${getUrl()}${STATUS_CONFIRMATION(
          collbackUrl.href,
          'profile',
        )}&entity=customer`,
      };
      ProfileStore.confirmationCustomerEmail(data);
      localStorage.setItem('CUSTOMER_ID', ProfileStore.userData.customer_id);
    },
  })),
  lifecycle({
    componentDidUpdate() {
      const {
        ogrnipValid,
        innValid,
        phoneValid,
        emailValid,
        bikValid,
        customerEmailValid,
        rsValid,
        ksValid,
        login,
        setIsValidIndividualEntrepreneur,
      } = this.props;
      if (
        ogrnipValid &&
        innValid &&
        phoneValid &&
        bikValid &&
        rsValid &&
        ksValid &&
        emailValid &&
        customerEmailValid &&
        hasValue(login)
      ) {
        setIsValidIndividualEntrepreneur(true);
      } else {
        setIsValidIndividualEntrepreneur(false);
      }
    },
  }),
);

export default enhance(IndividualEntrepreneur);
